import { memo, useEffect } from 'react';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { useIsFirstRender } from '@root/hooks';
import { IconBtn } from '@root/ui';

import {
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const VirtualizedTable = memo(
  ({
    tabType,
    children,
    tableConf,
    handleResetActiveRow,
    checkIsInclude,
    isTopBorder,
    headRowStyles,
    handleSorting,
    sortingType,
    sortDuration,
  }) => {
    const { color } = useTheme();
    const { logisticsTab } = useParams();

    const isFirstRender = useIsFirstRender();

    useEffect(() => {
      if (isFirstRender) return;
      handleResetActiveRow && handleResetActiveRow();
    }, [logisticsTab]);

    return (
      <TableContainer
        sx={{
          position: 'relative',
        }}
      >
        <Table
          size="small"
          aria-label="a dense table"
          sx={{
            position: 'relative',
          }}
        >
          <TableHead>
            <TableRow
              sx={{
                minHeight: '34px',
                borderBottom: `1px solid ${color.line}`,
                userSelect: 'auto',
                boxShadow: 'none',
                th: {
                  borderBottom: 'none',
                },
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
                borderTop: isTopBorder && `1px solid ${color.line}`,
                ...headRowStyles,
              }}
            >
              {tableConf.map(
                el =>
                  (checkIsInclude ? el.type.includes(tabType) : true) && (
                    <TableCell
                      sx={{
                        borderBottom: 'none',
                        ...el.style,
                        p: 0,
                      }}
                      key={el.slug}
                    >
                      <OpacityContainer
                        opacity={sortingType === el.slug ? 1 : 0}
                        style={{ display: 'inline-flex' }}
                      >
                        <Typography
                          variant="bodyBoldSmall"
                          component="p"
                          color={color.gray_60}
                        >
                          {el.label}
                        </Typography>
                        {el.sort && (
                          <IconBtn
                            href={`${Sprite}#icon-sorting`}
                            onClick={() =>
                              handleSorting && handleSorting(el.slug)
                            }
                            size={16}
                            sx={{
                              marginLeft: '6px',
                            }}
                            rotateSvgX={
                              sortDuration === el.slug ? '3.142rad' : false
                            }
                          />
                        )}
                      </OpacityContainer>
                    </TableCell>
                  ),
              )}
            </TableRow>
          </TableHead>
          {children}
        </Table>
      </TableContainer>
    );
  },
);

const OpacityContainer = styled.div`
  &:hover button {
    opacity: 1;
  }

  & button {
    opacity: ${({ opacity }) => opacity};
  }
`;

VirtualizedTable.propTypes = {
  tabType: PropTypes.string,
  tableConf: PropTypes.array.isRequired,
  handleResetActiveRow: PropTypes.func,
  checkIsInclude: PropTypes.bool.isRequired,
  isTopBorder: PropTypes.bool,
  handleSorting: PropTypes.func,
  sortingType: PropTypes.string,
  sortDuration: PropTypes.string,
};
