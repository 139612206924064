import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { SearchInput } from '@root/components';
import { useDebounce, useIsFirstRender, useQueryParams } from '@root/hooks';
import { BasicSelect, OutlineButtonBase, ToggleBtn } from '@root/ui';

import adminOperation from '@redux/admin/admin-operation';

export const AdminSearch = ({ accordionActive }) => {
  const [searchValue, setSearchValue] = useState('');

  const dispatch = useDispatch();
  const { adminTab } = useParams();

  const { getQueryObj, replaceQueryParam, setSearchParams } = useQueryParams();
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const debounceSearchValue = useDebounce(searchValue, 400);
  const { page, per_page } = getQueryObj();

  const isFirstRender = useIsFirstRender();

  useEffect(() => {
    if (!isFirstRender) {
      fetchAdminData(debounceSearchValue);
    }
  }, [debounceSearchValue]);

  useEffect(() => {
    if (isFirstRender) return;
    setSearchValue('');
  }, [adminTab]);

  const onChange = name => value => {
    setSearchValue(value);
  };

  const fetchAdminData = value => {
    if (!!value) {
      replaceQueryParam('q', searchValue);
    } else {
      const searchParams = getQueryObj();
      delete searchParams.q;

      setSearchParams(searchParams);
    }

    switch (adminTab) {
      case 'businesses': {
        !!value
          ? dispatch(
              adminOperation.getBusinessesSearch({
                search: searchValue,
              }),
            )
          : dispatch(
              adminOperation.getBusinessData({
                page: page || 1,
                per_page: per_page || 10,
              }),
            );
        break;
      }
      case 'counterparts': {
        !!value
          ? dispatch(
              adminOperation.getCounterpartsSearch({
                search: searchValue,
              }),
            )
          : dispatch(
              adminOperation.getCounterpartyData({
                page: page || 1,
                per_page: per_page || 10,
              }),
            );
        break;
      }
      case 'people': {
        !!value
          ? dispatch(
              adminOperation.getPeopleSearch({
                search: searchValue,
              }),
            )
          : dispatch(
              adminOperation.getPeopleData({
                page: page || 1,
                per_page: per_page || 10,
              }),
            );
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleClearSearch = () => {
    setSearchValue('');
  };

  return (
    <Component>
      <Form>
        <Label>
          <SearchInput
            onChange={onChange}
            placeholder={t('admin:search')}
            searchValue={searchValue}
            handleClearSearch={handleClearSearch}
          />
        </Label>
        <AdminButtons>
          {adminTab === 'people' && (
            <BasicSelect
              sx={{
                height: '36px',
                p: 0,
                width: accordionActive ? '196px' : '300px',
              }}
              height={'36px !important'}
              data={[{ id: 1, name: 'Виберіть бізнес ' }]}
              name={t('admin:business_placeholder')}
            />
          )}
          {adminTab === 'people' && (
            <BasicSelect
              sx={{
                height: '36px',
                p: 0,
                width: accordionActive ? '196px' : '300px',
              }}
              height={'36px !important'}
              data={[{ id: 1, name: 'Виберіть контрагента' }]}
              name={t('admin:counterparty_placeholder')}
            />
          )}
          {adminTab === 'counterparts' && (
            <BasicSelect
              sx={{ height: '36px', p: 0, width: '300px' }}
              height={'36px !important'}
              data={[{ id: 1, name: 'Виберіть бізнес ' }]}
              name={t('admin:business_placeholder')}
            />
          )}

          <OutlineButtonBase
            sx={{
              height: '36px',
              width: '100px',
              alignItems: 'center',
              gap: ' 4px',
            }}
            onClick={() => {}}
          >
            <svg height={16} width={16}>
              <use href={`${Sprite}#icon-appearance`}></use>
            </svg>
            {t('admin:appearance')}
          </OutlineButtonBase>
          <ToggleBtn
            size={36}
            onClick={() => {}}
            href={`${Sprite}#icon-archive`}
            tooltipText={t('common:tooltip.archive')}
          />
          <ToggleBtn
            size={36}
            onClick={() => {}}
            href={`${Sprite}#icon-filter`}
          />
        </AdminButtons>
      </Form>
    </Component>
  );
};

const AdminButtons = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
`;

const Component = styled.div`
  position: relative;
`;

const Form = styled.form`
  padding: 21px 20px;
  display: flex;
  align-items: center;
`;

const Svg = styled.svg``;

const Label = styled.label`
  display: block;
  width: 100%;
  margin-right: 16px;
  position: relative;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 0;
  right: 16px;
  transform: translate(0, 50%);

  display: flex;
`;

AdminSearch.propTypes = {
  accordionActive: PropTypes.bool,
};
