import { createSelector } from '@reduxjs/toolkit';

const getLoading = state => state.category.loading;
const getCategory = state => state.category.category;
const getFilters = state => state.category.filters;
const getStock = state => state.category.stock;
const getCategoryListToEdit = state => state.category.categoryListToEdit;
const getManageFilters = state => state.category.manageFiltersList;
const getFiltersMeta = state => state.category.meta;
const getManageWarehouses = state => state.category.manageWarehousesList;
const getSearchValue = state => state.category.searchValue;
const getManageWarehouseEditData = state =>
  state.category.manageWarehouseEditData;
const getWarehouseId = state => state.category.warehouseId;
const getListWarehouses = state => state.category.listWarehouses;

const filterWarehouse = createSelector(
  [getManageWarehouses, getSearchValue],
  (items, searchVal) => {
    const value = searchVal.toLowerCase();
    return items.filter(item => {
      return (
        item.name?.toLowerCase().includes(value) ||
        item.city?.toLowerCase().includes(value) ||
        item.public_name?.toLowerCase().includes(value) ||
        item.guid?.toLowerCase().includes(value)
      );
    });
  },
);

const categorySelectors = {
  getCategory,
  getFilters,
  getCategoryListToEdit,
  getManageFilters,
  getFiltersMeta,
  getManageWarehouses,
  getManageWarehouseEditData,
  getSearchValue,
  getLoading,
  filterWarehouse,
  getStock,
  getListWarehouses,
  getWarehouseId,
};
export default categorySelectors;
