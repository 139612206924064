import React from 'react';

import PropTypes from 'prop-types';

import { Mark } from '@root/assets/svg';
import { ColorlibStepIconRoot } from '@root/styles';
import { StepperBase } from '@root/ui';

import { Box, Typography, useTheme } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&`]: {
    marginLeft: '9px',
    height: '20px',
    position: 'relative',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },

  [`& .${stepConnectorClasses.line}`]: {
    top: '-17px',
    position: 'absolute',
    height: '35px',
    borderColor: theme.color.line,
  },
}));


function ColorlibStepIcon(props) {
  const { active, completed, className } = props;
  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={theme => ({
        position: 'relative',
        color: theme.color.gray_60,
        width: 18,
        height: 18,
        '&:before': {
          display: 'block',
          position: 'absolute',
          width: '6px',
          height: '6px',
          content: '""',
          borderRadius: '50%',
          background: !active && !completed && theme.color.white,
        },
      })}
    >
      {(completed || active) && <img src={Mark} alt="Mark" width="12px" />}
    </ColorlibStepIconRoot>
  );
}

export const StepperWithSpace = ({ steps, activeStep = 1 }) => {
  const { color } = useTheme();

  return (
    <Box sx={{ maxWidth: 400 }}>
      <StepperBase
        conf={steps}
        step={activeStep}
        defection={'vertical'}
        text={(label, index) => (
          <Typography
            variant={'bodyMedium'}
            color={color.gray_60}
            component={'p'}
          >
            {label}
          </Typography>
        )}
        sxLabel={index => {
          return {
            alignItems: 'stretch',
            padding: 0,
            '& .Mui-active, & .Mui-completed': {
              fontWeight: 400,
            },
          };
        }}
        connector={<QontoConnector />}
        iconComponent={ColorlibStepIcon}
        optional={description => (
          <Typography variant={'bodyExSmall'} color={color.gray_60}>
            {' '}
            {description}
          </Typography>
        )}
      />
    </Box>
  );
};

StepperWithSpace.propTypes = {
  steps: PropTypes.array.isRequired,
  activeStep: PropTypes.number.isRequired,
};
