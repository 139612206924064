import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const LabelDocument = ({ number, seller, buyer }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['accounting_documents'], {
    useSuspense: false,
  });
  return (
    <LabelDocumentWrapper>
      <Typography variant="bodyBoldSmall" color={color.gray_80} component="p">
        {t('accounting_documents:bill_to_pay')} № {number}{' '}
      </Typography>
      <Typography variant="bodyBoldSmall" color={color.gray_80} component="p">
        {t('accounting_documents:seller')}{' '}
        <Typography variant="bodySmall">{seller}</Typography>
      </Typography>
      <Typography variant="bodyBoldSmall" color={color.gray_80} component="p">
        {t('accounting_documents:buyer')}{' '}
        <Typography variant="bodySmall">{buyer}</Typography>
      </Typography>
    </LabelDocumentWrapper>
  );
};

const LabelDocumentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

LabelDocument.propTypes = {
  number: PropTypes.string,
  seller: PropTypes.string,
  buyer: PropTypes.string,
};
