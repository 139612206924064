import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { NoDeliveryDetailsImage } from '@root/assets/image';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const NoDeliveryDetails = () => {
  const { color } = useTheme();
  const { t } = useTranslation('logistic', { useSuspense: false });
  return (
    <NoDeliveryDetailsWrapper>
      <Image src={NoDeliveryDetailsImage} />
      <Typography
        component="div"
        variant="bodySmall"
        color={color.gray_80}
        marginTop="40px"
      >
        {t('no_content')}
      </Typography>
    </NoDeliveryDetailsWrapper>
  );
};

NoDeliveryDetails.propTypes = {};

const NoDeliveryDetailsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const Image = styled.img``;
