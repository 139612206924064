import { useMemo } from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Dagger, GreenChecked } from '@root/assets/svg';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const PasswordLights = ({ status, text }) => {
  const { color } = useTheme();

  return useMemo(() => {
    return (
      <Card key={text} data-test-item="PasswordLightItem">
        <img src={status ? GreenChecked : Dagger} alt="Status" />
        <Typography
          sx={{ ml: '8px', color: color.gray_80 }}
          variant={'bodyLarge'}
          component={'p'}
        >
          {text}
        </Typography>
      </Card>
    );
  }, [status, text]);
};

const Card = styled.div`
  display: flex;
`;

PasswordLights.propTypes = {
  status: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired,
};
