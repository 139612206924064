export const MutualSettlementsConf = t => [
  {
    label: t('mutual_settlements:conf.date'),
    slug: 'date',
    style: {
      minWidth: '120px',
      maxWidth: '100%',
    },
  },
  {
    label: '#',
    slug: 'number',
    style: { width: '100%', ml: '20px', minWidth: '60px', maxWidth: '80px' },
  },
  {
    label: t('mutual_settlements:conf.document_type'),
    slug: 'document_type',
    style: { width: '100%', ml: '20px', minWidth: '170px', maxWidth: '250px' },
  },
  {
    label: t('mutual_settlements:conf.expenses'),
    slug: 'expenses',
    style: { width: '100%', ml: '20px', minWidth: '120px', maxWidth: '150px' },
  },
  {
    label: t('mutual_settlements:conf.income'),
    slug: 'income',
    style: { width: '100%', ml: '20px', maxWidth: '200px' },
  },
];

export const MutualSettlementsDetailsConf = (t, data) => [
  {
    label: '#',
    slug: 'number',
    style: {
      borderBottom: 'none',
      maxWidth: '30px',
      width: '100%',
      padding: 0,
    },
  },
  {
    label: t('mutual_settlements:details_conf.name'),
    slug: 'name',
    style: {
      width: '100%',
      ml: '20px',
      mr: 'auto',
      borderBottom: 'none',
      maxWidth: '750px',
      padding: 0,
    },
  },
  {
    label: t('mutual_settlements:details_conf.quantity'),
    slug: 'quantity',
    style: {
      width: '100%',
      ml: '20px',
      borderBottom: 'none',
      maxWidth: '60px',
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    label: t('mutual_settlements:details_conf.unit_of_measurement'),
    slug: 'unit_of_measurement',
    style: {
      width: '100%',
      ml: '20px',
      borderBottom: 'none',
      maxWidth: '60px',
      padding: 0,
      textAlign: 'center',
    },
  },
  {
    label: t('mutual_settlements:details_conf.price'),
    slug: 'price',
    style: {
      width: '100%',
      ml: '20px',
      mr: '20px',
      maxWidth: '100px',
      padding: 0,
      borderBottom: 'none',
      textAlign: 'right',
    },
  },
  {
    label: t('mutual_settlements:details_conf.amount'),
    slug: 'amount',
    style: {
      width: '100%',
      ml: '20px',
      mr: '20px',
      maxWidth: '100px',
      padding: 0,
      borderBottom: 'none',
      textAlign: 'right',
    },
  },
];
