import { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

import Echo from 'laravel-echo';
import io from 'socket.io-client';
import styled from 'styled-components';

import { Container } from '@root/middleware';
import { Header } from '@root/modules';
import { Loader } from '@root/ui';
import { SideBar } from '@root/widgets';

import notificationsOperation from '@redux/notifications/notifications-operation';
import userSelectors from '@redux/user/user-selectors';

import Box from '@mui/material/Box';

export const TemplateMain = () => {
  const getUserProfile = useSelector(userSelectors.getUserProfile);
  const dispatch = useDispatch();

  useEffect(() => {
    const echo = new Echo({
      broadcaster: 'socket.io',
      transports: ['websocket', 'polling'],
      host: process.env.REACT_APP_ECHO_SERVER_HOST,
      client: io,
      disableStats: true,
      encrypted: true,
      forceTLS: true,
      enabledTransports: ['ws', 'wss'],
    });

    const channel = echo.channel(
      `viatec_database_notifications-${getUserProfile.id}`,
    );

    dispatch(
      notificationsOperation.getNotifications({
        page: 1,
        per_page: 10,
        type: 'base',
      }),
    );

    channel.listen('.has-new', e => {
      dispatch(
        notificationsOperation.getNotifications({
          page: 1,
          per_page: 10,
          type: 'base',
        }),
      );
    });

    return () => {
      echo.leaveChannel(`viatec_database_notifications-${getUserProfile.id}`);
    };
  }, []);

  return (
    <Container width={1400}>
      <Box sx={{ display: 'flex', height: '100%' }}>
        <SideBar />

        <Page>
          <Header />
          <Suspense fallback={<Loader />}>
            <Outlet />
          </Suspense>
        </Page>
      </Box>
    </Container>
  );
};

const Page = styled.div`
  width: 100%;
  height: 100vh;
  overflow: hidden;
`;
