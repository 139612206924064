import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { handleDispatchFetch } from '@root/helpers';
import {
  ButtonBase,
  CustomScroll,
  OutlineButtonBase,
  ProgressLoader,
} from '@root/ui';

import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import permissionOperation from '@redux/permission/permission-operation';
import permissionSelectors from '@redux/permission/permission-selectors';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

import { AddNewRoleModal } from '../AddNewRoleModal';
import { DeleteRoleModal } from './DeleteRoleModal';
import { PermissionInput } from './PermissionInput';

export const PermissionDetails = ({ handleTabClick }) => {
  const [data, setData] = useState({});
  const [errorData, setErrorData] = useState(null);
  const [open, setOpen] = useState(false);
  const permissionConf = useSelector(permissionSelectors.getPermissionConf);
  const permission = useSelector(userSelectors.getPermissions);
  const initData = useSelector(permissionSelectors.getDataById);
  const loading = useSelector(permissionSelectors.getLoadingDetails);
  const { t } = useTranslation(['profile', 'common'], { useSuspense: false });
  const { color } = useTheme();
  const dispatch = useDispatch();

  useEffect(() => {
    if (initData) {
      const dataCopy = JSON.parse(JSON.stringify(initData));
      setData(dataCopy);
    }
  }, [initData]);

  const handleToggleActivityPermission = (name, children) => value => {
    if (children) {
      const newChildrenStatus = JSON.parse(JSON.stringify(children)).reduce(
        (acc, el) => {
          acc = { ...acc, [el]: false };
          return acc;
        },
        {},
      );
      setData({ ...data, [name]: value, ...newChildrenStatus });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handelSubmit = e => {
    e.preventDefault();

    dispatch(
      permissionOperation.saveRoleById({ id: permissionConf.id, data }),
    ).then(el => {
      dispatch(
        setOpenNotification({
          open: true,
          message: t('profile:conf.roles.success_notification'),
        }),
      );
    });
  };

  const handelDeleteRole = ({ id }) => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          permissionOperation.deleteRoleById({
            id,
            onResolve,
            onReject,
          }),
        ),
      id => {
        handleTabClick(0, id);
        dispatch(clearModalState());
      },
      employees => {
        setErrorData({
          employees,
          id,
        });
      },
    );
  };

  const callConfirmModal = id => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        dataForConfirm: {
          id: id,
        },
        callback: data => {
          handelDeleteRole(data);
          dispatch(clearModalState());
        },
      }),
    );
  };

  return (
    <PermissionForm color={color} onSubmit={handelSubmit} id="edit-roles-form">
      {permissionConf && (
        <>
          <WrapperContent>
            <CustomScroll vertical={true}>
              {permissionConf?.permissions.map((el, idx, arr) => (
                <Wrapper
                  key={el.code}
                  color={color}
                  isLast={arr.length === idx}
                >
                  <PermissionInput
                    handleToggleActivityPermission={
                      handleToggleActivityPermission
                    }
                    data={el}
                    type={'parent'}
                    value={data[el.code]}
                  />
                  {el?.children?.length > 0 &&
                    el.children.map(childrenEl => (
                      <Fragment key={childrenEl.code}>
                        <PermissionInput
                          handleToggleActivityPermission={
                            handleToggleActivityPermission
                          }
                          data={childrenEl}
                          type={'children'}
                          value={data[childrenEl.code]}
                          disabled={!data[el.code]}
                        />
                        {childrenEl?.children?.length > 0 &&
                          childrenEl.children.map(subChildrenEl => (
                            <PermissionInput
                              key={subChildrenEl.code}
                              handleToggleActivityPermission={
                                handleToggleActivityPermission
                              }
                              data={subChildrenEl}
                              type={'children'}
                              value={data[subChildrenEl.code]}
                              disabled={!data[childrenEl.code]}
                            />
                          ))}
                      </Fragment>
                    ))}
                </Wrapper>
              ))}
            </CustomScroll>
          </WrapperContent>
          <Flex>
            {permissionConf.can_delete &&
              permission.profile_permissions_edit_access && (
                <ButtonBase
                  sx={{
                    padding: 0,

                    color: color.red,
                    backgroundColor: 'transparent',
                    '&:hover': {
                      background: 'transparent',
                      boxShadow: 'none',
                    },
                  }}
                  onClick={() => callConfirmModal(permissionConf.id)}
                >
                  <Typography variant={'bodyBoldSmall'}>
                    {t('profile:conf.roles.delete_role')}
                  </Typography>
                </ButtonBase>
              )}
            {permission.profile_permissions_create_access && (
              <OutlineButtonBase
                type="button"
                onClick={() => setOpen(true)}
                sx={{ height: '38px', ml: 'auto' }}
              >
                {t('profile:conf.roles.clone_into_new_role')}
              </OutlineButtonBase>
            )}

            {permissionConf?.code !== 'biznes-administrator' &&
              permission.profile_permissions_edit_access && (
                <ButtonBase type="submit" form="edit-roles-form">
                  {t('common:buttons.save_changes')}
                </ButtonBase>
              )}
          </Flex>{' '}
        </>
      )}
      {loading && <ProgressLoader position={'absolute'} />}
      {open && (
        <AddNewRoleModal
          open={open}
          handleClose={() => setOpen(false)}
          cloneId={permissionConf.id}
          handleRedirectToNewRole={handleTabClick}
        />
      )}
      {errorData && (
        <DeleteRoleModal
          employees={errorData?.employees}
          defaultRoleId={permissionConf.id}
          handleClose={() => setErrorData(null)}
          handelDeleteRole={handelDeleteRole}
        />
      )}
    </PermissionForm>
  );
};

const PermissionForm = styled.form`
  width: 100%;
  height: 100%;
  display: block;
  position: relative;

  border: 1px solid ${({ color }) => color.line_light};
  border-radius: 4px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  gap: 10px;
  border-bottom: 1px solid ${({ color, isLast }) => !isLast && color.line_light};
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  padding: 16px 17px;
  gap: 10px;
`;

const WrapperContent = styled.div`
  display: inline;
  height: calc(100% - 70px);
`;

PermissionDetails.propsTypes = {
  handleTabClick: PropTypes.func,
};
