import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { InfoIcon, Sprite } from '@root/assets/svg';
import { ImgPrev, LabelProduct } from '@root/components';
import { useDebounce } from '@root/hooks';
import {
  CustomScroll,
  IconBtn,
  MessageError,
  MessageSuccess,
  TextArea,
  TextInput,
} from '@root/ui';

import { Divider, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { checkSerial } from '../API/checkSerial';
import { searchModel } from '../API/searchModel';

export const UserForm = ({
  onChange,
  item,
  index,
  onClick,
  error,
  type,
  styles,
  guaranteeStatus,
  setGuaranteeStatus,
}) => {
  const [searchList, setSearchList] = useState([]);
  const [modelChose, setModelChose] = useState('');
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();
  const debounceSearchValue = useDebounce(item?.model, 400);

  useEffect(() => {
    if (debounceSearchValue === modelChose) return;
    (async function () {
      if (debounceSearchValue.length > 3) {
        const { data } = await searchModel({ model: debounceSearchValue });
        setSearchList(data.data);
      } else {
        setSearchList([]);
      }
    })();
  }, [debounceSearchValue]);

  const getError = () => {
    switch (guaranteeStatus) {
      case 'valid':
        if (item?.year_of_purchase)
          onChange('year_of_purchase', 'repair', index)('');
        return (
          <MessageSuccess
            successText={t('common:shipment_modal.successMess')}
            sx={{
              gridColumn: 'span 2',
              border: `1px solid ${color.green}`,
              padding: '7px 0',
              justifyContent: 'center',
              borderRadius: '4px',
            }}
          />
        );
      case 'invalid':
        if (item?.year_of_purchase)
          onChange('year_of_purchase', 'repair', index)('');
        return (
          <MessageError
            errorText={t('common:shipment_modal.errorMess')}
            sx={{
              background: color.red_light,
              gridColumn: 'span 2',
              padding: '7px 0',
              borderRadius: '4px',
              justifyContent: 'center',
              border: `1px solid ${color.red}`,
              marginTop: 0,
            }}
          />
        );
      case 'not_found':
        return (
          <SubWrap styles={{ marginTop: '0', gridColumn: 'span 2' }}>
            <TextInput
              error={
                error?.repairErr &&
                error?.repairErr[index]?.year_of_purchase?.init
              }
              errorText={
                error?.repairErr &&
                error?.repairErr[index]?.year_of_purchase?.text
              }
              type={'number'}
              styleWrapper={{ flex: '50%', marginBottom: '12px' }}
              sx={{ height: '36px', width: '49%' }}
              label={t('common:shipment_modal.year_of_purchase')}
              placeholder={t(
                'common:shipment_modal.year_of_purchase_placeholder',
              )}
              value={item?.year_of_purchase}
              onChange={onChange('year_of_purchase', 'repair', index)}
              required={true}
            />
            <LabelWrapper>
              <Img src={InfoIcon} />
              <Typography
                variant={'bodySmall'}
                sx={{ color: color.gray, ml: '2px', width: '57%' }}
                component={'span'}
              >
                {t('common:shipment_modal.year_of_purchase_descr')}
              </Typography>
            </LabelWrapper>
          </SubWrap>
        );
      default:
        break;
    }
  };

  const moduleMarckUp = type => {
    const errorKey = type === 'refund' ? 'refundErr' : 'repairErr';

    return (
      <ModelWrapper>
        <TextInput
          error={error?.[errorKey] && error[errorKey][index]?.model?.init}
          errorText={error?.[errorKey] && error[errorKey][index]?.model?.text}
          styleWrapper={{ flex: '50%' }}
          sx={{ height: '36px' }}
          label={t('common:shipment_modal.model')}
          placeholder={t('common:shipment_modal.model_placeholder')}
          value={item?.model}
          onChange={onChange('model', type, index)}
          required={true}
        />
        {searchList.length > 0 && (
          <SearchList colors={color}>
            <CustomScroll vertical={true}>
              {searchList.map(({ image, model, name, sku, id }, idx, arr) => {
                return (
                  <>
                    <Card
                      key={id}
                      border={
                        idx + 1 === arr.length
                          ? 'none'
                          : `1px solid ${color.line}`
                      }
                      colors={color}
                      onClick={() => {
                        onChange('model', type, index)(model);
                        setSearchList([]);
                        setModelChose(model);
                      }}
                    >
                      <ImgPrev src={image} />
                      <LabelProduct label={name} parm={model} article={sku} />
                    </Card>
                    {idx + 1 !== arr.length && <Divider />}
                  </>
                );
              })}
            </CustomScroll>
          </SearchList>
        )}
      </ModelWrapper>
    );
  };

  const isSerialNumActive = item?.model.length > 3;

  const handelCheckSerialNum = async () => {
    const { data } = await checkSerial({
      modal: item.model,
      serial: item?.serial_num,
    });

    setGuaranteeStatus(data.data.warranty);
  };

  return (
    <AdditionalForm
      styles={styles}
      colors={{ gray: color.gray_20, line: color.line }}
    >
      <IconBtn
        onClick={() => onClick(index, type)}
        href={`${Sprite}#icon-delete`}
        sx={{
          position: 'absolute',
          top: 16,
          right: 16,
        }}
      />

      {type === 'refund' ? (
        <>
          {moduleMarckUp(type)}
          <TextInput
            type={'number'}
            error={error?.refundErr && error?.refundErr[index]?.quantity?.init}
            errorText={
              error?.refundErr && error?.refundErr[index]?.quantity?.text
            }
            styleWrapper={{ flex: '50%' }}
            sx={{ height: '36px' }}
            label={t('common:shipment_modal.quantity')}
            placeholder={t('common:shipment_modal.quantity_placeholder')}
            value={item?.quantity}
            onChange={onChange('quantity', 'refund', index)}
            withOutSpace={true}
            required={true}
          />
        </>
      ) : (
        <>
          {moduleMarckUp(type)}

          <TextInput
            error={
              error?.repairErr && error?.repairErr[index]?.serial_num?.init
            }
            errorText={
              error?.repairErr && error?.repairErr[index]?.serial_num?.text
            }
            styleWrapper={{ flex: '50%' }}
            sx={{ height: '36px' }}
            label={t('common:shipment_modal.serial_num')}
            placeholder={t('common:shipment_modal.serial_num_placeholder')}
            value={item?.serial_num}
            onChange={onChange('serial_num', 'repair', index)}
            disabled={!isSerialNumActive}
            required={true}
            onBlur={handelCheckSerialNum}
          />

          {getError()}
          <SubWrap
            styles={{
              marginTop: '0',
              gridColumn: 'span 2',
            }}
          >
            <TextArea
              error={
                error?.repairErr &&
                error?.repairErr[index]?.declared_malfunction?.init
              }
              errorText={
                error?.repairErr &&
                error?.repairErr[index]?.declared_malfunction?.text
              }
              sx={{ fontSize: '1rem', height: '56px' }}
              placeholder={t('common:description')}
              type="text"
              max={5000}
              value={item?.declared_malfunction}
              onChange={onChange('declared_malfunction', 'repair', index)}
              label={t('common:shipment_modal.declared_malfunction')}
              required={true}
            />

            <TextArea
              error={
                error?.repairErr && error?.repairErr[index]?.complete_set?.init
              }
              errorText={
                error?.repairErr && error?.repairErr[index]?.complete_set?.text
              }
              sx={{ fontSize: '1rem', height: '56px' }}
              placeholder={t('common:description')}
              type="text"
              max={5000}
              value={item?.complete_set}
              onChange={onChange('complete_set', 'repair', index)}
              label={t('common:shipment_modal.complete_set')}
              required={true}
            />
          </SubWrap>
        </>
      )}
    </AdditionalForm>
  );
};

const AdditionalForm = styled.div`
  position: relative;
  margin-bottom: 8px;
  background: ${props => props.colors.gray};
  display: flex;
  padding: 16px 40px 16px 16px;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid ${props => props.colors.line};
  ${prop => prop.styles}
`;

const ModelWrapper = styled.div`
  position: relative;
`;

const SearchList = styled.div`
  position: absolute;
  width: 502px;
  max-height: 300px;
  background-color: ${props => props.colors.white};
  top: 53px;
  left: 0;
  z-index: 100;
  border: 1px solid ${props => props.colors.line};
  border-radius: 4px;
  overflow: hidden;
`;

const Card = styled.div`
  display: flex;
  padding: 5px 0;
  cursor: pointer;

  &:hover {
    background: ${props => props.colors.gray_20};
  }
`;

const SubWrap = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  ${prop => prop.styles}
`;

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`;

const Img = styled.img`
  margin-right: 6px;
`;

UserForm.propTypes = {
  onChange: PropTypes.func.isRequired,
  item: PropTypes.object,
  index: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  error: PropTypes.shape({
    init: PropTypes.bool,
    text: PropTypes.string,
  }),

  type: PropTypes.string,
  styles: PropTypes.object,
  guaranteeStatus: PropTypes.string,
  setGuaranteeStatus: PropTypes.func,
};
