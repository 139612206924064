import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Cost, ImgPrev, QuantityManager } from '@root/components';
import { IconBtn, ToggleBtn } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const ComparisonsCardDetails = ({
  data,
  handelOpenSavedModal,
  handleAddToCart,
  removeProductFromComparisons,
  titleHeight,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });
  const permission = useSelector(userSelectors.getPermissions);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  return (
    <CardWrap>
      <CardImgWrap>
        <NavLink to={currentLang + data?.url}>
          <ImgPrev src={data?.image} countImg={data?.images_quantity} />
        </NavLink>
      </CardImgWrap>
      <CardMainWrap>
        <CardHeader>
          <CardHeaderWrap>
            <NavLink
              to={currentLang + data?.url}
              style={{
                height: titleHeight,
                transition: 'initial',
                overflow: 'hidden',
              }}
            >
              <Typography variant={'bodyBoldSmall'} color={color.gray}>
                {data?.model}
              </Typography>
            </NavLink>
            <Typography
              variant={'bodySmall'}
              color={color.gray}
              sx={{ marginTop: '5px' }}
            >
              {data?.sku}
            </Typography>
          </CardHeaderWrap>
          <IconBtn
            size="16px"
            href={`${Sprite}#icon-cross`}
            onClick={() => removeProductFromComparisons(data.id)}
          />
        </CardHeader>
        <CardBody>
          <div style={{ cursor: 'pointer' }}>
            <Cost data={data} />
          </div>
          <IconBtn
            href={`${Sprite}#icon-hart`}
            onClick={e => handelOpenSavedModal(e, data.is_saved, data.id)}
            size={16}
            saved={data.is_saved}
            sx={{ margin: '0 35px 0 30px', position: 'relative', top: '-2px' }}
            tooltipText={t('common:tooltip.save')}
          />
          {permission.cart_add_access &&
            (data.is_in_cart ? (
              <QuantityManager data={data} hide_amount />
            ) : (
              <Wrapper margin={'16px'}>
                <ToggleBtn
                  disabled={!data.can_buy[choosedCurrency]}
                  onClick={e => handleAddToCart(e, data)}
                  size={32}
                  isActive={data.can_buy[choosedCurrency]}
                  href={`${Sprite}#icon-grocery_cart`}
                />
              </Wrapper>
            ))}
        </CardBody>
      </CardMainWrap>
    </CardWrap>
  );
};

const CardWrap = styled.div`
  display: flex;
  align-items: center;
`;
const CardImgWrap = styled.div`
  align-self: flex-start;
  margin: -5px 8px 0 0;
`;
const CardMainWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
`;
const CardHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 14px;
`;
const CardHeaderWrap = styled.div`
  display: flex;
  flex-direction: column;
`;
const CardBody = styled.div`
  display: flex;
  align-items: center;
  height: 34px;
`;

const Wrapper = styled.div`
  width: initial;
`;

ComparisonsCardDetails.propTypes = {
  handelOpenSavedModal: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  removeProductFromComparisons: PropTypes.func.isRequired,
  titleHeight: PropTypes.number,
  data: PropTypes.exact({
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.exact({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    properties: PropTypes.object,
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
  }),
};
