import { Fragment } from 'react';

import PropTypes from 'prop-types';

import { Divider, RadioGroup } from '@mui/material';

import { RadioItem } from '../RadioItem';
import { BillFrom } from './BillFrom';

export const FormPaymentInfo = ({
  payment_type,
  onChangeRadio,
  available_methods,
  onChange,
  payment_info,
  error,
}) => {
  return (
    <RadioGroup
      aria-labelledby="demo-radio-buttons-group-label"
      name="radio-buttons-group"
      value={payment_type}
      onChange={onChangeRadio('payment_type')}
    >
      {available_methods?.payment_types.map(el => (
        <Fragment key={el.key}>
          <RadioItem data={el} data-test-item="PaymentRadioItem" />
          {payment_type === 'Bill' && el.key === 'Bill' && (
            <BillFrom
              onChangeRadio={onChangeRadio}
              onChange={onChange}
              payment_info={payment_info}
              error={error}
            />
          )}
          <Divider />
        </Fragment>
      ))}
    </RadioGroup>
  );
};

FormPaymentInfo.propTypes = {
  payment_type: PropTypes.string.isRequired,
  onChangeRadio: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  available_methods: PropTypes.shape({
    delivery_types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        items: PropTypes.arrayOf(
          PropTypes.shape({
            icon: PropTypes.string,
            id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
            isDefault: PropTypes.bool,
            key: PropTypes.string,
            subTitle: PropTypes.string,
            title: PropTypes.string,
          }),
        ),
        key: PropTypes.string,
        label: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    payment_types: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        isDefault: PropTypes.bool,
        key: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
  payment_info: PropTypes.shape({
    account_option: PropTypes.string,
    contractor_code: PropTypes.string,
    email: PropTypes.string,
    identification_code: PropTypes.string,
    name_fop_organization: PropTypes.string,
    name_organization: PropTypes.string,
    payer_type: PropTypes.string,
    send_invoice: PropTypes.bool,
  }),
  error: PropTypes.object,
};
