import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getAccountingDocuments = createAsyncThunk(
  'accountingDocuments/getAccountingDocuments',
  async ({ date_from, date_to }, thunkApi) => {
    try {
      const response = await axios.get(`user/documents/accounting`, {
        params: {
          date_from,
          date_to,
        },
      });

      return response.data.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error.response);
    }
  },
);

export default {
  getAccountingDocuments,
};
