import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { NumListItem } from '@root/components';
import { UserAgreementConf } from '@root/conf';

import { Typography, useTheme } from '@mui/material';

export const UserAgreement = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['user_agreement'], { useSuspense: false });
  return (
    <UserAgreementWrapper>
      {UserAgreementConf(t).header.map(headerItem => (
        <Typography
          color={color.gray_80}
          variant="bodyLarge"
          component="p"
          marginTop="23px"
        >
          {headerItem.text}
        </Typography>
      ))}
      <List>
        {UserAgreementConf(t).main.map(itemList => (
          <NumListItem text={itemList.listTitle}>
            {itemList.subList && (
              <List>
                {itemList.subList.map(subItem => (
                  <NumListItem>
                    {subItem.text}
                    {subItem.subList && (
                      <List>
                        {subItem.subList.map(subItem => (
                          <NumListItem>{subItem.text}</NumListItem>
                        ))}
                      </List>
                    )}
                  </NumListItem>
                ))}
              </List>
            )}
          </NumListItem>
        ))}
      </List>

      {UserAgreementConf(t).footer.map(headerItem => (
        <Typography
          color={color.gray_80}
          variant="bodyLarge"
          component="p"
          marginTop="23px"
        >
          {headerItem.text}
        </Typography>
      ))}
    </UserAgreementWrapper>
  );
};

const UserAgreementWrapper = styled.div``;

const List = styled.ol`
  ol {
    counter-reset: listCounter;
  }
`;
