import React from 'react';

import { getMonth, getYear } from 'date-fns';
import range from 'lodash/range';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { BasicSelect, IconBtn } from '@root/ui';

import { useTheme } from '@mui/material';

export const CustomDatePickerHeader = ({
  date,
  changeYear,
  changeMonth,
  decreaseMonth,
  increaseMonth,
  prevMonthButtonDisabled,
  nextMonthButtonDisabled,
  monthsList,
  minDate,
}) => {
  const { color } = useTheme();
  const years = range(1950, getYear(new Date()) + 10, 1);
  const handleSelectYear = (value, actionType) => {
    actionType(value);
  };
  const handleSelectMonth = (value, actionType, options) => {
    actionType(options.indexOf(value));
  };

  return (
    <DatePickerHeader>
      <IconBtn
        onClick={decreaseMonth}
        href={`${Sprite}#icon-arrow-left`}
        size={14}
        disabled={prevMonthButtonDisabled}
      />
      <ElWrap>
        <BasicSelect
          textTransform="capitalize"
          data={monthsList}
          value={monthsList[getMonth(date)]}
          callBack={value => handleSelectMonth(value, changeMonth, monthsList)}
          customMenuProps={{
            sx: {
              position: 'absolute',
              height: '400px',
              width: '400px',
              '.MuiPaper-root': {
                top: '38px !important',
                left: '0 !important',
              },

              '.MuiMenuItem-root': {
                whiteSpace: 'wrap',
              },
            },
            disablePortal: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          sx={{
            fieldset: {
              border: 'none',
            },
            img: {
              display: 'none',
            },
            minWidth: 'initial',
            width: 'auto',
            '&:disabled': {
              display: 'none',
            },
          }}
          selectSx={{
            '& .MuiInputBase-input': {
              color: color.gray,
              fontWeight: 700,
              textTransform: 'capitalize',
            },
            '& .MuiSelect-select': {
              padding: '5px !important',
              textAlign: 'right',
            },
          }}
        />
        <BasicSelect
          data={years}
          value={getYear(date)}
          callBack={value => handleSelectYear(value, changeYear)}
          disabled={minDate ? minDate : false}
          customMenuProps={{
            sx: {
              position: 'absolute',
              height: '400px',
              width: '400px',
              '.MuiPaper-root': {
                top: '38px !important',
                left: '0 !important',
              },

              '.MuiMenuItem-root': {
                whiteSpace: 'wrap',
              },
            },
            disablePortal: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
          sx={{
            fieldset: {
              border: 'none',
            },
            img: {
              display: 'none',
            },
            minWidth: 'initial',
            width: 'auto',
          }}
          selectSx={{
            '& .MuiInputBase-input': {
              color: color.gray,
              fontWeight: 700,
            },
            '& .MuiSelect-select': {
              padding: '5px !important',
              textAlign: 'left',
            },
          }}
        />
      </ElWrap>
      <IconBtn
        onClick={increaseMonth}
        href={`${Sprite}#icon-arrow-right`}
        size={14}
        disabled={nextMonthButtonDisabled}
      />
    </DatePickerHeader>
  );
};

const DatePickerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
`;

const ElWrap = styled.div`
  display: flex;
  align-items: center;
`;

CustomDatePickerHeader.propTypes = {
  date: PropTypes.object,
  changeYear: PropTypes.func.isRequired,
  changeMonth: PropTypes.func.isRequired,
  decreaseMonth: PropTypes.func.isRequired,
  increaseMonth: PropTypes.func.isRequired,
  prevMonthButtonDisabled: PropTypes.bool,
  nextMonthButtonDisabled: PropTypes.bool,
  monthsList: PropTypes.array,
  minDate: PropTypes.bool,
};
