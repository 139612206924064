import React from 'react';

import PropTypes from 'prop-types';

import { Sprite } from '@root/assets/svg';
import { ColorlibStepIconRoot } from '@root/styles';
import { StepperBase } from '@root/ui';

import { Box, Typography, useTheme } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&`]: {
    position: 'relative',
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },

  [`& .${stepConnectorClasses.line}`]: {
    width: 'calc(100% + 40px)',
    height: '1px',
    position: 'absolute',
    top: '-10px',
    left: '-20px',
    borderColor: theme.color.line,
  },
}));

function ColorlibStepIcon(props, steps) {
  const { active, completed, className, icon } = props;
  const isReject = steps[icon - 1].code === 'reject';
  const isDone = steps[icon - 1].code === 'done';

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={theme => ({
        fill: theme.color.gray_60,
        stroke: theme.color.gray_60,
        background: `${theme.color.gray_20}`,
        width: 36,
        height: 36,
        border: `1px solid transparent`,
      })}
      sxComplete={theme => ({
        fill: `${theme.color.white}!important`,
        stroke: `${theme.color.white}!important`,
        background: isReject
          ? `${theme.color.red} !important`
          : isDone
          ? `${theme.color.green} !important`
          : `${theme.color.main} !important`,
      })}
      sxActive={theme => ({
        fill: isReject
          ? `${theme.color.red}!important`
          : isDone
          ? `${theme.color.green}!important`
          : `${theme.color.main}!important`,
        stroke: isReject
          ? `${theme.color.red}!important`
          : isDone
          ? `${theme.color.green}!important`
          : `${theme.color.main}!important`,
        border: isReject
          ? `1px solid ${theme.color.red}!important`
          : isDone
          ? `1px solid ${theme.color.green}!important`
          : `1px solid ${theme.color.main} !important`,
        background: isReject
          ? `${theme.color.red_light}!important`
          : isDone
          ? `${theme.color.green_light}!important`
          : `${theme.color.main_light}!important`,
      })}
    >
      <svg width="16" height="16">
        <use href={`${Sprite}#${steps[icon - 1].icon}`}></use>
        {icon}
      </svg>
    </ColorlibStepIconRoot>
  );
}

export const StepperStatusWithIcon = ({ steps }) => {
  const { color } = useTheme();
  const active = [...steps].find(el => el.status === 'current');
  const activeStep = [...steps].indexOf(active);

  return (
    <Box
      sx={{
        minHeight: '30px',
      }}
    >
      <StepperBase
        conf={steps}
        step={activeStep}
        defection={'horizontal'}
        text={(label, index) => (
          <Typography
            variant={'bodyExSmall'}
            color={color.gray_80}
            sx={{
              display: 'block',
              mt: '12px',
              width: '94px',
            }}
            align={'center'}
          >
            {label}
          </Typography>
        )}
        sx={{ p: '0 ', minHeight: '10px' }}
        sxLabel={() => {
          return {
            position: 'relative',
            flexDirection: 'column',
            '& .MuiStepLabel-iconContainer': {
              padding: '0 ',
            },
          };
        }}
        connector={<QontoConnector />}
        iconComponent={props => ColorlibStepIcon(props, steps)}
      />
    </Box>
  );
};

StepperStatusWithIcon.propTypes = {
  steps: PropTypes.array.isRequired,
};
