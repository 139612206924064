import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { getErrorStack, handleDispatchFetch } from '@root/helpers';
import { ModalBase, ProgressLoader, RadioCustom } from '@root/ui';

import commercialOfferOperation from '@redux/commercial-offer/commercial-offer-operation';
import commercialOfferSelectors from '@redux/commercial-offer/commercial-offer-selectors';
import { updateProduct } from '@redux/commercial-offer/commercial-offer-slice';

import {
  FormControlLabel,
  RadioGroup,
  Typography,
  useTheme,
} from '@mui/material';

import { ProductOrService } from '../Forms/ProductOrService';

export const AddOrEditProduct = ({ open, handleClose, productDetails }) => {
  const [data, setData] = useState({
    type: 'custom',
    picture: null,
    name: '',
    model: '',
    price: '',
    quantity: 1,
    description: '',
    warranty: '',
    is_delete_file: false,
  });
  const [error, setError] = useState({});
  const [opacity, setOpacity] = useState(0);
  const products = useSelector(commercialOfferSelectors.getProducts);
  const loading = useSelector(commercialOfferSelectors.customProductsLoading);
  const customProducts = useSelector(
    commercialOfferSelectors.getCustomProducts,
  );
  const customServices = useSelector(
    commercialOfferSelectors.getCustomServices,
  );
  const dispatch = useDispatch();
  const { color } = useTheme();
  const { t } = useTranslation(['commercial_offer', 'validation'], {
    useSuspense: false,
  });
  const [selectedVal, setSelectedVal] = useState(-1);

  useEffect(() => {
    setTimeout(() => {
      setOpacity(1);
    }, 0);
    if (productDetails.type === 'new') {
      dispatch(commercialOfferOperation.getCommercialOfferCustomsProducts());
    } else {
      const editData = products.find(item => item.id === productDetails.id);
      setData(prevState => ({ ...prevState, ...editData }));
    }
  }, []);

  const onChangeRadio = value => {
    setData({
      picture: null,
      name: '',
      model: '',
      price: '',
      quantity: 1,
      description: '',
      warranty: '',
      is_delete_file: false,
      type: value,
    });
    setError({});
    setSelectedVal(-1);
  };

  const onChange = (name, type) => value => {
    if (type === 'quantity') {
      const quantityValue = !value.length ? 1 : value;
      setData(prevState => ({ ...prevState, [name]: quantityValue }));
    } else {
      setData(prevState => ({ ...prevState, [name]: value }));
    }
  };
  const handleSetImage = img => {
    if (!img) setData(prevState => ({ ...prevState, is_delete_file: true }));
    setData(prevState => ({ ...prevState, picture: img }));
  };

  const handleSelect = id => {
    setSelectedVal(id);
    if (id < 0) {
      setData(prevState => ({
        ...prevState,
        picture: null,
        name: '',
        model: '',
        price: '',
        quantity: 1,
        description: '',
        warranty: '',
        is_delete_file: false,
      }));
    } else {
      switch (data.type) {
        case 'custom':
          findCustomEl(customProducts, id);
          break;
        default:
          findCustomEl(customServices, id);
          break;
      }
    }
  };
  const findCustomEl = (arr, id) => {
    const data = arr.find(item => item.id === id);
    setData(prevState => ({ ...prevState, ...data }));
  };

  const handleQuantity = (flag, { target }) => {
    if (target.tagName === 'BUTTON') return;

    setData(prevState => ({
      ...prevState,
      quantity:
        flag === 'plus'
          ? Number(prevState.quantity) + 1
          : Number(prevState.quantity) - 1,
    }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const { name, model, price, description, warranty, type } = data;

    Object.keys({
      price,
      warranty,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });
    if (type !== 'base') {
      if (!description || description === '') {
        currentError = getErrorStack(
          currentError,
          'description',
          t('validation:required'),
        );
        flag = true;
      }
      if (!name || name === '') {
        currentError = getErrorStack(
          currentError,
          'name',
          t('validation:required'),
        );
        flag = true;
      }
    }
    if (type !== 'service' && type !== 'base') {
      if (!model || model === '') {
        currentError = getErrorStack(
          currentError,
          'model',
          t('validation:required'),
        );
        flag = true;
      }
    }

    setError(currentError);
    return flag;
  };
  const onSubmit = e => {
    e.preventDefault();

    if (validation()) return;

    if (data.type === 'base') {
      dispatch(updateProduct(data));
    } else {
      const cloneData = JSON.parse(JSON.stringify(data));
      const pic = data.picture
        ? data.picture.includes('https')
          ? ''
          : data.picture
        : '';

      delete cloneData.picture;
      if (productDetails.type === 'new') {
        if (selectedVal < 0) {
          handleDispatchFetch(({ onResolve, onReject }) =>
            dispatch(
              commercialOfferOperation.createCustomProduct({
                formData: serialize(
                  {
                    ...cloneData,
                    picture: pic,
                  },
                  {
                    indices: true,
                    nullsAsUndefineds: true,
                    booleansAsIntegers: true,
                  },
                ),
                onResolve,
                onReject,
              }),
            ),
          );
        } else {
          handleDispatchFetch(({ onResolve, onReject }) =>
            dispatch(
              commercialOfferOperation.updateCustomProduct({
                formData: serialize(
                  {
                    ...cloneData,
                    picture: pic,
                  },
                  {
                    indices: true,
                    nullsAsUndefineds: true,
                    booleansAsIntegers: true,
                  },
                ),
                id: selectedVal,
                onResolve,
                onReject,
              }),
            ),
          );
        }
      } else {
        handleDispatchFetch(({ onResolve, onReject }) =>
          dispatch(
            commercialOfferOperation.updateCustomProduct({
              formData: serialize(
                {
                  ...cloneData,
                  picture: pic,
                },
                {
                  indices: true,
                  nullsAsUndefineds: true,
                  booleansAsIntegers: true,
                },
              ),
              id: productDetails.id,
              onResolve,
              onReject,
            }),
          ),
        );
      }
    }

    handleClose();
  };

  return (
    <ModalBase
      handleClose={handleClose}
      open={open}
      modalHeader={
        <HeaderDefault
          text={t(
            `commercial_offer:${
              productDetails.type === 'new' ? 'add_position' : 'edit_position'
            }`,
          )}
          onClose={handleClose}
        />
      }
      sx={{
        opacity: opacity,
      }}
    >
      <ModalWrap>
        {loading && <ProgressLoader />}
        {productDetails.type === 'new' && (
          <RadioGroup
            onChange={(_, value) => onChangeRadio(value)}
            value={data.type}
            sx={{
              flexDirection: 'row',
              borderBottom: `1px solid ${color.line_light}`,
              paddingBottom: '10px',
              marginBottom: '12px',
            }}
          >
            <FormControlLabel
              control={<RadioCustom />}
              sx={{
                marginLeft: '-9px',
              }}
              label={
                <Typography
                  variant={'bodyBoldMedium'}
                  component={'span'}
                  sx={{
                    fontWeight: 400,
                    color: color.gray_80,
                  }}
                >
                  {t('commercial_offer:product')}
                </Typography>
              }
              value={'custom'}
            />
            <FormControlLabel
              control={<RadioCustom />}
              sx={{
                marginLeft: '-8px',
              }}
              label={
                <Typography
                  variant={'bodyBoldMedium'}
                  component={'span'}
                  sx={{
                    fontWeight: 400,
                    color: color.gray_80,
                  }}
                >
                  {t('commercial_offer:service')}
                </Typography>
              }
              value={'service'}
            />
          </RadioGroup>
        )}
        <ProductOrService
          modalContent={data.type}
          data={data}
          error={error}
          productDetails={productDetails}
          handleSetImage={handleSetImage}
          handleQuantity={handleQuantity}
          onChange={onChange}
          onSubmit={onSubmit}
          handleClose={handleClose}
          handleSelect={handleSelect}
          selectedVal={selectedVal}
        />
      </ModalWrap>
    </ModalBase>
  );
};

const ModalWrap = styled.div`
  width: 300px;
  margin-top: 5px;
  padding: 0 5px;
`;

AddOrEditProduct.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  productDetails: PropTypes.exact({
    type: PropTypes.string,
    id: PropTypes.number,
  }),
};
