import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { BasicSelect, PhoneInput, TextInput } from '@root/ui';

import optionSelectors from '@redux/option/option-selectors';

export const ShipmentForm = ({
  selectedId,
  data,
  onChange,
  onChangeSelect,
  error,
}) => {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const filialList = useSelector(optionSelectors.getFilialList);

  const { place, filial_id, address, contact_person, phone, novaposhta_ttn } =
    data;

  const renderShipmentForm = () => {
    switch (selectedId) {
      case 'novaposhta':
        return (
          <FormWrap>
            <TextInput
              error={error?.place?.init}
              errorText={error?.place?.text}
              type={'number'}
              styleWrapper={{ width: '74px', marginBottom: '12px' }}
              sx={{ height: '36px' }}
              label={t('common:shipment_modal.place')}
              value={place}
              onChange={onChange('place')}
              withOutSpace={true}
              required={true}
            />
            <TextInput
              error={error?.novaposhta_ttn?.init}
              errorText={error?.novaposhta_ttn?.text}
              styleWrapper={{ width: '300px', marginBottom: '12px' }}
              sx={{ height: '36px' }}
              label={t('common:shipment_modal.ttn_num')}
              value={novaposhta_ttn}
              onChange={onChange('novaposhta_ttn')}
              placeholder={t('common:shipment_modal.ttn_num_placeholder')}
              withOutSpace={true}
              required={true}
            />
          </FormWrap>
        );
      case 'courier':
        return (
          <FormWrap>
            <TextInput
              error={error?.place?.init}
              errorText={error?.place?.text}
              type={'number'}
              styleWrapper={{ width: '74px', marginBottom: '12px' }}
              sx={{ height: '36px' }}
              label={t('common:shipment_modal.place')}
              value={place}
              onChange={onChange('place')}
              required={true}
            />
            <TextInput
              error={error?.address?.init}
              errorText={error?.address?.text}
              styleWrapper={{ width: '300px', marginBottom: '12px' }}
              sx={{ height: '36px' }}
              label={t('common:shipment_modal.address')}
              value={address}
              onChange={onChange('address')}
              placeholder={t('common:shipment_modal.address_placeholder')}
              required={true}
            />
            <TextInput
              error={error?.contact_person?.init}
              errorText={error?.contact_person?.text}
              styleWrapper={{ width: '300px', marginBottom: '12px' }}
              sx={{ height: '36px' }}
              label={t('common:shipment_modal.contact_person')}
              value={contact_person}
              onChange={onChange('contact_person')}
              placeholder={t(
                'common:shipment_modal.contact_person_placeholder',
              )}
              required={true}
            />
            <PhoneInput
              error={error?.phone?.init}
              errorText={error?.phone?.text}
              onChange={onChange('phone')}
              value={phone}
              placeholder={'+380'}
              sx={{ height: '36px', width: '300px' }}
              label={t('common:shipment_modal.phone')}
              required={true}
            />
          </FormWrap>
        );
      case 'filial':
        return (
          <FormWrap>
            <TextInput
              error={error?.place?.init}
              errorText={error?.place?.text}
              type={'number'}
              styleWrapper={{ width: '74px', marginBottom: '12px' }}
              sx={{ height: '36px' }}
              label={t('common:shipment_modal.place')}
              value={place}
              onChange={onChange('place')}
              withOutSpace={true}
              required={true}
            />
            <BasicSelect
              error={error?.filial_id?.init}
              helperText={error?.filial_id?.text}
              height={36}
              sx={{
                maxWidth: '300px',
                marginBottom: '12px',
              }}
              label={t('common:shipment_modal.choose_delivery_point')}
              name={t('common:shipment_modal.select')}
              data={filialList}
              currentId={filial_id}
              callBack={onChangeSelect}
              required={true}
            />
          </FormWrap>
        );
      default:
        break;
    }
  };
  return <>{renderShipmentForm()}</>;
};

const FormWrap = styled.div``;

ShipmentForm.propTypes = {
  selectedId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  data: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onChangeSelect: PropTypes.func.isRequired,
  error: PropTypes.shape({
    init: PropTypes.bool,
    text: PropTypes.string,
  }),
};
