import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import {
  getNovaPoshtaDepartmentOrOfficeByRef,
  novaPoshtaDepartmentOrPostOffice,
  novaPoshtaReqCities,
} from '@root/helpers';

import userSelectors from '@redux/user/user-selectors';

export const useNovaPoshta = ({ data, setData }) => {
  const userNpApiKey = useSelector(userSelectors.getNpApiKey);
  const [npValues, setNpValues] = useState({
    cityValue: null,
    departmentOrOfficeValue: null,
  });

  const [npData, setNpData] = useState({
    cities: [],
    departmentOrOffice: [],
  });
  const [page, setPage] = useState({
    citiesPaginationPageNum: 1,
    departmentOrOfficePageNum: 1,
  });
  const [search, setSearch] = useState('');
  const [npApiKey, setNpApiKey] = useState('');
  const [npValidationKey, setNpValidationKey] = useState(true);

  useEffect(() => {
    if (data.its_drop) {
      setNpApiKey(userNpApiKey);
    } else {
      setNpApiKey('');
    }
    setNpValues({ cityValue: null, departmentOrOfficeValue: null });
  }, [data.its_drop]);

  useEffect(() => {
    const { city_ref, department_ref, post_office_ref } = data?.delivery_info;
    (async function () {
      if (city_ref) {
        await getNovaPoshtaData(
          npApiKey,
          'Address',
          'getCities',
          1,
          '',
          city_ref,
        );

        if (department_ref) {
          await getDepartmentOrOfficeById(
            npApiKey,
            city_ref,
            department_ref,
            false,
          );
        }
        if (post_office_ref) {
          await getDepartmentOrOfficeById(
            npApiKey,
            city_ref,
            post_office_ref,
            true,
          );
        }
      }
    })();
  }, [data?.delivery_info?.city_ref]);

  useEffect(() => {
    setNpValues({ cityValue: null, departmentOrOfficeValue: null });
  }, [data.delivery_info.type]);

  const validationUserNpKey = error => {
    if (!error.response.data.success) {
      setNpValues({ cityValue: null, departmentOrOfficeValue: null });
      setData(prevState => {
        return {
          ...prevState,
          its_drop: false,
          delivery_info: {
            ...prevState.delivery_info,
            city_title: '',
            city_ref: '',
            department_title: '',
            department_ref: '',
            post_office_title: '',
            post_office_ref: '',
            street_title: '',
            street_number: '',
            flat_num: '',
            floor_num: '',
            elevator: false,
            npPhone: '',
            date: '',
            time: '',
          },
        };
      });
      setNpValidationKey(false);
    }
  };

  const getNovaPoshtaData = async (
    dropNpApiKey,
    modelName,
    calledMethod,
    pageNum,
    search,
    ref,
  ) => {
    try {
      const res = await novaPoshtaReqCities(
        dropNpApiKey,
        modelName,
        calledMethod,
        pageNum,
        search,
        ref,
      );

      const { data } = res.data;

      if (
        !pageNum ||
        (!search.length && pageNum === 1) ||
        (search.length && pageNum === 1)
      ) {
        if (ref) {
          setNpValues(prevState => {
            return { ...prevState, cityValue: data[0] };
          });
          setData(prevState => {
            return {
              ...prevState,
              delivery_info: {
                ...prevState.delivery_info,
                city_title: data[0].Description,
                city_ref: data[0].Ref,
              },
            };
          });
        }

        setNpData(prevState => ({
          cities: data,
          departmentOrOffice: [],
        }));
      } else {
        if (pageNum > 1 && data.length === 0) return;

        setNpData(prevState => {
          return {
            cities: [...prevState.cities, ...data],
            departmentOrOffice: [],
          };
        });
      }
    } catch (error) {
      validationUserNpKey(error);
    }
  };

  const getDepartmentOrOfficeById = async (
    dropNpApiKey,
    city_ref,
    department_or_office_ref,
    isOffice,
  ) => {
    try {
      const res = await getNovaPoshtaDepartmentOrOfficeByRef(
        dropNpApiKey,
        city_ref,
        department_or_office_ref,
        isOffice,
      );
      const { data } = res.data;
      setNpValues(prevState => {
        return {
          ...prevState,
          departmentOrOfficeValue: data[0],
        };
      });
    } catch (error) {
      validationUserNpKey(error);
    }
  };

  const novaPoshtaSearch = (event, value, reason, type) => {
    const isClear = reason === 'clear' ? '' : value;
    if (event && event.type === 'change') {
      if (!type) {
        setSearch(value);
        getNovaPoshtaData(npApiKey, 'Address', 'getCities', 1, isClear);
      } else if (type === 'department') {
        getDepartmentOrOfficeData(npApiKey, npValues.cityValue.Ref, isClear, 1);
      } else {
        getDepartmentOrOfficeData(
          npApiKey,
          npValues.cityValue.Ref,
          isClear,
          1,
          true,
        );
      }
    }
  };

  const getDepartmentOrOfficeData = async (
    dropNpApiKey,
    ref,
    search,
    page,
    isOffice,
  ) => {
    try {
      const res = await novaPoshtaDepartmentOrPostOffice(
        dropNpApiKey,
        ref,
        search,
        page,
        isOffice,
      );
      if (page === 1) {
        setNpData(prevState => {
          return {
            ...prevState,
            departmentOrOffice: res.data.data,
          };
        });
      } else {
        setNpData(prevState => {
          return {
            ...prevState,
            departmentOrOffice: [
              ...prevState.departmentOrOffice,
              ...res.data.data,
            ],
          };
        });
      }
    } catch (error) {
      validationUserNpKey(error);
    }
  };

  const novaPoshtaPagination = type => {
    if (!type) {
      setPage(prevState => {
        return {
          ...prevState,
          citiesPaginationPageNum: prevState.citiesPaginationPageNum + 1,
        };
      });
      getNovaPoshtaData(
        npApiKey,
        'Address',
        'getCities',
        page.citiesPaginationPageNum + 1,
        search,
      );
    } else {
      setPage(prevState => {
        return {
          ...prevState,
          departmentOrOfficePageNum: prevState.departmentOrOfficePageNum + 1,
        };
      });
      if (type === 'department') {
        getDepartmentOrOfficeData(
          npApiKey,
          npValues.cityValue.Ref,
          '',
          page.departmentOrOfficePageNum + 1,
        );
      } else {
        getDepartmentOrOfficeData(
          npApiKey,
          npValues.cityValue.Ref,
          '',
          page.departmentOrOfficePageNum + 1,
          true,
        );
      }
    }
  };

  const onAutocompleteChange = (event, newValue, type) => {
    if (!type) {
      setNpValues(prevState => {
        return {
          ...prevState,
          cityValue: newValue,
        };
      });
      setData(prevState => {
        return {
          ...prevState,
          delivery_info: {
            ...prevState.delivery_info,
            city_title: newValue?.Description,
            city_ref: newValue?.Ref,
          },
        };
      });
    } else if (type === 'department') {
      setNpValues(prevState => {
        return {
          ...prevState,
          departmentOrOfficeValue: newValue,
        };
      });
      setData(prevState => {
        return {
          ...prevState,
          delivery_info: {
            ...prevState.delivery_info,
            department_title: newValue?.Description,
            department_ref: newValue?.Ref,
          },
        };
      });
    } else {
      setNpValues(prevState => {
        return {
          ...prevState,
          departmentOrOfficeValue: newValue,
        };
      });
      setData(prevState => {
        return {
          ...prevState,
          delivery_info: {
            ...prevState.delivery_info,
            post_office_title: newValue?.Description,
            post_office_ref: newValue?.Ref,
          },
        };
      });
    }
  };

  const onAutocompleteOpen = type => {
    if (!type) {
      setSearch('');
      getNovaPoshtaData(npApiKey);
    } else if (type === 'department' && npValues.cityValue) {
      getDepartmentOrOfficeData(npApiKey, npValues.cityValue.Ref, '', 1);
    } else {
      getDepartmentOrOfficeData(npApiKey, npValues.cityValue.Ref, '', 1, true);
    }
    setPage(prevState => {
      return {
        departmentOrOfficePageNum: 1,
        citiesPaginationPageNum: 1,
      };
    });
  };

  const quickNpCitySelection = ref => {
    getNovaPoshtaData(npApiKey, 'Address', 'getCities', 1, '', ref);
  };

  return {
    quickCitySelection: quickNpCitySelection,
    onAutocompleteOpen,
    onAutocompleteChange,
    autocompletePagination: novaPoshtaPagination,
    autocompleteInputChange: novaPoshtaSearch,
    setNpValidationKey,
    validationApiKey: npValidationKey,
    autocompleteData: npData,
    npValues,
  };
};
