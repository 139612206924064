import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Amount,
  ErrorAvailabilityProduct,
  ImgPrev,
  LabelProduct,
  UserAgreementModal,
} from '@root/components';
import { UserAgreement } from '@root/components/UserAgreement';
import { ButtonBase, CustomScroll, TextInput } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Divider, Typography, useTheme } from '@mui/material';

export const Order = ({ order, error, onChange, orderError, data }) => {
  const { color } = useTheme();
  const { discount } = data;
  const prices = useSelector(userSelectors.getPrices);
  const widgetCurrency = useSelector(userSelectors.getChoosedCurrencyWidget);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const handelSubmitDiscount = e => {
    e.preventDefault();
  };

  const { t } = useTranslation(['placing_order', 'common'], {
    useSuspense: false,
  });

  const handleToggleModal = isOpen => {
    setIsModalOpen(isOpen);
  };

  return (
    <Component>
      {isModalOpen && (
        <UserAgreementModal
          open={isModalOpen}
          handleToggleModal={handleToggleModal}
        />
      )}

      <OrderWrapper color={color.white}>
        <TitleWrapper>
          <Typography
            variant={'h3'}
            sx={{ color: color.gray }}
            component={'h2'}
          >
            {t('placing_order:ordered_products')}
          </Typography>
          <NavLink
            to={currentLang + '/catalog/cart'}
            style={{
              cursor: 'pointer',
            }}
          >
            <Typography
              variant={'bodyBoldSmall'}
              sx={{ color: color.main }}
              component={'p'}
            >
              {t('placing_order:edit_order')}
            </Typography>
          </NavLink>
        </TitleWrapper>

        <Divider />
        <Wrapper padding={'5px 24px 5px 24px'}>
          <Typography
            variant={'bodySmall'}
            sx={{ color: color.gray_80, width: '265px' }}
            component={'p'}
          >
            {t('placing_order:approximate_total_weight_of_order')}:
          </Typography>

          <Typography
            variant={'bodyBoldSmall'}
            sx={{ color: color.gray }}
            component={'p'}
          >
            {order?.weight} {t('placing_order:weight')}
          </Typography>
        </Wrapper>
        <Divider />
        <WrapperCart color={color.line}>
          <div style={{ height: '100%' }}>
            <CustomScroll vertical={true}>
              {order?.details.map(el => (
                <Fragment key={el.id}>
                  <Cart data-test-item="CartItem">
                    <ImgPrev src={el.image} />
                    <LabelProduct
                      label={el.product_name}
                      payment={{
                        price: el.personal_price,
                        quantity: el.quantity,
                      }}
                      isOrder
                    />
                    <AmountsWrapper>
                      <Amount data={el} onlyUAH />
                    </AmountsWrapper>
                  </Cart>
                  {orderError.length > 0 &&
                    orderError.map(
                      orderErr =>
                        orderErr.id === el.product_id && (
                          <Fragment key={orderErr.id}>
                            <ErrorAvailabilityProduct
                              text={orderErr?.error}
                              url={el.url}
                              has_analogs={orderErr?.has_analogs}
                            />
                          </Fragment>
                        ),
                    )}
                </Fragment>
              ))}
            </CustomScroll>
          </div>
        </WrapperCart>
        <Divider />
        <FormDiscount>
          <Typography
            variant={'bodySmall'}
            sx={{ color: color.gray_80, width: '133px', mr: '10px' }}
            component={'p'}
          >
            {t('placing_order:discount_coupon')}:
          </Typography>
          <FlexContainer>
            <TextInput
              error={error?.discount?.init}
              errorText={error?.discount?.text}
              sx={{ width: '100%' }}
              styleWrapper={{ width: '100%' }}
              value={discount}
              onChange={onChange('discount')}
              placeholder={t('placing_order:fields.type_coupon')}
              type="text"
              max={50}
              required={true}
              withOutSpace={true}
              // onBlur={hideValidation}
            />
            <ButtonBase
              onClick={handelSubmitDiscount}
              type="submit"
              size={'medium'}
              sx={{ flex: 1, minWidth: '96px', ml: '10px' }}
            >
              <Typography variant={'bodyBoldSmall'}>
                {t('common:buttons.apply')}
              </Typography>
            </ButtonBase>
          </FlexContainer>
        </FormDiscount>
        <Divider />
        <Wrapper padding={'12px 24px 12px 24px'}>
          <Typography
            variant={'bodySmall'}
            sx={{ color: color.gray_80, width: '265px' }}
            component={'p'}
          >
            {t('placing_order:to_be_paid')}:
          </Typography>

          <Typography
            variant={'h1'}
            sx={{
              color: color.gray,
              display: 'flex',
              alignItems: 'center',
            }}
            component={'p'}
            data-test-id="ToBePaidAmount"
          >
            {order?.amounts[choosedCurrency]}
            <Typography
              variant={'bodyBoldSmall'}
              sx={{ color: color.gray, ml: '5px' }}
            >
              {widgetCurrency.id === 2
                ? widgetCurrency.title
                : prices['price_uah_pdv'].title}
            </Typography>
          </Typography>
        </Wrapper>
      </OrderWrapper>
      <Typography
        variant={'bodySmall'}
        sx={{
          color: color.gray,
          mt: '21px',
          textAlign: 'center',
        }}
        component={'p'}
      >
        {t('placing_order:by_confirming_order_accept_term_&_condition')}{' '}
        <Typography
          variant={'bodyBoldSmall'}
          sx={{ color: color.gray, cursor: 'pointer' }}
          onClick={() => handleToggleModal(true)}
        >
          {t('placing_order:user_agreement')}
        </Typography>
      </Typography>
    </Component>
  );
};

const OrderWrapper = styled.div`
  height: calc(100% - 120px);
  background: ${props => props.color};
  border-radius: 4px;
  margin-left: 25px;
`;

const TitleWrapper = styled.div`
  display: flex;
  padding: 26px 24px 16px 24px;
  align-items: center;
  justify-content: space-between;
`;

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ padding }) => padding};
`;

const Cart = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px 16px;
`;

const WrapperCart = styled.div`
  height: calc(100% - 225px);

  ${Cart} + ${Cart} {
    border-top: 1px solid ${props => props.color};
  }
`;

const FormDiscount = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 24px 12px 24px;
`;

const FlexContainer = styled.div`
  display: flex;
  flex: 1;
`;

const AmountsWrapper = styled.div`
  margin-left: auto;
`;

const Component = styled.div`
  min-width: 42%;
`;

Order.propTypes = {
  order: PropTypes.exact({
    amounts: PropTypes.exact({
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      price_uah_cash: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      price_uah_no_pdv: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      price_uah_pdv: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    }),
    available_methods: PropTypes.exact({
      delivery_types: PropTypes.arrayOf(
        PropTypes.exact({
          id: PropTypes.number,
          items: PropTypes.arrayOf(
            PropTypes.exact({
              icon: PropTypes.string,
              id: PropTypes.number,
              isDefault: PropTypes.bool,
              key: PropTypes.string,
              subTitle: PropTypes.string,
              title: PropTypes.string,
            }),
          ),
          key: PropTypes.string,
          label: PropTypes.string,
          subTitle: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
      payment_types: PropTypes.arrayOf(
        PropTypes.exact({
          icon: PropTypes.string,
          id: PropTypes.number,
          isDefault: PropTypes.bool,
          key: PropTypes.string,
          subTitle: PropTypes.string,
          title: PropTypes.string,
        }),
      ),
    }),
    coupon: PropTypes.string,
    delivery_type: PropTypes.string,
    details: PropTypes.arrayOf(
      PropTypes.exact({
        amounts_personal: PropTypes.exact({
          price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          price_uah_cash: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          price_uah_no_pdv: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          price_uah_pdv: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
        }),
        amounts_product: PropTypes.exact({
          price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          price_uah_cash: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          price_uah_no_pdv: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          price_uah_pdv: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
        }),
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        description: PropTypes.string,
        id: PropTypes.number,
        product_id: PropTypes.number,
        product_name: PropTypes.string,
        quantity: PropTypes.number,
        image: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          price_uah_cash: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          price_uah_no_pdv: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          price_uah_pdv: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
        }),
        price: PropTypes.exact({
          price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
          price_uah_cash: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          price_uah_no_pdv: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
          price_uah_pdv: PropTypes.oneOfType([
            PropTypes.number,
            PropTypes.string,
          ]),
        }),
        prices: PropTypes.array,
        stock: PropTypes.number,
        url: PropTypes.string,
      }),
    ),
    dont_call: PropTypes.bool,
    first_name: PropTypes.string,
    id: PropTypes.number,
    last_name: PropTypes.string,
    middle_name: PropTypes.string,
    note: PropTypes.string,
    payment_type: PropTypes.string,
    phone: PropTypes.string,
    status: PropTypes.string,
    user_id: PropTypes.number,
    weight: PropTypes.number,
  }),
  error: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  orderError: PropTypes.array.isRequired,
  data: PropTypes.shape({
    discount: PropTypes.string,
  }),
};
