import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const saveUserNpApiKey = createAsyncThunk(
  'user/getUserNpApiKey',
  async ({ userApiKey, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put('/user/novaposhta', {
        key: userApiKey,
      });
      if (onResolve) onResolve();
      return data.data;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const putCurrencyValue = createAsyncThunk(
  'user/putCurrencyValue',
  async ({ price }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put('/user/price', {
        price,
      });
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateUserProfile = createAsyncThunk(
  'user/updateUserProfile',
  async ({ onResolve, onReject, ...restPayload }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put('user', restPayload);
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

const updateUserCompany = createAsyncThunk(
  'user/updateUserCompany',
  async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('company', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const getUserLogins = createAsyncThunk(
  'user/getUserLogins',
  async ({ onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('user/logins');
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const updateSecuritySettings = createAsyncThunk(
  'user/toggleSaveLogins',
  async (
    { onResolve, onReject, show_logins, ga_active },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.post(`user/settings`, null, {
        params: {
          show_logins,
          ga_active,
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const changePassword = createAsyncThunk(
  'user/changePassword',
  async (
    { onResolve, onReject, password, password_new, password_confirm },
    { rejectWithValue },
  ) => {
    try {
      const { data } = await axios.put(`user/password`, null, {
        params: {
          password,
          password_new,
          password_confirm,
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const getTwoFactorAuthenticationInfo = createAsyncThunk(
  'user/getTwoFactorAuthenticationInfo',
  async ({ onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(`user/gaactivate`, null);
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const activateTwoFactorAuthentication = createAsyncThunk(
  'user/activateTwoFactorAuthentication',
  async ({ onResolve, onReject, secret }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`user/gacheck`, null, {
        params: {
          secret,
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const deactivateTwoFactorAuthentication = createAsyncThunk(
  'user/activateTwoFactorAuthentication',
  async ({ onResolve, onReject, secret }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`user/gadisable`, null, {
        params: {
          secret,
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const createNewColorConfig = createAsyncThunk(
  'user/createNewColorConfig',
  async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('user/colors', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve();
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

export default {
  putCurrencyValue,
  saveUserNpApiKey,
  updateUserProfile,
  updateUserCompany,
  getUserLogins,
  updateSecuritySettings,
  changePassword,
  getTwoFactorAuthenticationInfo,
  activateTwoFactorAuthentication,
  deactivateTwoFactorAuthentication,
  createNewColorConfig,
};
