import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContactField } from '@root/components';

import adminSelectors from '@redux/admin/admin-selectors';

import { Typography, useTheme } from '@mui/material';

export const BusinessForm = () => {
  const data = useSelector(adminSelectors.getBusinessDetails);

  const {
    name,
    contractor_code,
    identification_code,
    company_type,
    status,
    official_name,
  } = data;

  const { t } = useTranslation(['admin'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <Form>
      <Typography variant={'h2'} sx={{ textTransform: 'uppercase' }}>
        {official_name}
      </Typography>
      <Wrapper>
        <ContactField
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.status')}
          value={
            status === 'active'
              ? t('admin:active_status')
              : t('admin:inactive_status')
          }
          variant={'bodyMedium'}
          onEdit={() => {}}
          goToStep={() => {}}
        />
        <ContactField
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.business')}
          variant={'bodyMedium'}
          value={name}
          onEdit={() => {}}
          goToStep={() => {}}
        />
        <ContactField
          padding={'28px 0'}
          title={t('admin:counterparties_table_conf.type')}
          value={company_type}
          onEdit={() => {}}
          goToStep={() => {}}
          variant={'bodyMedium'}
        />
        {contractor_code && (
          <ContactField
            padding={'28px 0'}
            title={t('admin:counterparties_table_conf.edrpou')}
            value={contractor_code}
            onEdit={() => {}}
            goToStep={() => {}}
            variant={'bodyMedium'}
          />
        )}
        {identification_code && (
          <ContactField
            padding={'28px 0'}
            title={t('admin:counterparties_table_conf.ipn')}
            value={identification_code}
            onEdit={() => {}}
            goToStep={() => {}}
            variant={'bodyMedium'}
          />
        )}
      </Wrapper>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 425px;
  padding-bottom: 50px;
`;
const Wrapper = styled.div`
  margin-top: 28px;
`;

BusinessForm.propTypes = {
  data: PropTypes.exact({
    additional_info: PropTypes.string,
    company_type: PropTypes.string,
    company_type_id: PropTypes.number,
    contractor_code: PropTypes.number,
    id: PropTypes.number,
    identification_code: PropTypes.number,
    name: PropTypes.string,
    official_name: PropTypes.string,
    site: PropTypes.string,
    status: PropTypes.string,
  }),
};
