import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import moment from 'moment/moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Blank } from '@root/assets/image';
import { Sprite } from '@root/assets/svg';
import { EditorContentWrapper, Empty } from '@root/components';
import { useNavigation } from '@root/hooks';
import { ListOfParticipantsModal } from '@root/modules';
import {
  ButtonBase,
  CustomScroll,
  IconBtn,
  OutlineButtonBase,
  ProgressLoader,
} from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';
import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';
import newsAndEventsOperation from '@redux/news-and-events/news-and-events-operation';
import newsAndEventsSelectors from '@redux/news-and-events/news-and-events-selectors';
import { clearState } from '@redux/news-and-events/news-and-events-slice';

import { Typography, useTheme } from '@mui/material';

export const Details = ({
  handleEditNewsModal,
  handleEditEventModal,
  openSignUpModal,
}) => {
  const [openListOfParticipantsModal, setOpenListOfParticipantsModal] =
    useState(false);
  const userRole = useSelector(authSelectors.getRole);
  const getArticleDetails = useSelector(newsAndEventsSelectors.articleDetails);
  const loadingArticleDetails = useSelector(
    newsAndEventsSelectors.loadingArticleDetails,
  );
  const dispatch = useDispatch();

  const { tab, slug } = useParams();
  const { navigation } = useNavigation();
  const { t } = useTranslation('news_and_events');
  const { color } = useTheme();

  useEffect(() => {
    if (slug) {
      if (tab === 'news') {
        dispatch(newsAndEventsOperation.getNewsListItemDetails({ slug }));
      } else {
        dispatch(newsAndEventsOperation.getEventsListItemDetails({ slug }));
      }
    }
  }, [slug]);

  const navigationAfterDeleteArticle = data => {
    if (tab === 'news') {
      dispatch(newsAndEventsOperation.deleteNews({ id: data.id })).then(res => {
        const { payload } = res;
        navigationTo(payload.data);
      });
    } else {
      dispatch(newsAndEventsOperation.deleteEvent({ id: data.id })).then(
        res => {
          const { payload } = res;
          navigationTo(payload.data);
        },
      );
    }
  };

  const navigationTo = data => {
    if (data.length > 0) {
      navigation(`/announcements/${tab}/${data[0].slug}`);
    } else {
      navigation(`/announcements/${tab}`);
      dispatch(clearState());
    }
    dispatch(clearModalState());
  };

  const callConfirmModal = () => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        dataForConfirm: {
          id: getArticleDetails?.id,
        },
        callback: data => {
          navigationAfterDeleteArticle(data);
        },
      }),
    );
  };

  return (
    <Wrap color={color}>
      <ScrollWrap>
        <CustomScroll vertical={true}>
          {loadingArticleDetails ? (
            <ProgressLoader />
          ) : Object.keys(getArticleDetails).length === 0 ? (
            <Empty type="EmptyBox" title="" subTitle="" buttonTittle="">
              <img src={Blank} />
            </Empty>
          ) : (
            <ContentWrap>
              {userRole === 'admin' && (
                <FlexWrap
                  display="flex"
                  justify={
                    tab !== 'events' ||
                    getArticleDetails?.registrations.length === 0
                  }
                >
                  {tab === 'events' &&
                    getArticleDetails?.registrations.length > 0 && (
                      <OutlineButtonBase
                        sx={{ height: '36px' }}
                        onClick={() => setOpenListOfParticipantsModal(true)}
                      >
                        <Typography
                          color={color.gray_60}
                          variant="bodyBoldSmall"
                        >
                          {t('buttons.list_of_participants')}
                        </Typography>
                      </OutlineButtonBase>
                    )}
                  <ButtonsWrap>
                    <IconBtn
                      size="16px"
                      href={`${Sprite}#icon-edit`}
                      sx={{ display: 'flex', alignItems: 'center' }}
                      onClick={
                        tab === 'news'
                          ? handleEditNewsModal
                          : handleEditEventModal
                      }
                    >
                      <Typography
                        color={`${color.gray_80} !important`}
                        variant="bodyBoldSmall"
                        sx={{ marginLeft: '3px' }}
                      >
                        {t('buttons.edit')}
                      </Typography>
                    </IconBtn>
                    <IconBtn
                      size="16px"
                      href={`${Sprite}#icon-delete`}
                      sx={{ display: 'flex', alignItems: 'center' }}
                      onClick={callConfirmModal}
                    >
                      <Typography
                        color={`${color.gray_80} !important`}
                        variant="bodyBoldSmall"
                        sx={{ marginLeft: '3px' }}
                      >
                        {t('buttons.delete')}
                      </Typography>
                    </IconBtn>
                  </ButtonsWrap>
                </FlexWrap>
              )}
              <FlexWrap display="flex">
                <Typography variant="h2" color={color.gray}>
                  {getArticleDetails?.title}
                </Typography>
              </FlexWrap>
              {tab === 'events' && (
                <FlexWrap display="flex">
                  <Div>
                    <Typography
                      color={color.gray_80}
                      variant="bodyBoldMedium"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Svg>
                        <use href={`${Sprite}#icon-calendar`}></use>
                      </Svg>
                      {moment(getArticleDetails?.date).format('DD.MM.YYYY')}{' '}
                      {getArticleDetails?.time}
                    </Typography>
                    {getArticleDetails?.location && (
                      <Typography
                        color={color.gray_80}
                        variant="bodyBoldMedium"
                        sx={{ display: 'flex', alignItems: 'center' }}
                      >
                        <Svg>
                          <use href={`${Sprite}#icon-location`}></use>
                        </Svg>
                        {getArticleDetails?.location}
                      </Typography>
                    )}
                  </Div>
                  <ButtonBase
                    disabled={!getArticleDetails?.is_active}
                    sx={{ height: '36px' }}
                    onClick={() => openSignUpModal(true, getArticleDetails?.id)}
                  >
                    <Typography variant="bodyBoldSmall" color={color.white}>
                      {t('buttons.sign_up')}
                    </Typography>
                  </ButtonBase>
                </FlexWrap>
              )}
              {getArticleDetails?.image && (
                <FlexWrap display="flex">
                  <Img src={getArticleDetails?.image} />
                </FlexWrap>
              )}
              <FlexWrap mb="0px">
                <EditorContentWrapper
                  editorContent={getArticleDetails?.content}
                />
              </FlexWrap>
            </ContentWrap>
          )}
        </CustomScroll>
      </ScrollWrap>
      {openListOfParticipantsModal && (
        <ListOfParticipantsModal
          openModal={openListOfParticipantsModal}
          handleCloseModal={() => setOpenListOfParticipantsModal(false)}
          data={getArticleDetails?.registrations}
        />
      )}
    </Wrap>
  );
};

const Wrap = styled.div`
  flex: 65%;
  background-color: ${({ color }) => color.white};
  position: relative;
  border: 1px solid ${({ color }) => color.line};
  border-radius: 4px;
`;

const ScrollWrap = styled.div`
  height: 100%;
`;

const ContentWrap = styled.div`
  padding: 34px;
`;

const FlexWrap = styled.div`
  display: ${({ display }) => (display ? display : 'block')};
  height: ${({ height }) => height};
  justify-content: ${({ justify }) => (justify ? 'flex-end' : 'space-between')};
  align-items: center;
  margin-bottom: ${({ mb }) => (mb ? mb : '24px')};
`;

const ButtonsWrap = styled.div`
  display: flex;
  gap: 27px;
`;

const Div = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
`;

const Svg = styled.svg`
  width: 15px;
  height: 15px;
  vertical-align: middle;
  position: relative;
  margin-right: 5px;
`;

Details.propTypes = {
  handleEditNewsModal: PropTypes.func.isRequired,
  handleEditEventModal: PropTypes.func.isRequired,
  openSignUpModal: PropTypes.func.isRequired,
};
