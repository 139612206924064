import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getOrder = createAsyncThunk(
  'order/getOrder',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

//*******OrderTemplate**********//
const getSaveOrderTemplate = createAsyncThunk(
  'order/getSaveOrderTemplate',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order/saved-payment-delivery');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addSaveOrderTemplate = createAsyncThunk(
  'order/addSaveOrderTemplate',
  async (payload, { rejectWithValue }) => {
    const { orderData, entity } = payload;
    try {
      const { data } = await axios.post(
        `order/saved-payment-delivery/${entity}`,
        orderData,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const editSaveOrderTemplate = createAsyncThunk(
  'order/editSaveOrderTemplate',
  async (payload, { rejectWithValue }) => {
    const { orderData, entity, id } = payload;
    try {
      const { data } = await axios.put(
        `order/saved-payment-delivery/${entity}/${id}`,
        orderData,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const changePinnedTemplate = createAsyncThunk(
  'order/changePinnedTemplate',
  async (payload, { rejectWithValue }) => {
    const { entity, id, pin } = payload;
    try {
      const { data } = await axios.put(
        `order/saved-payment-delivery/${entity}/${id}?pin=${pin}`,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const deleteTemplate = createAsyncThunk(
  'order/deleteTemplate',
  async (payload, { rejectWithValue }) => {
    const { entity, id } = payload;
    try {
      const { data } = await axios.delete(
        `order/saved-payment-delivery/${entity}/${id}`,
      );
      return data.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

//*******EndOrderTemplate**********//

const confirmOrder = createAsyncThunk(
  'order/confirmOrder',
  async (payload, { rejectWithValue }) => {
    try {
      const { data } = await axios.put('order', payload);
      return data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  },
);

const getMyOrders = createAsyncThunk(
  'order/getMyOrders',
  async ({ page, search, filterActive }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order/history', {
        params: { page, search, filterActive },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getArchiveOrders = createAsyncThunk(
  'order/getArchiveOrders',
  async ({ filterActive, date_from, date_to }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order/history/archives', {
        params: { filterActive, date_from, date_to },
      });

      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  },
);

const getCoOrders = createAsyncThunk(
  'order/getCoOrders',
  async ({ page, search, filterActive }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('commercials/list', {
        params: { page, search, filterActive },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getMyOrdersBySearch = createAsyncThunk(
  'order/getMyOrdersBySearch',
  async ({ page, search, filterActive }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order/history', {
        params: { page, search, filterActive },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getCoOrdersBySearch = createAsyncThunk(
  'order/getCoOrdersBySearch',
  async ({ page, search, filterActive }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('commercials/list', {
        params: { page, search, filterActive },
      });
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHistoryOrderById = createAsyncThunk(
  'order/getHistoryOrderById',
  async (
    { id, orderTab, archiveOrderWidjet },
    { rejectWithValue, getState },
  ) => {
    try {
      switch (orderTab) {
        case 'archive-orders':
          const state = getState();
          const archiveData = archiveOrderWidjet
            ? state.home.lastMonthsOrders
            : state.order.archiveOrders;
          return { data: archiveData.find(el => el.id === id) };
        case 'my-commercial-offers':
          const commercialsData = await axios.get(`commercials/list/${id}`);
          return commercialsData.data;
        default:
          const orderData = await axios.get(`order/history/${id}`);
          return orderData.data;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const cloneHistoryOrderById = createAsyncThunk(
  'order/cloneHistoryOrderById',
  async ({ id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`order/history/${id}/clone`);
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

export default {
  getOrder,
  getSaveOrderTemplate,
  getArchiveOrders,
  confirmOrder,
  getMyOrders,
  getHistoryOrderById,
  getMyOrdersBySearch,
  cloneHistoryOrderById,
  changePinnedTemplate,
  deleteTemplate,
  addSaveOrderTemplate,
  editSaveOrderTemplate,
  getCoOrders,
  getCoOrdersBySearch,
};
