import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  HeaderDefault,
  Menu,
  RoleMenu,
  Status,
  UserShortInfo,
} from '@root/components';
import { DeleteRoleConf } from '@root/conf';
import { correctPhone } from '@root/helpers';
import { ButtonBase, ModalBase, OutlineButtonBase } from '@root/ui';

import permissionOperation from '@redux/permission/permission-operation';
import permissionSelectors from '@redux/permission/permission-selectors';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

export const DeleteRoleModal = ({
  employees = [],
  defaultRoleId,
  handleClose,
  handelDeleteRole,
}) => {
  const [users, setUser] = useState([...employees]);
  const roles = useSelector(permissionSelectors.getPermission);
  const { color } = useTheme();
  const { t } = useTranslation(['profile', 'common'], { useSuspense: false });
  const dispatch = useDispatch();

  const handleUpdateUsersInfo = (id, name, value) => {
    setUser(prevUsers =>
      prevUsers.map(user =>
        user.id === id ? { ...user, [name]: value } : user,
      ),
    );
  };

  const isAllRolesChanged = useMemo(() => {
    return users.every(user => user.client_role_id !== defaultRoleId);
  }, [defaultRoleId, users]);

  const handleSubmit = () => {
    dispatch(
      permissionOperation.changeRoleByEmployees({
        users,
      }),
    ).then(el => {
      handelDeleteRole({ id: defaultRoleId });
      handleClose();
    });
  };

  return (
    <ModalBase
      open={true}
      handleClose={handleClose}
      sx={{ width: '100%', maxWidth: '923px' }}
      modalHeader={
        <HeaderDefault
          text={t('profile:conf.roles.modals.delete_role_modal.title')}
          onClose={handleClose}
        />
      }
      modalBottom={
        <ActionsWrapper>
          <OutlineButtonBase onClick={handleClose}>
            {t('common:buttons.cancel')}
          </OutlineButtonBase>
          <ButtonBase disabled={!isAllRolesChanged} onClick={handleSubmit}>
            {t('profile:conf.roles.modals.delete_role_modal.delete_role_btn')}
          </ButtonBase>
        </ActionsWrapper>
      }
    >
      <DeleteRoleWrapper>
        <Table sx={{ display: 'table !important' }} stickyHeader>
          <TableHead
            sx={{
              display: 'table-header-group !important',
            }}
          >
            <TableRow
              height="40px"
              sx={{
                borderBottom: `1px solid ${color.line}`,
              }}
            >
              {DeleteRoleConf(t).map(el => (
                <TableCell
                  sx={{
                    ...el.style,
                    borderBottom: `1px solid ${color.line}`,
                    borderTop: `1px solid ${color.line}`,
                  }}
                  key={el.label}
                >
                  <Typography variant="bodyBoldSmall" color={color.gray_60}>
                    {el.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {users.map(user => (
              <TableRow
                key={user.id}
                height="54px"
                sx={{
                  borderBottom: `1px solid ${color.line}`,
                }}
              >
                {DeleteRoleConf(t).map((el, idx) => {
                  switch (el.slug) {
                    case 'user':
                      return (
                        <TableCell sx={el.style} key={idx + 'key'}>
                          <UserShortInfo
                            avatar={user.avatar}
                            title={user.name}
                            subtitle={user.email}
                          />
                        </TableCell>
                      );

                    case 'role':
                      return (
                        <TableCell sx={el.style} key={idx + 'key'}>
                          <Menu
                            isDisplayItem={() => true}
                            type="role"
                            itemName={
                              [...roles].find(
                                el => el.id === user.client_role_id,
                              ).name
                            }
                            itemValue={
                              [...roles].find(
                                el => el.id === user.client_role_id,
                              ).id
                            }
                            menuItems={roles.filter(
                              el => el.id !== defaultRoleId,
                            )}
                            isNotDefaultRole={
                              user.client_role_id !== defaultRoleId
                            }
                            onChange={value =>
                              handleUpdateUsersInfo(
                                user.id,
                                'client_role_id',
                                value,
                              )
                            }
                            anchorOriginPosition={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            transformOriginPosition={{
                              vertical: 'top',
                              horizontal: 'left',
                            }}
                            menuBodySx={{
                              minWidth: '190px',
                              padding: '2px',
                              borderRadius: '4px',
                            }}
                            menuButtonSx={{
                              columnGap: '7px',
                              justifyItems: 'initial',
                              justifyContent: 'flex-start',
                              textTransform: 'initial',
                              textAlign: 'left',
                              fontSize: '12px',
                            }}
                            itemSx={{
                              padding: '8px !important',
                              color: color.gray,
                            }}
                          />
                        </TableCell>
                      );

                    case 'phone':
                      return (
                        <TableCell sx={el.style} key={idx + 'key'}>
                          {user.phones.map(el => (
                            <Typography
                              key={el}
                              variant="bodySmall"
                              color={color.gray}
                            >
                              {correctPhone(el?.phone)}
                            </Typography>
                          ))}
                        </TableCell>
                      );

                    case 'city':
                      return (
                        <TableCell sx={el.style} key={idx + 'key'}>
                          <Typography variant="bodySmall" color={color.gray}>
                            {user.city}
                          </Typography>
                        </TableCell>
                      );

                    case 'status':
                      return (
                        <TableCell sx={el.style} key={idx + 'key'}>
                          <Status
                            currentStatus={user.status}
                            statuses={{
                              active: {
                                text: t(
                                  'profile:conf.roles.modals.delete_role_modal.active_status',
                                ),
                                color: color.green,
                              },
                              inactive: {
                                text: t(
                                  'profile:conf.roles.modals.delete_role_modal.inactive_status',
                                ),
                                color: color.red,
                              },
                            }}
                          />
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </DeleteRoleWrapper>
    </ModalBase>
  );
};

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 24px;
`;

const DeleteRoleWrapper = styled.div``;

DeleteRoleModal.propsTypes = {
  employees: PropTypes.arrayOf(PropTypes.object),
  defaultRoleId: PropTypes.number,
  handleClose: PropTypes.func,
  handelDeleteRole: PropTypes.func,
};
