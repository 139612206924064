import PropTypes from 'prop-types';
import styled from 'styled-components';

import { theme } from '@root/styles';

import { Typography } from '@mui/material';

export const CharacteristicsInfo = ({
  data = {},
  width = '100%',
  hiddenTittle,
  limit,
}) => {
  //TODO a temporary solution, there is no ready-made category editor yet
  const arrInLimit = limit ? data.properties.slice(0, limit) : data.properties;

  return (
    <CharacteristicsBlock>
      {!hiddenTittle && (
        <Typography
          variant={'bodyBoldLarge'}
          sx={{
            marginTop: '24px',
            marginBottom: '16px',
            ml: '28px',
            color: theme.color.gray,
          }}
        >
          {data?.name}
        </Typography>
      )}

      {arrInLimit.map(el => (
        <Item width={width} color={theme.color.line} key={el.id}>
          <Typography
            variant={'bodySmall'}
            sx={{
              color: theme.color.gray,
              flex: 1,
            }}
          >
            {el.name}
          </Typography>
          <Typography
            variant={'bodySmall'}
            sx={{
              color: theme.color.gray,
              flex: 1,
            }}
          >
            {el.value}
          </Typography>
        </Item>
      ))}
    </CharacteristicsBlock>
  );
};

const Item = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 0;
  border-bottom: 1px solid ${props => props.color};
  width: ${({ width }) => width};
  margin-left: 28px;
`;

const CharacteristicsBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  max-height: inherit;
  margin-left: -28px;
`;

CharacteristicsInfo.propTypes = {
  data: PropTypes.exact({
    id: PropTypes.number,
    name: PropTypes.string,
    properties: PropTypes.array,
  }),
  width: PropTypes.string,
  hiddenTittle: PropTypes.bool,
  limit: PropTypes.number,
};
