import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';

import { Typography, useTheme } from '@mui/material';

export const StatusWithoutSteps = ({ data }) => {
  const { color } = useTheme();

  const { status_key, status_icon } = data;

  const colorStatus = () => {
    switch (status_key) {
      case 'executed':
        return color.green;
      case 'canceled':
        return color.red;
      default:
        return color.main;
    }
  };

  return (
    <Typography
      variant={'bodyBoldSmall'}
      component={'p'}
      sx={{
        mt: '4px',
        display: 'flex',
        alignItems: 'center',
        color: colorStatus(),
      }}
    >
      <Svg width="16" height="16" colorChosen={colorStatus()}>
        <use href={`${Sprite}#${status_icon}`}></use>
      </Svg>{' '}
      {data?.status}
    </Typography>
  );
};

const Svg = styled.svg`
  fill: ${({ colorChosen }) => colorChosen};
  stroke: ${({ colorChosen }) => colorChosen};
  margin: 0 4px 0 0;
  flex-shrink: 0;
`;

StatusWithoutSteps.propTypes = {
  data: PropTypes.object,
};
