import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ButtonBase, OutlineButtonBase } from '@root/ui';

import cartSelectors from '@redux/cart/cart-selectors';
import { onClearErrors } from '@redux/cart/cart-slice';
import notificationWatcherSelectors from '@redux/notification-watcher/notification-watcher-selectors';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import userSelectors from '@redux/user/user-selectors';

import { useTheme } from '@mui/material';

import { CreateSavedOrderModal } from './CreateSavedOrderModal';
import { SavedOrdersModal } from './SavedOrdersModal';

export const CartHeader = ({ emptyCart, handleClick }) => {
  const [isSavedOrdersModalOpen, setIsSavedOrdersModalOpen] = useState(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const permission = useSelector(userSelectors.getPermissions);
  const isNotificationOpen = useSelector(
    notificationWatcherSelectors.configuration,
  ).open;
  const error = useSelector(cartSelectors.getError);
  const dispatch = useDispatch();
  const { t } = useTranslation(['cart'], { useSuspense: false });
  const { color } = useTheme();

  const handleSetIsCreateIsOpen = () =>
    setIsCreateModalOpen(prevIsCreateModalOpen => !prevIsCreateModalOpen);
  const handleSetIsSavedOrdersOpen = () => {
    if (error.length > 0) {
      dispatch(onClearErrors());
    }
    setIsSavedOrdersModalOpen(
      prevIsSavedOrdersModalOpen => !prevIsSavedOrdersModalOpen,
    );
  };

  const handleSetIsNotificationOpen = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('cart:saved_product'),
        action: (
          <ButtonBase onClick={handleSetIsSavedOrdersOpen}>
            {t('common:buttons.view')}
          </ButtonBase>
        ),
      }),
    );
  };

  const handleCloseCreateModal = () => setIsCreateModalOpen(false);
  const handleCloseSavedOrderModal = () => setIsSavedOrdersModalOpen(false);

  return (
    <Component color={color.white}>
      <ButtonsWrapper>
        <OutlineButtonBase
          sx={{ marginRight: 'auto' }}
          onClick={handleSetIsSavedOrdersOpen}
          data-test-id="SavedOrdersButton"
        >
          {t('cart:saved_order')}
        </OutlineButtonBase>
        {!!emptyCart && (
          <>
            {permission.make_commercial_offer_access && (
              <OutlineButtonBase onClick={handleClick}>
                <svg width={16} height={16} style={{ marginRight: '5px' }}>
                  <use href={`${Sprite}#icon-co-doc`}></use>
                </svg>
                {t('cart:buttons.create_commercial_offer')}
              </OutlineButtonBase>
            )}
            {permission.cart_add_access && (
              <>
                <OutlineButtonBase
                  sx={{ marginLeft: '16px' }}
                  onClick={handleSetIsCreateIsOpen}
                  data-test-id="SaveCurrentOrderButton"
                >
                  <svg width={16} height={16} style={{ marginRight: '5px' }}>
                    <use href={`${Sprite}#icon-hart`}></use>
                  </svg>
                  {t('cart:buttons.save_order')}
                </OutlineButtonBase>

                <OutlineButtonBase sx={{ marginLeft: '16px' }}>
                  <svg width={16} height={16} style={{ marginRight: '5px' }}>
                    <use href={`${Sprite}#export`}></use>
                  </svg>
                  {t('cart:buttons.export')}
                </OutlineButtonBase>
              </>
            )}
          </>
        )}
        {permission.cart_add_access && (
          <OutlineButtonBase sx={{ marginLeft: '16px' }}>
            <svg width={16} height={16} style={{ marginRight: '5px' }}>
              <use href={`${Sprite}#import`}></use>
            </svg>
            {t('cart:buttons.import')}
          </OutlineButtonBase>
        )}
        {isCreateModalOpen && (
          <CreateSavedOrderModal
            open={isCreateModalOpen}
            handleClose={handleCloseCreateModal}
            onSavedOrderCreate={() => {
              handleSetIsNotificationOpen();
              handleCloseCreateModal();
            }}
          />
        )}

        {isSavedOrdersModalOpen && (
          <SavedOrdersModal
            open={isSavedOrdersModalOpen}
            handleClose={handleCloseSavedOrderModal}
            isCreateSavedOrder={isNotificationOpen}
          />
        )}
      </ButtonsWrapper>
    </Component>
  );
};

const ButtonsWrapper = styled.div`
  display: flex;
  padding: 20px 17px;
  flex-direction: row;
  align-items: center;
`;

const Component = styled.div`
  position: relative;
  background: ${props => props.color};
`;
CartHeader.propTypes = {
  emptyCart: PropTypes.number.isRequired,
};
