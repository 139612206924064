import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getCategory = createAsyncThunk(
  'category/getCategory',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('/category');
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getFilersCategory = createAsyncThunk(
  'category/getFilersCategory',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/filter/category/${id}`);

      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getCategoryListToAdmin = createAsyncThunk(
  'category/getCategoryListToAdmin',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/category/admin`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const postCategoryList = createAsyncThunk(
  'category/postCategoryList',
  async ({ categories, deleted, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const response = await axios.post(`/category/admin`, {
        categories,
        deleted,
      });

      if (onResolve) onResolve();
      return response.data.data;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const getManageFilters = createAsyncThunk(
  'category/getManageFilters',
  async ({ page, search, updateBySearch }, { rejectWithValue }) => {
    try {
      const response = await axios.get('filter/admin', {
        params: {
          page,
          search,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getManageWarehouses = createAsyncThunk(
  'category/getManageWarehouses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get('warehouse/admin');
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const editManageWarehouse = createAsyncThunk(
  'category/editManageWarehouse',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`warehouse/admin/${id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateManageWarehouseData = createAsyncThunk(
  'category/updateManageWarehouseData',
  async ({ id, public_name, sort, city_id }, thunkApi) => {
    try {
      const response = await axios.put(`warehouse/admin/${id}`, {
        public_name,
        sort,
        city_id,
      });
      thunkApi.dispatch(getListWarehouses());
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const removeManageWarehouseById = createAsyncThunk(
  'category/removeManageWarehouseById',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`warehouse/admin/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const postNewFilter = createAsyncThunk(
  'category/postNewFilter',
  async (data, { rejectWithValue }) => {
    try {
      const response = await axios.post('filter/admin', data);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const removeFilter = createAsyncThunk(
  'category/removeFilter',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`filter/admin/${id}`);

      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getListWarehouses = createAsyncThunk(
  'category/getListWarehouses',
  async (_, { rejectWithValue }) => {
    try {
      const response = await axios.get(`warehouse`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default {
  postCategoryList,
  getListWarehouses,
  getCategory,
  getFilersCategory,
  getCategoryListToAdmin,
  getManageFilters,
  postNewFilter,
  removeFilter,
  getManageWarehouses,
  editManageWarehouse,
  updateManageWarehouseData,
  removeManageWarehouseById,
};
