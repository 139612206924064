import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { getErrorStack } from '@root/helpers';
import {
  ButtonBase,
  CheckboxBase,
  ModalBase,
  ProgressLoader,
  RadioCustom,
  TextArea,
  TextInput,
} from '@root/ui';

import comparisonsOperation from '@redux/comparisons/comparisons-operation';
import comparisonsSelectors from '@redux/comparisons/comparisons-selectors';
import userSelectors from '@redux/user/user-selectors';

import {
  Divider,
  FormControlLabel,
  RadioGroup,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { Product } from './Product';

export const ComparisonCreatePdfModal = ({
  open,
  openFileModal,
  handleCloseModal,
  fieldsForCreatePdf,
}) => {
  const { color } = useTheme();
  const [data, setData] = useState({
    category_id: null,
    name: '',
    description: '',
    multiple: null,
    highlight_differences: true,
    only_differences: false,
    hide_price: true,
    own_price: [],
  });
  const { t } = useTranslation(['comparison', 'common', 'validation'], {
    useSuspense: false,
  });

  const [error, setError] = useState({});
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(userSelectors.getChoosedCurrencyWidget);
  const loading = useSelector(comparisonsSelectors.createPdfLoading);
  const dispatch = useDispatch();

  const { name, description, highlight_differences, hide_price, multiple } =
    data;

  useEffect(() => {
    const { category_id, only_differences, products } = fieldsForCreatePdf;
    setData(prevState => ({ ...prevState, category_id, only_differences }));
    rebuildProductsObj(products);
  }, [fieldsForCreatePdf]);

  const rebuildProductsObj = products => {
    const copyProducts = JSON.parse(JSON.stringify(products));
    let arr = [];
    for (const key in copyProducts) {
      arr.push({
        id: copyProducts[key].id,
        image: copyProducts[key].image,
        model: copyProducts[key].model,
        sku: copyProducts[key].sku,
        priceUsd: copyProducts[key].personal_price.price,
        choosedCurrencyPrice:
          copyProducts[key].personal_price[choosedCurrency.field_name],
      });
    }
    setData(prevState => ({
      ...prevState,
      own_price: arr,
    }));
  };
  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };
  const onChangePrice = (name, idx) => value => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.own_price[idx][name] = value;
    setData(dataCopy);
  };
  const onChangeRadio = (e, value) => {
    setData(prevState => ({ ...prevState, hide_price: value === 'true' }));
  };
  const onChangeCheckbox = value => {
    setData(prevState => ({ ...prevState, highlight_differences: value }));
  };
  const validation = () => {
    let flag = false;
    let currentError = {};

    if (!data.name || data.name === '') {
      currentError = getErrorStack(
        currentError,
        'name',
        t('validation:required'),
      );
      flag = true;
    }
    setError(currentError);
    return flag;
  };
  const createPdfFile = e => {
    e.preventDefault();
    if (validation()) return;

    const dataForSend = JSON.parse(JSON.stringify(data));

    dataForSend.own_price = dataForSend.own_price.map(item => ({
      id: item.id,
      price:
        choosedCurrency.id !== 4 && !multiple
          ? item.choosedCurrencyPrice
          : item.priceUsd,
    }));

    if (hide_price) delete dataForSend.own_price;

    dispatch(comparisonsOperation.createComparisonPdf(dataForSend)).then(() => {
      handleCloseModal();
      openFileModal(true);
    });
  };
  const TextInputAdornment = ({ type = 'start', text }) => {
    return (
      <TextInputStartAdornmentWrapper type={type}>
        {type === 'start' && (
          <TextInputStartAdornmentLine color={color.line} type={type} />
        )}
        <Typography color={color.gray} variant="bodySmall">
          {text}
        </Typography>
        {type === 'end' && (
          <TextInputStartAdornmentLine color={color.line} type={type} />
        )}
      </TextInputStartAdornmentWrapper>
    );
  };

  const calculateProductPrice = () => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.own_price.forEach((el, idx) => {
      if (dataCopy.multiple) {
        dataCopy.own_price[idx].choosedCurrencyPrice =
          Number(el.priceUsd) * Number(dataCopy.multiple);
      }
    });
    setData(dataCopy);
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleCloseModal}
      sx={{ width: '100%', maxWidth: '442px' }}
      modalHeader={
        <HeaderDefault
          text={t('comparisons:modals.settings_pdf_modal.settings_pdf_title')}
          onClose={handleCloseModal}
        />
      }
      modalBottom={
        <ButtonBaseWrapper>
          <ButtonBase type="submit" onClick={createPdfFile}>
            {t('common:buttons.generate')}
          </ButtonBase>
        </ButtonBaseWrapper>
      }
    >
      {loading && <ProgressLoader position={'absolute'} />}
      <SettingsPDFModalInner>
        <SettingsPDFModalPadding>
          <TextInput
            sx={{ height: '36px' }}
            required
            placeholder={t('comparisons:modals.settings_pdf_modal.fields.name')}
            value={name}
            error={error?.name?.init}
            errorText={error?.name?.text}
            max={50}
            onChange={onChange('name')}
          />
          <TextArea
            sx={{ width: '100%', margin: '12px 0' }}
            placeholder={t(
              'comparisons:modals.settings_pdf_modal.fields.description',
            )}
            value={description}
            max={200}
            onChange={onChange('description')}
          />
          <CheckboxBase
            width="16"
            checked={highlight_differences}
            onChange={value => onChangeCheckbox(value)}
          >
            <Typography
              color={color.gray_80}
              variant="bodyMedium"
              marginLeft="8px"
              marginTop="-1.5px"
            >
              {t(
                'comparisons:modals.settings_pdf_modal.highlight_differences_checkbox',
              )}
            </Typography>
          </CheckboxBase>

          <Divider sx={{ marginTop: '20px', marginBottom: '20px' }} />
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            value={hide_price}
            onChange={onChangeRadio}
            sx={{ paddingLeft: '2px' }}
          >
            <FormControlLabel
              value={true}
              control={<RadioCustom />}
              sx={{ width: 'fit-content' }}
              label={
                <Typography
                  variant={'bodyMedium'}
                  sx={{ color: color.gray_80 }}
                  component={'p'}
                >
                  {t('comparisons:modals.settings_pdf_modal.radio.hide_price')}
                </Typography>
              }
            />
            <FormControlLabel
              value={false}
              control={<RadioCustom />}
              sx={{ width: 'fit-content' }}
              label={
                <Typography
                  variant={'bodyMedium'}
                  sx={{ color: color.gray_80 }}
                  component={'p'}
                >
                  {t(
                    'comparisons:modals.settings_pdf_modal.radio.set_own_price',
                  )}
                </Typography>
              }
            />
          </RadioGroup>
          {!hide_price && (
            <SettingsPriceWrapper>
              {choosedCurrency.id !== 4 && (
                <ExchangeRateWrapper>
                  <Typography variant={'bodyMedium'} color={color.gray}>
                    {t('comparisons:course')}:
                  </Typography>
                  <TextInput
                    startAdornment={
                      <TextInputAdornment type="end" text="USD" />
                    }
                    type={'number'}
                    sx={{ height: '32px', width: '136px' }}
                    value={multiple}
                    onChange={onChange('multiple')}
                    onBlur={calculateProductPrice}
                  />
                </ExchangeRateWrapper>
              )}
              <PricesWrapper>
                {data.own_price.map((product, idx) => (
                  <Product
                    key={product.id}
                    index={idx}
                    product={product}
                    TextInputAdornment={TextInputAdornment}
                    onChangePrice={onChangePrice}
                    onBlur={calculateProductPrice}
                  />
                ))}
              </PricesWrapper>
            </SettingsPriceWrapper>
          )}
        </SettingsPDFModalPadding>
      </SettingsPDFModalInner>
    </ModalBase>
  );
};

const ButtonBaseWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 23px;
`;

const SettingsPriceWrapper = styled.div``;

const PricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-top: 12px;
`;

const ExchangeRateWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 24px;
`;

const SettingsPDFModalInner = styled.div`
  max-height: 740px;
  overflow-y: auto;
`;

const SettingsPDFModalPadding = styled.div`
  padding-right: 5px;
`;

const TextInputStartAdornmentWrapper = styled.div`
  padding-right: ${({ type }) => (type === 'start' ? '0px' : '8px')};
  display: flex;
  align-items: center;
`;
const TextInputStartAdornmentLine = styled.div`
  width: 1px;
  height: 15px;
  background-color: ${props => props.color};
  margin-left: 8px;
  margin-right: ${({ type }) => (type === 'end' ? '0px' : '8px')};
`;

ComparisonCreatePdfModal.propTypes = {
  open: PropTypes.bool.isRequired,
  openFileModal: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  fieldsForCreatePdf: PropTypes.exact({
    category_id: PropTypes.number,
    only_differences: PropTypes.bool,
    products: PropTypes.objectOf(
      PropTypes.exact({
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        properties: PropTypes.object,
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
      }),
    ),
  }),
};
