import { createSlice } from '@reduxjs/toolkit';

import orderServiceOperation from './order-service-operation';

const initialState = {
  serviceOrders: null,
  isServiceOrdersLoading: false,
  searchValue: '',
  errorOneC: false,
};

const orderServiceSlice = createSlice({
  name: 'orderService',
  initialState,
  reducers: {
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
    onClearServiceOrders: state => {
      state.serviceOrders = initialState.serviceOrders;
      state.errorOneC = initialState.errorOneC;
    },
  },
  extraReducers: {
    //====getServiceOrders====//
    [orderServiceOperation.getServiceOrders.pending]: (state, { payload }) => {
      state.isServiceOrdersLoading = true;
      state.errorOneC = false;
    },
    [orderServiceOperation.getServiceOrders.fulfilled]: (
      state,
      { payload },
    ) => {
      state.isServiceOrdersLoading = false;
      state.serviceOrders = payload;
      state.errorOneC = false;
    },
    [orderServiceOperation.getServiceOrders.rejected]: (state, { payload }) => {
      const { data } = payload;
      state.isServiceOrdersLoading = false;
      state.errorOneC = data.error_key === '1C_OUT';
    },
    //====createNewServiceLogistic====//
    [orderServiceOperation.createNewServiceLogistic.pending]: (
      state,
      { payload },
    ) => {
      state.isServiceOrdersLoading = true;
    },
    [orderServiceOperation.createNewServiceLogistic.fulfilled]: (
      state,
      { payload },
    ) => {
      state.isServiceOrdersLoading = false;
      state.serviceOrders = payload;
    },
    [orderServiceOperation.createNewServiceLogistic.rejected]: (
      state,
      { payload },
    ) => {
      state.isServiceOrdersLoading = false;
    },
  },
});

export const { setSearchValue, onClearServiceOrders } =
  orderServiceSlice.actions;

export default orderServiceSlice.reducer;
