import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { CustomizedSwitches, IconBtn } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const InputField = ({ variant, sx, type, value, onEdit, goToStep }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <>
      <FieldDescription>
        <WrapperText>
          <Typography
            sx={{
              position: 'relative',
              display: 'flex',
              alignItems: 'center',
              width: '190px',
              wordBreak: 'break-all',

              ...sx,
            }}
            variant={variant}
            component={'p'}
          >
            {type === 'area' ? '' : value}
          </Typography>
        </WrapperText>
      </FieldDescription>

      {(onEdit || goToStep) &&
        (!isEdit ? (
          <IconBtn
            href={`${Sprite}#icon-pan`}
            onClick={() => (goToStep ? goToStep() : setIsEdit(true))}
            tooltipText={t('common:tooltip.edit')}
          />
        ) : (
          <IconsWrapper>
            <IconBtn
              onClick={() => setIsEdit(false)}
              href={`${Sprite}#icon-cross`}
              sx={{ stroke: color.red }}
            />
            <IconBtn
              onClick={() => {}}
              href={`${Sprite}#icon-tick`}
              sx={{ stroke: color.green }}
            />
          </IconsWrapper>
        ))}
    </>
  );
};

const FieldDescription = styled.div`
  flex: 1 1 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WrapperText = styled.div`
  padding-right: 5px;
`;
