import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { MutualSettlementsDetailsConf } from '@root/conf';
import { CustomScroll } from '@root/ui';

import mutualSettlementsSelectors from '@redux/mutual-settlements/mutual-settlements-selectors';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { NoMutualSettlementDetails } from './NoMutualSettlementDetails';

export const MutualSettlementsDetails = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['mutual_settlements']);

  const selectedMutualSettlement = useSelector(
    mutualSettlementsSelectors.getSelectedMutualSettlement,
  );
  return (
    <MutualSettlementsDetailsWrapper color={color}>
      {selectedMutualSettlement ? (
        selectedMutualSettlement.template === 'act' ? (
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow sx={{ display: 'block' }}>
                    <TableRowWrap color={color}>
                      {MutualSettlementsDetailsConf(t).map(detail => (
                        <TableCell sx={{ ...detail.style }} key={detail.slug}>
                          <Typography
                            variant="bodyBoldSmall"
                            component="p"
                            color={color.gray_60}
                          >
                            {detail.label}
                          </Typography>
                        </TableCell>
                      ))}
                    </TableRowWrap>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    height: 'calc(100vh - 214px)',
                    display: 'block',
                  }}
                >
                  <CustomScroll vertical>
                    {selectedMutualSettlement.document_details &&
                      [
                        ...selectedMutualSettlement.document_details,
                        { isTotal: true },
                      ].map((data, idx) => (
                        <TableRow
                          key={data.product_name + data.product_guid}
                          sx={{ display: 'block' }}
                        >
                          <TableRowWrap color={color} isTotal={data.isTotal}>
                            {MutualSettlementsDetailsConf(t).map(
                              (detail, index) => {
                                switch (detail.slug) {
                                  case 'number':
                                    return (
                                      <TableCell
                                        key={
                                          data.product_name +
                                          data.product_guid +
                                          detail.slug
                                        }
                                        sx={{
                                          ...detail.style,
                                        }}
                                      >
                                        {!data.isTotal && (
                                          <Typography
                                            variant="bodySmall"
                                            component="p"
                                            color={color.gray_80}
                                          >
                                            {idx + 1}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    );
                                  case 'name':
                                    return (
                                      <TableCell
                                        key={
                                          data.product_name +
                                          data.product_guid +
                                          detail.slug
                                        }
                                        sx={{ ...detail.style }}
                                      >
                                        <Typography
                                          variant="bodySmall"
                                          component="p"
                                          color={color.gray_80}
                                        >
                                          {data.product_name}
                                        </Typography>
                                      </TableCell>
                                    );
                                  case 'quantity':
                                    return (
                                      <TableCell
                                        key={
                                          data.product_name +
                                          data.product_guid +
                                          detail.slug
                                        }
                                        sx={{ ...detail.style }}
                                      >
                                        <Typography
                                          variant="bodySmall"
                                          component="p"
                                          color={color.gray_80}
                                        >
                                          {data.qty}
                                        </Typography>
                                      </TableCell>
                                    );
                                  case 'unit_of_measurement':
                                    return (
                                      <TableCell
                                        key={
                                          data.product_name +
                                          data.product_guid +
                                          detail.slug
                                        }
                                        sx={{ ...detail.style }}
                                      >
                                        <Typography
                                          variant="bodySmall"
                                          component="p"
                                          color={color.gray_80}
                                        >
                                          {data.unit}
                                        </Typography>
                                      </TableCell>
                                    );
                                  case 'price':
                                    return (
                                      <TableCell
                                        key={
                                          data.product_name +
                                          data.product_guid +
                                          detail.slug
                                        }
                                        sx={{ ...detail.style }}
                                      >
                                        {data.isTotal ? (
                                          <Typography
                                            variant="bodySmall"
                                            color={color.gray_80}
                                          >
                                            Всього
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="bodySmall"
                                            component="p"
                                            color={color.gray_80}
                                          >
                                            {data.price}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    );
                                  case 'amount':
                                    return (
                                      <TableCell
                                        key={
                                          data.product_name +
                                          data.product_guid +
                                          detail.slug
                                        }
                                        sx={{ ...detail.style }}
                                      >
                                        {data.isTotal ? (
                                          <Typography
                                            variant="bodyBoldSmall"
                                            color={color.gray}
                                          >
                                            {
                                              selectedMutualSettlement.money_plus
                                            }
                                          </Typography>
                                        ) : (
                                          <Typography
                                            variant="bodySmall"
                                            component="p"
                                            color={color.gray_80}
                                          >
                                            {data.summ}
                                          </Typography>
                                        )}
                                      </TableCell>
                                    );
                                }
                              },
                            )}
                          </TableRowWrap>
                        </TableRow>
                      ))}
                  </CustomScroll>
                </TableBody>
              </Table>
            </TableContainer>
          </>
        ) : (
          <IncomeBox>
            <IncomeBoxHeader color={color}>
              <Typography
                component={'span'}
                color={color.gray_60}
                variant="bodyBoldSmall"
                sx={{
                  flex: '33.33%',
                }}
              >
                {t('amount_in_currency')}
              </Typography>
              <Typography
                component={'span'}
                color={color.gray_60}
                variant="bodyBoldSmall"
                sx={{
                  flex: '33.33%',
                }}
              >
                {t('amount_of_mutual_settlement')}
              </Typography>
              <Typography
                component={'span'}
                color={color.gray_60}
                variant="bodyBoldSmall"
                sx={{
                  flex: '33.33%',
                }}
              >
                {t('course')}
              </Typography>
            </IncomeBoxHeader>
            {selectedMutualSettlement.document_details.map((item, idx) => (
              <IncomeBoxBody key={idx} color={color}>
                {Object.keys(item).map(
                  (subItem, subIdx) =>
                    subItem !== 'currency' &&
                    subItem !== 'currency_code' && (
                      <Typography
                        key={subIdx + 1}
                        component={'span'}
                        color={color.gray_80}
                        variant="bodySmall"
                        sx={{
                          flex: '33.33%',
                        }}
                      >
                        {item[subItem]}
                      </Typography>
                    ),
                )}
              </IncomeBoxBody>
            ))}
            {selectedMutualSettlement.notes && (
              <Typography
                color={color.gray_80}
                variant="bodySmall"
                component="p"
                sx={{ padding: '16px' }}
              >
                {selectedMutualSettlement.notes}
              </Typography>
            )}
          </IncomeBox>
        )
      ) : (
        <NoMutualSettlementDetails t={t} />
      )}
    </MutualSettlementsDetailsWrapper>
  );
};

const MutualSettlementsDetailsWrapper = styled.div`
  flex: 0 1 50%;
  border-radius: 4px;
  border: ${({ color }) => `1px solid ${color.line}`};
  background: ${({ color }) => color.white};
`;

const IncomeBox = styled.div``;

const IncomeBoxHeader = styled.div`
  padding: 12px 24px;
  display: flex;
  text-align: center;
  border-bottom: 1px solid ${({ color }) => color.line};
`;

const IncomeBoxBody = styled.div`
  display: flex;
  padding: 12px 24px;
  border-bottom: 1px solid ${({ color }) => color.line};
  text-align: center;
`;

const TableRowWrap = styled.div`
  display: flex;
  align-items: center;
  border-bottom: ${({ color, isTotal }) =>
    !isTotal && `1px solid ${color.line}`};
  padding: 12px 24px;
`;
