import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, Header, VirtualizedTable } from '@root/components';
import { DeliveryHistoryConf } from '@root/conf';
import { DeliveryHistoryNavigation } from '@root/modules/DeliveryHistory/DeliveryHistoryNavigation';
import { BasicTooltip, LabelStatus, ProgressLoader } from '@root/ui';

import logisticSelectors from '@redux/logistic/logistic-selectors';
import {
  setSearchValue,
  setSelectedDelivery,
} from '@redux/logistic/logistic-slice';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const DeliveryHistory = ({ handleFetchDeliveryHistory }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });

  const [isSearchExpanded, setIsSearchExpanded] = useState(false);
  const [activeRow, setActiveRow] = useState();

  const { logisticsTab } = useParams();

  const dispatch = useDispatch();

  const filteredDeliveryHistory = useSelector(
    logisticSelectors.getFilteredogistic,
  );
  const isDeliveryHistoryLoading = useSelector(
    logisticSelectors.getIsDeliveryHistoryLoading,
  );
  const searchTerm = useSelector(logisticSelectors.getSearchValue);

  const handleToggleSearchExpended = () =>
    setIsSearchExpanded(prevIsSearchExpanded => !prevIsSearchExpanded);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50,
    }),
  );

  const handleSetActiveRow = selectedRow => {
    const {
      number,
      repair: repair_array,
      return: return_array,
      logistic_service,
      ttn_number,
      phone_number,
    } = selectedRow;
    setActiveRow(number);
    dispatch(
      setSelectedDelivery({
        repair_array,
        return_array,
        logistic_service,
        ttn_number,
        phone_number,
      }),
    );
  };

  const handleResetActiveRow = () => {
    setActiveRow(0);
  };

  return (
    <DeliveryHistoryWrapper background={color.white} border={color.line}>
      <DeliveryHistoryNavigation
        handleFetchDeliveryHistory={handleFetchDeliveryHistory}
      >
        <Header
          isTopBorder={true}
          isBottomBorder={true}
          isExpanded={isSearchExpanded}
          handleFetchDeliveryHistory={handleFetchDeliveryHistory}
          handleToggleSearchExpended={handleToggleSearchExpended}
          onSearchTermUpdate={searchTerm =>
            dispatch(setSearchValue(searchTerm))
          }
        />
        <div
          style={{
            position: 'relative',
            height: 'calc(100vh - 271px)',
          }}
        >
          {isDeliveryHistoryLoading && <ProgressLoader />}
          {filteredDeliveryHistory.length > 0 && (
            <VirtualizedTable
              tabType={logisticsTab}
              isSearchExpanded={isSearchExpanded}
              handleResetActiveRow={handleResetActiveRow}
              tableConf={DeliveryHistoryConf(t)}
              checkIsInclude={true}
            >
              <TableBody
                sx={{
                  height: 'calc(100vh - 305px)',
                  display: 'block !important',
                }}
              >
                <ScrollWrapper
                  colors={{
                    gray: color.gray_40,
                    white: color.white,
                    line: color.line,
                  }}
                >
                  <AutoSizer>
                    {({ width, height }) => (
                      <List
                        width={width}
                        height={height}
                        rowHeight={cache.current.rowHeight}
                        defferedMeasurementCache={cache.current}
                        rowCount={filteredDeliveryHistory.length}
                        rowRenderer={({ key, index, style, parent }) => {
                          const data = filteredDeliveryHistory[index];

                          return (
                            <CellMeasurer
                              key={key}
                              cache={cache.current}
                              parent={parent}
                              columnIndex={0}
                              rowIndex={index}
                            >
                              <TableRow
                                onClick={() => handleSetActiveRow(data)}
                                sx={{
                                  borderBottom: `1px solid ${color.line}`,
                                  '&:last-child, &:last-child td': {
                                    borderBottom: 0,
                                  },
                                  userSelect: 'auto',
                                  boxShadow: 'none',
                                  th: {
                                    borderBottom: 'none',
                                  },
                                  display: 'flex',
                                  alignItems: 'center',
                                  padding: '4px 16px',
                                  cursor: 'pointer',
                                  background:
                                    activeRow === data.number
                                      ? color.main_light
                                      : color.white,
                                  borderLeft:
                                    activeRow === data.number
                                      ? `3px solid ${color.main}`
                                      : '',

                                  '&:hover': {
                                    background:
                                      activeRow !== data.number &&
                                      color.gray_20,
                                  },
                                }}
                                style={style}
                              >
                                {DeliveryHistoryConf(t).map(delivery => {
                                  switch (delivery.slug) {
                                    case 'date':
                                      return (
                                        delivery.type.includes(
                                          logisticsTab,
                                        ) && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug + key}
                                          >
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray_80}
                                            >
                                              {data.date}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );

                                    case 'document_number':
                                      return (
                                        delivery.type.includes(
                                          logisticsTab,
                                        ) && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug + key}
                                          >
                                            <Typography
                                              variant="bodyBoldSmall"
                                              color={color.gray}
                                            >
                                              #{data.number}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );

                                    case 'delivery_method':
                                      return (
                                        delivery.type.includes(
                                          logisticsTab,
                                        ) && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug + key}
                                          >
                                            {data.logistic_service ===
                                              'Нова Пошта' &&
                                            !data.ttn_number ? null : (
                                              <Typography
                                                color={color.gray}
                                                variant="bodyBoldSmall"
                                              >
                                                {data.logistic_service}
                                              </Typography>
                                            )}{' '}
                                            {data.logistic_service ===
                                              'Нова Пошта' &&
                                              data.ttn_number && (
                                                <Typography
                                                  variant="bodySmall"
                                                  color={color.gray_80}
                                                >
                                                  TTH №{data.ttn_number}
                                                </Typography>
                                              )}
                                          </TableCell>
                                        )
                                      );

                                    case 'weight':
                                      return (
                                        delivery.type.includes(
                                          logisticsTab,
                                        ) && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug + key}
                                          >
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray}
                                            >
                                              {data.weight}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );

                                    case 'sits':
                                      return (
                                        delivery.type.includes(
                                          logisticsTab,
                                        ) && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug + key}
                                          >
                                            <Typography
                                              variant="bodySmall"
                                              color={color.gray}
                                            >
                                              {data.places}
                                            </Typography>
                                          </TableCell>
                                        )
                                      );

                                    case 'status':
                                      return (
                                        delivery.type.includes(
                                          logisticsTab,
                                        ) && (
                                          <TableCell
                                            sx={{
                                              borderBottom: 'none',
                                              ...delivery.style,
                                              p: 0,
                                            }}
                                            key={delivery.slug + key}
                                          >
                                            <LabelStatus>
                                              <Typography
                                                variant="bodyBoldSmall"
                                                color={`${color.gray} !important`}
                                              >
                                                {data.status}
                                              </Typography>
                                            </LabelStatus>
                                          </TableCell>
                                        )
                                      );
                                  }
                                })}
                              </TableRow>
                            </CellMeasurer>
                          );
                        }}
                      />
                    )}
                  </AutoSizer>
                </ScrollWrapper>
              </TableBody>
            </VirtualizedTable>
          )}

          {searchTerm === '' && filteredDeliveryHistory.length === 0 && (
            <Empty type={'NoHistory'} />
          )}

          {searchTerm !== '' && filteredDeliveryHistory.length === 0 && (
            <Empty />
          )}
        </div>
      </DeliveryHistoryNavigation>
    </DeliveryHistoryWrapper>
  );
};

DeliveryHistory.propTypes = {
  handleFetchDeliveryHistory: PropTypes.func.isRequired,
};

const DeliveryHistoryWrapper = styled.div`
  border: ${({ border }) => `1px solid ${border}`};
  border-radius: 4px;
  background: ${({ background }) => background};
  width: 42%;
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  height: inherit;
  max-height: inherit;

  div {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => props.colors.gray};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => props.colors.white};
      border-left: 1px solid ${props => props.colors.line};
    }
  }
`;
