import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, useTheme } from '@mui/material';

export const NumListItem = ({ children, text, marginTop }) => {
  const { color } = useTheme();
  return (
    <ItemListWrapper marginTop={marginTop || '23px'}>
      <Typography color={color.gray_80} variant={text ? 'h2' : 'bodyLarge'}>
        . {text}
        {children}
      </Typography>
    </ItemListWrapper>
  );
};

const ItemListWrapper = styled.li`
  display: flex;
  align-items: baseline;
  counter-increment: listCounter;

  h2 {
    margin-top: 28px;
  }

  span {
    margin-top: ${({ marginTop }) => marginTop};
  }

  span::marker,
  h2::marker {
    content: counters(listCounter, '.', upper-roman);
  }

  span:before,
  h2:before {
    content: counters(listCounter, '.');
  }
`;

NumListItem.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  marginTop: PropTypes.string,
};
