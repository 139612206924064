import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getBusinessData = createAsyncThunk(
  'admin/getBusinessData',
  async ({ page, per_page }, thunkApi) => {
    try {
      const response = await axios.get(`admin/company`, {
        params: { page, per_page },
      });

      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getCounterpartyData = createAsyncThunk(
  'admin/getCounterpartyData',
  async ({ page, per_page, q }, thunkApi) => {
    try {
      const response = await axios.get(`admin/counterparty`, {
        params: { page, per_page },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);
const getPeopleData = createAsyncThunk(
  'admin/getPeople',
  async ({ page, per_page, q }, thunkApi) => {
    try {
      const response = await axios.get(`admin/user`, {
        params: { page, per_page, q },
        headers: {
          Accept: 'application/json',
        },
      });
      return response.data;
    } catch (error) {
      return thunkApi.rejectWithValue(error);
    }
  },
);

const getBusinessesSearch = createAsyncThunk(
  'admin/getBusinessesSearch',
  async ({ search, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`admin/company/search`, {
        params: { q: search, page, per_page },
      });

      return { ...response.data, search };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getCounterpartsSearch = createAsyncThunk(
  'admin/getCounterpartsSearch',
  async ({ search, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`admin/counterparty/search`, {
        params: { q: search, page, per_page },
      });

      return { ...response.data, search };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getPeopleSearch = createAsyncThunk(
  'admin/getPeopleSearch',
  async ({ search, page, per_page }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`admin/user/search`, {
        params: { q: search, page, per_page },
      });

      return { ...response.data, search };
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const updateAdminBusiness = createAsyncThunk(
  'admin/updateAdminCompanyStatus',
  async ({ id, payload, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`admin/company/${id}`, {
        ...payload,
      });

      if (onResolve) onResolve();

      return data.data;
    } catch (error) {
      if (onReject) onReject();
      return rejectWithValue(error);
    }
  },
);

const updateAdminUser = createAsyncThunk(
  'admin/updateAdminUser',
  async ({ id, payload, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`admin/user/${id}`, {
        ...payload,
      });

      if (onResolve) onResolve();

      return data.data;
    } catch (error) {
      if (onReject) onReject(error);
      return rejectWithValue(error);
    }
  },
);

export default {
  getBusinessData,
  getCounterpartyData,
  getPeopleData,
  getBusinessesSearch,
  getCounterpartsSearch,
  getPeopleSearch,
  updateAdminBusiness,
  updateAdminUser,
};
