import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Menu, Pagination, UserShortInfo } from '@root/components';
import { EmployeeStatusConf, ListOfEmployeesConf } from '@root/conf';
import { correctPhone, handleDispatchFetch } from '@root/helpers';
import { useQueryParams } from '@root/hooks';
import {
  BasicTooltip,
  CustomizedSwitches,
  ProgressLoader,
  StatusBase,
} from '@root/ui';

import employeesOperation from '@redux/employees/employees-operation';
import employeesSelectors from '@redux/employees/employees-selectors';
import { setOpenModal } from '@redux/modal-watcher/modal-watcher-slice';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import permissionOperation from '@redux/permission/permission-operation';
import userSelectors from '@redux/user/user-selectors';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

export const ListOfEmployees = () => {
  const employees = useSelector(employeesSelectors.getEmployees);
  const employeesMeta = useSelector(employeesSelectors.getEmployeesMeta);
  const permission = useSelector(userSelectors.getPermissions);
  const isEmployeesLoading = useSelector(
    employeesSelectors.getIsEmployeesLoading,
  );

  const employeeRoles = useSelector(employeesSelectors.getEmployeeRoles);
  const currentUserId = useSelector(userSelectors.getUserId);

  const [companyEmployees, setCompanyEmployees] = useState([]);

  const { getQueryObj, setSearchParams } = useQueryParams();

  const dispatch = useDispatch();

  const { t } = useTranslation(['profile'], { useSuspense: false });
  const { color } = useTheme();

  const STATUSES = {
    active: {
      id: 'active',
      name: t('common:active'),
      color: color.green,
    },
    inactive: {
      id: 'inactive',
      name: t('common:locked'),
      color: color.red,
    },
  };

  document.title = t('profile:conf.list_of_employees.title');

  useEffect(() => {
    const { page, per_page } = getQueryObj();

    const fetchParams =
      page && per_page
        ? {
            page,
            per_page,
          }
        : {};

    handleGetEmployees(fetchParams);
    handleGetRoles();
  }, []);

  useEffect(() => {
    setCompanyEmployees(employees);
  }, [employees]);

  const handleGetEmployees = ({ page = 1, per_page = 10 }) => {
    setSearchParams({ page, per_page });
    dispatch(employeesOperation.getEmployees({ page, per_page }));
  };

  const handleGetRoles = () => {
    dispatch(permissionOperation.getPermission({}));
  };

  const handleUpdateEmployeeByActions = ({
    reduxAction,
    id,
    payload,
    onFetchSuccess,
  }) => {
    const fetchParams = payload ? { id, payload } : { id };
    handleDispatchFetch(
      ({ onResolve, onReject }) => {
        dispatch(
          employeesOperation[reduxAction]({
            ...fetchParams,
            onResolve,
            onReject,
          }),
        );
      },
      () => onFetchSuccess && onFetchSuccess(),
    );
  };

  const handleCopyEmployees = () =>
    JSON.parse(JSON.stringify(companyEmployees));

  const handleUpdateEmployee = (id, name) => value => {
    const companyEmployeesCopy = handleCopyEmployees();
    const companyEmployeesNew = companyEmployeesCopy.map(employee =>
      employee.id === id ? { ...employee, [name]: value } : employee,
    );

    if (name === 'status') {
      handleEmployeeActions(id, {
        id: value,
        reduxAction: 'updateEmployeeById',
      });
    }

    setCompanyEmployees(companyEmployeesNew);
  };

  const handleUpdateRole = id => value => {
    const companyEmployeesCopy = handleCopyEmployees();
    const newRoleName = employeeRoles.find(role => role.id === value).name;

    const companyEmployeesNew = companyEmployeesCopy.map(employee =>
      employee.id === id
        ? { ...employee, client_role: newRoleName, client_role_id: value }
        : employee,
    );

    handleEmployeeActions(id, {
      id: 'role',
      reduxAction: 'updateEmployeeById',
      role: value,
    });

    setCompanyEmployees(companyEmployeesNew);
  };

  const handleEmployeeActions = async (employeeId, info) => {
    const { id, reduxAction, ...restPayload } = info;

    switch (id) {
      case 'reinvite': {
        return handleUpdateEmployeeByActions({
          reduxAction,
          id: employeeId,
          onFetchSuccess: () =>
            handleOpenNotification(
              t('common:notifications.employee_invited_again'),
            ),
        });
      }

      case 'inactive':
      case 'active': {
        return handleUpdateEmployeeByActions({
          reduxAction,
          id: employeeId,
          payload: {
            status: id,
          },
          onFetchSuccess: () =>
            handleOpenNotification(t('common:notifications.status_changed')),
        });
      }

      case 'delete': {
        return handleOpenConfirmModal(() => {
          handleUpdateEmployeeByActions({
            reduxAction,
            id: employeeId,
            payload: {
              page: employeesMeta?.current_page,
              per_page: employeesMeta?.per_page,
            },
          });
          handleCloseConfirmModal();
        });
      }

      case 'role':
        return handleUpdateEmployeeByActions({
          reduxAction,
          id: employeeId,
          payload: restPayload,
          onFetchSuccess: () =>
            handleOpenNotification(t('common:notifications.role_changed')),
        });

      default: {
        return;
      }
    }
  };

  const handleOpenConfirmModal = callbackOnConfirm => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        callback: () => callbackOnConfirm && callbackOnConfirm(),
      }),
    );
  };

  const handleCloseConfirmModal = () => {
    dispatch(setOpenModal({ open: false }));
  };

  const handleOpenNotification = notificationMessage => {
    dispatch(
      setOpenNotification({
        open: true,
        width: 'initial',
        message: notificationMessage,
      }),
    );
  };

  const isCurrentUser = employee => employee.id === currentUserId;

  return (
    <ListOfEmployeesWrapper color={color}>
      <TableContainer
        component={Paper}
        sx={{
          position: 'relative',
          boxShadow: 'none',
          borderRadius: 0,
          overflowY: 'auto',
          height: 'calc(100vh - 405px)',
          '&::-webkit-scrollbar': {
            width: '5px',
          },

          '&::-webkit-scrollbar-thumb': {
            background: color.gray_40,
            borderRadius: '4px',
          },

          '&::-webkit-scrollbar-track': {
            background: color.white,
            borderLeft: `1px solid ${color.line}`,
          },
        }}
      >
        {isEmployeesLoading && <ProgressLoader />}
        <Table sx={{ display: 'table !important' }} stickyHeader>
          <TableHead sx={{ display: 'table-header-group !important' }}>
            <TableRow
              height="39px"
              sx={{
                th: {
                  borderTop: `1px solid ${color.line}`,
                  borderBottom: `1px solid ${color.line}`,
                },
              }}
            >
              {ListOfEmployeesConf(t).map(el => (
                <TableCell sx={el.style} key={el.slug}>
                  <CellWrapper isIcon={el?.icon}>
                    <Typography variant="bodyBoldSmall" color={color.gray_60}>
                      {el.label}
                    </Typography>

                    {el?.icon && (
                      <BasicTooltip
                        title={t(
                          'profile:conf.list_of_employees.allow_sign_in_by_message',
                        )}
                        placement="top"
                      >
                        <Svg
                          width="14px"
                          height="14px"
                          sx={{ flex: '0 0 14px' }}
                        >
                          <use href={`${Sprite}${el?.icon}`} />
                        </Svg>
                      </BasicTooltip>
                    )}
                  </CellWrapper>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {companyEmployees.map(employee => {
              return (
                <TableRow
                  height="53px"
                  sx={{
                    position: 'relative',
                    td: {
                      borderBottom: `1px solid ${color.line}`,
                    },
                  }}
                  key={employee.id}
                >
                  {ListOfEmployeesConf(t).map(el => {
                    switch (el.slug) {
                      case 'user':
                        return (
                          <TableCell sx={el.style} key={el.slug + employee.id}>
                            <UserShortInfo
                              avatar={employee.avatar}
                              title={employee.name}
                              subtitle={employee.email}
                            />
                          </TableCell>
                        );
                      case 'role':
                        return (
                          <TableCell sx={el.style} key={el.slug + employee.id}>
                            {isCurrentUser(employee) ? (
                              <Typography
                                variant="bodyBoldSmall"
                                color={color.gray}
                                fontWeight="500"
                              >
                                {employee.client_role}
                              </Typography>
                            ) : (
                              <Menu
                                type="role"
                                itemName={employee.client_role}
                                itemValue={employee.client_role}
                                menuItems={employeeRoles}
                                onChange={handleUpdateRole(employee.id)}
                                isDisplayItem={() => true}
                                anchorOriginPosition={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                transformOriginPosition={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                menuBodySx={{
                                  minWidth: '190px',
                                  padding: '2px',
                                  borderRadius: '4px',
                                }}
                                menuButtonSx={{
                                  columnGap: '5px',
                                  justifyItems: 'initial',
                                  justifyContent: 'flex-start',
                                  textTransform: 'initial',
                                  textAlign: 'left',
                                  fontSize: '12px',
                                }}
                                itemSx={{
                                  padding: '8px !important',
                                  color: color.gray,
                                }}
                                disabled={
                                  !permission?.profile_workers_edit_access
                                }
                              />
                            )}
                          </TableCell>
                        );

                      case 'phone':
                        return (
                          <TableCell sx={el.style} key={el.slug + employee.id}>
                            <PhonesWrapper>
                              {employee?.phones.map(phone => (
                                <Typography
                                  key={phone?.id}
                                  variant="bodySmall"
                                  color={color.gray}
                                >
                                  {correctPhone(phone?.phone)}
                                </Typography>
                              ))}
                            </PhonesWrapper>
                          </TableCell>
                        );
                      case 'city':
                        return (
                          <TableCell sx={el.style} key={el.slug + employee.id}>
                            <Typography variant="bodySmall" color={color.gray}>
                              {employee.city}
                            </Typography>
                          </TableCell>
                        );
                      case 'status':
                        return (
                          <TableCell sx={el.style} key={el.slug + employee.id}>
                            {employee.invited && !isCurrentUser(employee) && (
                              <Menu
                                type="status"
                                itemName={STATUSES[employee?.status].name}
                                itemValue={
                                  employee.invited
                                    ? STATUSES[employee?.status].id
                                    : 'invited'
                                }
                                menuItems={Object.values(STATUSES)}
                                isDisplayItem={() => true}
                                onChange={handleUpdateEmployee(
                                  employee.id,
                                  'status',
                                )}
                                anchorOriginPosition={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                transformOriginPosition={{
                                  vertical: 'top',
                                  horizontal: 'left',
                                }}
                                menuBodySx={{
                                  minWidth: '190px',
                                  padding: '2px',
                                  borderRadius: '4px',
                                }}
                                menuButtonSx={{
                                  columnGap: '5px',
                                  justifyItems: 'initial',
                                  justifyContent: 'flex-start',
                                  textTransform: 'initial',
                                  textAlign: 'left',
                                  fontSize: '12px',
                                }}
                                itemSx={{
                                  padding: '8px !important',
                                  color: color.gray,
                                }}
                                disabled={
                                  !permission?.profile_workers_edit_access
                                }
                              />
                            )}

                            {((employee.invited &&
                              employee.id === currentUserId) ||
                              !employee.invited) && (
                              <StatusBase
                                currentStatus={
                                  !employee.invited
                                    ? 'invited'
                                    : employee.status
                                }
                                statuses={{
                                  active: {
                                    id: 'active',
                                    name: t('common:active'),
                                    color: color.green,
                                  },
                                  inactive: {
                                    id: 'inactive',
                                    name: t('common:locked'),
                                    color: color.red,
                                  },
                                  invited: {
                                    id: 'invited',
                                    name: t('common:invited'),
                                    color: color.orange,
                                  },
                                }}
                              />
                            )}
                          </TableCell>
                        );
                      case 'sign_in_by_message':
                        return (
                          <TableCell
                            sx={{
                              ...el.style,
                              position: 'relative',
                              zIndex: 1,
                            }}
                            key={el.slug + employee.id}
                          >
                            <SignInByMessage>
                              <CustomizedSwitches
                                onChange={handleUpdateEmployee(
                                  employee.id,
                                  'sign_in_by_message',
                                )}
                                checked={employee?.sign_in_by_message}
                                sx={{ margin: 0 }}
                              />
                            </SignInByMessage>
                          </TableCell>
                        );

                      case 'menu':
                        return (
                          <TableCell
                            sx={{
                              ...el.style,
                              position: 'relative',
                              zIndex: 1,
                            }}
                            key={el.slug + employee.id}
                          >
                            <Menu
                              itemSx={{
                                fontSize: '0.75rem',
                                color: color.gray_80,
                              }}
                              menuBodySx={{
                                padding: '2px',
                              }}
                              menuButtonSx={{
                                minWidth: '22px',
                                marginLeft: '6px',
                              }}
                              isDisplayItem={item => {
                                return item?.availableForStatuses.includes(
                                  employee.status,
                                );
                              }}
                              type="employee"
                              menuItems={EmployeeStatusConf(
                                t,
                                employee.invited,
                                employee.id === currentUserId,
                                employee.is_admin,
                              )}
                              onChange={(_, item) =>
                                handleEmployeeActions(employee.id, item)
                              }
                              anchorOriginPosition={{
                                vertical: 'bottom',
                                horizontal: 'right',
                              }}
                              transformOriginPosition={{
                                vertical: 'top',
                                horizontal: 'right',
                              }}
                              disabled={
                                !permission?.profile_workers_edit_access
                              }
                            />
                          </TableCell>
                        );
                    }
                  })}

                  {employee?.status === 'inactive' && employee?.invited && (
                    <LockPlaceholder color={color} />
                  )}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        handelPagination={handleGetEmployees}
        meta={employeesMeta}
        subTitle={t('common:records')}
      />
    </ListOfEmployeesWrapper>
  );
};

const ListOfEmployeesWrapper = styled.div`
  border: 1px solid ${({ color }) => color.line};
  border-top: none;
`;

const SignInByMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Svg = styled.svg``;

const LockPlaceholder = styled.div`
  background-color: ${({ color }) => color.gray_20};
  position: absolute;
  opacity: 0.5;
  inset: 0;
`;

const CellWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: ${({ isIcon }) => isIcon && 'center'};
  column-gap: 5px;
`;

const PhonesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;
`;
