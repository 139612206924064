import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { BasicSelect, OutlineButtonBase } from '@root/ui';

import commercialOfferSelectors from '@redux/commercial-offer/commercial-offer-selectors';

import { Divider, Typography, useTheme } from '@mui/material';

import { CompanyTemplate } from '../CompanyTemplate/CompanyTemplate';

export const AboutCompany = ({
  handleOpenModal,
  companyId,
  setCommercialData,
  error,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation('commercial_offer', { useSuspense: false });
  const getCompanyList = useSelector(commercialOfferSelectors.getCompanyList);
  const getCompanyTemplates = useSelector(
    commercialOfferSelectors.getCompanyTemplates,
  );

  const wrapperRef = useRef();

  const setSelectedValue = value =>
    setCommercialData(prevstate => ({ ...prevstate, company_id: value }));

  useEffect(() => {
    if (getCompanyList && getCompanyList.length > 0) {
      if (getCompanyList[0]?.selected) {
        setCommercialData(prevstate => ({
          ...prevstate,
          company_id: getCompanyList[0].id,
        }));
      }
    }
  }, [getCompanyList]);

  return (
    <Wrapper>
      <Typography
        sx={{
          marginBottom: '16px',
        }}
        component="p"
        variant="bodyBoldLarge"
        color={color.gray}
      >
        {t('information_about_company')}
      </Typography>
      <Divider
        sx={{
          margin: '0 -23px 30px',
        }}
      />
      <AboutCompanyWrapper>
        <BasicSelect
          required
          label={t('choose_company')}
          name={t('select_placeholder')}
          sx={{ marginBottom: '8px', position: 'absolute', width: '100%' }}
          currentId={companyId}
          data={getCompanyList}
          callBack={setSelectedValue}
          helperText={error?.company_id?.text}
          error={error?.company_id?.init}
          customMenuProps={{
            sx: {
              position: 'absolute',
              height: '400px',
              '.MuiPaper-root': {
                top: '38px !important',
                left: '0 !important',
              },

              '.MuiMenuItem-root': {
                whiteSpace: 'wrap',
              },
            },
            disablePortal: true,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        />

        <OutlineButtonBase
          sx={{
            width: '100%',
            marginTop: error?.company_id?.init ? '80px' : '60px',
          }}
          onClick={() => handleOpenModal(true, 'new')}
        >
          <Svg color={color}>
            <use href={`${Sprite}#icon-plus`}></use>
          </Svg>
          {t('buttons.add_new_company')}
        </OutlineButtonBase>
      </AboutCompanyWrapper>
      {companyId && (
        <CompanyTemplate
          handleOpenModal={handleOpenModal}
          data={getCompanyTemplates.find(item => item.id === companyId)}
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 25px 23px 34px 23px;
`;

const Svg = styled.svg`
  width: 16px;
  height: 16px;
  stroke: ${({ color }) => color.gray_80};
`;

AboutCompany.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  companyId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  setCommercialData: PropTypes.func.isRequired,
  error: PropTypes.object,
};

const AboutCompanyWrapper = styled.div`
  width: 70%;
  position: relative;
`;
