import React, { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Amount, Cost, SavedOrderAccordion } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { theme } from '@root/styles';
import { ButtonBase, OutlineButtonBase } from '@root/ui';

import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import savedOrdersOperation from '@redux/saved-orders/saved-orders-operation';
import {
  updateSavedOrderOnDelete,
  updateSavedOrderProductOnDelete,
} from '@redux/saved-orders/saved-orders-slice';
import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';

import { SavedOrderProducts } from './SavedOrderProducts';

export const SavedOrder = memo(({ data, handleClose, defaultExpanded }) => {
  const chosenCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const permission = useSelector(userSelectors.getPermissions);

  const { name, description, amounts_personal, amounts_product, amounts } =
    data;

  const dispatch = useDispatch();

  const { t } = useTranslation(['cart', 'common'], { useSuspense: false });

  const AccordionSummary = useMemo(
    () => () =>
      (
        <AccordionSummaryWrapper>
          <AccordionSummaryText>
            <Typography variant="bodyBoldSmall" color={theme.color.gray}>
              {name}
            </Typography>
            <Typography
              variant="bodyExSmall"
              color={theme.color.gray_60}
              marginTop="4px"
            >
              {description}
            </Typography>
          </AccordionSummaryText>

          <AccordionSummaryPrices color={theme.color.line_light}>
            <Cost
              data={{
                prices: amounts,
                personal_price: amounts_personal,
                price: amounts_product,
              }}
            />
          </AccordionSummaryPrices>
        </AccordionSummaryWrapper>
      ),
    [name, description, amounts_product, amounts_personal, chosenCurrency],
  );

  const handleSetIsNotificationOpen = () => {
    dispatch(
      setOpenNotification({
        open: true,
        width: '700px',
        message: t('cart:saved_order_description'),
        action: (
          <ActionsWrapper>
            <ButtonBase onClick={handleClose}>
              {t('common:buttons.view')}
            </ButtonBase>
            <OutlineButtonBase
              onClick={handleCancelAddSavedOrderToCart}
              sx={{ padding: '6px 10px' }}
            >
              {t('common:buttons.cancel')}
            </OutlineButtonBase>
          </ActionsWrapper>
        ),
      }),
    );
  };
  const handleCloseNotification = () =>
    dispatch(setOpenNotification({ open: false }));

  const handleSetIsDeleteModalOpen = () => {
    dispatch(
      setOpenModal({
        open: true,
        key: 'confirm',
        isHiddenHeader: true,
        inactiveBackdrop: true,
        callback: () => handleDeleteSavedOrder(),
      }),
    );
  };
  const handleDeleteProduct = productId => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          savedOrdersOperation.deleteSavedOrderProductById({
            id: data.id,
            productId,
            onResolve,
            onReject,
          }),
        ),
      () => {
        dispatch(
          updateSavedOrderProductOnDelete({
            id: data.id,
            productId,
          }),
        );
      },
    );
  };

  const handleAddProductToCart = productId => {
    dispatch(
      savedOrdersOperation.addSavedOrderProductToCart({
        id: data.id,
        productId,
      }),
    );
  };

  const handleDeleteSavedOrder = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          savedOrdersOperation.deleteSavedOrderById({
            id: data.id,
            onResolve,
            onReject,
          }),
        ),
      () => {
        dispatch(updateSavedOrderOnDelete(data.id));
        dispatch(clearModalState());
      },
    );
  };

  const handleAddSavedOrderToCart = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          savedOrdersOperation.addSavedOrderToCart({
            id: data.id,
            onResolve,
            onReject,
          }),
        ),
      response => {
        handleSetIsNotificationOpen();
      },
    );
  };

  const handleCancelAddSavedOrderToCart = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          savedOrdersOperation.cancelAddSavedOrderToCart({
            id: data.id,
            onResolve,
            onReject,
          }),
        ),
      response => {
        handleCloseNotification();
      },
    );
  };

  return (
    <SavedOrderWrapper data-test-item="SavedOrderItem">
      <SavedOrderAccordion
        data={data}
        accordionSummary={<AccordionSummary />}
        defaultExpanded={defaultExpanded}
      >
        <AccordionDetailsWrapper>
          <SavedOrderProducts
            data={data.details}
            handleDeleteProduct={productId => handleDeleteProduct(productId)}
            handleAddProductToCart={(productId, quantity) =>
              handleAddProductToCart(productId, quantity)
            }
          />
          <ButtonsWrapper>
            <OutlineButtonBase
              sx={{ padding: '6px 10px' }}
              onClick={handleSetIsDeleteModalOpen}
              data-test-id="DeleteSavedOrderItemButton"
            >
              {t('common:buttons.delete')}
            </OutlineButtonBase>
            {permission.cart_add_access && (
              <ButtonBase
                onClick={handleAddSavedOrderToCart}
                data-test-id="AddSavedOrderItemToCartButton"
              >
                {t('cart:buttons.add_all_to_cart')}
              </ButtonBase>
            )}
          </ButtonsWrapper>
        </AccordionDetailsWrapper>
      </SavedOrderAccordion>
    </SavedOrderWrapper>
  );
});

const SavedOrderWrapper = styled.div`
  height: auto;
  margin-bottom: 15px;
`;

const AccordionSummaryWrapper = styled.div`
  display: flex;
  column-gap: 15px;
  width: 100%;
`;
const AccordionSummaryText = styled.div`
  flex: 0 1 550px;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const AccordionDetailsWrapper = styled.div``;
const AccordionSummaryPrices = styled.div`
  border-left: 1px solid ${props => props.color};
  padding-left: 8px;
  flex: 0 1 185px;
  align-self: flex-end;
  display: flex;
  flex-direction: column;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  column-gap: 10px;
  margin-top: 16px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
`;

SavedOrder.propTypes = {
  data: PropTypes.object,
  handleClose: PropTypes.func.isRequired,
  defaultExpanded: PropTypes.bool,
};
