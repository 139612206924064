import { Typography } from '@mui/material';

export const AccountingDocumentsConf = t => [
  {
    label: t('accounting_documents:conf.document'),
    slug: 'document',
    sort: false,
    style: {
      minWidth: '400px',
      width: '100%',
    },
  },
  {
    label: t('accounting_documents:conf.date'),
    slug: 'date',
    sort: true,
    style: {
      minWidth: '130px',
    },
  },
  {
    label: t('accounting_documents:conf.status'),
    slug: 'status',
    sort: false,
    style: {
      minWidth: '130px',
      textAlign: 'center',
    },
  },
  {
    label: (
      <>
        <Typography variant="bodyBoldSmall" component="p">
          {t('accounting_documents:conf.summ_all')}
        </Typography>
        <Typography variant="bodySmall" component="p">
          {t('accounting_documents:conf.summ_paid')}
        </Typography>
        <Typography variant="bodySmall" component="p">
          {t('accounting_documents:conf.summ_need_to_pay')}
        </Typography>
      </>
    ),
    slug: 'balance',
    sort: false,
    style: {
      minWidth: '80px',
      textAlign: 'right',
    },
  },
  {
    label: (
      <>
        <Typography variant="bodyBoldSmall" component="p">
          {t('accounting_documents:conf.bill')}
        </Typography>
        <Typography variant="bodyBoldSmall" component="p">
          {t('accounting_documents:conf.expenses')}
        </Typography>
      </>
    ),
    sort: false,
    slug: 'bill',
    style: {
      minWidth: '130px',
      textAlign: 'center',
    },
  },
];
