import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CustomizedSwitches } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const PermissionInput = ({
  handleToggleActivityPermission,
  data,
  type,
  value,
  disabled = false,
}) => {
  const { color } = useTheme();

  const getInput = () => {
    switch (data?.type) {
      case 'switch':
        return (
          <CustomizedSwitches
            checked={value}
            onChange={handleToggleActivityPermission(
              data?.code,
              data?.child_codes,
            )}
            sx={{
              ml: 0,
              mr: 0,
            }}
            label={
              <Typography
                color={type === 'parent' ? color.gray : color.gray_80}
                component="p"
                variant={type === 'parent' ? 'bodyBoldMedium' : 'bodyMedium'}
                sx={{ mr: 'auto' }}
              >
                {data?.name}
              </Typography>
            }
            disabled={disabled}
            labelPlacement="start"
          />
        );

      default:
        return (
          <>
            <Typography
              color={type === 'parent' ? color.gray : color.gray_80}
              component="p"
              variant={type === 'parent' ? 'bodyBoldMedium' : 'bodyMedium'}
              sx={{ mr: 'auto' }}
            >
              {data?.name}
            </Typography>
          </>
        );
    }
  };
  return <Item>{getInput()}</Item>;
};

const Item = styled.div`
  display: block;
  padding: 4px 0;
  width: 100%;
`;

PermissionInput.propsTypes = {
  handleToggleActivityPermission: PropTypes.func,
  data: PropTypes.exact({
    name: PropTypes.string,
    type: PropTypes.string,
    code: PropTypes.string,
    child_codes: PropTypes.arrayOf(PropTypes.string),
    id: PropTypes.number,
    children: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    checked: PropTypes.bool,
  }),
  type: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
};
