import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const Balance = ({ summAll, summPaid, shipped }) => {
  const { color } = useTheme();
  return (
    <BalanceWrapper>
      <Typography variant="bodyBoldSmall" color={color.gray}>
        {summAll}
      </Typography>
      <Typography variant="bodySmall" color={color.gray}>
        {summPaid}
      </Typography>
      <Typography variant="bodySmall" color={color.gray}>
        {shipped}
      </Typography>
    </BalanceWrapper>
  );
};

const BalanceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 2px;
`;

Balance.propTypes = {
  summAll: PropTypes.number.isRequired,
  summPaid: PropTypes.number.isRequired,
  shipped: PropTypes.number.isRequired,
};
