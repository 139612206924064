import { css } from '@emotion/css';
import PropTypes from 'prop-types';

import Menu from '@mui/material/Menu';
import { useTheme } from '@mui/material/styles';

const styles = colors => css`
  & li {
    &:hover {
      background-color: ${colors.gray_20};
      color: ${colors.gray_80};
    }
  }

  .Mui-selected {
    background-color: ${colors.main} !important;
    color: ${colors.white};
  }
`;

export const MenuBody = ({
  children,
  anchorEl,
  open,
  handleClose,
  sx,
  anchorOriginPosition,
  transformOriginPosition,
  ...prop
}) => {
  const { color } = useTheme();
  return (
    <Menu
      id="basic-menu"
      data-test-id="BasicMenuTestId"
      anchorEl={anchorEl}
      anchorOrigin={
        anchorOriginPosition || {
          vertical: 'bottom',
          horizontal: 'center',
        }
      }
      transformOrigin={
        transformOriginPosition || {
          vertical: 'top',
          horizontal: 'center',
        }
      }
      open={open}
      onClose={handleClose}
      MenuListProps={{
        'aria-labelledby': 'basic-button',
      }}
      variant={'selectedMenu'}
      sx={{
        '.MuiPaper-root': {
          boxShadow: '6px 6px 10px 0px rgba(54, 74, 99, 0.06)',
        },
        ul: {
          border: `1px solid ${color.line}`,
          borderRadius: `4px`,
          p: '2px',
          ...sx,
        },
        li: {
          padding: '4px 5px',
          borderRadius: '2px',
          '&:hover': {
            backgroundColor: color.gray_20,
          },
        },
      }}
      MenuProps={{
        classes: {
          list: styles({
            gray_20: color.gray_20,
            gray_80: color.gray_80,
            main: color.main,
            white: color.white,
          }),
        },
      }}
      {...prop}
    >
      {children}
    </Menu>
  );
};

MenuBody.propTypes = {
  children: PropTypes.node,
  anchorEl: PropTypes.object,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  sx: PropTypes.object,
};
