import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { ModalLiqPay, ModalStatusOrder } from '@root/components';
import { useOrder } from '@root/hooks';

import orderOperation from '@redux/order/order-operation';
import orderSelectors from '@redux/order/order-selectors';

import { InfoClientMurk } from './InfoClient';
import { servicesValidation } from './InfoClient/FormPersonalInfo/DeliveryClientInfo/Validation/validation';
import { Order } from './Order';

export const OrderForm = () => {
  const order = useSelector(orderSelectors.getOrder);
  const {
    onChange,
    error,
    onChangeRadio,
    handleSelectFilial,
    handleCardRadio,
    validation,
    data,
    setData,
    validationPayment,
  } = useOrder({
    servicesValidation,
    available_methods: order?.available_methods,
  });
  const dispatch = useDispatch();
  const orderId = useSelector(orderSelectors.getOrderId);
  const orderError = useSelector(orderSelectors.getError).order;
  const onlineForm = useSelector(orderSelectors.getOnlineForm);
  const delivery = useSelector(orderSelectors.getSavedOrderTemplate)?.delivery;
  const payment = useSelector(orderSelectors.getSavedOrderTemplate)?.payment;

  const deliveryItemSelected = useMemo(
    () => () => delivery.find(el => el.is_selected),
    [delivery],
  );

  const paymentItemSelected = useMemo(
    () => () => payment.find(el => el.is_selected),
    [payment],
  );

  useEffect(() => {
    if (order && delivery.length === 0 && payment.length === 0) {
      setData(prevState => ({
        ...prevState,
        payment_type: order?.available_methods?.payment_types[0]?.key,
        delivery_type: order?.available_methods?.delivery_types[0]?.key,
      }));
    }
  }, [order]);

  const handelSubmit = e => {
    e.preventDefault();
    if (delivery.length > 0 && payment.length > 0) {
      dispatch(
        orderOperation.confirmOrder({
          delivery_id: deliveryItemSelected().id,
          payment_id: paymentItemSelected().id,
          note: data?.note,
          dont_call: data?.dont_call,
          discount: data?.discount,
        }),
      );
    } else {
      if (validation()) return;
      if (validationPayment()) return;
      dispatch(orderOperation.confirmOrder(data));
    }
  };

  return (
    <>
      <Form onSubmit={handelSubmit}>
        <InfoClientMurk
          error={error}
          data={data}
          onChange={onChange}
          handleSelectFilial={handleSelectFilial}
          onChangeRadio={onChangeRadio}
          handleCardRadio={handleCardRadio}
          available_methods={order?.available_methods}
          setData={setData}
          delivery={delivery}
          payment={payment}
        />
        <Order
          order={order}
          error={error}
          onChange={onChange}
          data={data}
          orderError={orderError}
        />
        <ModalStatusOrder open={!onlineForm && orderId} orderId={orderId} />
      </Form>
      {onlineForm && <ModalLiqPay onlineForm={onlineForm} />}
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: row;
  height: 100%;
`;
