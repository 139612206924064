import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, useTheme } from '@mui/material';

export const ColorInput = ({ colorNum, ...prop }) => {
  const { color } = useTheme();

  return (
    <Input color={color} {...prop}>
      <Typography color={color.gray_80} textAlign="center">
        {colorNum.split('#').join('')}
      </Typography>
      <ColorIcon color={colorNum} />
    </Input>
  );
};

const Input = styled.div`
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;

  border-radius: 4px;
  border: 1px solid ${({ color }) => color.line};
  background: ${({ color }) => color.white};
`;
const ColorIcon = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: ${({ color }) => `${color}`};
`;

ColorInput.propTypes = {
  colorNum: PropTypes.string.isRequired,
  onClick: PropTypes.func,
};
