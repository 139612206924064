import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import accountingDocumentsSlice from '@redux/accounting-documents/accounting-documents-slice';
import adminSlice from '@redux/admin/admin-slice';
import cartSlice from '@redux/cart/cart-slice';
import employeesSlice from '@redux/employees/employees-slice';
import favoriteSlice from '@redux/favorite/favorite-slice';
import homeSlice from '@redux/home/home-slice';
import logisticSlice from '@redux/logistic/logistic-slice';
import mutualSettlementsSlice from '@redux/mutual-settlements/mutual-settlements-slice';
import orderServiceSlice from '@redux/order-service/order-service-slice';
import orderSlice from '@redux/order/order-slice';
import savedOrdersSlice from '@redux/saved-orders/saved-orders-slice';

import authReducer from './auth/auth-slice';
import categorySlice from './category/category-slice';
import commercialOfferReducer from './commercial-offer/commercial-offer-slice';
import comparisonsSlice from './comparisons/comparisons-slice';
import modalWatcherReducer from './modal-watcher/modal-watcher-slice';
import newsAndEventsReducer from './news-and-events/news-and-events-slice';
import notificationWatcherReducer from './notification-watcher/notification-watcher-slice';
import notificationsSlice from './notifications/notifications-slice';
import optionReducer from './option/option-slice';
import permissionSlice from './permission/permission-slice';
import productSlice from './product/product-slice';
import searchReducer from './search/search-slice';
import userReducer from './user/user-slice';

const middleware = [
  ...getDefaultMiddleware({
    serializableCheck: false,
    // {ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]}
  }),
];

const authPersistConfig = {
  key: 'auth',
  storage,
  whitelist: ['token'],
};

export const store = configureStore({
  reducer: {
    auth: persistReducer(authPersistConfig, authReducer),
    option: optionReducer,
    search: searchReducer,
    category: categorySlice,
    product: productSlice,
    admin: adminSlice,
    cart: cartSlice,
    order: orderSlice,
    favorite: favoriteSlice,
    savedOrders: savedOrdersSlice,
    logistic: logisticSlice,
    comparisons: comparisonsSlice,
    orderService: orderServiceSlice,
    user: userReducer,
    mutualSettlements: mutualSettlementsSlice,
    accountingDocuments: accountingDocumentsSlice,
    modalWatcher: modalWatcherReducer,
    notificationWatcher: notificationWatcherReducer,
    home: homeSlice,
    commercialOffer: commercialOfferReducer,
    notifications: notificationsSlice,
    permission: permissionSlice,
    employees: employeesSlice,
    newsAndEvents: newsAndEventsReducer,
  },

  middleware,
  devTools: process.env.NODE_ENV === 'development',
});
export const persistor = persistStore(store);
