import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getNewsList = createAsyncThunk(
  'newsAndEvents/getNewsList',
  async ({ page }, { rejectWithValue }) => {
    try {
      const response = await axios.get('/news', {
        params: {
          page: page,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getEventsList = createAsyncThunk(
  'newsAndEvents/getEventsList',
  async ({ page }, { rejectWithValue }) => {
    try {
      const response = await axios.get('/events', {
        params: {
          page: page,
        },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getNewsListItemDetails = createAsyncThunk(
  'newsAndEvents/getNewsListItemDetails',
  async ({ slug }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/news/${slug}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getEventsListItemDetails = createAsyncThunk(
  'newsAndEvents/getEventsListItemDetails',
  async ({ slug }, { rejectWithValue }) => {
    try {
      const response = await axios.get(`/events/${slug}`);
      return response.data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const createNews = createAsyncThunk(
  'newsAndEvents/createNews',
  async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/news/admin', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve();
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const createEvent = createAsyncThunk(
  'newsAndEvents/createEvent',
  async ({ formData, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('/events/admin', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve();
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const updateNews = createAsyncThunk(
  'newsAndEvents/updateNews',
  async ({ formData, id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/news/admin/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const updateEvent = createAsyncThunk(
  'newsAndEvents/updateEvent',
  async ({ formData, id, onResolve, onReject }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/events/admin/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      if (onResolve) onResolve(data.data);
      return data.data;
    } catch (error) {
      if (onReject) onReject(error.response.data.message);
      return rejectWithValue(error);
    }
  },
);

const deleteNews = createAsyncThunk(
  'newsAndEvents/deleteNews',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/news/admin/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const deleteEvent = createAsyncThunk(
  'newsAndEvents/deleteEvent',
  async ({ id }, { rejectWithValue }) => {
    try {
      const response = await axios.delete(`/events/admin/${id}`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const eventRegistration = createAsyncThunk(
  'newsAndEvents/eventRegistration',
  async ({ info, id }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(`/events/${id}/register`, info);
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default {
  createNews,
  createEvent,
  deleteNews,
  deleteEvent,
  getNewsList,
  getEventsList,
  getNewsListItemDetails,
  getEventsListItemDetails,
  updateNews,
  updateEvent,
  eventRegistration,
};
