import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { ButtonBase, ModalBase, RadioCustom, TextInput } from '@root/ui';

import FormControlLabel from '@mui/material/FormControlLabel';
import RadioGroup from '@mui/material/RadioGroup';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const ConfirmModal = ({ phone, handleClose, changeConfirm, open }) => {
  const [chose, setChose] = useState('');
  const [code, setCode] = useState('');
  const { t } = useTranslation(['registration'], { useSuspense: false });
  const { color } = useTheme();

  const SubmitForm = () => {
    handleClose();
    let regexp = /\d/g;
    const valueEx = `${phone}`.match(regexp).join('');
    changeConfirm(valueEx);
  };

  const onChangeInput = name => value => setCode(value);
  const onChangeRadio = event => setChose(event.target.value);

  const massage = () => {
    const phoneArray = phone.split('');
    const phoneMurkUp = phoneArray.reduce((acc, el, i) => {
      if (i === 0 || i === 2 || i === 7) {
        acc = acc + `${el} `;
      } else if (i === 10 || i === 12 || i === 13) {
        acc = acc + `X`;
      } else {
        acc = acc + `${el}`;
      }

      return acc;
    }, '');

    return (
      <WrapperForm>
        {' '}
        <Typography
          variant={'bodyMedium'}
          sx={{ color: color.gray_80, mt: '18px' }}
          component={'p'}
        >
          {t('registration:step_two.modal.label_phone_conf', { phoneMurkUp })}
        </Typography>
        <FlexContainer>
          <TextInput
            sx={{ mt: '28px', width: '100%', height: '48px' }}
            value={code}
            onChange={onChangeInput('code')}
            placeholder={t('registration:field.sms')}
            type="text"
            max={6}
            withOutSpace={true}
          />
          <ButtonBase
            type="button"
            size={'large'}
            sx={{ mt: '28px', p: '16px 34px', ml: '12px' }}
            onClick={SubmitForm}
          >
            <Typography variant={'bodyBoldSmall'}>
              {' '}
              {t('registration:step_two.modal.confirm_btn')}
            </Typography>
          </ButtonBase>
        </FlexContainer>
      </WrapperForm>
    );
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      sx={{ width: '442px' }}
      modalHeader={
        <HeaderDefault
          text={t('registration:step_two.modal.title')}
          onClose={handleClose}
        />
      }
    >
      <Typography
        variant={'bodyMedium'}
        sx={{ color: color.gray_80, mt: '12px' }}
        component={'p'}
      >
        {t('registration:step_two.modal.slogan')}
      </Typography>

      <RadioGroup
        aria-labelledby="demo-radio-buttons-group-label"
        name="radio-buttons-group"
        value={chose}
        onChange={onChangeRadio}
        sx={{
          marginLeft: '2px',
        }}
      >
        <FormControlLabel
          value="viber"
          control={<RadioCustom />}
          label={
            <Typography
              variant={'bodyMedium'}
              sx={{ color: color.gray_80 }}
              component={'p'}
            >
              Viber
            </Typography>
          }
        />
        {chose === 'viber' && massage()}
        <FormControlLabel
          value="sms"
          control={<RadioCustom />}
          label={
            <Typography
              variant={'bodyMedium'}
              sx={{ color: color.gray_80 }}
              component={'p'}
            >
              SMS
            </Typography>
          }
        />
        {chose === 'sms' && massage()}
      </RadioGroup>
    </ModalBase>
  );
};

const WrapperForm = styled.form``;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;

  margin-bottom: 16px;
`;
ConfirmModal.propTypes = {
  phone: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
  changeConfirm: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
