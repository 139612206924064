import { FooterDefault, HeaderDefault } from '@root/components';
import { HistoryDetails, OrderServiceDetails } from '@root/modules';
import {
  Banner,
  CommercialOffersChart,
  LastMonthsOrder,
  NewsAndEventsWidget,
  NotificationDashboardWidjet,
  OrderChart,
  OrderWidjet,
  ServiceOrdersWidget,
  TopSale,
} from '@root/widgets';
import { DownloadBannerModal } from '@root/widgets/Banner/DownloadBannerModal';
import { TopSaleModal } from '@root/widgets/TopSale/TopSaleModal';

export function getConfDashboardElement(t) {
  return {
    banner: {
      widget: ({ conf }) => <Banner conf={conf} />,
      onChangeEl: ({ handleCloseModal }) => (
        <DownloadBannerModal handleCloseModal={handleCloseModal} />
      ),
      headerModal: ({ handleCloseModal }) => (
        <HeaderDefault
          onClose={handleCloseModal}
          text={t('home:modals.download_banner_modal.title')}
        />
      ),
      footer: ({ handleCloseModal }) => (
        <FooterDefault
          conf={[
            {
              type: 'outline',
              text: t('common:buttons.cancel'),
              onClose: handleCloseModal,
              form: '',
            },
            {
              type: 'inline',
              text: t('common:buttons.save'),
              onClose: () => {},
              form: 'banner',
            },
          ]}
        />
      ),
    },
    top_sale: {
      widget: prop => <TopSale {...prop} />,
      onChangeEl: prop => <TopSaleModal {...prop} />,
      headerModal: ({ handleCloseModal }) => (
        <HeaderDefault
          onClose={handleCloseModal}
          text={t('home:modals.total_price_modal.title')}
        />
      ),
      footer: ({ handleCloseModal }) => (
        <FooterDefault
          conf={[
            {
              type: 'outline',
              text: t('common:buttons.reject'),
              onClose: handleCloseModal,
              form: '',
            },
            {
              type: 'inline',
              text: t('common:buttons.save'),
              onClose: () => {},
              form: 'top_sale',
            },
          ]}
        />
      ),
    },
    order: {
      widget: prop => (
        <>
          <OrderWidjet
            {...prop}
            historyDetailsModule={
              <HistoryDetails
                isModal
                activeTab={
                  prop.activeTab === 'my-orders'
                    ? 'my-orders'
                    : 'my-commercial-offers'
                }
              />
            }
          />
        </>
      ),
      onChangeEl: null,
    },
    services: {
      widget: prop => (
        <>
          <NotificationDashboardWidjet {...prop} />
        </>
      ),
      onChangeEl: null,
    },
    order_chart: {
      widget: prop => <OrderChart {...prop} />,

      onChangeEl: null,
    },
    buys_most_often: {
      widget: prop => <TopSale {...prop} />,
      onChangeEl: null,
    },
    service_orders: {
      widget: prop => (
        <ServiceOrdersWidget
          {...prop}
          serviceOrderDetailsModule={({
            selectedServiceOrder,
            width,
            height,
          }) => (
            <OrderServiceDetails
              selectedServiceOrder={selectedServiceOrder}
              width={width}
              height={height}
            />
          )}
        />
      ),
      onChangeEl: null,
    },
    commercial_offers_chart: {
      widget: prop => <CommercialOffersChart {...prop} />,
    },
    last_months_order: {
      widget: prop => (
        <LastMonthsOrder
          {...prop}
          historyDetailsModule={
            <HistoryDetails isModal activeTab={'archive-orders'} />
          }
        />
      ),
    },
    news_events: {
      widget: prop => <NewsAndEventsWidget {...prop} />,
    },
  };
}
