import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { NavLink } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  Action,
  Availability,
  CardDetails,
  Cost,
  ImgPrev,
  LabelProduct,
  Pagination,
} from '@root/components';
import { IconBtn, ProgressLoader } from '@root/ui';

import categorySelectors from '@redux/category/category-selectors';
import userSelectors from '@redux/user/user-selectors';

import {
  Divider,
  Paper,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

export const DenseTable = ({
  conf,
  data,
  meta,
  handelPagination,
  accordionActive,
  choseItem,
  addAnimation,
  handelOpenSavedModal,
  tableBodyHeight = 170,
  handleGetDataBySort,
  loading,
  addOrRemoveFromComparisons,
}) => {
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(null);
  const [sortingOn, setSortingOn] = useState(false);
  const [sortDuration, setSortDuration] = useState(null);
  const [sortingType, setSortingType] = useState(null);
  const warehouseId = useSelector(categorySelectors.getWarehouseId);
  const permission = useSelector(userSelectors.getPermissions);
  const { category, categoryChildren, lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const { t } = useTranslation(['catalog', 'common'], { useSuspense: false });
  const { color } = useTheme();

  const handelDetailCard = (e, id) => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };

  const handleSorting = type => {
    switch (type) {
      case 'name': {
        handleGetDataBySort(type, sortDuration);
        setSortDuration(sortDuration === type ? null : type);
        break;
      }
      case 'price': {
        handleGetDataBySort(type, sortDuration);
        setSortDuration(sortDuration === type ? null : type);
        break;
      }
      case 'stock': {
        handleGetDataBySort(type, sortDuration);
        setSortDuration(sortDuration === type ? null : type);
        break;
      }
      default: {
        break;
      }
    }
    setSortingType(type);
    setSortingOn(!sortingOn);
  };

  useEffect(() => {
    setOpenCard(false);
    setChoseIdCard(null);
  }, [meta.current_page]);

  useEffect(() => {
    setSortingType(null);
    setSortDuration(null);
  }, [category, categoryChildren]);

  return (
    <TableContainer
      component={Paper}
      sx={{
        height: `calc(100% - ${tableBodyHeight}px)`,
        overflowX: 'initial',
      }}
    >
      <Table
        size="small"
        aria-label="a dense table"
        sx={{
          position: 'relative',
          height: 'calc(100% - 78px)',
        }}
        data-test-id="Table"
      >
        <TableHead>
          <TableRow sx={{ display: 'flex', p: '12px 16px' }}>
            {conf.map(el => {
              if (el.hide && accordionActive) return '';
              if (
                el.value === 'availability' &&
                !permission.product_stock_access
              )
                return <Fragment key={el.value} />;
              return (
                <TableCell
                  key={el.value}
                  sx={{
                    borderBottom: 'none',
                    display: 'flex',
                    ...el.style,
                    p: 0,
                    flexDirection: 'row',
                  }}
                >
                  <OpacityContainer opacity={sortingType === el.type ? 1 : 0}>
                    <Typography
                      variant={'bodyBoldSmall'}
                      component={'p'}
                      sx={{ color: color.gray_60 }}
                    >
                      {t(`common:table.table_heads.${el.value}`)}
                    </Typography>
                    {el.sort && (
                      <IconBtn
                        href={`${Sprite}#icon-sorting`}
                        onClick={() => handleSorting(el.type)}
                        size={16}
                        sx={{
                          marginLeft: '6px',
                        }}
                        rotateSvgX={
                          sortDuration === el.type ? '3.142rad' : false
                        }
                      />
                    )}
                  </OpacityContainer>
                </TableCell>
              );
            })}
          </TableRow>
        </TableHead>
        <Divider />
        <TableBody
          colors={{ gray: color.gray_40, white: color.white, line: color.line }}
        >
          {data &&
            data.map(row => {
              return (
                <Fragment key={'pe' + row.id}>
                  <TableRow
                    sx={{
                      minHeight: '109px',
                      borderBottom: `1px solid ${color.line}`,
                      userSelect: 'auto',
                      th: {
                        borderBottom: 'none',
                      },
                      '&:last-child, &:last-child th': { borderBottom: 0 },
                    }}
                  >
                    <TableRowInner>
                      {conf.map(el => {
                        switch (el?.value) {
                          case 'product':
                            return (
                              <NavLink
                                to={currentLang + row.url}
                                style={{ ...el.style }}
                                key={'sis' + row.id + el?.value}
                              >
                                {' '}
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                                >
                                  <ImgPrev
                                    src={row?.image}
                                    countImg={row?.images_quantity}
                                  />
                                </TableCell>
                              </NavLink>
                            );

                          case 'name':
                            return (
                              <NavLink
                                to={currentLang + row.url}
                                style={{ ...el.style }}
                                key={'sis' + row.id + el?.value}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                                >
                                  <LabelProduct
                                    label={row?.name}
                                    parm={row?.model}
                                    article={row?.sku}
                                  />{' '}
                                </TableCell>{' '}
                              </NavLink>
                            );

                          case 'cost':
                            return (
                              <TableCell
                                component="th"
                                scope="row"
                                key={'sis' + row.id + el?.value}
                                sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                              >
                                <Cost data={row} />
                              </TableCell>
                            );
                          case 'availability':
                            if (!permission.product_stock_access) return '';
                            return (
                              <TableCell
                                component="th"
                                scope="row"
                                key={'sis' + row.id + el?.value}
                                sx={{
                                  p: 0,
                                  display: 'flex',
                                  flexDirection: 'column',
                                  gap: '4px',
                                  cursor: 'pointer',
                                  ...el.style,
                                }}
                              >
                                <Availability
                                  present={row.stock_percent}
                                  inStock={row.stock}
                                  notAvailable={row.stock}
                                  storage={row.stock}
                                  warehouseId={warehouseId}
                                  warehouses={row.warehouses}
                                />
                              </TableCell>
                            );

                          case 'action':
                            return (
                              <TableCell
                                component="th"
                                scope="row"
                                key={'sis' + row.id + el?.value}
                                sx={{ p: 0, ...el.style }}
                              >
                                <Action
                                  addAnimation={addAnimation}
                                  chooseProduct={row.cart}
                                  isSaved={row.is_saved}
                                  inCompare={row.in_compare}
                                  availableNumber={row.stock}
                                  handelOpenSavedModal={e =>
                                    handelOpenSavedModal(
                                      e,
                                      row.is_saved,
                                      row.id,
                                    )
                                  }
                                  handelDetailCard={e =>
                                    handelDetailCard(e, row.id)
                                  }
                                  isOpenDetail={
                                    openCard && choseIdCard === row.id
                                  }
                                  productPrice={row.price}
                                  addedToCart={row.is_in_cart}
                                  onClick={e => choseItem(e, row)}
                                  data={row}
                                  addOrRemoveFromComparisons={() =>
                                    addOrRemoveFromComparisons(
                                      row.id,
                                      row.in_compare,
                                    )
                                  }
                                />
                              </TableCell>
                            );
                          default:
                            return (
                              <TableCell
                                component="th"
                                scope="row"
                                key={'sis' + row.id + el?.value}
                              >
                                {row[el.value]}
                              </TableCell>
                            );
                        }
                      })}
                    </TableRowInner>
                  </TableRow>
                  {openCard && row.id === choseIdCard && (
                    <TableRow>
                      <TableCell
                        component="th"
                        scope="row"
                        sx={{ p: 0, width: '100%', display: 'block' }}
                      >
                        <CardDetails
                          handelOpenSavedModal={handelOpenSavedModal}
                          addOrRemoveFromComparisons={
                            addOrRemoveFromComparisons
                          }
                          id={choseIdCard}
                          t={t}
                        />
                      </TableCell>
                    </TableRow>
                  )}
                </Fragment>
              );
            })}
          {loading && (
            <TableRow>
              <TableCell
                component="th"
                scope="row"
                sx={{ p: 0, width: '100%', display: 'block' }}
              >
                <ProgressLoader />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>

      <Pagination meta={meta} handelPagination={handelPagination} />
    </TableContainer>
  );
};

const TableRowInner = styled.div`
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 16px;
`;

const TableBody = styled.tbody`
  display: flex;
  flex-direction: column;

  height: calc(100% - 40px);

  overflow-y: overlay;
  scrollbar-width: thin;

  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${props => props.colors.gray};
    border-radius: 4px;
  }

  ::-webkit-scrollbar-track {
    background: ${props => props.colors.white};
    border-left: 1px solid ${props => props.colors.line};
  }
`;

const OpacityContainer = styled.div`
  display: flex;
  flex-direction: row;

  &:hover button {
    opacity: 1;
  }

  & button {
    opacity: ${({ opacity }) => opacity};
  }
`;

DenseTable.propTypes = {
  conf: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      sort: PropTypes.bool,
      style: PropTypes.object,
      type: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  data: PropTypes.arrayOf(PropTypes.object),
  meta: PropTypes.exact({
    current_page: PropTypes.number,
    from: PropTypes.number,
    last_page: PropTypes.number,
    links: PropTypes.arrayOf(
      PropTypes.exact({
        url: PropTypes.string,
        label: PropTypes.string,
        active: PropTypes.bool,
      }),
    ),
    path: PropTypes.string,
    per_page: PropTypes.number,
    to: PropTypes.number,
    total: PropTypes.number,
  }),
  handelPagination: PropTypes.func.isRequired,
  accordionActive: PropTypes.bool,
  choseItem: PropTypes.func.isRequired,
  addAnimation: PropTypes.func.isRequired,
  handelOpenSavedModal: PropTypes.func.isRequired,
  tableBodyHeight: PropTypes.number,
  handleGetDataBySort: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
