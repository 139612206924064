import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getErrorStack, handleDispatchFetch } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  BasicSelect,
  ButtonBase,
  FileLoader,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import authOperation from '@redux/auth/auth-operation';

import Typography from '@mui/material/Typography';

export const AboutCompany = ({ initData, pathParent }) => {
  const [data, setData] = useState({
    official_name: '',
    company_type_id: '',
    contractor_code: '',
    identification_code: '',
    is_delete_file: false,
  });
  const [logo, setLogo] = useState(null);
  const [error, setError] = useState({});
  const fileRef = useRef();
  const { t } = useTranslation(['registration', 'validation', 'common'], {
    useSuspense: false,
  });
  const dispatch = useDispatch();
  const { navigation } = useNavigation();

  useEffect(() => {
    const {
      official_name,
      company_type_id,
      contractor_code,
      identification_code,
      logo,
    } = initData.company;

    setData({
      official_name,
      company_type_id,
      contractor_code,
      identification_code,
    });
    setLogo(logo);
  }, [dispatch, initData]);

  const handelSubmit = e => {
    e.preventDefault();

    if (validation()) return;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          authOperation.signUpStepThierd({
            formData: serialize(
              {
                ...data,
                logo: typeof logo === 'string' ? null : logo,
              },
              {
                indices: true,
                nullsAsUndefineds: true,
                booleansAsIntegers: true,
              },
            ),
            onResolve,
            onReject,
          }),
        ),
      () => navigation(`${pathParent}/3`),
    );
  };

  const onChange = name => value => {
    if (name === 'company_type_id') {
      setData(prevState => ({
        ...prevState,
        [name]: value,
        identification_code: value === 2 ? prevState.identification_code : null,
        contractor_code: value === 1 ? prevState.contractor_code : null,
      }));
    } else {
      setData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const {
      official_name,
      company_type_id,
      contractor_code,
      identification_code,
    } = data;

    if (!official_name || official_name === '') {
      currentError = getErrorStack(
        currentError,
        'official_name',
        t('validation:required'),
      );
      flag = true;
    } else if (official_name.length < 2) {
      currentError = getErrorStack(
        currentError,
        'official_name',
        t(`${t('validation:minLengthLetters')} 2`),
      );
      flag = true;
    }

    if (!company_type_id || company_type_id === '') {
      currentError = getErrorStack(
        currentError,
        'company_type_id',
        t('validation:required'),
      );
      flag = true;
    }

    if (company_type_id === 1) {
      if (!contractor_code || contractor_code === '') {
        currentError = getErrorStack(
          currentError,
          'contractor_code',
          t('validation:required'),
        );
        flag = true;
      } else if (contractor_code.length < 8) {
        currentError = getErrorStack(
          currentError,
          'contractor_code',
          t(`${t('validation:minLengthLetters')} 8`),
        );
        flag = true;
      }
    }

    if (company_type_id === 2) {
      if (
        (!identification_code || identification_code === '') &&
        company_type_id !== ''
      ) {
        currentError = getErrorStack(
          currentError,
          'identification_code',
          t('validation:required'),
        );
        flag = true;
      } else if (identification_code.length < 10) {
        currentError = getErrorStack(
          currentError,
          'identification_code',
          t(`${t('validation:minLengthLetters')} 10`),
        );
        flag = true;
      }
    }
    if (logo)
      if (logo?.size > 6291456) {
        currentError = getErrorStack(
          currentError,
          'logo',
          t('validation:file_size'),
        );
        flag = true;
      }
    setError(currentError);
    return flag;
  };

  const handleSetImage = img => {
    if (!img) setData(prevState => ({ ...prevState, is_delete_file: true }));
    setLogo(img);
  };

  const {
    official_name,
    company_type_id,
    contractor_code,
    identification_code,
  } = data;

  return (
    <Wrapper>
      <Form onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'}>
          {t('registration:step_tree.tittle')}
        </Typography>
        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('registration:step_tree.slogan')}
        </Typography>

        <FileLoader
          fileRef={fileRef}
          file={logo}
          setFile={setLogo}
          title={t('registration:step_tree.loader.title')}
          handleSetImage={handleSetImage}
          isLogo={true}
          initError={error?.logo}
        />

        <TextInput
          error={error?.official_name?.init}
          errorText={error?.official_name?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={official_name}
          onChange={onChange('official_name')}
          placeholder={t('registration:field.legal_name_of_the_company', {
            req: '*',
          })}
          type="text"
          max={255}
          data-test-id="CompanyTitle"
        />

        <BasicSelect
          error={error?.company_type_id?.init}
          helperText={error?.company_type_id?.text}
          currentId={company_type_id}
          data={[
            { id: 1, name: t('registration:option.tov') },
            { id: 2, name: t('registration:option.fop') },
          ]}
          name={t('registration:field.type_of_cooperation', { req: '*' })}
          callBack={onChange('company_type_id')}
          sx={{ mt: '28px' }}
          data-test-id="Tov"
          dataTestItem="TovItem"
          height="48px"
        />

        {company_type_id === 1 && (
          <TextInput
            error={error?.contractor_code?.init}
            errorText={error?.contractor_code?.text}
            sx={{ mt: '28px', height: '48px' }}
            value={contractor_code}
            onChange={onChange('contractor_code')}
            placeholder={t('registration:field.company_registration_number', {
              req: '*',
            })}
            type="text"
            max={8}
            digitsOnly={true}
            withOutSpace={true}
            data-test-id="ContractorCode"
          />
        )}

        {company_type_id === 2 && (
          <TextInput
            error={error?.identification_code?.init}
            errorText={error?.identification_code?.text}
            sx={{ mt: '28px', height: '48px' }}
            value={identification_code}
            onChange={onChange('identification_code')}
            placeholder={t('registration:field.ipn', { req: '*' })}
            type="text"
            max={10}
            digitsOnly={true}
            withOutSpace={true}
            data-test-id="IdentificationCode"
          />
        )}

        <WrapperRow>
          <Wrapper>
            <OutlineButtonBase
              type="button"
              sx={{ flex: 1, maxWidth: '208px' }}
              onClick={() => {
                navigation(`${pathParent}/1`);
              }}
            >
              <Typography variant={'bodyBoldLarge'} align={'center'}>
                {t('common:buttons.back')}
              </Typography>
            </OutlineButtonBase>

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ flex: 1, ml: '12px', maxWidth: '208px' }}
            >
              <Typography variant={'bodyBoldLarge'}>
                {t('common:buttons.continue')}
              </Typography>
            </ButtonBase>
          </Wrapper>
        </WrapperRow>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const WrapperRow = styled.div`
  margin-top: 28px;
`;

AboutCompany.propTypes = {
  initData: PropTypes.shape({
    company: PropTypes.shape({
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
      ]),
      identification_code: PropTypes.number,
      logo: PropTypes.string,
      official_name: PropTypes.string,
    }),
  }),
  pathParent: PropTypes.string.isRequired,
};
