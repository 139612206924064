import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useLocation, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import authSelectors from '@redux/auth/auth-selectors';
import userSelectors from '@redux/user/user-selectors';

import { LangWatcher } from '../LangWatcher';

export function PrivateRoute({ element }) {
  const token = useSelector(authSelectors.getUserToken);
  const userRole = useSelector(authSelectors.getRole);
  const userStatus = useSelector(userSelectors.getUserProfile).status;
  const { pathname } = useLocation();
  const { lang, step } = useParams();
  let path;

  if (lang) {
    if (step) {
      const expValue = pathname.split(`/${lang}`)[1];
      path = expValue.split(`/${step}`)[0];
    } else {
      path = pathname.split(`/${lang}`)[1];
    }
  } else {
    if (step) {
      path = pathname.split(`/${step}`)[0];
    } else {
      path = pathname;
    }
  }

  return (
    <LangWatcher>
      {token ? (
        userRole === 'guest' && path !== '/sign-up-continuation-form' ? (
          <Navigate
            to={
              lang
                ? `/${lang}/sign-up-continuation-form/${step ? step : 1}`
                : `/sign-up-continuation-form/${step ? step : 1}`
            }
          />
        ) : userRole !== 'guest' &&
          path === '/sign-up-continuation-form' &&
          userStatus === 'active' ? (
          <Navigate to={lang ? `/${lang}/` : `/`} />
        ) : (
          element
        )
      ) : (
        <Navigate to={lang ? `/${lang}/login` : '/login'} />
      )}
    </LangWatcher>
  );
}

PrivateRoute.propTypes = {
  element: PropTypes.node,
};
