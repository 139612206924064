import React from 'react';

import PropTypes from 'prop-types';

import { Double_Check } from '@root/assets/svg';

import FormHelperText from '@mui/material/FormHelperText';
import { useTheme } from '@mui/material/styles';

export const MessageSuccess = ({ successText, sx }) => {
  const { color } = useTheme();
  return (
    <FormHelperText
      id="component-success-text"
      sx={{
        display: 'flex',
        background: color.green_light,
        color: color.green,
        marginTop: 0,
        ...sx,
      }}
    >
      <img src={Double_Check} alt="Error Icon" style={{ marginRight: '5px' }} />
      {successText}
    </FormHelperText>
  );
};

MessageSuccess.propTypes = {
  successText: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
