import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault, RichEditor } from '@root/components';
import { getErrorStack, handleDispatchFetch, validURL } from '@root/helpers';
import {
  ButtonBase,
  FileLoader,
  ModalBase,
  OutlineButtonBase,
  TextInput,
} from '@root/ui';

import newsAndEventsOperation from '@redux/news-and-events/news-and-events-operation';

const initialData = {
  image: null,
  preview_image: null,
  title: '',
  description: '',
  content: '',
  link: '',
};

export const CreateNewsModal = ({
  isOpen,
  handleCloseModal,
  editNewsInfo,
  navigationAfterUpdateArticle,
}) => {
  const [newsInfo, setNewsInfo] = useState(() => editNewsInfo || initialData);
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();

  const bigImageRef = useRef();
  const smallImageRef = useRef();

  useEffect(() => {
    if (!editNewsInfo) return;

    setNewsInfo({
      ...editNewsInfo,
    });
  }, [editNewsInfo]);

  const { t } = useTranslation(['validation', 'news_and_events'], {
    useSuspense: false,
  });

  const handleUpdateNewsInfo = (name, value) => {
    setNewsInfo(prevNewsInfo => ({ ...prevNewsInfo, [name]: value }));
  };

  const handleUpdateNewsImages = (name, img) => {
    if (!img) {
      setNewsInfo(prevNewsInfo => {
        if (name === 'image') {
          return {
            ...prevNewsInfo,
            delete_image: true,
            [name]: null,
          };
        } else {
          return {
            ...prevNewsInfo,
            delete_preview_image: true,
            [name]: null,
          };
        }
      });
    } else {
      setNewsInfo(prevNewsInfo => {
        const { delete_image, delete_preview_image, ...newNewsInfo } =
          prevNewsInfo;
        if (name === 'image') {
          return { ...newNewsInfo, [name]: img };
        } else {
          return { ...newNewsInfo, [name]: img };
        }
      });
    }
  };

  const validation = () => {
    let currentError = {};
    let flag = false;

    const { title, description, link, content } = newsInfo;

    Object.keys({ title, description, link, content }).forEach(key => {
      if (!newsInfo[key] || !newsInfo[key].length) {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );

        flag = true;
      }
    });

    if (!validURL(link) && link !== '') {
      currentError = getErrorStack(
        currentError,
        'link',
        t('validation:enter_valid_url'),
      );
      flag = true;
    }

    setErrors(currentError);

    return flag;
  };

  const handleSubmit = e => {
    e.preventDefault();

    if (validation()) return;

    const image = typeof newsInfo.image === 'string' ? null : newsInfo.image;
    const preview_image =
      typeof newsInfo.preview_image === 'string'
        ? null
        : newsInfo.preview_image;

    if (!editNewsInfo) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            newsAndEventsOperation.createNews({
              formData: serialize(
                {
                  ...newsInfo,
                  image,
                  preview_image,
                },
                {
                  indices: true,
                  nullsAsUndefineds: true,
                  booleansAsIntegers: true,
                },
              ),
              onResolve,
              onReject,
            }),
          ),
        () => {
          handleCloseModal();
        },
      );
    } else {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            newsAndEventsOperation.updateNews({
              formData: serialize(
                {
                  ...newsInfo,
                  image,
                  preview_image,
                },
                {
                  indices: true,
                  nullsAsUndefineds: true,
                  booleansAsIntegers: true,
                },
              ),
              id: newsInfo.id,
              onResolve,
              onReject,
            }),
          ),
        res => {
          handleCloseModal();
          navigationAfterUpdateArticle(res.slug);
        },
      );
    }
  };

  return (
    <ModalBase
      handleClose={handleCloseModal}
      open={isOpen}
      sx={{ width: '100%', maxWidth: '630px' }}
      modalHeader={
        <HeaderDefault onClose={handleCloseModal} text="Додати новину" />
      }
    >
      <CreateNewsModalWrapper>
        <FormWrapper onSubmit={handleSubmit}>
          <InputsWrapper>
            <FileLoader
              title={t('news_and_events:formFields.main_image_title')}
              optionalRecommendationSize={t(
                'news_and_events:image_optimal_size',
                {
                  size: '893x349',
                },
              )}
              styles={{ margin: '0px' }}
              file={newsInfo.image}
              setFile={value => handleUpdateNewsImages('image', value)}
              fileRef={bigImageRef}
              handleSetImage={value => handleUpdateNewsImages('image', value)}
              isLogo
              imageStyles={{
                height: '150px',
              }}
            />
            <FileLoader
              title={t('news_and_events:formFields.secondary_image_title')}
              optionalRecommendationSize={t(
                'news_and_events:image_optimal_size',
                {
                  size: '76x58',
                },
              )}
              styles={{ margin: '0px' }}
              file={newsInfo.preview_image}
              setFile={value => handleUpdateNewsImages('preview_image', value)}
              fileRef={smallImageRef}
              handleSetImage={value =>
                handleUpdateNewsImages('preview_image', value)
              }
              isLogo
              imageStyles={{
                height: '150px',
              }}
            />
            <TextInput
              label={t('news_and_events:formFields.title_label')}
              placeholder={t('news_and_events:formFields.title_placeholder')}
              value={newsInfo.title}
              error={errors?.title?.init}
              errorText={errors?.title?.text}
              required
              onChange={value => handleUpdateNewsInfo('title', value)}
              onBlur={() => {}}
            />
            <TextInput
              label={t('news_and_events:formFields.description_label')}
              placeholder={t(
                'news_and_events:formFields.description_placeholder',
              )}
              value={newsInfo.description}
              error={errors?.description?.init}
              errorText={errors?.description?.text}
              required
              onChange={value => handleUpdateNewsInfo('description', value)}
              onBlur={() => {}}
            />
            <RichEditorWrapper>
              <RichEditor
                label={t('news_and_events:formFields.editor_label')}
                placeholder={t('news_and_events:formFields.editor_placeholder')}
                error={errors?.content}
                value={newsInfo.content}
                onChange={value => handleUpdateNewsInfo('content', value)}
              />
            </RichEditorWrapper>

            <TextInput
              label={t('news_and_events:formFields.url_label')}
              placeholder={t('news_and_events:formFields.url_placeholder')}
              value={newsInfo.link}
              error={errors?.link?.init}
              errorText={errors?.link?.text}
              required
              onChange={value => handleUpdateNewsInfo('link', value)}
              onBlur={() => {}}
            />
          </InputsWrapper>

          <ActionsWrapper>
            <OutlineButtonBase onClick={handleCloseModal}>
              {t('news_and_events:buttons.cancel')}
            </OutlineButtonBase>
            <ButtonBase type="type">
              {t(`news_and_events:buttons.${!editNewsInfo ? 'add' : 'edit'}`)}
            </ButtonBase>
          </ActionsWrapper>
        </FormWrapper>
      </CreateNewsModalWrapper>
    </ModalBase>
  );
};

const CreateNewsModalWrapper = styled.div`
  padding-right: 5px;
`;

const InputsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 12px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  column-gap: 12px;
`;

const RichEditorWrapper = styled.div`
  width: 100%;
`;

const FormWrapper = styled.form``;

CreateNewsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  editNewsInfo: PropTypes.exact({
    image: PropTypes.any,
    preview_image: PropTypes.any,
    title: PropTypes.string,
    description: PropTypes.string,
    content: PropTypes.string,
    link: PropTypes.string,
    is_delete_file: PropTypes.bool,
  }),
  navigationAfterUpdateArticle: PropTypes.func.isRequired,
};
