const getRegisterData = state => state.auth.register;
const getUserToken = state => state.auth.token;
const getIsRefreshing = state => state.auth.isRefreshing;
const getLoading = state => state.auth.loading;
const getRole = state => state.auth.role;
const getDataUpdate = state => state.auth.dataUpdate;

const authSelectors = {
  getRole,
  getUserToken,
  getIsRefreshing,
  getLoading,
  getRegisterData,
  getDataUpdate,
};
export default authSelectors;
