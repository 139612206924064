import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { CounterInput } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { BasicTooltip, IconBtn } from '@root/ui';

import cartOperation from '@redux/cart/cart-operation';
import { updateFavoriteById } from '@redux/favorite/favorite-slice';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const QuantityManager = ({
  addAnimation,
  errorBorder,
  data,
  hide_amount = false,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });
  const dispatch = useDispatch();
  const [productQuantity, setProductQuantity] = useState(0);
  const [inputWidth, setInputWidth] = useState(28);
  const inputRef = useRef(null);
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const { stock, cart } = data;
  const cartQuantity = cart.quantity;

  const handleUpdateProduct = (res, favoriteProductId) => {
    const addedToCardProducts = res.data.products;
    dispatch(
      updateFavoriteById({
        addedToCardProducts,
        favoriteProductId,
      }),
    );
  };

  const handleAddProduct = e => {
    if (addAnimation) addAnimation(e);
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          cartOperation.putOneToCart({
            id: cart.id,
            quantity: productQuantity + 1,
            onResolve,
            onReject,
          }),
        ),
      res => handleUpdateProduct(res, cart.id),
      error => {},
    );
  };

  const handleDeleteOneProduct = e => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          cartOperation.putOneToCart({
            id: cart.id,
            quantity: productQuantity - 1,
            onResolve,
            onReject,
          }),
        ),
      data => handleUpdateProduct(data, cart.id),
      error => {},
    );
  };

  const handleClickOutside = event => {
    event.preventDefault();
    addToCart();
  };

  useEffect(() => {
    setProductQuantity(cartQuantity);
    if (cartQuantity) setInputWidth(cartQuantity.toString().length * 8 + 28);
  }, [cartQuantity]);

  const handleSetNumberAddToCart = e => {
    const validatedNumbers = validateNumbers(e.target.value);
    const validatedText = validateLength(validatedNumbers, 4);
    const validatedTextLength = validateLength(validatedNumbers, 3).length;
    if (e.target.value <= 0) return setProductQuantity(1);
    setProductQuantity(validatedText);
    setInputWidth(validatedTextLength * 8 + 28);
  };

  const validateNumbers = text => {
    const regexpText = text.match(/\d/g) ?? [];

    return regexpText.join('');
  };

  const validateLength = (text, maxLength) => {
    return text.length > maxLength ? text.slice(0, maxLength) : text;
  };

  const checkIsFirstLetterZero = event => {
    const textLength = event.target.value.length;
    return textLength === 0 && event.keyCode === 48;
  };

  const handleEnter = event => {
    if (checkIsFirstLetterZero(event)) {
      event.preventDefault();
    }

    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      event.preventDefault();
      addToCart();
    }
  };

  const addToCart = () => {
    if (productQuantity > stock) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            cartOperation.putOneToCart({
              id: cart.id,
              quantity: stock,
              onResolve,
              onReject,
            }),
          ),
        data => handleUpdateProduct(data, cart.id),
      );
      setProductQuantity(stock);
    } else {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            cartOperation.putOneToCart({
              id: cart.id,
              quantity: productQuantity,
              onResolve,
              onReject,
            }),
          ),
        data => handleUpdateProduct(data, cart.id),
      );
    }
  };

  const handleDeleteProduct = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          cartOperation.deleteOneFromCart({
            id: cart.id,
            onResolve,
            onReject,
          }),
        ),
      data => handleUpdateProduct(data, cart.id),
      error => {},
    );
  };

  return (
    <AddToCartWrapper>
      <IconBtn
        onClick={handleDeleteProduct}
        href={`${Sprite}#icon-delete`}
        size={17}
        sx={{
          display: 'flex',
          alignSelf: 'baseline',
          position: 'absolute',
          top: '8px',
          left: '-23px',
        }}
        data-test-id="ButtonDeleteFromCart"
      />
      <AddToCartActionWrap>
        <CounterInput
          data={data}
          handleDecrement={() =>
            cartQuantity === 1
              ? handleDeleteProduct()
              : handleDeleteOneProduct()
          }
          handleAddProduct={handleAddProduct}
          handleClickOutside={handleClickOutside}
          inputRef={inputRef}
          handleEnter={handleEnter}
          inputWidth={inputWidth}
          errorBorder={errorBorder}
          productQuantity={productQuantity}
          handleSetNumberAddToCart={handleSetNumberAddToCart}
          isDisabledIncrementBtn={cartQuantity === stock}
        />

        {cart.personal_amount[choosedCurrency] > 0 && !hide_amount && (
          <MainPriceWrapper stroke={color.gray_80}>
            <BasicTooltip title={t('common:tooltip.product_in_cart')}>
              <svg width={16} height={16}>
                <use href={`${Sprite}#icon-grocery_cart`}></use>
              </svg>
            </BasicTooltip>
            <svg height={12} width={11}>
              <use href={`${Sprite}#icon-right`}></use>
            </svg>

            <Typography variant={'bodyBoldSmall'}>
              {cart.personal_amount[choosedCurrency]} {currency}
            </Typography>
          </MainPriceWrapper>
        )}
      </AddToCartActionWrap>
    </AddToCartWrapper>
  );
};

const MainPriceWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  stroke: ${props => props.stroke};
  max-width: 70px;
  width: 100%;
  white-space: nowrap;

  svg {
    flex-shrink: 0;
  }
`;

const AddToCartWrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

const AddToCartActionWrap = styled.div``;

QuantityManager.propTypes = {
  addAnimation: PropTypes.func,
  errorBorder: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  data: PropTypes.shape({
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
  }),
  hide_amount: PropTypes.bool,
};
