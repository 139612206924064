import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Empty, StatusWithoutSteps } from '@root/components';
import { ErrorChecker1C } from '@root/middleware';
import { CustomScroll, IconBtn, ModalBase, ProgressLoader } from '@root/ui';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';
import orderOperation from '@redux/order/order-operation';
import { clearOrderHistoryDetails } from '@redux/order/order-slice';

import { Divider, Typography, useTheme } from '@mui/material';

export const LastMonthsOrder = ({ conf, historyDetailsModule }) => {
  const [open, setOpen] = useState(false);
  const ordersListData = useSelector(homeSelectors.getLastMonthsOrdersList);
  const loading = useSelector(homeSelectors.getIsLastMonthsOrdersLoading);
  const dispatch = useDispatch();
  const errorOneC = useSelector(
    homeSelectors.getErrorOneС,
  ).lastMonthsOrdersError;

  const { color } = useTheme();
  const { t } = useTranslation(['order_history', 'common'], {
    useSuspense: false,
  });
  const isExpanded = conf.size === '2fr';

  useEffect(() => {
    dispatch(homeOperation.getLastMonthsOrders());
  }, []);

  const onClose = () => {
    setOpen(false);
    dispatch(clearOrderHistoryDetails());
  };

  const showOrderDetails = orderId => {
    setOpen(true);
    dispatch(
      orderOperation.getHistoryOrderById({
        id: orderId,
        orderTab: 'archive-orders',
        archiveOrderWidjet: true,
      }),
    );
  };

  return (
    <ErrorChecker1C
      error={errorOneC}
      errorPageStyles={{
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '65%',
        height: '100%',
        padding: 0,
      }}
    >
      <ContentWrap>
        {loading && <ProgressLoader />}
        <List expanded={isExpanded}>
          <CustomScroll vertical={true}>
            {!loading && !ordersListData.length && (
              <Empty
                type={'NoHistory'}
                title={t('order_history:no_data_for_past_month')}
              />
            )}
            {ordersListData.map(listItem => (
              <ListItem color={color} key={listItem.id}>
                <ListItemHeader isExpanded={isExpanded}>
                  <Button
                    color={color}
                    onClick={() => showOrderDetails(listItem.id)}
                  >
                    <Typography color={color.gray} variant={'bodyBoldSmall'}>
                      № {listItem?.title}
                    </Typography>
                  </Button>
                  {isExpanded && (
                    <SmallDetailsOrder>
                      <Typography
                        variant={'bodySmall'}
                        color={color.gray_60}
                        sx={{ minWidth: '65px' }}
                      >
                        {listItem?.quantity}{' '}
                        {t('order_history:items_count.key', {
                          count: Number(listItem?.quantity),
                        })}
                      </Typography>
                      <Divider
                        orientation="vertical"
                        flexItem={true}
                        sx={{
                          margin: '0 7px',
                        }}
                      />
                      <Typography variant={'bodyBoldSmall'} color={color.gray}>
                        {listItem?.amount} {listItem?.currency}
                      </Typography>
                    </SmallDetailsOrder>
                  )}
                  {listItem?.status && <StatusWithoutSteps data={listItem} />}
                </ListItemHeader>
                <ListItemBody>
                  <Typography
                    sx={{ whiteSpace: 'nowrap' }}
                    color={color.gray_60}
                    variant={'bodySmall'}
                  >
                    {t('order_history:create')} {listItem?.created}
                  </Typography>
                </ListItemBody>
              </ListItem>
            ))}
          </CustomScroll>
        </List>
        <ModalBase
          sx={{
            padding: '45px 38px 38px',
          }}
          open={open}
          handleClose={onClose}
        >
          <IconBtn
            sx={{
              position: 'absolute',
              top: '10px',
              right: '38px',
            }}
            size={25}
            href={`${Sprite}#icon-cross`}
            onClick={onClose}
          />
          {historyDetailsModule}
        </ModalBase>
      </ContentWrap>
    </ErrorChecker1C>
  );
};

const ContentWrap = styled.div`
  height: 100%;
`;

const ListItem = styled.div`
  padding: 12px 10px;
  border-bottom: 1px solid ${({ color }) => color.line};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ListItemHeader = styled.div`
  display: flex;
  align-items: ${({ isExpanded }) => (isExpanded ? 'center' : 'flex-start')};
  justify-content: ${({ isExpanded }) =>
    isExpanded ? 'start' : 'space-between'};

  gap: 85px;
  margin-bottom: 8px;
`;

const List = styled.div`
  height: 307px;

  & ${ListItem} {
    flex-direction: ${({ expanded }) => (expanded ? 'row' : '')};
  }

  & ${ListItemHeader} {
    justify-content: ${({ expanded }) => (expanded ? 'initial' : '')};
    width: 100%;
  }
`;

const Button = styled.button`
  transition: 0.25s;
  cursor: pointer;
  text-decoration: underline;
  color: ${({ color }) => color.text_color.main};
  min-width: 160px;
  text-align: left;

  &:hover {
    color: ${({ color }) => color.main};
  }
`;

const SmallDetailsOrder = styled.div`
  text-transform: lowercase;
  display: flex;
  align-items: center;
  height: 8px;
  min-width: 170px;
`;

const ListItemBody = styled.div``;

LastMonthsOrder.propTypes = {
  conf: PropTypes.object,
  historyDetailsModule: PropTypes.node,
};
