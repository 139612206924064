import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StockBar } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const ListWarehouses = ({ data }) => {
  const { color } = useTheme();

  return (
    data.warehouses &&
    data.warehouses.length > 0 && (
      <CityStatisticsWrapper>
        {data.warehouses.map((warehouseObj, warehouseIndex) => (
          <>
            <Typography
              key={`warehouse-city-${warehouseObj.id}`}
              variant={'uppercaseBoldSmall'}
              sx={{
                marginTop: '16px',
                color: color.gray_80,
              }}
            >
              {warehouseObj.city_name}
            </Typography>
            <StocksWrapper
              style={{
                padding:
                  warehouseIndex !== data.warehouses.length - 1
                    ? '8px 0 22px 0'
                    : '8px 0 8px 0',
                borderBottom:
                  warehouseIndex !== data.warehouses.length - 1
                    ? `1px dashed ${color.line_light}`
                    : '',
              }}
            >
              {warehouseObj.warehouses.map((warehouse, index) => (
                <StockBar
                  index={index}
                  key={`warehouse${warehouse.id}`}
                  warehouse={warehouse}
                />
              ))}
            </StocksWrapper>
          </>
        ))}
      </CityStatisticsWrapper>
    )
  );
};

const CityStatisticsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StocksWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  width: 535px;
`;

ListWarehouses.propTypes = {
  data: PropTypes.array,
};
