import { createSelector } from '@reduxjs/toolkit';

const getServiceOrders = state => state.orderService.serviceOrders;
const getIsServiceOrdersLoading = state =>
  state.orderService.isServiceOrdersLoading;

const getSearchValue = state => state.orderService.searchValue;
const getErrorOneC = state => state.orderService.errorOneC;

const getFilteredServiceOrders = createSelector(
  [getServiceOrders, getSearchValue],
  (items, searchValue) => {
    const value = searchValue.toLowerCase().trim();

    return (
      items &&
      items.filter(deliveryItem => {
        return (
          deliveryItem.type.toLowerCase().includes(value) ||
          deliveryItem.date.toLowerCase().includes(value) ||
          deliveryItem.number.toLowerCase().includes(value) ||
          deliveryItem.model.toLowerCase().includes(value) ||
          deliveryItem.serial.toLowerCase().includes(value) ||
          deliveryItem.status.toLowerCase().includes(value)
        );
      })
    );
  },
);

export default {
  getServiceOrders,
  getIsServiceOrdersLoading,
  getSearchValue,
  getFilteredServiceOrders,
  getErrorOneC,
};
