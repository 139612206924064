import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const BonusWidjet = () => {
  const userBonusDetails = useSelector(userSelectors.getUserProfile).bonuses;
  const { color } = useTheme();
  const { t } = useTranslation('header', { useSuspense: false });

  return (
    <MainWrap color={color}>
      <LeftSide>
        <Svg color={color}>
          <use href={`${Sprite}#icon-coins`}></use>
        </Svg>
        <Typography
          color={`${color.gray} !important`}
          variant={'bodyBoldSmall'}
          component={'span'}
        >
          {t('bonus_widjet.balance')}
        </Typography>
      </LeftSide>
      <RightSide>
        <Typography
          color={`${color.gray} !important`}
          variant={'bodySmall'}
          component={'span'}
        >
          {userBonusDetails.bonus || 0}
        </Typography>
        <Typography
          color={`${color.gray} !important`}
          variant={'bodySmall'}
          component={'span'}
        >
          $
        </Typography>
      </RightSide>
    </MainWrap>
  );
};

const MainWrap = styled.div`
  display: flex;
  align-items: center;
  //TODO create object for static colors, if situation with background repeat
  background: #f5f6fa;
  padding: 8px 12px;
  border-radius: 4px;
  width: 100%;
  gap: 12px;
  border: 1px solid ${({ color }) => color.line};
`;

const LeftSide = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  flex: 50%;
`;
const RightSide = styled.div`
  flex: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
`;
const Svg = styled.svg`
  width: 16px;
  height: 16px;
  fill: transparent;
  vertical-align: middle;
  position: relative;
  top: -2px;
`;
