import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Link } from '@root/ui';

import { useTheme } from '@mui/material/styles';

export const LabelStatus = ({ children, styles }) => {
  const { color } = useTheme();
  return (
    <LabelWrapper styles={styles} color={color.background}>
      {children}
    </LabelWrapper>
  );
};

const LabelWrapper = styled.span`
  padding: 0 4px 4px;
  background-color: ${({ color }) => color};
  border-radius: 2px;
  ${({ styles }) => styles}
`;

LabelStatus.propTypes = {
  children: PropTypes.node,
};
