export const OrderServiceConf = t => [
  {
    label: t('order_service:conf.date'),
    slug: 'date',
    style: {
      minWidth: '120px',
      maxWidth: '100%',
    },
  },
  {
    label: t('order_service:conf.order_number'),
    slug: 'order_number',
    style: { width: '100%', ml: '20px', minWidth: '60px', maxWidth: '80px' },
  },
  {
    label: t('order_service:conf.device'),
    slug: 'device',
    style: { width: '100%', ml: '20px', minWidth: '120px', maxWidth: '150px' },
  },
  {
    label: t('order_service:conf.serial_number'),
    slug: 'serial_number',
    style: { width: '100%', ml: '20px', minWidth: '120px', maxWidth: '150px' },
  },
  {
    label: t('order_service:conf.warranty_payments'),
    slug: 'warranty_payments',
    style: { width: '100%', ml: '20px', maxWidth: '200px' },
  },
  {
    label: t('order_service:conf.status'),
    slug: 'status',
    style: { width: '100%', ml: '20px' },
  },
];
