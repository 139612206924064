export const DeliveryHistoryConf = t => [
  {
    label: t('common:table.table_heads.date'),
    slug: 'date',
    type: ['delivery-from-client', 'sending-to-client'],
    style: {
      minWidth: '81px',
    },
  },
  {
    label: t('common:table.table_heads.document_number'),
    slug: 'document_number',
    type: ['delivery-from-client', 'sending-to-client'],
    style: {
      minWidth: '81px',
      ml: '20px',
    },
  },
  {
    label: t('common:table.table_heads.delivery_method'),
    slug: 'delivery_method',
    type: ['delivery-from-client', 'sending-to-client'],
    style: {
      width: '100%',
      ml: '20px',
    },
  },
  {
    label: t('common:table.table_heads.weight'),
    slug: 'weight',
    type: ['sending-to-client'],
    style: {
      minWidth: '70px',
      ml: '20px',
    },
  },
  {
    label: t('common:table.table_heads.sits'),
    slug: 'sits',
    type: ['delivery-from-client', 'sending-to-client'],
    style: {
      minWidth: '60px',
      textAlign: 'center',
    },
  },
  {
    label: t('common:table.table_heads.status'),
    slug: 'status',
    type: ['delivery-from-client'],
    style: {
      minWidth: '120px',
      ml: '20px',
    },
  },
];
