import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Empty, InfiniteScroll, NewsOrEventCard } from '@root/components';
import { useNavigation } from '@root/hooks';
import { CustomScroll, NavigationButton, ProgressLoader } from '@root/ui';

import newsAndEventsOperation from '@redux/news-and-events/news-and-events-operation';
import newsAndEventsSelectors from '@redux/news-and-events/news-and-events-selectors';
import { clearState } from '@redux/news-and-events/news-and-events-slice';

import { useTheme } from '@mui/material';

export const List = () => {
  const [activeCard, setActiveCard] = useState(null);
  const meta = useSelector(newsAndEventsSelectors.articlesListMeta);
  const getArticlesList = useSelector(newsAndEventsSelectors.articlesList);
  const loadingArticlesList = useSelector(
    newsAndEventsSelectors.loadingArticlesList,
  );
  const dispatch = useDispatch();

  const { tab, slug } = useParams();
  const { navigation } = useNavigation();
  const { t } = useTranslation('news_and_events');
  const { color } = useTheme();

  const tabs = [
    {
      label: t('buttons.news_count.key', { count: 2 }),
      key: 'news',
    },
    {
      label: t('buttons.events_count.key', { count: 2 }),
      key: 'events',
    },
  ];

  useEffect(() => {
    if (!slug) return;
    setActiveCard(slug);
  }, [slug]);

  const currentUrl = currentTab => {
    switch (currentTab) {
      case 'news':
        return `/announcements/${currentTab}`;
      case 'events':
        return `/announcements/${currentTab}`;
      default:
        break;
    }
  };

  const getNewsOrEventsData = (page, currentTab) => {
    switch (currentTab) {
      case 'news':
        dispatch(newsAndEventsOperation.getNewsList({ page }));
        break;
      case 'events':
        dispatch(newsAndEventsOperation.getEventsList({ page }));
        break;
      default:
        break;
    }
  };

  const handleNavigation = currentTab => {
    dispatch(clearState());
    navigation(currentUrl(currentTab));
    getNewsOrEventsData(1, currentTab);
    setActiveCard(null);
  };

  const handleCardEvent = slug => {
    setActiveCard(slug);
    navigation(`/announcements/${tab}/${slug}`);
  };

  return (
    <Wrap color={color}>
      <NavigationWrapper color={color}>
        {tabs.map(({ key, label }) => (
          <NavigationButton
            key={key}
            isActive={tab === key}
            sx={{
              padding: '8px 20px !important',
            }}
            onClick={() => handleNavigation(key)}
          >
            {label}
          </NavigationButton>
        ))}
      </NavigationWrapper>
      <ContentWrap>
        {!loadingArticlesList && getArticlesList.length === 0 ? (
          <Empty
            type="EmptyBox"
            title={t(
              `news_and_events:${
                tab === 'news' ? 'empty_news_title' : 'empty_events_title'
              }`,
            )}
            subTitle=""
            buttonTittle=""
          />
        ) : (
          <CustomScroll vertical={true}>
            <InfiniteScroll
              data={getArticlesList}
              onScroll={({ inView }) =>
                inView &&
                meta?.current_page !== meta?.last_page &&
                getNewsOrEventsData(meta.current_page + 1, tab)
              }
            >
              {({ isInView, item, index }) => {
                return (
                  tab === item.type && (
                    <NewsOrEventCard
                      isActive={activeCard === item.slug}
                      item={item}
                      viewType={tab}
                      handleCardEvent={handleCardEvent}
                    />
                  )
                );
              }}
            </InfiniteScroll>
            {loadingArticlesList && <ProgressLoader />}
          </CustomScroll>
        )}
      </ContentWrap>
    </Wrap>
  );
};

const Wrap = styled.div`
  background: ${({ color }) => color.white};
  border: 1px solid ${({ color }) => color.line};
  border-radius: 4px;
  flex: 1 0 35%;
  position: relative;
`;

const NavigationWrapper = styled.div`
  border-bottom: 1px solid ${({ color }) => color.line};
`;

const ContentWrap = styled.div`
  height: calc(100% - 21px);
`;
