export const DeleteRoleConf = t => {
  return [
    {
      label: t('profile:conf.roles.modals.delete_role_modal.conf.user'),
      slug: 'user',
      sort: false,
      style: {
        padding: '0 10px 0 32px',
        width: '20%',
      },
    },
    {
      label: t('profile:conf.roles.modals.delete_role_modal.conf.role'),
      slug: 'role',
      sort: false,
      style: {
        padding: '0 20px',
        width: '25%',
      },
    },
    {
      label: t('profile:conf.roles.modals.delete_role_modal.conf.phone'),
      slug: 'phone',
      sort: false,
      style: {
        padding: '0 20px',
        width: '20%',
      },
    },
    {
      label: t('profile:conf.roles.modals.delete_role_modal.conf.city'),
      slug: 'city',
      sort: false,
      style: {
        padding: '0 20px',
        width: '15%',
      },
    },
    {
      label: t('profile:conf.roles.modals.delete_role_modal.conf.status'),
      slug: 'status',
      sort: false,
      style: {
        padding: '0 20px',
        width: '20%',
      },
    },
  ];
};
