import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { handleCopy } from '@root/helpers';
import { BasicTooltip, IconBtn } from '@root/ui';

export const CopyToClipboard = ({
  children,
  copyText,
  styles,
  onCopySuccess,
  copyIcon = true,
}) => {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const handleCopyToClipboard = async () => {
    handleCopy(copyText);

    onCopySuccess && onCopySuccess();
  };
  return (
    <CopyToClipboardWrapper style={styles} onClick={handleCopyToClipboard}>
      {children}
      {copyIcon && (
        <IconBtn
          href={`${Sprite}#icon-copy`}
          tooltipText={t('common:tooltip.copy')}
        />
      )}
    </CopyToClipboardWrapper>
  );
};

const CopyToClipboardWrapper = styled.div`
  display: flex;
  align-items: center;
  column-gap: 8px;
`;

CopyToClipboard.propTypes = {
  children: PropTypes.node,
  copyText: PropTypes.string.isRequired,
  styles: PropTypes.object,
  onCopySuccess: PropTypes.func.isRequired,
  copyIcon: PropTypes.bool,
};
