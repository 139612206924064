import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Blank } from '@root/assets/image/';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const EmptyBlank = ({ text }) => {
  const { color } = useTheme();

  return (
    <EmptyBox>
      <img src={Blank} alt="Blank" />
      <Typography
        variant={'bodyBoldSmall'}
        component={'p'}
        sx={{
          display: 'flex',
          alignItems: 'center',
          mt: '40px',

          color: color.gray,
        }}
      >
        {text}
      </Typography>
    </EmptyBox>
  );
};

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

EmptyBlank.propTypes = {
  text: PropTypes.string,
};
