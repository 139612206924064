import PropTypes from 'prop-types';

import { InlineTab, InlineTabs } from '@root/styles';

export const LangManager = ({ currentLang, handleChangeLang, conf }) => {
  return (
    <InlineTabs
      value={currentLang}
      onChange={handleChangeLang}
      aria-label="Lang Tabs"
    >
      {conf.map(el => (
        <InlineTab key={el.value} label={el.label} value={el.value} />
      ))}
    </InlineTabs>
  );
};

LangManager.propTypes = {
  currentLang: PropTypes.string.isRequired,
  handleChangeLang: PropTypes.func.isRequired,
  conf: PropTypes.arrayOf(
    PropTypes.exact({ label: PropTypes.string, value: PropTypes.string }),
  ),
};
