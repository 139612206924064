import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, InfiniteScroll, NotificationLoader } from '@root/components';
import { CustomScroll, ProgressLoader } from '@root/ui';

import notificationsOperation from '@redux/notifications/notifications-operation';
import notificationsSelectors from '@redux/notifications/notifications-selectors';

import { useTheme } from '@mui/material';

import { Notification } from './Notification';
import { TabsNavigation } from './TabsNavigation';

const DEFAULTS_NOTIFICATIONS_PARAMS = {
  page: 1,
  per_page: 10,
  type: 'base',
};

export const Notifications = ({
  containerHeight,
  tabsNavigation = true,
  baseLoader = false,
  handleClosePopUp,
}) => {
  const baseNotifications = useSelector(
    notificationsSelectors.getBaseNotifications,
  );
  const isBaseNotificationsLoading = useSelector(
    notificationsSelectors.getIsBaseNotificationsLoading,
  );
  const notificationsMeta = useSelector(
    notificationsSelectors.getNotificationsMeta,
  );

  const { t } = useTranslation('common', { useSuspense: false });

  const { color } = useTheme();

  const dispatch = useDispatch();

  useEffect(() => {
    handleGetNotifications(DEFAULTS_NOTIFICATIONS_PARAMS);
  }, []);

  const handleGetNotifications = ({ page, per_page, type }) => {
    dispatch(
      notificationsOperation.getNotifications({
        page,
        per_page,
        type,
      }),
    );
  };

  const handleMarkNotificationAsRead = (type, id) => {
    dispatch(notificationsOperation.markOneAsRead({ type, id }));
  };

  return (
    <NotificationsWrapper>
      <TabsNavigation
        handleGetNotifications={handleGetNotifications}
        tabsNavigation={tabsNavigation}
      >
        {({ getSelectedNotifications, type }) => {
          const notifications = getSelectedNotifications();
          return (
            <NotificationsBody
              borderColor={color.line}
              containerHeight={containerHeight}
            >
              {!isBaseNotificationsLoading && baseNotifications.length === 0 ? (
                <Empty
                  type={'EmptyBox'}
                  title={t('empty_notifications')}
                  subTitle={''}
                  buttonTittle={''}
                  boxStyles={{
                    padding: '0 10px',
                  }}
                />
              ) : (
                <CustomScroll vertical>
                  <InfiniteScroll
                    data={baseNotifications}
                    onScroll={({ inView }) =>
                      inView &&
                      notificationsMeta.current_page !==
                        notificationsMeta.last_page &&
                      handleGetNotifications({
                        page: notificationsMeta.current_page + 1,
                        per_page: 10,
                        type,
                      })
                    }
                  >
                    {({ isInView, item, index }) => {
                      const { title, detail, created, readed, url, id } = item;
                      return (
                        <Notification
                          isViewed={readed}
                          title={title}
                          text={detail}
                          created={created}
                          url={url}
                          handleClosePopUp={handleClosePopUp}
                          onNotificationClick={() =>
                            handleMarkNotificationAsRead(type, id)
                          }
                        />
                      );
                    }}
                  </InfiniteScroll>
                  {isBaseNotificationsLoading &&
                    (baseLoader ? (
                      <ProgressLoader />
                    ) : (
                      [...Array(10)].map((el, idx) => (
                        <NotificationLoader key={idx} />
                      ))
                    ))}
                </CustomScroll>
              )}
            </NotificationsBody>
          );
        }}
      </TabsNavigation>
    </NotificationsWrapper>
  );
};

const NotificationsWrapper = styled.div``;

const NotificationsBody = styled.div`
  height: ${({ containerHeight }) => containerHeight};
`;

Notifications.propTypes = {
  containerHeight: PropTypes.string,
  tabsNavigation: PropTypes.bool,
  baseLoader: PropTypes.bool,
  handleClosePopUp: PropTypes.func,
};
