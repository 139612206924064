import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { PricesTooltip } from '@root/components';
import { theme } from '@root/styles';

import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';

export const CostDetails = ({ item, sx }) => {
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const currency = useSelector(userSelectors.getChoosedCurrency);

  const { t } = useTranslation([]);

  return (
    <CostDetailsWrapper style={sx}>
      {item?.personal_price[choosedCurrency] > 0 ? (
        <PricesTooltip prices={item?.prices}>
          <Box>
            <Typography
              component={'span'}
              variant={'h1'}
              sx={{
                color: theme.color.gray,
                cursor: 'pointer',
              }}
            >
              {item?.personal_price[choosedCurrency]}
            </Typography>
            <Typography
              component={'span'}
              variant={'h3'}
              sx={{
                color: theme.color.gray,
                cursor: 'pointer',

                ml: '4px',
              }}
            >
              {currency}
            </Typography>
          </Box>
        </PricesTooltip>
      ) : (
        <Typography
          variant={'h1'}
          component={'span'}
          sx={{
            color: theme.color.gray,
          }}
        >
          {t('common:check_price_for_details')}
        </Typography>
      )}
    </CostDetailsWrapper>
  );
};

const Box = styled.div`
  display: flex;
  align-items: center;
`;

const CostDetailsWrapper = styled.div``;
