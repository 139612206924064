import { useCallback, useEffect, useMemo, useState } from 'react';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  Availability,
  CostDetails,
  Empty,
  ImgPrev,
  LabelProduct,
  ProductCard,
  QuantityManagerLarge,
} from '@root/components';
import { useIsFirstRender, useNavigation } from '@root/hooks';
import { useSaleData } from '@root/hooks/useSaleData';
import { ErrorChecker1C } from '@root/middleware';
import { ButtonBase, CustomScroll, IconBtn, ProgressLoader } from '@root/ui';

import cartOperation from '@redux/cart/cart-operation';
import comparisonsOperation from '@redux/comparisons/comparisons-operation';
import favoriteOperation from '@redux/favorite/favorite-operation';
import { setOpenModal } from '@redux/modal-watcher/modal-watcher-slice';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

const SALE_WIDGET_KEYS = ['buys_most_often', 'top_sale'];

export const TopSale = ({ conf, activeTab }) => {
  const { size, key } = conf;
  const [count, setCount] = useState(0);
  const { data, item, loading, errorOneC } = useSaleData({
    key,
    count,
    activeTab,
  });
  const dispatch = useDispatch();
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const permission = useSelector(userSelectors.getPermissions);
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const { color } = useTheme();
  const { t } = useTranslation(['product_details', 'home', 'common']);
  const isFirstRender = useIsFirstRender();

  const { navigation } = useNavigation();

  useEffect(() => {
    if (isFirstRender) return;
    setCount(0);
  }, [activeTab]);

  const handelNavigate = () => {
    navigation(item?.product_url ? item?.product_url : item?.url);
  };

  const handleChoseItem = param => {
    dispatch(
      cartOperation.addProductToCart({
        id: param.id,
        quantity: param?.relation_quantity ? param?.relation_quantity : 1,
      }),
    );
  };

  const handelOpenSavedModal = el => {
    if (el.is_saved) {
      dispatch(
        favoriteOperation.deleteProductFromSaved({
          id: el.id,
        }),
      );
    } else {
      dispatch(
        setOpenModal({
          open: true,
          key: 'favorite',
          id: el.id,
          notification_open: true,
        }),
      );

      dispatch(
        setOpenNotification({
          width: 'initial',
          message: t('common:notifications.product_saved'),
          key: 'favorite-notification',
        }),
      );
    }
  };

  const addOrRemoveFromComparisons = el => {
    if (el.in_compare) {
      dispatch(
        comparisonsOperation.removeFromComparisons({
          id: el.id,
        }),
      );
    } else {
      dispatch(
        comparisonsOperation.addToComparisons({
          id: el.id,
        }),
      );
    }
  };

  const getWidgetMurk = useCallback(() => {
    if (!activeTab && key === SALE_WIDGET_KEYS[0]) return;

    const currentSalesData =
      key === SALE_WIDGET_KEYS[0] ? data[activeTab] : data;

    if (!currentSalesData.length) return <Empty type="EmptyBox" />;
    switch (size) {
      case '1fr':
        return (
          <Widget data-test-id="TopSaleWidget">
            <Flex onClick={handelNavigate}>
              <ImgPrev src={item?.image} />
              <LabelProduct
                label={item?.name}
                parm={item?.model}
                article={item?.sku}
                isEllipsis={true}
                lengthEllipsis={{
                  label: 40,
                  parm: 40,
                }}
              />
            </Flex>
            <Wrapper color={color}>
              <PricesWrapper>
                <CostDetails item={item} sx={{ alignSelf: 'flex-start' }} />
                {item?.price[choosedCurrency] > 0 && (
                  <Typography
                    variant={'bodyMedium'}
                    sx={{
                      color: color.gray_60,
                    }}
                  >
                    {t('product_details:recommended_selling_price')}:{' '}
                    <Typography
                      variant={'bodyBoldMedium'}
                      sx={{
                        color: color.gray_60,
                      }}
                    >
                      {item?.price[choosedCurrency]} {currency}
                    </Typography>
                  </Typography>
                )}
              </PricesWrapper>
              <FlexAction>
                {permission.cart_add_access && (
                  <QuantityManagerLarge
                    productCartData={item?.cart}
                    itemInCart={item?.is_in_cart}
                    isActive={item?.can_buy[choosedCurrency]}
                    handleChoseItem={() => handleChoseItem(item)}
                    handleUpdateProductDetails={() => {}}
                    availableNumber={item?.stock}
                    itemPrice={item?.price[choosedCurrency]}
                  />
                )}
                <IconBtn
                  href={`${Sprite}#icon-hart`}
                  onClick={() => handelOpenSavedModal(item)}
                  size={24}
                  saved={item?.is_saved}
                  tooltipText={t('common:tooltip.save')}
                  data-test-id="WidgetAddToFavoritesButton"
                />
                {permission.product_compare_access && (
                  <IconBtn
                    href={`${Sprite}#icon-counter`}
                    onClick={() => addOrRemoveFromComparisons(item)}
                    size={24}
                    compare={item?.in_compare}
                    tooltipText={t('common:tooltip.compare')}
                  />
                )}
              </FlexAction>
              {permission.product_stock_access && (
                <InStockWrapper>
                  <Typography
                    variant={'bodySmall'}
                    sx={{
                      color: color.gray,
                    }}
                  >
                    {t('common:in_stock')}:
                  </Typography>
                  <Availability
                    present={item?.stock_percent}
                    inStock={item?.stock}
                    notAvailable={item?.stock}
                  />
                </InStockWrapper>
              )}
            </Wrapper>

            <PaginationWrapper>
              {count !== 0 ? (
                <IconBtn
                  href={`${Sprite}#icon-arrow-left`}
                  onClick={() => {
                    setCount(count - 1);
                  }}
                  size={16}
                  sx={{ marginRight: '34px' }}
                />
              ) : (
                <Plug margin="0 34px 0 0" />
              )}

              <Typography
                variant={'bodyBoldSmall'}
                sx={{
                  color: color.gray,
                }}
              >
                {count + 1}
              </Typography>
              <Typography
                variant={'bodySmall'}
                sx={{
                  color: color.gray,
                }}
              >
                /{currentSalesData.length}
              </Typography>
              {count + 1 !== currentSalesData.length ? (
                <IconBtn
                  href={`${Sprite}#icon-arrow-right`}
                  onClick={() => {
                    setCount(count + 1);
                  }}
                  size={16}
                  sx={{ marginLeft: '34px' }}
                />
              ) : (
                <Plug margin="0 0 0 34px" />
              )}
            </PaginationWrapper>
          </Widget>
        );
      case '2fr':
        return (
          <ContentWrap>
            <CustomScroll vertical={true}>
              {currentSalesData.map(el => (
                <ProductCard
                  handelOpenSavedModal={() => handelOpenSavedModal(el)}
                  addOrRemoveFromComparisons={() =>
                    addOrRemoveFromComparisons(el)
                  }
                  data={el}
                  handleChoseItem={() =>
                    handleChoseItem(el, el?.relation_quantity)
                  }
                  style={{ wrapper: { borderLeft: 'none' } }}
                  isHideArrowCard={true}
                />
              ))}
            </CustomScroll>
          </ContentWrap>
        );

      default:
        break;
    }
  }, [choosedCurrency, color, count, currency, data, item, size, activeTab]);

  return (
    <ErrorChecker1C
      error={errorOneC}
      errorPageStyles={{
        position: 'absolute',
        top: '55%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '65%',
        height: '100%',
        padding: 0,
      }}
    >
      {!loading ? <>{getWidgetMurk()}</> : <ProgressLoader />}
    </ErrorChecker1C>
  );
};

const Widget = styled.div`
  padding: 12px 24px 22px 24px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

const Flex = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  cursor: pointer;

  gap: 16px;

  margin-bottom: 11px;
`;

const FlexAction = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 34px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  border-radius: 4px;
  border: 1px solid ${({ color }) => color.line_light};
  background: ${({ color }) => color.background};
  gap: 6px;

  padding: 10px 20px;
`;

const InStockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const PaginationWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 11px;
`;

const Plug = styled.div`
  width: 16px;
  height: 16px;
  margin: ${({ margin }) => margin};
`;

const ContentWrap = styled.div`
  height: 305px;
`;

const PricesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
