export const EmployeeStatusConf = (t, invited, isCurrentUser, isAdmin) => {
  return [
    {
      name: t('common:buttons.edit'),
      id: 'edit',
      availableForStatuses: [invited && 'active', invited && 'inactive'],
    },
    {
      name: t('common:buttons.reinvite'),
      id: 'reinvite',
      availableForStatuses: [!invited && 'active', !invited && 'inactive'],
      reduxAction: 'reinviteEmployeeById',
    },
    {
      name: t('common:buttons.unlock'),
      id: 'active',
      availableForStatuses: [invited && !isCurrentUser && 'inactive'],
      reduxAction: 'updateEmployeeById',
    },
    {
      name: t('common:buttons.lock'),
      id: 'inactive',
      availableForStatuses: [invited && !isCurrentUser && 'active'],
      reduxAction: 'updateEmployeeById',
    },
    {
      name: t('common:buttons.delete'),
      id: 'delete',
      availableForStatuses: [
        (!isCurrentUser || !isAdmin) && 'active',
        (!isCurrentUser || !isAdmin) && 'inactive',
      ],
      reduxAction: 'deleteEmployeeById',
    },
  ];
};
