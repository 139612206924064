import { createSelector } from '@reduxjs/toolkit';

const getAccountingDocuments = state =>
  state.accountingDocuments.accountingDocuments;

const getIsAccountingDocumentsLoading = state =>
  state.accountingDocuments.isAccountingDocumentsLoading;
const getSearchValue = state => state.accountingDocuments.searchValue;
const getErrorOneC = state => state.accountingDocuments.errorOneC;

const getFilteredAccountingDocuments = createSelector(
  [getAccountingDocuments, getSearchValue],
  (items, searchValue) => {
    const value = searchValue.toLowerCase().trim();
    return (
      items &&
      items.filter(
        deliveryItem =>
          deliveryItem.number.toLowerCase().includes(value) ||
          deliveryItem.date.toLowerCase().includes(value) ||
          deliveryItem.seller.toLowerCase().includes(value) ||
          deliveryItem.buyer.toLowerCase().includes(value) ||
          deliveryItem.status.toLowerCase().includes(value) ||
          deliveryItem.summ_all.toString().includes(value) ||
          deliveryItem.summ_need_to_pay.toString().includes(value) ||
          deliveryItem.summ_paid.toString().includes(value),
      )
    );
  },
);

export default {
  getFilteredAccountingDocuments,
  getIsAccountingDocumentsLoading,
  getSearchValue,
  getErrorOneC,
};
