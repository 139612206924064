import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import moment from 'moment/moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  AdvertisingSlider,
  Availability,
  CharacteristicsInfo,
  CostDetails,
  ListWarehouses,
  QuantityManagerLarge,
} from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { theme } from '@root/styles';
import { ButtonBase, EllipsisText, IconBtn, StockBar } from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';
import cartOperation from '@redux/cart/cart-operation';
import comparisonsOperation from '@redux/comparisons/comparisons-operation';
import favoriteOperation from '@redux/favorite/favorite-operation';
import { setOpenModal } from '@redux/modal-watcher/modal-watcher-slice';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import productOperation from '@redux/product/product-operation';
import productSelectors from '@redux/product/product-selectors';
import {
  onClearByEditIdData,
  updateProductById,
} from '@redux/product/product-slice';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import { AdminController } from './AdminController';
import { ProductTitle } from './ProductTitle';
import { RelatedProducts } from './RelatedProducts';
import { SimilarProducts } from './SimilarProducts';

const INIT_EDIT_DATA = {
  brand_id: 'none',
  categories: [],
  category_id: 'none',
  id: null,
  model: '',
  name: { uk: '', en: '' },
};

export const ProductInfo = ({
  handelMoveToCart,
  item,
  setNavSection,
  handelOpenSavedModal,
  addOrRemoveFromComparisons,
}) => {
  const [mainImage, setMainImage] = useState();
  const [relatedScoop, setRelatedScoop] = useState(false);
  const [openCard, setOpenCard] = useState(false);
  const [choseIdCard, setChoseIdCard] = useState(null);
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const editProduct = useSelector(productSelectors.getProductByEditId);
  const permission = useSelector(userSelectors.getPermissions);
  const deliveryDay = moment().add(3, 'days').format('DD/MM/YYYY');
  const userRole = useSelector(authSelectors.getRole);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const [isEdit, setIsEdit] = useState(false);
  const [lang, setLang] = useState('uk');
  const [editData, setEditData] = useState(INIT_EDIT_DATA);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isEdit && !editData?.id) {
      dispatch(productOperation.getProductByEditId({ id: item.id }));
    }
  }, [isEdit]);

  useEffect(() => {
    return () => {
      dispatch(onClearByEditIdData());
    };
  }, []);

  useEffect(() => {
    if (editProduct) {
      setEditData(editProduct);
    }
  }, [editProduct]);

  const handleLangData = key => value => {
    const copyData = JSON.parse(JSON.stringify(editData));
    if (key === 'name') {
      copyData[key][lang] = value;
    } else {
      copyData[key] = value;
    }

    setEditData(copyData);
  };

  const { t } = useTranslation(['product_details', 'common'], {
    useSuspense: false,
  });
  const { color } = useTheme();

  const handelDetailCard = id => {
    if (choseIdCard !== id) {
      setOpenCard(true);
      setChoseIdCard(id);
    } else {
      setOpenCard(!openCard);
      setChoseIdCard(openCard ? null : id);
    }
  };

  const handleChoseItem = param => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('common:notifications.added_to_cart'),
        action: (
          <ButtonBase onClick={handelMoveToCart} sx={{ height: '25px' }}>
            {t('common:buttons.view')}
          </ButtonBase>
        ),
      }),
    );

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          cartOperation.addProductToCart({
            id: param.id,
            quantity: param?.relation_quantity ? param?.relation_quantity : 1,
            onResolve,
            onReject,
          }),
        ),
      data => handleUpdateProductDetails(data, param.id),
      error => {},
    );
  };

  const handleUpdateProductDetails = (data, productId) => {
    const addedToCardProducts = data.data.products;
    dispatch(updateProductById({ addedToCardProducts, productId }));
  };

  useEffect(() => {
    if (item) {
      setMainImage(item.images[0]?.big);
    }
  }, [item]);

  const choseImage = img => {
    setMainImage(img);
  };

  const handleSaveAnalogueProduct = (e, saved, id) => {
    if (saved) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            favoriteOperation.deleteProductFromSaved({
              id,
              onResolve,
              onReject,
            }),
          ),
        () => dispatch(productOperation.getProductById({ id: item.id })),
        error => {},
      );
    } else {
      dispatch(
        setOpenModal({
          open: true,
          key: 'favorite',
          id: id,
          notification_open: true,
          reqAfterClose: () =>
            dispatch(productOperation.getProductById({ id: item.id })),
        }),
      );
      dispatch(
        setOpenNotification({
          width: 'initial',
          message: t('common:notifications.product_saved'),
          key: 'favorite-notification',
        }),
      );
    }
  };

  const addOrRemoveComparisons = (id, isCompare) => {
    if (isCompare) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            comparisonsOperation.removeFromComparisons({
              id,
              onResolve,
              onReject,
            }),
          ),
        () => dispatch(productOperation.getProductById({ id: item.id })),
        error => {},
      );
    } else {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            comparisonsOperation.addToComparisons({
              id,
              onResolve,
              onReject,
            }),
          ),
        () => dispatch(productOperation.getProductById({ id: item.id })),
        error => {},
      );
    }
  };

  const handleSubmitEditData = isClose => {
    dispatch(
      productOperation.editProductByEditId({
        id: editData.id,
        editData,
      }),
    ).then(({ payload }) => {
      if (isClose) {
        setIsEdit(!isEdit);
        setEditData(INIT_EDIT_DATA);
        dispatch(onClearByEditIdData());
      }
      navigate(payload.url, { replace: true });
      dispatch(
        setOpenNotification({
          open: true,
          width: 'initial',
          message: t('common:notifications.data_successfully_saved'),
        }),
      );
    });
  };

  return (
    <ProductBody id="product_details_inner">
      {userRole === 'admin' && (
        <AdminController
          isEdit={isEdit}
          onChangeMode={event => setIsEdit(event)}
          currentLang={lang}
          handleChangeLang={(event, newValue) => {
            setLang(newValue);
          }}
          onSubmit={handleSubmitEditData}
        />
      )}
      <WrapperForMainContent id="about_product">
        <InView
          onChange={(inView, entry) => inView && setNavSection('about_product')}
        />
        <WrapperForPhotos>
          <Box
            style={{
              border: `1px solid ${color.gray_30}`,
              width: '489px',
              height: '503px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {mainImage && (
              <img height={420} width={388} src={mainImage} alt={item.name} />
            )}
          </Box>
          <WrapperForSmallImage>
            {item.images &&
              item.images.map(element => (
                <Box
                  key={`product-images-${element.id}`}
                  style={{
                    padding: 11.5,
                    border:
                      mainImage === element.big
                        ? `1px solid ${theme.color.main}`
                        : 'none',
                    borderRadius: '4px',
                    cursor: 'pointer',
                  }}
                >
                  <img
                    onClick={() => choseImage(element.big)}
                    width={85}
                    height={78}
                    src={element.thumbnail}
                    alt={item.name}
                  />
                </Box>
              ))}
          </WrapperForSmallImage>
        </WrapperForPhotos>
        <InfoBody>
          <ProductTitle
            data={item}
            isEdit={isEdit}
            editData={editData}
            handleLangData={handleLangData}
            lang={lang}
          />
          <HistoryPriceWrapper>
            <PriceInfoWrapper>
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  flexDirection: 'row',
                }}
              >
                <CostDetails item={item} />
                <Typography
                  variant={'bodySmall'}
                  sx={{
                    marginLeft: 'auto',
                    color: theme.color.gray,
                  }}
                >
                  {t('product_details:price_history')}
                </Typography>
              </Box>
              {item.price[choosedCurrency] > 0 && (
                <Typography
                  variant={'bodySmall'}
                  sx={{
                    color: theme.color.gray,
                  }}
                >
                  {t('product_details:recommended_selling_price')}:
                  {item.price[choosedCurrency]} {currency}
                </Typography>
              )}

              <ActionWrapper>
                {permission.cart_add_access && (
                  <QuantityManagerLarge
                    productCartData={item.cart}
                    itemInCart={item.is_in_cart}
                    isActive={item.can_buy[choosedCurrency]}
                    handleChoseItem={() => handleChoseItem(item)}
                    handleUpdateProductDetails={handleUpdateProductDetails}
                    availableNumber={item.stock}
                    itemPrice={item.price[choosedCurrency]}
                  />
                )}
                <IconBtn
                  href={`${Sprite}#icon-hart`}
                  onClick={handelOpenSavedModal}
                  size={24}
                  saved={item.is_saved}
                  tooltipText={t('common:tooltip.save')}
                  data-test-id="AddToFavoritesOnProductPageButton"
                />
                {permission.product_compare_access && (
                  <IconBtn
                    href={`${Sprite}#icon-counter`}
                    onClick={addOrRemoveFromComparisons}
                    size={24}
                    compare={item.in_compare}
                    tooltipText={t('common:tooltip.compare')}
                  />
                )}
                <IconBtn
                  href={`${Sprite}#icon-bell-dollar`}
                  onClick={() => {}}
                  size={24}
                  tooltipText={t('common:tooltip.alert_changing_price')}
                />
                {item.related_products.length > 0 && (
                  <Typography
                    variant={'bodyBoldMedium'}
                    sx={{
                      color: theme.color.gray_80,
                    }}
                  >
                    {t('product_details:related_products')}
                  </Typography>
                )}
              </ActionWrapper>

              {item.is_in_cart && (
                <PriceWrapper>
                  <Typography
                    variant={'bodySmall'}
                    sx={{
                      color: color.gray_80,
                    }}
                  >
                    {t('product_details:products_in_cart_for_amount')}
                  </Typography>
                  <Typography
                    variant={'bodyBoldSmall'}
                    sx={{
                      color: color.gray,
                      marginLeft: '2px',
                    }}
                  >
                    {item.cart.personal_amount[choosedCurrency]} {currency}
                  </Typography>
                </PriceWrapper>
              )}

              {item.related_products.length > 0 && (
                <OpportunityWrapper>
                  <SliderOpportunityWrapper>
                    <AdvertisingSlider
                      data={item.related_products}
                      handleChoseItem={handleChoseItem}
                      styledContainer={{ padding: '16px 0 25px 90px' }}
                    />
                  </SliderOpportunityWrapper>
                </OpportunityWrapper>
              )}
            </PriceInfoWrapper>
            <HistoryBlock>
              <Typography
                variant={'bodyMedium'}
                sx={{
                  color: theme.color.gray,
                }}
              >
                {t('product_details:already_buy_before')}
              </Typography>
              <Typography
                variant={'bodyBoldMedium'}
                sx={{
                  color: theme.color.gray,
                }}
              >
                {t('product_details:view_purchase_history')}
              </Typography>
            </HistoryBlock>
          </HistoryPriceWrapper>
          {permission.product_stock_access && (
            <InStockWrapper>
              <Typography
                variant={'bodySmall'}
                sx={{
                  color: theme.color.gray,
                }}
              >
                {t('common:in_stock')}:
              </Typography>

              <Availability
                present={item.stock_percent}
                inStock={item.stock}
                notAvailable={item.stock}
              />
            </InStockWrapper>
          )}
          <DeliveryBlock>
            <DeliveryWrapper>
              <Typography
                variant={'bodyBoldMedium'}
                sx={{
                  color: theme.color.gray,
                }}
              >
                {t('common:delivery')}
              </Typography>

              <Svg stroke={color.gray_60} style={{ marginLeft: 'auto' }}>
                <use href={`${Sprite}#icon-plus`}></use>
              </Svg>
              <Typography
                variant={'bodySmall'}
                sx={{
                  color: theme.color.gray,
                  marginLeft: '4px',
                }}
              >
                {t('common:buttons.add_city')}
              </Typography>
            </DeliveryWrapper>
            <SelfDeliveryWrapper>
              <SelfDWInfo>
                <Typography
                  variant={'bodyMedium'}
                  sx={{
                    color: theme.color.gray_80,
                  }}
                >
                  {t('common:product_delivery.pickup_and_delivery')}
                </Typography>
                <Typography
                  variant={'bodySmall'}
                  sx={{
                    color: theme.color.gray_60,
                  }}
                >
                  {t('common:product_delivery.from_point_of_issuing_orders')}
                </Typography>
              </SelfDWInfo>
              <TextBox>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '5px',
                    flexBasis: '145px',
                  }}
                >
                  <Typography
                    variant={'bodySmall'}
                    sx={{
                      color: theme.color.gray_60,
                    }}
                  >
                    {t('common:product_delivery.pick_up')}{' '}
                  </Typography>
                  <Typography
                    variant={'bodySmall'}
                    sx={{
                      color: theme.color.main,
                    }}
                  >
                    {t('common:today')}
                  </Typography>
                </div>
              </TextBox>
              <div style={{ flex: '33.33%', textAlign: 'right' }}>
                <Typography
                  variant={'bodyBoldExSmall'}
                  sx={{
                    background: theme.color.green,
                    borderRadius: '2px',
                    padding: ' 2px 4px',
                    color: theme.color.white,
                    width: '122px',
                    display: 'inline-block',
                    textAlign: 'left',
                  }}
                >
                  {t('common:free')}
                </Typography>
              </div>
            </SelfDeliveryWrapper>
            {permission.product_stock_access && <ListWarehouses data={item} />}
            <Divider style={{ width: '100%' }} />
            <DeliveryInfoWrapper>
              <DeliveryInfo>
                <Typography
                  variant={'bodyMedium'}
                  sx={{
                    color: theme.color.gray_80,
                  }}
                >
                  {t('common:product_delivery.delivery_to_specified_address')}
                </Typography>
                <Typography
                  variant={'bodySmall'}
                  sx={{
                    color: theme.color.gray_60,
                  }}
                >
                  {t('common:product_delivery.by_courier_to_specified_address')}
                </Typography>
              </DeliveryInfo>
              <Typography
                variant={'bodySmall'}
                sx={{
                  color: theme.color.gray_60,
                  flex: '33.33%',
                  paddingLeft: '10%',
                }}
              >
                {t('common:product_delivery.deliver')} {deliveryDay}
              </Typography>

              <Typography
                variant={'bodyBoldExSmall'}
                sx={{
                  width: '122px',
                  color: theme.color.main,
                  textAlign: 'end',
                  flex: '33.33%',
                }}
              >
                {t('common:from')} 120 {t('common:currency')}
              </Typography>
            </DeliveryInfoWrapper>
            <Divider style={{ width: '100%' }} />
            <DeliveryInfoWrapper>
              <DeliveryInfo>
                <Typography
                  variant={'bodyMedium'}
                  sx={{
                    color: theme.color.gray_80,
                  }}
                >
                  {t('common:product_delivery.pickup_from_branch')}
                </Typography>
                <Typography
                  variant={'bodySmall'}
                  sx={{
                    color: theme.color.gray_60,
                  }}
                >
                  {t('common:product_delivery.meest_nova_poshta_ukrposhta')}
                </Typography>
              </DeliveryInfo>
              <Typography
                variant={'bodySmall'}
                sx={{
                  color: theme.color.gray_60,
                  flex: '33.33%',
                  paddingLeft: '10%',
                }}
              >
                {t('common:product_delivery.deliver')} {deliveryDay}
              </Typography>

              <Typography
                variant={'bodyBoldExSmall'}
                sx={{
                  color: theme.color.main,
                  flex: '33.33%',
                  textAlign: 'right',
                }}
              >
                {t('common:product_delivery.at_carrier_rates')}
              </Typography>
            </DeliveryInfoWrapper>
          </DeliveryBlock>
          {item.description && (
            <ProductDetails>
              <EllipsisText text={item.description} length={500} />
            </ProductDetails>
          )}
        </InfoBody>
      </WrapperForMainContent>

      <RelatedProducts
        isEdit={isEdit}
        setNavSection={setNavSection}
        item={item}
        handleChoseItem={handleChoseItem}
      />

      {item?.property_categories.length > 0 && (
        <InView
          onChange={(inView, entry) =>
            inView && setNavSection('property_categories')
          }
        >
          <CharacteristicsWrapper id="property_categories">
            <Typography
              variant={'h2'}
              sx={{
                color: theme.color.gray,
              }}
            >
              {t('common:characteristics')}
            </Typography>
            {item?.property_categories.map(block => (
              <CharacteristicsInfo key={block.id} data={block} />
            ))}
          </CharacteristicsWrapper>
        </InView>
      )}
      <SimilarProducts
        isEdit={isEdit}
        setNavSection={setNavSection}
        handleSaveAnalogueProduct={handleSaveAnalogueProduct}
        addOrRemoveComparisons={addOrRemoveComparisons}
        item={item}
        relatedScoop={relatedScoop}
        openCard={openCard}
        choseIdCard={choseIdCard}
        handleChoseItem={handleChoseItem}
        handelDetailCard={handelDetailCard}
        setRelatedScoop={setRelatedScoop}
      />
    </ProductBody>
  );
};

const Svg = styled.svg`
  stroke: ${props => props.stroke};
  width: 16px;
  height: 16px;
`;

const SliderOpportunityWrapper = styled.div`
  min-width: calc(100vw - 970px);
  max-width: calc(100vw - 970px);
`;

const SliderWrapper = styled.div`
  padding-top: 25px;
  margin-left: -20px;
  margin-right: -20px;
  background: ${props => props.color};
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const CharacteristicsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 34px 103px;
`;

const ProductDetails = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 4px;
`;

const DeliveryInfo = styled.div`
  display: flex;
  flex: 33.33%;
  flex-direction: column;
  align-items: flex-start;
  padding: 6px 0px;
`;

const DeliveryInfoWrapper = styled.div`
  display: flex;
  width: inherit;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
`;

const DeliveryBlock = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
`;

const TextBox = styled.div`
  display: flex;
  flex: 33.33%;
  flex-direction: row;
  gap: 4px;
  padding-left: 10.1%;
`;

const SelfDWInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 33.33%;
`;

const SelfDeliveryWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const DeliveryWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const InStockWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
`;

const HistoryPriceWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const HistoryBlock = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  padding: 8px 16px;
  gap: 10px;
  border: 1px solid ${theme.color.line_light};
  border-radius: 4px;
  background: ${theme.color.line_light};
`;

const OpportunityWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 34px;
`;

const PriceInfoWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  gap: 10px;
  border: 1px solid ${theme.color.line_light};
  border-radius: 4px;
  background: ${theme.color.background};
`;

const InfoBody = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  flex: 1;
  overflow: hidden;
`;

const WrapperForSmallImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 16px;
`;

const WrapperForPhotos = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  align-self: flex-start;
  position: sticky;
  top: 0;
  gap: 11px;
`;

const WrapperForMainContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 24px;
`;

const ProductBody = styled.div(() => ({
  display: 'flex',
  height: 'calc(100% - 122px)',
  flexDirection: ' column',
  overflow: 'auto',
  padding: '16px 19px',
  gap: '34px',
  scrollbarWidth: 'thin',
  scrollBehavior: 'smooth',
  '::-webkit-scrollbar-thumb': {
    width: '5px',
    height: '257.27px',
    background: theme.color.gray_40,
    borderRadius: '4px',
  },
  '::-webkit-scrollbar': {
    width: '7px',
    background: theme.color.background,
    border: `1px solid ${theme.color.line}`,
  },
}));

ProductInfo.propTypes = {
  handelMoveToCart: PropTypes.func.isRequired,
  item: PropTypes.exact({
    analogue_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.exact({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    documents: PropTypes.array,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.exact({
        big: PropTypes.string,
        full: PropTypes.string,
        thumbnail: PropTypes.string,
        thumbnail_second: PropTypes.string,
      }),
    ),
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    property_categories: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        name: PropTypes.string,
        properties: PropTypes.arrayOf(
          PropTypes.exact({
            id: PropTypes.number,
            is_pinned: PropTypes.bool,
            name: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
    related_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        relation_quantity: PropTypes.number,
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
  }),
  setNavSection: PropTypes.func.isRequired,
  handelOpenSavedModal: PropTypes.func.isRequired,
  handelMoveToFavorite: PropTypes.func.isRequired,
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
