import validator from 'validator';

export const validEmail = value => {
  if (!value) return false;

  if (
    validator.isEmail(value, {
      allow_display_name: true,
      blacklisted_chars: 'а-яА-ЯёЁїЇіІєЄґҐ',
    })
  ) {
    return false;
  } else {
    return true;
  }
};

export const validPassword = (pas, conf) => {
  if (!pas) return false;
  return validator.isStrongPassword(pas, {
    minLength: 1,
    minLowercase: 0,
    minUppercase: 0,
    minNumbers: 0,
    minSymbols: 0,
    ...conf,
  });
};

export const validURL = url => {
  if (!url) return false;
  return validator.isURL(url, {
    protocols: ['http', 'https', 'ftp'],
    require_tld: true,
    require_protocol: true,
    require_host: true,
    require_port: false,
    require_valid_protocol: true,
  });
};

export const getErrorStack = (currentError, name, error) => {
  return {
    ...currentError,
    [name]: { init: true, text: error },
  };
};
