import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { ModalBase } from '@root/ui';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

export const ListOfParticipantsModal = ({
  openModal,
  handleCloseModal,
  data,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation('news_and_events', { useSuspense: false });

  const tableHeads = [
    { id: 1, label: t('user') },
    { id: 2, label: t('phone') },
    { id: 3, label: t('company') },
    { id: 4, label: t('position') },
  ];

  return (
    <ModalBase
      open={openModal}
      handleClose={handleCloseModal}
      modalHeader={
        <HeaderDefault
          text={t('buttons.list_of_participants')}
          onClose={handleCloseModal}
        />
      }
    >
      <ModalContentWrap>
        <TableContainer
          sx={{ borderRadius: 0, overflowX: 'visible', boxShadow: 'initial' }}
          component={Paper}
        >
          <Table
            stickyHeader={true}
            sx={{ width: '100%', display: 'table !important' }}
          >
            <TableHead
              sx={{
                display: 'table-header-group !important',
              }}
            >
              <TableRow>
                {tableHeads.map((th, idx) => (
                  <TableCell
                    align="left"
                    key={th.id}
                    sx={{
                      padding: '8px',
                      paddingLeft: idx === 0 ? 0 : '8px',
                      borderTop: `1px solid ${color.line}`,
                    }}
                  >
                    <Typography color={color.gray_60} variant="bodyBoldSmall">
                      {th.label}
                    </Typography>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map(tc => (
                <TableRow key={tc.id}>
                  <TableCell sx={{ padding: '11px', paddingLeft: 0 }}>
                    <Typography
                      color={color.gray}
                      variant="bodyBoldSmall"
                      sx={{ display: 'block' }}
                    >
                      {tc.first_name + ' ' + tc.last_name}
                    </Typography>
                    <Typography color={color.gray_80} variant="bodySmall">
                      {tc.email}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: '11px' }}>
                    <Typography variant="bodySmall" color={color.gray}>
                      {tc.phone}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: '11px' }}>
                    <Typography variant="bodySmall" color={color.gray}>
                      {tc.company_name}
                    </Typography>
                  </TableCell>
                  <TableCell sx={{ padding: '11px' }}>
                    <Typography variant="bodySmall" color={color.gray}>
                      {tc.position}
                    </Typography>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </ModalContentWrap>
    </ModalBase>
  );
};

const ModalContentWrap = styled.div`
  min-width: 734px;
  max-height: 550px;
`;

ListOfParticipantsModal.propTypes = {
  openModal: PropTypes.bool,
  handleCloseModal: PropTypes.func.isRequired,
  data: PropTypes.array,
};
