import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Chart, Empty } from '@root/components';
import { ProgressLoader } from '@root/ui';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';

import { useTheme } from '@mui/material';

export const CommercialOffersChart = ({ conf }) => {
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [maxValGraph, setMaxValGraph] = useState(0);

  const loading = useSelector(
    homeSelectors.getIsHomeCommercialOffersChartLoading,
  );
  const data = useSelector(homeSelectors.getHomeCommercialOffersChart);

  const dispatch = useDispatch();

  const { color } = useTheme();
  const { t } = useTranslation(['home']);

  useEffect(() => {
    dispatch(homeOperation.getHomeCommercialOffersChart());
  }, []);

  useEffect(() => {
    createChart();
  }, [data]);

  const getMaxValGraph = num => {
    const numLength = num.toString().length;

    switch (numLength) {
      case 1:
        return num + 2;
      case 2:
        return num + 10;
      case 3:
        return num + 100;
      case 4:
        return num + 1000;
    }
  };

  const createChart = () => {
    const sortedData = data.slice(0).sort((a, b) => b.count - a.count);
    const dataCounts = sortedData.map(item => item.count);
    const max = Math.max(...dataCounts);
    setMaxValGraph(getMaxValGraph(max));
    setChartData({
      labels: sortedData.map(item => item.name),
      datasets: [
        {
          label: sortedData.map(item => item.name),
          data: dataCounts,
          backgroundColor: [color.main],
          datalabels: {
            backgroundColor: color.main,
            borderRadius: 4,
            color: color.white,
            anchor: 'end',
            align: 'end',
            padding: 4,
            font: {
              size: '10px',
              weight: 'bold',
            },
          },
        },
      ],
    });
  };

  return (
    <CommercialOffersWrapper>
      {loading ? (
        <ProgressLoader />
      ) : data.length ? (
        <Chart
          data={chartData}
          defaultSize={false}
          customLabels={true}
          verticalMode={conf.size === '2fr'}
          maxValGraph={maxValGraph}
        />
      ) : (
        <Empty
          type={'EmptyBox'}
          title={t('home:there_is_no_any_commercial_offers')}
        />
      )}
    </CommercialOffersWrapper>
  );
};

const CommercialOffersWrapper = styled.div`
  height: 100%;
  max-height: 305px;
  padding: 5px 13px 13px;
`;
