import { NovaPoshtaImg } from '@root/assets/image';
import { BigLogo } from '@root/assets/svg';

export const OrderConfData = [
  {
    id: 1,
    value: 'Nova_Poshta_branch',
    data: [
      {
        key: 'department',
        isCustomRadioBtn: true,
        subTitle: '',
        title: '“Нова Пошта” до відділення',
        src: NovaPoshtaImg,
        radioStyle: {
          position: 'absolute',
          padding: 0,
          top: '26px',
          left: '10px',
        },
        labelStyle: {
          position: 'relative',
          marginLeft: 'initial',
        },
      },
      {
        key: 'post_office',
        isCustomRadioBtn: true,
        subTitle: '',
        title: '“Нова Пошта” в поштомат',
        src: NovaPoshtaImg,
        radioStyle: {
          position: 'absolute',
          padding: 0,
          top: '26px',
          left: '10px',
        },
        labelStyle: {
          position: 'relative',
          marginLeft: 'initial',
        },
      },
    ],
  },
  {
    id: 1,
    value: 'Address',
    data: [
      {
        key: 'np_courier',
        isCustomRadioBtn: true,
        subTitle: '',
        title: '“Нова Пошта” кур’єр',
        src: NovaPoshtaImg,
        radioStyle: {
          position: 'absolute',
          padding: 0,
          top: '26px',
          left: '10px',
        },
        labelStyle: {
          position: 'relative',
          marginLeft: 'initial',
        },
      },
      {
        key: 'viatec_courier',
        isCustomRadioBtn: true,
        subTitle: '',
        title: 'Viatec доставка за адресою',
        src: BigLogo,
        imgStyle: {
          maxWidth: '70px',
          marginBottom: '22px',
        },
        radioStyle: {
          position: 'absolute',
          padding: 0,
          top: '26px',
          left: '10px',
        },
        labelStyle: {
          position: 'relative',
          marginLeft: 'initial',
        },
      },
    ],
  },
];

export const npHotCities = [
  { id: 4, title: 'Київ', ref: '8d5a980d-391c-11dd-90d9-001a92567626' },
  { id: 13, title: 'Харків', ref: 'db5c88e0-391c-11dd-90d9-001a92567626' },
  { id: 1, title: 'Дніпро', ref: 'db5c88f0-391c-11dd-90d9-001a92567626' },
  { id: 10, title: 'Одеса', ref: 'db5c88d0-391c-11dd-90d9-001a92567626' },
  { id: 8, title: 'Львів', ref: 'db5c88f5-391c-11dd-90d9-001a92567626' },
];

export const getPayerTypeList = t => [
  {
    label: t('placing_order:fields.legal_entity'),
    value: 'legal_entity',
  },
  {
    label: t('placing_order:fields.individual'),
    value: 'individual',
  },
  {
    label: t('placing_order:fields.individual_entrepreneur'),
    value: 'individual_entrepreneur',
  },
];

export const getAccountOptionList = (t, currencyRates) => [
  {
    label: t('placing_order:fields.with_vat', {
      rate: currencyRates.rate,
    }),
    value: 'with_vat',
  },
  {
    label: t('placing_order:fields.with_out_vat', {
      rate: currencyRates.rate,
    }),
    value: 'with_out_vat',
  },
];

export const getOrderTabList = (t, currencyRates) => [
  {
    label: t('order_history:tab.my_order'),
    key: 'my-orders',
    isDisabled: false,
    dataTestId: 'OrderTabListMyOrders',
  },
  {
    label: t('order_history:tab.my_kp'),
    key: 'my-commercial-offers',
    isDisabled: false,
    dataTestId: 'OrderTabListMyCommercialOffers',
  },
  {
    label: t('order_history:tab.archive_orders'),
    key: 'archive-orders',
    isDisabled: false,
    dataTestId: 'OrderTabListArchiveOrders',
  },
];
