import { NavLink, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Error } from '@root/assets/svg';

import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';

export const ErrorAvailabilityProduct = ({ text, url, has_analogs }) => {
  const { color } = useTheme();
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  return (
    <Component color={color}>
      <img src={Error} alt="error" />
      <Typography
        variant={'bodySmall'}
        component={'p'}
        sx={{
          color: color.red,
          mx: '4px',
        }}
      >
        {text}
      </Typography>
      {has_analogs && (
        <Typography
          variant={'bodyBoldSmall'}
          component={'p'}
          sx={{
            color: color.red,
            mx: '4px',
          }}
        >
          <NavLink
            to={currentLang + `${url}?id=analogue_products`}
            style={{
              display: 'flex',
              alignItems: 'center',
              transition: '0.25s',
              '&:hover': {
                textDecoration: 'underline',
              },
            }}
          >
            Переглянути аналоги{' '}
            <ArrowForwardRoundedIcon
              sx={{ color: color.red, ml: '4px', fontSize: '1rem' }}
            />
          </NavLink>
        </Typography>
      )}
    </Component>
  );
};

const Component = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  background-color: ${({ color }) => color.red_light};
`;

ErrorAvailabilityProduct.propTypes = {
  text: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  has_analogs: PropTypes.bool,
};
