import { createGlobalStyle } from 'styled-components';

import { theme } from '@root/styles';

export const DatePickerStyles = createGlobalStyle`
  .react-datepicker {
    border-color: ${theme.color.line};
    border-radius: 4px;
  }

  .react-datepicker__month-container {
    width: 250px;
    padding-bottom: 10px;
  }

  .react-datepicker__header {
    background-color: ${theme.color.white};
    border: none;
    padding-top: 10px;
  }

  .react-datepicker__day-names {
    margin-bottom: -5px;
  }

  .react-datepicker__month {
    margin: 0;
  }

  .react-datepicker__day-name,
  .react-datepicker__day {
    font-weight: 700;
    font-size: 0.75rem;
    text-transform: capitalize;
    color: ${theme.color.gray};
  }

  .react-datepicker__day {
    &:hover {
      border-radius: 4px;
      background-color: ${theme.color.background};
    }
  }

  .react-datepicker__day--selected {
    border-radius: 4px;
    color: ${theme.color.white};
    background-color: ${theme.color.main};

    &:hover {
      background-color: ${theme.color.main};
    }
  }

  .react-datepicker__day--disabled {
    color: ${theme.color.gray_40};

    &:hover {
      background-color: transparent;
    }
  }

  .react-datepicker__day--keyboard-selected,
  .react-datepicker__day--in-range,
  .react-datepicker__day--in-selecting-range {
    background-color: ${theme.color.background} !important;
  }
`;
