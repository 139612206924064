import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ContactField, Empty } from '@root/components';
import { getErrorStack, handleDispatchFetch } from '@root/helpers';
import { AvatarUI } from '@root/ui';

import adminOperation from '@redux/admin/admin-operation';
import adminSelectors from '@redux/admin/admin-selectors';

import { Typography } from '@mui/material';

export const UsersForm = () => {
  const data = useSelector(adminSelectors.getBusinessDetails);

  const { email, city, birthday, phones, avatar, company, name, status, id } =
    data;

  const [errors, setErrors] = useState({});

  const { t } = useTranslation(['admin'], { useSuspense: false });

  const dispatch = useDispatch();

  const handleResetErrors = name => {
    const copyErrors = JSON.parse(JSON.stringify(errors));
    delete copyErrors[name];
    setErrors(copyErrors);
  };

  const handleErrors = errors => {
    let currentError = {};

    for (const [key, value] of Object.entries(errors)) {
      currentError = getErrorStack(currentError, key, value);
    }

    setErrors(currentError);
  };

  const handleUpdateUserInfo = ({ id, payload, onEditSuccess }) => {
    handleResetErrors();
    handleDispatchFetch(
      ({ onResolve, onReject }) => {
        dispatch(
          adminOperation.updateAdminUser({
            id,
            payload,
            onResolve,
            onReject,
          }),
        );
      },
      () => onEditSuccess(),
      errors => {
        handleErrors(errors.response.data.errors);
      },
    );
  };

  return (
    <Form>
      <AvatarUI src={avatar}>
        <Empty
          type={'EmptyLogo'}
          isCompany={false}
          name={name}
          noAvatarSx={{
            fontSize: '2rem',
          }}
        />
      </AvatarUI>
      <Typography
        variant={'h2'}
        sx={{ textTransform: 'uppercase', mt: '28px' }}
      >
        {name}
      </Typography>
      <Wrapper>
        <ContactField
          type="switch"
          padding={'28px 0'}
          errors={errors.status}
          title={t('admin:people_table_conf.status')}
          editValue={status === 'active'}
          value={
            status === 'active'
              ? t('admin:active_status')
              : t('admin:inactive_status')
          }
          variant={'bodyMedium'}
          onEdit={(payload, onEditSuccess) =>
            handleUpdateUserInfo({
              id,
              payload: { status: payload ? 'active' : 'inactive' },
              onEditSuccess,
            })
          }
          onCancel={() => handleResetErrors('status')}
        />
        <ContactField
          padding={'28px 0'}
          title={'Email'}
          variant={'bodyMedium'}
          value={email}
          onEdit={() => {}}
          goToStep={() => {}}
        />
        <ContactField
          type={'phone'}
          padding={'28px 0'}
          title={t('admin:people_table_conf.phone')}
          variant={'bodyMedium'}
          value={phones}
          onEdit={() => {}}
          goToStep={() => {}}
        />
        <ContactField
          padding={'28px 0'}
          title={t('admin:people_table_conf.business')}
          variant={'bodyMedium'}
          value={company?.name}
          onEdit={() => {}}
          goToStep={() => {}}
        />
        <ContactField
          padding={'28px 0'}
          title={t('admin:people_table_conf.counterparts')}
          variant={'bodyMedium'}
          value={company?.official_name}
          onEdit={() => {}}
          goToStep={() => {}}
        />
        <ContactField
          padding={'28px 0'}
          title={t('admin:people_table_conf.role')}
          variant={'bodyMedium'}
          value={'Роль 1'}
          onEdit={() => {}}
          goToStep={() => {}}
        />
        <ContactField
          padding={'28px 0'}
          title={t('admin:people_table_conf.birthday')}
          variant={'bodyMedium'}
          value={birthday ? moment(birthday).format('DD.MM.YYYY') : ''}
          onEdit={() => {}}
          goToStep={() => {}}
        />
        <ContactField
          padding={'28px 0'}
          title={t('admin:people_table_conf.city')}
          variant={'bodyMedium'}
          value={city?.name}
          onEdit={() => {}}
          goToStep={() => {}}
        />
      </Wrapper>
    </Form>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 425px;
  padding-bottom: 50px;
`;

const Wrapper = styled.div`
  margin-top: 28px;
`;

UsersForm.propTypes = {
  data: PropTypes.exact({
    avatar: PropTypes.string,
    birthday: PropTypes.string,
    city: PropTypes.string,
    city_id: PropTypes.number,
    company: PropTypes.exact({
      activity_areas: PropTypes.arrayOf(
        PropTypes.exact({ id: PropTypes.number, name: PropTypes.string }),
      ),
      additional_info: PropTypes.string,
      company_type: PropTypes.string,
      company_type_id: PropTypes.number,
      contractor_code: PropTypes.number,
      employees: PropTypes.array,
      employees_quantity: PropTypes.number,
      identification_code: PropTypes.number,
      logo: PropTypes.string,
      name: PropTypes.string,
      official_name: PropTypes.string,
      site: PropTypes.string,
    }),
    created_at: PropTypes.string,
    deleted_at: PropTypes.string,
    deleted_by: PropTypes.string,
    dont_show_logins: PropTypes.number,
    email: PropTypes.string,
    email_verified_at: PropTypes.string,
    ga_active: PropTypes.number,
    ga_secret: PropTypes.string,
    guid: PropTypes.string,
    id: PropTypes.number,
    media: PropTypes.array,
    name: PropTypes.string,
    password_updated_at: PropTypes.string,
    permissions: PropTypes.array,
    phones: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        phone: PropTypes.string,
        confirm: PropTypes.bool,
        user_id: PropTypes.number,
        created_at: PropTypes.string,
        updated_at: PropTypes.string,
      }),
    ),
    roles: PropTypes.arrayOf(
      PropTypes.exact({
        created_at: PropTypes.string,
        guard_name: PropTypes.string,
        id: PropTypes.number,
        name: PropTypes.string,
        pivot: PropTypes.exact({
          model_id: PropTypes.number,
          model_type: PropTypes.string,
          role_id: PropTypes.number,
        }),
        updated_at: PropTypes.string,
      }),
    ),
    status: PropTypes.string,
    updated_at: PropTypes.string,
    use_price: PropTypes.string,
  }),
};
