import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { DeliveryFromClientDetailsConf } from '@root/conf';
import { NoDeliveryDetails } from '@root/modules/DeliveryDetails/NoDeliveryDetails';

import logisticSelectors from '@redux/logistic/logistic-selectors';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const DeliveryFromClient = () => {
  const { t } = useTranslation(['common', 'logistic'], { useSuspense: false });
  const { color } = useTheme();

  const { repair_array, return_array } = useSelector(
    logisticSelectors.getSelectedDelivery,
  );

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow
            sx={{
              display: 'flex',
            }}
          >
            {DeliveryFromClientDetailsConf(t).map(detail => (
              <TableCell sx={{ ...detail.style }} key={detail.slug}>
                <Typography
                  variant="bodyBoldSmall"
                  component="p"
                  color={color.gray_60}
                >
                  {detail.label}
                </Typography>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody
          sx={{
            display: 'block',
          }}
        >
          {repair_array.map(data => (
            <TableRow
              key={'repair' + data.id}
              sx={{
                display: 'flex',
                wordBreak: 'break-all',
              }}
            >
              {DeliveryFromClientDetailsConf(t).map(detail => {
                switch (detail.slug) {
                  case 'model':
                    return (
                      <TableCell
                        key={'repair' + data.id + detail.slug}
                        sx={{ ...detail.style }}
                      >
                        <Typography color={color.gray_80} variant="bodySmall">
                          {data.model}
                        </Typography>
                      </TableCell>
                    );
                  case 'serial_number':
                    return (
                      <TableCell
                        key={'repair' + data.id + detail.slug}
                        sx={{ ...detail.style }}
                      >
                        <Typography color={color.gray_80} variant="bodySmall">
                          {data.serial}
                        </Typography>
                      </TableCell>
                    );
                  case 'reported_malfunction':
                    return (
                      <TableCell
                        key={'repair' + data.id + detail.slug}
                        sx={{ ...detail.style }}
                      >
                        <Typography color={color.gray_80} variant="bodySmall">
                          {data.malfunction}
                        </Typography>
                      </TableCell>
                    );
                  case 'equipment':
                    return (
                      <TableCell
                        key={'repair' + data.id + detail.slug}
                        sx={{ ...detail.style }}
                      >
                        <Typography
                          color={color.gray_80}
                          variant="bodySmall"
                          textTransform="capitalize"
                        >
                          {data.complect}
                        </Typography>
                      </TableCell>
                    );
                }
              })}
            </TableRow>
          ))}
          {return_array.map(data => (
            <TableRow
              key={'return' + data.id}
              sx={{
                display: 'flex',
                wordBreak: 'break-all',
              }}
            >
              {DeliveryFromClientDetailsConf(t).map(detail => {
                switch (detail.slug) {
                  case 'model':
                    return (
                      <TableCell
                        key={'return' + data.id + detail.slug}
                        sx={{ ...detail.style }}
                      >
                        <Typography color={color.gray_80} variant="bodySmall">
                          {data.value || data.product_guid}
                        </Typography>
                      </TableCell>
                    );
                  case 'serial_number':
                    return (
                      <TableCell
                        key={'return' + data.id + detail.slug}
                        sx={{ ...detail.style }}
                      ></TableCell>
                    );
                  case 'reported_malfunction':
                    return (
                      <TableCell
                        key={'return' + data.id + detail.slug}
                        sx={{ ...detail.style }}
                      >
                        <Typography color={color.gray_80} variant="bodySmall">
                          {t('logistic:return')}
                        </Typography>
                      </TableCell>
                    );
                  case 'equipment':
                    return (
                      <TableCell
                        key={'return' + data.id + detail.slug}
                        sx={{ ...detail.style }}
                      ></TableCell>
                    );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

DeliveryFromClient.propTypes = {};
