import React from 'react';

import PropTypes from 'prop-types';

import { StatusLabel } from '@root/components';
import { ColorlibStepIconRoot } from '@root/styles';
import { StepperBase } from '@root/ui';

import { Box, Typography, useTheme } from '@mui/material';
import StepConnector, {
  stepConnectorClasses,
} from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.color.main,
    },
  },

  [`& .${stepConnectorClasses.line}`]: {
    width: '100%',
    height: '1px',
    borderColor: theme.color.line,
  },
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
      sx={theme => ({
        color: theme.color.gray_60,
        width: 6,
        height: 6,
      })}
    />
  );
}

export const StepperStatusTyne = ({ steps, withSteps = true, boxSx }) => {
  const active = [...steps].find(el => el.status === 'current');
  const activeStep = [...steps].indexOf(active);

  return (
    <Box
      sx={{
        maxWidth: 400,
        minWidth: 130,
        minHeight: '30px',
        ...boxSx,
      }}
      data-test-id="StatusStepper"
    >
      <StatusLabel active={active ? active : steps[0]} />

      {active &&
        active.code !== 'done' &&
        active.code !== 'reject' &&
        withSteps && (
          <StepperBase
            conf={steps}
            step={activeStep}
            defection={'horizontal'}
            text={() => <></>}
            sx={{ p: '2px', minHeight: '10px' }}
            sxLabel={() => {
              return {
                '& .MuiStepLabel-iconContainer': {
                  padding: 0,
                },
              };
            }}
            connector={<QontoConnector />}
            iconComponent={ColorlibStepIcon}
          />
        )}
    </Box>
  );
};

StepperStatusTyne.propTypes = {
  steps: PropTypes.array.isRequired,
};
