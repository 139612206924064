import { createSelector } from '@reduxjs/toolkit';

const getMutualSettlements = state => state.mutualSettlements.mutualSettlements;
const getIsMutualSettlementsLoading = state =>
  state.mutualSettlements.isMutualSettlementsLoading;
const getSelectedMutualSettlement = state =>
  state.mutualSettlements.selectedMutualSettlement;
const getMutualSettlementsTypes = state =>
  state.mutualSettlements.mutualSettlementsTypes;
const getSearchValue = state => state.mutualSettlements.searchValue;
const getExportBaseUrl = state => state.mutualSettlements.exportBaseUrl;
const getErrorOneC = state => state.mutualSettlements.errorOneC;

const getFilteredMutualSettlements = createSelector(
  [getMutualSettlements, getSearchValue],
  (items, searchValue) => {
    const value = searchValue.toLowerCase().trim();
    return (
      items &&
      items.filter(
        deliveryItem =>
          deliveryItem.number.toLowerCase().includes(value) ||
          deliveryItem.date.toLowerCase().includes(value) ||
          deliveryItem.document_type.toLowerCase().includes(value),
      )
    );
  },
);

export default {
  getFilteredMutualSettlements,
  getExportBaseUrl,
  getMutualSettlements,
  getIsMutualSettlementsLoading,
  getSelectedMutualSettlement,
  getMutualSettlementsTypes,
  getSearchValue,
  getErrorOneC,
};
