export const NavAdmin = t => [
  {
    path: 'businesses',
    name: t('admin:nav_admin_conf.businesses'),
  },
  {
    path: 'counterparts',
    name: t('admin:nav_admin_conf.counterparts'),
  },
  {
    path: 'people',
    name: t('admin:nav_admin_conf.people'),
  },
];
