import { Suspense, lazy, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { AxiosService, DefaultColorsConf, langConf } from '@root/conf';
import { useLang } from '@root/hooks';
import {
  ErrorBoundary,
  Guard,
  History,
  ModalWatcher,
  MouseFlow,
  NotificationWatcher,
  PrivateRoute,
  PublicRoute,
} from '@root/middleware';
import { TemplateMain, TemplateSignUp } from '@root/template';
import { Loader } from '@root/ui';

import operation from '@redux/auth/auth-operation';
import authSelectors from '@redux/auth/auth-selectors';
import userSelectors from '@redux/user/user-selectors';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import { deepmerge } from '@mui/utils';

import { GlobalStyle, theme } from './styles';

const Home = lazy(() => import('./page/Home' /* webpackChunkName: 'Home' */));

const Page403 = lazy(() =>
  import('./page/Errors/Page403' /* webpackChunkName: 'Page403' */),
);

const Page404 = lazy(() =>
  import('./page/Errors/Page404' /* webpackChunkName: 'Page404' */),
);

const Page500 = lazy(() =>
  import('./page/Errors/Page500' /* webpackChunkName: 'Page500' */),
);

const InDevelopment = lazy(() =>
  import('./page/Errors/InDevelopment' /* webpackChunkName: 'InDevelopment' */),
);

const OnMaintenance = lazy(() =>
  import('./page/Errors/OnMaintenance' /* webpackChunkName: 'OnMaintenance' */),
);

const Login = lazy(() =>
  import('./page/Login/Base' /* webpackChunkName: 'Login' */),
);

const Registration = lazy(() =>
  import('./page/Sign_Up/Registration' /* webpackChunkName: 'Registration' */),
);

const RegistrationContinuationForm = lazy(() =>
  import(
    './page/Sign_Up/RegistrationContinuationForm' /* webpackChunkName: 'Registration' */
  ),
);

const SuccessfullyMessagePage = lazy(() =>
  import(
    './page/Sign_Up/SuccessfullyMessagePage' /* webpackChunkName: 'SuccessfullyMessagePage' */
  ),
);

const Redirect = lazy(() =>
  import('./page/Redirect' /* webpackChunkName: 'Redirect' */),
);

const Catalog = lazy(() =>
  import('./page/Catalog' /* webpackChunkName: 'Catalog' */),
);

const ProductDetails = lazy(() =>
  import('./page/ProductDetails' /* webpackChunkName: 'ProductDetails' */),
);

const FavoriteTable = lazy(() =>
  import('./page/FavoriteTable' /* webpackChunkName: 'FavoriteTable' */),
);

const AdminPanel = lazy(() =>
  import('./page/AdminPanel' /* webpackChunkName: 'AdminPanel' */),
);

const CartPage = lazy(() =>
  import('./page/CartPage' /* webpackChunkName: 'CartPage' */),
);

const SearchPage = lazy(() =>
  import('./page/SearchPage' /* webpackChunkName: 'SearchPage' */),
);

const PlacingAnOrder = lazy(() =>
  import('./page/PlacingAnOrder' /* webpackChunkName: 'PlacingAnOrder' */),
);

const HistoryOrder = lazy(() =>
  import('./page/HistoryOrder' /* webpackChunkName: 'HistoryOrder' */),
);

const Logistics = lazy(() =>
  import('./page/Logistics' /* webpackChunkName: 'Logistics' */),
);

const Settings = lazy(() =>
  import('./page/Settings' /* webpackChunkName: 'Settings' */),
);

const OrderService = lazy(() =>
  import('./page/OrderService' /* webpackChunkName: 'OrderService' */),
);

const ComparisonsPage = lazy(() =>
  import('./page/ComparisonsPage' /* webpackChunkName: 'ComparisonsPage' */),
);

const Profile = lazy(() =>
  import('./page/Profile' /* webpackChunkName: 'Profile' */),
);

const MutualSettlements = lazy(() =>
  import(
    './page/MutualSettlements' /* webpackChunkName: 'MutualSettlements' */
  ),
);

const AccountingDocuments = lazy(() =>
  import(
    './page/AccountingDocuments' /* webpackChunkName: 'AccountingDocuments' */
  ),
);

const NotificationsPage = lazy(() =>
  import(
    './page/NotificationsPage' /* webpackChunkName: 'NotificationsPage' */
  ),
);

const NewsAndEventsPage = lazy(() =>
  import(
    './page/NewsAndEventsPage' /* webpackChunkName: 'NewsAndEventsPage' */
  ),
);

const UpdateUserData = lazy(() =>
  import(
    './page/Login/UpdateUserData' /* webpackChunkName: 'NotificationsPage' */
  ),
);

const CommercialOffer = lazy(
  () =>
    import('./page/CommercialOffer') /* webpackChunkName: 'CommercialOffer' */,
);

const UserAgreementPage = lazy(
  () =>
    import(
      './page/UserAgreementPage'
    ) /* webpackChunkName: 'UserAgreementPage' */,
);
const PrivacyPolicyPage = lazy(
  () =>
    import(
      './page/PrivacyPolicyPage'
    ) /* webpackChunkName: 'PrivacyPolicyPage' */,
);

export function App() {
  const token = useSelector(authSelectors.getUserToken);
  const isRefreshing = useSelector(authSelectors.getIsRefreshing);
  const colorConf = useSelector(userSelectors.getUserColorConf);
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { initLang, localLang, checkLanguageFromURL } = useLang();

  useEffect(() => {
    const urlLang = checkLanguageFromURL(pathname);
    const langVerify = langConf.includes(urlLang);
    const lang = langVerify ? urlLang : 'uk';

    AxiosService.setLang(lang);
    dispatch(operation.getUser({ signInToken: null }));

    if (lang !== localLang) {
      initLang(lang);
    }
  }, []);

  const themeCustoms = useMemo(() => {
    let theme_two;
    if (token) {
      if (Object.keys(colorConf).length > 0) {
        theme_two = {
          color: {
            ...colorConf,
            background: colorConf.background_color,
            main_dark: `linear-gradient(0deg, rgba(0, 0, 0, 0.28) 0%, rgba(0, 0, 0, 0.28) 100%), ${colorConf.main}`,
            main_light: `linear-gradient(0deg, rgba(255, 255, 255, 0.92) 0%, rgba(255, 255, 255, 0.92) 100%), ${colorConf.main}`,
            menu_overlay: `${colorConf.menu}` + 50,
            menu_icon_color: colorConf.menu_icon_color,
            text_color: {
              main: colorConf.text_color,
              secondary: colorConf.text_color + 'CC',
            },
          },
        };
      } else {
        return theme;
      }
    } else {
      theme_two = {
        color: {
          ...DefaultColorsConf,
        },
      };
    }
    return createTheme(deepmerge(theme, theme_two));
  }, [colorConf, token]);

  return (
    <ThemeProvider theme={themeCustoms}>
      <GlobalStyle color={themeCustoms.color} />
      <div
        className="App"
        data-reactroot
        style={{ backgroundColor: themeCustoms.color.background }}
      >
        <ToastContainer />

        {!isRefreshing ? (
          <ErrorBoundary fallback={<Page500 withLogo />}>
            <Guard>
              <MouseFlow>
                <History>
                  {' '}
                  <Suspense fallback={<Loader />}>
                    <Routes>
                      <Route path="/:lang?/error-500" element={<Page500 />} />
                      <Route
                        path="/:lang?/on-maintenance"
                        element={<OnMaintenance />}
                      />
                      <Route
                        path="/:lang?/in-development"
                        element={<InDevelopment />}
                      />
                      <Route path="/:lang?/error-403" element={<Page403 />} />
                      <Route path="/:lang?/error-404" element={<Page404 />} />
                      <Route
                        path="/:lang?/"
                        element={<PrivateRoute element={<TemplateMain />} />}
                      >
                        <Route
                          index
                          element={<PrivateRoute element={<Home />} />}
                        />
                        <Route
                          path="announcements/:tab/:slug?"
                          element={
                            <PrivateRoute element={<NewsAndEventsPage />} />
                          }
                        />
                        <Route
                          path="catalog/:category?/:categoryChildren?/?"
                          element={<PrivateRoute element={<Catalog />} />}
                        />
                        <Route
                          path="catalog/product/:category?/:categoryChildren?/:productId"
                          element={
                            <PrivateRoute element={<ProductDetails />} />
                          }
                        />
                        <Route
                          path="saved/:category?/:categoryChildren?/?"
                          element={<PrivateRoute element={<FavoriteTable />} />}
                        />
                        <Route
                          path="admin-panel/:adminTab?"
                          element={<PrivateRoute element={<AdminPanel />} />}
                        />
                        <Route
                          path="catalog/cart"
                          element={<PrivateRoute element={<CartPage />} />}
                        />
                        <Route
                          path="catalog/search-results/:category?/:searchString?/:searchType?"
                          element={<PrivateRoute element={<SearchPage />} />}
                        />
                        <Route
                          path="catalog/placing-an-order"
                          element={
                            <PrivateRoute element={<PlacingAnOrder />} />
                          }
                        />
                        <Route
                          path="orders/:orderTab?"
                          element={<PrivateRoute element={<HistoryOrder />} />}
                        />
                        <Route
                          path="accounting-documents"
                          element={
                            <PrivateRoute element={<AccountingDocuments />} />
                          }
                        />
                        <Route
                          path="logistics/:logisticsTab?"
                          element={<PrivateRoute element={<Logistics />} />}
                        />
                        <Route
                          path="settings"
                          element={<PrivateRoute element={<Settings />} />}
                        />
                        <Route
                          path="order-service"
                          element={<PrivateRoute element={<OrderService />} />}
                        />
                        <Route
                          path="mutual-settlements"
                          element={
                            <PrivateRoute element={<MutualSettlements />} />
                          }
                        />
                        <Route
                          path="comparisons"
                          element={
                            <PrivateRoute element={<ComparisonsPage />} />
                          }
                        />

                        <Route
                          path="notifications"
                          element={
                            <PrivateRoute element={<NotificationsPage />} />
                          }
                        />
                        <Route
                          path="commercial-offer"
                          element={
                            <PrivateRoute element={<CommercialOffer />} />
                          }
                        />
                        <Route
                          path="profile/:profileTab?"
                          element={<PrivateRoute element={<Profile />} />}
                        />
                      </Route>

                      <Route path="/:lang?/" element={<TemplateSignUp />}>
                        <Route
                          path="user-agreement"
                          element={
                            <PublicRoute element={<UserAgreementPage />} />
                          }
                        />
                        <Route
                          path="privacy-policy"
                          element={
                            <PublicRoute element={<PrivacyPolicyPage />} />
                          }
                        />
                        <Route
                          path="sign-up"
                          element={<PublicRoute element={<Registration />} />}
                        />
                        <Route
                          path="sign-up-continuation-form/:step"
                          element={
                            <PrivateRoute
                              element={<RegistrationContinuationForm />}
                            />
                          }
                        />
                        <Route
                          path="successfully-sign-up"
                          element={
                            <PrivateRoute
                              element={<SuccessfullyMessagePage />}
                            />
                          }
                        />
                        <Route
                          path="pre-login"
                          element={<PublicRoute element={<UpdateUserData />} />}
                        />
                      </Route>
                      <Route
                        path="/:lang?/login"
                        element={<PublicRoute element={<Login />} />}
                      />
                      <Route path="/:lang?/*" element={<Redirect />} />
                    </Routes>
                  </Suspense>{' '}
                </History>
              </MouseFlow>
            </Guard>
          </ErrorBoundary>
        ) : (
          <Loader />
        )}
      </div>
      <ModalWatcher />
      <NotificationWatcher />
    </ThemeProvider>
  );
}
