import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { staticNotificationsSwitchData } from '@root/conf';
import {
  CheckboxBase,
  CustomizedSwitches,
  TextArea,
  TextInput,
} from '@root/ui';

import { Divider, Typography, useTheme } from '@mui/material';

export const OfferDetails = ({ commercialData, setCommercialData, error }) => {
  const { color } = useTheme();
  const { t } = useTranslation('commercial_offer', { useSuspense: false });

  const {
    offer_name,
    offer_text,
    additional_info,
    hide_model,
    hide_product_description,
  } = commercialData;

  const onChange = name => value => {
    setCommercialData(prevstate => ({ ...prevstate, [name]: value }));
  };

  return (
    <Wrapper>
      <Typography
        sx={{
          marginBottom: '16px',
        }}
        component="p"
        variant="bodyBoldLarge"
        color={color.gray}
      >
        {t('offer_details')}
      </Typography>
      <Divider
        sx={{
          margin: '0 -23px 30px',
        }}
      />
      <div style={{ width: '70%' }}>
        <FieldsWrap>
          <TextInput
            label={t('offer_details_fields.name_offer_label')}
            placeholder={t('offer_details_fields.name_offer_placeholder')}
            value={offer_name}
            onChange={onChange('offer_name')}
            required
            max={100}
            error={error?.offer_name?.init}
            errorText={error?.offer_name?.text}
          />
          <TextArea
            label={t('offer_details_fields.text_offer_label')}
            placeholder={t('offer_details_fields.text_offer_placeholder')}
            value={offer_text}
            onChange={onChange('offer_text')}
            max={500}
          />
          <TextArea
            label={t('offer_details_fields.additional_info_label')}
            placeholder={t('offer_details_fields.additional_info_placeholder')}
            value={additional_info}
            onChange={onChange('additional_info')}
            max={500}
          />
          <CheckboxesWrap>
            <Typography
              component="p"
              variant="bodyBoldMedium"
              color={color.gray_80}
              sx={{ marginBottom: '5px' }}
            >
              {t('information_display')}
            </Typography>
            <CheckboxBase
              checked={hide_model}
              widthLabel={'fit-content'}
              width={16}
              onChange={onChange('hide_model')}
            >
              <Typography
                sx={{ marginLeft: '8px' }}
                color={color.gray_80}
                variant="bodyMedium"
              >
                {t('offer_details_fields.hide_model')}
              </Typography>
            </CheckboxBase>
            <CheckboxBase
              checked={hide_product_description}
              widthLabel={'fit-content'}
              width={16}
              onChange={onChange('hide_product_description')}
            >
              <Typography
                sx={{ marginLeft: '8px' }}
                color={color.gray_80}
                variant="bodyMedium"
              >
                {t('offer_details_fields.hide_product_description')}
              </Typography>
            </CheckboxBase>
          </CheckboxesWrap>
          <NotificationsWrap>
            <Typography
              component="p"
              variant="bodyBoldMedium"
              color={color.gray_80}
              sx={{ marginBottom: '5px' }}
            >
              {t('notify_title')}
            </Typography>
            {staticNotificationsSwitchData(t).map(item => (
              <CustomizedSwitches
                key={item.id}
                checked={commercialData[item.key]}
                sx={{ margin: 0 }}
                label={item.label}
                onChange={onChange([item.key])}
              />
            ))}
          </NotificationsWrap>
        </FieldsWrap>
      </div>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 25px 23px 34px 23px;
`;

const FieldsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const CheckboxesWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 11px;
`;

const NotificationsWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

OfferDetails.propTypes = {
  commercialData: PropTypes.shape({
    company_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order_calculation: PropTypes.string,
    proposition_count: PropTypes.string,
    proposition_time: PropTypes.number,
    give_discount: PropTypes.bool,
    discount: PropTypes.string,
    discount_option: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offer_name: PropTypes.string,
    offer_text: PropTypes.string,
    additional_info: PropTypes.string,
    hide_model: PropTypes.bool,
    hide_product_description: PropTypes.bool,
    offer_accepted: PropTypes.bool,
    offer_declined: PropTypes.bool,
    offer_open: PropTypes.bool,
    offer_expired: PropTypes.bool,
  }),

  setCommercialData: PropTypes.func.isRequired,
  error: PropTypes.object,
};
