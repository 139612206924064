import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import PropTypes, { string } from 'prop-types';
import styled from 'styled-components';

import { CompanyForm, HeaderDefault } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { useCommercialOffer } from '@root/hooks';
import { ModalBase, ProgressLoader } from '@root/ui';

import commercialOfferOperation from '@redux/commercial-offer/commercial-offer-operation';
import commercialOfferSelectors from '@redux/commercial-offer/commercial-offer-selectors';

export const CreateOrEditCompany = ({ open, handleClose, companyDetails }) => {
  const { data, setData, error, onChange, validation } = useCommercialOffer();
  const getCompanyFormData = useSelector(
    commercialOfferSelectors.getCompanyTemplates,
  );
  const loading = useSelector(commercialOfferSelectors.loading);
  const dispatch = useDispatch();

  const { type, id } = companyDetails;

  useEffect(() => {
    if (type === 'new') return;
    const getEditData = getCompanyFormData.find(item => item.id === id);
    setData(prevState => ({ ...prevState, ...getEditData }));
  }, []);

  const submitForm = e => {
    e.preventDefault();

    if (validation()) return;

    const logo = typeof data.logo === 'string' ? null : data.logo;

    type === 'new'
      ? handleDispatchFetch(
          ({ onResolve, onReject }) =>
            dispatch(
              commercialOfferOperation.createCompany({
                formData: serialize(
                  {
                    ...data,
                    logo: logo,
                  },
                  {
                    indices: true,
                    nullsAsUndefineds: true,
                    booleansAsIntegers: true,
                  },
                ),
                onResolve,
                onReject,
              }),
            ),
          () => {
            handleClose();
          },
        )
      : handleDispatchFetch(
          ({ onResolve, onReject }) =>
            dispatch(
              commercialOfferOperation.updateCompany({
                formData: serialize(
                  {
                    ...data,
                    logo: logo,
                  },
                  {
                    indices: true,
                    nullsAsUndefineds: true,
                    booleansAsIntegers: true,
                  },
                ),
                id: id,
                onResolve,
                onReject,
              }),
            ),
          () => handleClose(),
        );
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      modalHeader={<HeaderDefault text="" onClose={handleClose} />}
      scrollConf={{ vertical: true, horizontal: false }}
    >
      <ModalContent>
        {loading && <ProgressLoader />}
        <CompanyForm
          verticalScroll
          data={data}
          setData={setData}
          onChange={onChange}
          submitForm={submitForm}
          error={error}
        />
      </ModalContent>
    </ModalBase>
  );
};

const ModalContent = styled.div`
  width: 608px;
  padding: 0 5px;
`;

CreateOrEditCompany.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  companyDetails: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }),
};
