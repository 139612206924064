import { createSlice } from '@reduxjs/toolkit';

import operation from './admin-operation';

const initialState = {
  loadingBusiness: false,
  loadingCounterparty: false,
  loadingPeople: false,
  links: {},
  meta: {},
  data: [],
  businessDetails: {},
  isUpdateBusinessDetailsLoading: false,
  search: '',
};

const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setBusinessDetails: (state, { payload }) => {
      state.businessDetails = payload;
    },
    onClearAdmin: state => {
      state.links = initialState.links;
      state.meta = initialState.meta;
      state.data = initialState.data;
      state.search = initialState.search;
      state.loading = initialState.loading;
    },
  },
  extraReducers: {
    //=====getBusiness=====//
    [operation.getBusinessData.pending]: (state, action) => {
      state.loadingBusiness = true;
    },
    [operation.getBusinessData.fulfilled]: (state, { payload }) => {
      const { links, meta, data } = payload;
      state.data = data;
      state.links = links;
      state.meta = meta;
      state.loadingBusiness = false;
    },
    [operation.getBusinessData.rejected]: (state, action) => {
      state.loadingBusiness = false;
    },
    //====getCounterpartyData====//
    [operation.getCounterpartyData.pending]: (state, action) => {
      state.loadingCounterparty = true;
    },
    [operation.getCounterpartyData.fulfilled]: (state, { payload }) => {
      const { links, meta, data } = payload;
      state.data = data;
      state.links = links;
      state.meta = meta;
      state.loadingCounterparty = false;
    },
    [operation.getCounterpartyData.rejected]: (state, action) => {
      state.loadingCounterparty = false;
    },
    //====getPeople====//
    [operation.getPeopleData.pending]: (state, action) => {
      state.loadingPeople = true;
    },
    [operation.getPeopleData.fulfilled]: (state, { payload }) => {
      const { links, meta, data } = payload;
      state.data = data;
      state.links = links;
      state.meta = meta;
      state.loadingPeople = false;
    },
    [operation.getPeopleData.rejected]: (state, action) => {
      state.loadingPeople = false;
    },
    //=====getBusinessesSearch====//
    [operation.getBusinessesSearch.pending]: (state, action) => {
      state.loadingBusiness = true;
    },
    [operation.getBusinessesSearch.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.search = payload.search;
      state.meta = payload.meta;
      state.links = payload.links;
      state.loadingBusiness = false;
    },
    [operation.getBusinessesSearch.rejected]: (state, action) => {
      state.loadingBusiness = false;
    },
    //=====getCounterpartsSearch====//
    [operation.getCounterpartsSearch.pending]: (state, action) => {
      state.loadingCounterparts = true;
    },
    [operation.getCounterpartsSearch.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.search = payload.search;
      state.meta = payload.meta;
      state.links = payload.links;
      state.loadingCounterparts = false;
    },
    [operation.getCounterpartsSearch.rejected]: (state, action) => {
      state.loadingCounterparts = false;
    },
    //=====getPeopleSearch====//
    [operation.getPeopleSearch.pending]: (state, action) => {
      state.loadingPeople = true;
    },
    [operation.getPeopleSearch.fulfilled]: (state, { payload }) => {
      state.data = payload.data;
      state.search = payload.search;
      state.meta = payload.meta;
      state.links = payload.links;
      state.loadingPeople = false;
    },
    [operation.getPeopleSearch.rejected]: (state, action) => {
      state.loadingPeople = false;
    },

    [operation.updateAdminBusiness.pending]: (state, { payload }) => {},

    [operation.updateAdminBusiness.fulfilled]: (state, { payload }) => {
      state.data = [...state.data].map(adminItem =>
        adminItem.id === payload.id
          ? { ...adminItem, status: payload.status }
          : adminItem,
      );
      state.businessDetails = {
        ...state.businessDetails,
        status: payload.status,
      };
      state.isUpdateBusinessDetailsLoading = false;
    },

    [operation.updateAdminBusiness.pending]: (state, { payload }) => {},

    [operation.updateAdminUser.fulfilled]: (state, { payload }) => {
      state.data = [...state.data].map(adminItem =>
        adminItem.id === payload.id
          ? { ...adminItem, status: payload.status }
          : adminItem,
      );
      state.businessDetails = {
        ...state.businessDetails,
        status: payload.status,
      };
    },
  },
});
export const { onClearAdmin, setBusinessDetails } = adminSlice.actions;
export default adminSlice.reducer;
