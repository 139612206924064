import PropTypes from 'prop-types';
import styled from 'styled-components';

import { useTheme } from '@mui/material';

export const SliderItem = ({ img, title, text, slogan, styles }) => {
  const { color } = useTheme();
  return (
    <ImageBg img={img}>
      <Wrapper style={{ ...styles }} color={color}>
        <TextTitle color={color.white}>{title}</TextTitle>
        <Slogan color={color.white}>{slogan}</Slogan>
        <Text color={color.white}>{text}</Text>
      </Wrapper>
    </ImageBg>
  );
};

const ImageBg = styled.div`
  display: flex;
  background-image: url(${prop => prop.img});
  background-position: center;
  background-origin: border-box;
  background-repeat: no-repeat;
  background-size: cover;

  width: 100%;
  height: 100vh;

  flex-direction: column;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  width: 375px;
  position: absolute;
  top: 0;
  right: 0;
  color: ${({ color }) => color.black};
`;

const TextTitle = styled.h2`
  font-weight: 500;
  font-size: 4rem;
  line-height: 1.5;

  text-transform: uppercase;
  color: inherit;
`;

const Slogan = styled.p`
  font-weight: 700;
  font-size: 8rem;
  line-height: 1;

  text-transform: uppercase;

  color: inherit;
  background-image: ${prop => prop.img};
`;

const Text = styled.p`
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5;

  color: inherit;
  background-image: ${prop => prop.img};
`;

SliderItem.propTypes = {
  img: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
  slogan: PropTypes.string,
};
