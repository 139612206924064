import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { MenuBody, MenuButton, MenuItemCustoms } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const SettingsMenu = ({
  handelClickCard,
  id,
  menuList,
  verticalDots = false,
  menuButtonSx,
  menuBodyProp,
  buttonText = '',
  buttonTextSx,
}) => {
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();

  const handleClick = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  return (
    <div>
      <MenuButton
        sx={{
          ...menuButtonSx,
        }}
        open={open}
        handleClick={handleClick}
      >
        {buttonText && (
          <Typography variant="bodyBoldSmall" sx={{ ...buttonTextSx }}>
            {buttonText}
          </Typography>
        )}
        <Svg
          rotate={verticalDots ? '90deg' : 'initial'}
          color={color}
          width="18"
          height="18"
        >
          <use href={`${Sprite}#icon-dots`}></use>
        </Svg>
      </MenuButton>

      <MenuBody
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        sx={{ width: '96px' }}
        {...menuBodyProp}
      >
        {menuList(t, color).map(menuListItem => (
          <MenuItemCustoms
            key={menuListItem.id}
            handelClick={() => {
              handleClose();
              handelClickCard(menuListItem.type, id);
            }}
            colorText={menuListItem.color}
          >
            <Typography variant={menuListItem.typographyVariant}>
              {menuListItem.title}
            </Typography>
          </MenuItemCustoms>
        ))}
      </MenuBody>
    </div>
  );
};

const Svg = styled.svg`
  transform: rotate(${({ rotate }) => rotate});
  stroke: ${({ color }) => color.main};
`;

SettingsMenu.propTypes = {
  handelClickCard: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  menuList: PropTypes.func,
  verticalDots: PropTypes.bool,
  menuButtonSx: PropTypes.object,
  menuBodyProp: PropTypes.object,
  buttonText: PropTypes.string,
  buttonTextSx: PropTypes.object,
};
