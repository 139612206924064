import * as React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';

export const Status = ({ style, status }) => {
  const { t } = useTranslation(['admin'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <TableCell
      component="th"
      scope="row"
      sx={{
        p: 0,
        ...style,
        display: 'flex',
        flexDirection: 'row',
        gap: '4px',
        alignItems: 'center',
      }}
    >
      <StatusCircle
        color={status === 'active' ? color.green : color.dark_red}
      />
      <Typography
        sx={{
          color:
            status === 'active'
              ? `${color.gray} !important`
              : `${color.dark_red} !important`,
        }}
        variant={'bodySmall'}
        component={'p'}
      >
        {status === 'active'
          ? t('admin:active_status')
          : t('admin:inactive_status')}
      </Typography>
    </TableCell>
  );
};

const StatusCircle = styled.div`
  background: ${props => props.color};
  height: 8px;
  width: 8px;
  border-radius: 50%;
`;

Status.propTypes = {
  style: PropTypes.object,
  status: PropTypes.string,
};
