import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';

import { theme } from '@root/styles';

import { Typography } from '@mui/material';

export const EllipsisText = ({ text, length }) => {
  const [toggleHide, setToggleHide] = useState(false);
  const textEllipsis = text.slice(0, length);

  const { t } = useTranslation(['common'], { useSuspense: false });

  return (
    <>
      <Typography
        variant={'bodyMedium'}
        sx={{
          color: theme.color.gray_80,
        }}
      >
        {toggleHide ? text : textEllipsis}
        {!toggleHide && <span>...</span>}
      </Typography>

      {text.length > 500 && (
        <Typography
          variant={'bodyMedium'}
          onClick={() => setToggleHide(!toggleHide)}
          sx={{
            color: theme.color.main,
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
        >
          {toggleHide ? t('common:buttons.hide') : t('common:buttons.read_all')}
        </Typography>
      )}
    </>
  );
};

EllipsisText.propTypes = {
  text: PropTypes.string.isRequired,
  length: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
};
