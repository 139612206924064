import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Error } from '@root/assets/image';
import { Sprite } from '@root/assets/svg';
import { HeaderDefault } from '@root/components';
import { useNavigation } from '@root/hooks';
import { ButtonBase, ModalBase } from '@root/ui';

import { clearOrder } from '@redux/order/order-slice';
import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const ModalStatusOrder = ({
  open,
  orderId,
  hideControl = false,
  isFulfilled = true,
  isCommercialOffer = false,
  children,
}) => {
  const { color } = useTheme();
  const { navigation } = useNavigation();
  const { lang } = useParams();
  const dispatch = useDispatch();
  const { t } = useTranslation(['order_history', 'commercial_offer'], {
    useSuspense: false,
  });
  const permission = useSelector(userSelectors.getPermissions);

  const currentLang = lang ? `/${lang}` : '';

  const handleClose = url => {
    if (!hideControl) {
      dispatch(clearOrder());
    }

    navigation(url);
  };

  const defaultRedirectPath = hideControl
    ? '/orders/my-orders'
    : isCommercialOffer
    ? `/orders/my-commercial-offers?order=${orderId}`
    : '/catalog';

  return (
    <ModalBase
      open={open}
      onClose={() => handleClose(defaultRedirectPath)}
      modalHeader={
        <HeaderDefault
          text=""
          onClose={() => handleClose(defaultRedirectPath)}
        />
      }
    >
      {isFulfilled ? (
        <>
          <WrapperImage bgcolor={color.green_light}>
            <Svg width={44} height={44} bgcolor={color.green}>
              <use href={`${Sprite}#icon-mark_big`}></use>
            </Svg>
          </WrapperImage>
          <Content>
            <Typography
              sx={{ margin: '15px 0 0 0' }}
              variant={'bodyBoldLarge'}
              color={color.gray}
              component={'p'}
            >
              {isCommercialOffer
                ? t('commercial_offer:success_title')
                : t('order_history:status_success_title')}
            </Typography>
            <Typography
              sx={{
                margin: '16px 0 ',
                textAlign: 'center',
                width: '558px',
              }}
              variant={'bodyMedium'}
              color={color.gray_60}
              component={'p'}
              data-test-id="OrderSuccessDescription"
            >
              {isCommercialOffer
                ? t('commercial_offer:success_description')
                : t('order_history:status_success_description', {
                    orderId: orderId,
                  })}
            </Typography>
            {!hideControl && (
              <>
                {!isCommercialOffer && permission.orders_history_access && (
                  <Typography
                    sx={{ margin: '0 0 16px 0' }}
                    variant={'bodyMedium'}
                    color={color.gray_60}
                  >
                    {t('order_history:check_status')}
                    <Typography
                      sx={{ ml: '4px', cursor: 'pointer' }}
                      variant={'bodyBoldMedium'}
                      color={color.main}
                      onClick={() => handleClose('/orders/my-orders')}
                    >
                      {t('order_history:history_order')}
                    </Typography>
                  </Typography>
                )}
                {children && <Flex>{children}</Flex>}

                <ButtonBase
                  onClick={() => handleClose(defaultRedirectPath)}
                  size={'medium'}
                >
                  <Typography variant={'bodyBoldSmall'}>
                    {isCommercialOffer
                      ? t('commercial_offer:buttons.navigation')
                      : t('order_history:buttons.go_to_catalog')}
                  </Typography>
                </ButtonBase>
              </>
            )}
          </Content>
        </>
      ) : (
        <>
          <WrapperImage bgcolor={color.white}>
            <img src={Error} alt="Error" />
          </WrapperImage>
          <Content>
            <Typography
              sx={{ margin: '15px 0 0 0' }}
              variant={'bodyBoldLarge'}
              color={color.gray}
              component={'p'}
            >
              {t('order_history:status_denied_title')}
            </Typography>

            <Typography
              sx={{
                margin: '16px 0 ',
                textAlign: 'center',
                width: '558px',
              }}
              variant={'bodyMedium'}
              color={color.gray_60}
              component={'p'}
            >
              {t('order_history:status_denied_description')}
            </Typography>

            {!hideControl && (
              <>
                {' '}
                <Typography
                  sx={{ margin: '0 0 16px 0' }}
                  variant={'bodyMedium'}
                  color={color.gray_60}
                >
                  {t('order_history:check_status')}
                  <Typography
                    sx={{ ml: '4px' }}
                    variant={'bodyBoldMedium'}
                    color={color.main}
                  >
                    <NavLink to={currentLang + '/orders/my-orders'}>
                      {t('order_history:history_order')}
                    </NavLink>
                  </Typography>
                </Typography>
                <ButtonBase
                  onClick={() =>
                    handleClose(hideControl ? '/orders/my-orders' : '/catalog')
                  }
                  size={'medium'}
                >
                  <Typography variant={'bodyBoldSmall'}>
                    {t('order_history:buttons.go_to_catalog')}
                  </Typography>
                </ButtonBase>
              </>
            )}
          </Content>
        </>
      )}
    </ModalBase>
  );
};

const WrapperImage = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;

  margin: 0 auto;
  width: 100px;
  height: 100px;
  background-color: ${({ bgcolor }) => bgcolor};
  border-radius: 50%;
`;

const Svg = styled.svg`
  fill: ${({ bgcolor }) => bgcolor};
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Flex = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-bottom: 10px;
`;

ModalStatusOrder.propTypes = {
  open: PropTypes.bool,
  orderId: PropTypes.number,
  hideControl: PropTypes.bool,
  isFulfilled: PropTypes.bool,
  children: PropTypes.node,
};
