import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { productPricesMap } from '@root/conf';
import { theme } from '@root/styles';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const Amount = ({ data, onlyUAH, manualCurrency }) => {
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const chosenCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { amounts_personal, amounts_product } = data;
  const { color } = useTheme();

  return (
    <AmountWrapper
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {amounts_personal && amounts_personal[chosenCurrency] > 0 ? (
        <Typography
          color={theme.color.gray}
          variant="bodyBoldMedium"
          sx={{
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          {amounts_personal[chosenCurrency]}
          <Typography
            sx={{ ml: '2px', display: 'block' }}
            variant="bodyExSmall"
            align="center"
          >
            {manualCurrency
              ? manualCurrency
              : onlyUAH
              ? t('common:currency')
              : currency}
          </Typography>
        </Typography>
      ) : (
        <Typography
          sx={{
            color: color.gray,
            display: 'flex',
            alignItems: 'baseline',
            width: '90%',
            textAlign: 'left',
          }}
          variant={'bodyBoldMedium'}
          align={'center'}
          component={'p'}
        >
          {t('common:check_price_for_details')}
        </Typography>
      )}
      {amounts_product && amounts_product[chosenCurrency] && (
        <Typography
          color={theme.color.gray_60}
          variant="bodyBoldExSmall"
          sx={{
            display: 'flex',
            alignItems: 'baseline',
          }}
        >
          {amounts_product[chosenCurrency]}
          <Typography
            sx={{ ml: '2px', display: 'block' }}
            variant="bodyExSmall"
            align="center"
          >
            {manualCurrency
              ? manualCurrency
              : onlyUAH
              ? t('common:currency')
              : currency}
          </Typography>
        </Typography>
      )}
    </AmountWrapper>
  );
};

const AmountWrapper = styled.div``;

Amount.propTypes = {
  data: PropTypes.shape({
    can_buy: PropTypes.shape({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    description: PropTypes.string,
    id: PropTypes.number,
    image: PropTypes.string,
    model: PropTypes.string,
    personal_price: PropTypes.shape({
      price: PropTypes.number,
      price_uah_cash: PropTypes.number,
      price_uah_no_pdv: PropTypes.number,
      price_uah_pdv: PropTypes.number,
    }),
    price: PropTypes.shape({
      price: PropTypes.number,
      price_uah_cash: PropTypes.number,
      price_uah_no_pdv: PropTypes.number,
      price_uah_pdv: PropTypes.number,
    }),
    prices: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.shape({
        price: PropTypes.shape({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_cash: PropTypes.shape({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_no_pdv: PropTypes.shape({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
        price_uah_pdv: PropTypes.shape({
          product: PropTypes.string,
          personal: PropTypes.string,
        }),
      }),
    ]),
    stock: PropTypes.number,
    url: PropTypes.string,
  }),
  onlyUAH: PropTypes.bool,
  manualCurrency: PropTypes.string,
};
