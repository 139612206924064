import 'moment/locale/uk';
import moment from 'moment/moment';

export const getPriorDateFromNow = (ago, type = 'days') =>
  moment().subtract(ago, type).format('YYYY-MM-DD');

export const getCurrentDate = () => moment().format('YYYY-MM-DD');
export const getFirstDateOfCurrentMonth = () =>
  moment().startOf('month').format('YYYY-MM-DD');

export const formatDate = (
  date,
  format = 'DD MMMM YYYY [р.]',
  locale = 'uk',
) => {
  return moment(date, 'DD.MM.YYYY HH:mm:ss').locale(locale).format(format);
};
