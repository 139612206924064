import React, { useEffect, useMemo, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useLocation } from 'react-router-dom';

import moment from 'moment/moment';

import { getCurrentDate, getFirstDateOfCurrentMonth } from '@root/helpers';
import { useLang } from '@root/hooks';
import { DatePickerStyles } from '@root/styles';
import { OutlinedInputCustom } from '@root/ui/Input/style/OutlinedInput';

import { useTheme } from '@mui/material';

import { CustomDatePickerHeader } from '../ui';

export const RangeDatePicker = ({
  inputSx,
  date,
  onChangeDate,
  onCalendarClose,
  observer,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [monthsList, setMonthsList] = useState([]);
  const [dateRange, setDateRange] = useState([
    date[0] ? new Date(date[0]) : null,
    date[1] ? new Date(date[1]) : null,
  ]);
  const [startDate, endDate] = dateRange;
  const { checkLanguageFromURL } = useLang();
  const { pathname } = useLocation();
  const { color } = useTheme();

  useEffect(() => {
    moment.locale(checkLanguageFromURL(pathname));
    setMonthsList(moment.months());
  }, []);

  useEffect(() => {
    if (observer) {
      setDateRange([
        new Date(getFirstDateOfCurrentMonth()),
        new Date(getCurrentDate()),
      ]);
    }
  }, [observer]);

  const changeDate = date => {
    const dates = [];
    setDateRange(date);

    date.forEach(d => {
      if (d) {
        dates.push(moment(d).format('YYYY-MM-DD'));
      } else {
        dates.push(d);
      }
    });

    onChangeDate(dates);

    if (date[0] && date[1]) {
      setIsOpen(false);
    }
  };

  const maxDate = useMemo(
    () => () => {
      const date = dateRange[0];
      if (!date) {
        return new Date();
      }
      const startTime = new Date(date).getTime();
      const daysInMonth = moment(date).daysInMonth();

      if (dateRange[1]) {
        return new Date();
      } else {
        return new Date(startTime + daysInMonth * 24 * 60 * 60 * 1000);
      }
    },
    [dateRange],
  );

  return (
    <>
      <DatePicker
        showPopperArrow={false}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={e => {
          const classNames = [
            'MuiBackdrop-root',
            'MuiPaper-root',
            'MuiButtonBase-root',
          ];
          if (
            classNames.some(className => e.target.classList.contains(className))
          )
            return;
          setIsOpen(false);
        }}
        open={isOpen}
        locale={checkLanguageFromURL(pathname)}
        dateFormat="dd/MM/yyyy"
        selectsRange={true}
        startDate={startDate}
        endDate={endDate}
        maxDate={maxDate()}
        onChange={update => changeDate(update)}
        onCalendarClose={onCalendarClose}
        filterDate={date => {
          return new Date() > date;
        }}
        selectsDisabledDaysInRange
        customInput={
          <OutlinedInputCustom
            sx={{
              width: '100% !important',
              cursor: 'pointer',
              ...inputSx,
              '& input': {
                cursor: 'pointer',
                color: color.gray,
              },
            }}
          />
        }
        renderCustomHeader={({
          date,
          changeYear,
          changeMonth,
          decreaseMonth,
          increaseMonth,
          prevMonthButtonDisabled,
          nextMonthButtonDisabled,
        }) => (
          <CustomDatePickerHeader
            date={date}
            changeYear={changeYear}
            changeMonth={changeMonth}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            monthsList={monthsList}
          />
        )}
      />
      <DatePickerStyles />
    </>
  );
};
