import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { useNavigation } from '@root/hooks';
import { ButtonBase, Notification } from '@root/ui';

import notificationWatcherSelectors from '@redux/notification-watcher/notification-watcher-selectors';
import {
  clearNotificationState,
  setOpenNotification,
} from '@redux/notification-watcher/notification-watcher-slice';

export const NotificationWatcher = () => {
  const { navigation } = useNavigation();
  const { t } = useTranslation('common', { useSuspense: false });
  const configuration = useSelector(notificationWatcherSelectors.configuration);
  const dispatch = useDispatch();
  const { width, message, action, open, key } = configuration;
  let timer = null;

  useEffect(() => {
    if (open) {
      timer = setTimeout(() => {
        dispatch(clearNotificationState());
        clearTimeout(timer);
      }, 6000);
    }
  }, [open]);

  const closeNotification = () => {
    dispatch(
      setOpenNotification({
        ...configuration,
        open: false,
      }),
    );
  };

  const handleMoveToPage = () => {
    let url = '';
    const { favoriteList, favoriteNewListItem } = configuration?.data;
    if (Object.keys(favoriteNewListItem).length > 0) {
      url = `/saved/zberezene/${favoriteNewListItem?.slug}?id=${favoriteNewListItem?.id}&page=1&per_page=10`;
    } else if (
      favoriteList &&
      (favoriteList.length === 0 || favoriteList.length > 1)
    ) {
      url = '/saved/zberezene/saved-products';
    } else {
      url = `/saved/zberezene/${favoriteList[0]?.slug}?id=${favoriteList[0]?.id}&page=1&per_page=10`;
    }
    closeNotification();
    return navigation(url);
  };

  return (
    <Notification
      open={open}
      handleCloseNotification={closeNotification}
      width={width}
      message={message}
      action={
        action ? (
          action
        ) : key === 'favorite-notification' ? (
          <ButtonBase onClick={handleMoveToPage} sx={{ height: '25px' }}>
            {t('buttons.view')}
          </ButtonBase>
        ) : (
          ''
        )
      }
    />
  );
};
