import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import { nanoid } from 'nanoid';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { PasswordLights, PhoneConfirmation } from '@root/components';
import { useNavigation, useUserAuthOptions } from '@root/hooks';
import {
  ButtonBase,
  CheckboxBase,
  OutlineButtonBase,
  PasswordInput,
  TextInput,
} from '@root/ui';

import authOperation from '@redux/auth/auth-operation';

import Typography from '@mui/material/Typography';

export const UpdateUserDataForm = ({ initData }) => {
  const {
    data,
    setData,
    error,
    isVisibleValid,
    steps,
    validation,
    ConfirmPhones,
    handelAddPhoneInput,
    onChange,
    hideValidation,
  } = useUserAuthOptions();
  const { t } = useTranslation(['login'], { useSuspense: false });
  const dispatch = useDispatch();
  const { navigation } = useNavigation();
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  document.title = t('login:title_meta_update');

  const { userInfo, type } = initData;

  useEffect(() => {
    if (Object.keys(userInfo).length) {
      setData({
        ...data,
        ...userInfo,
        phones:
          userInfo?.phones?.length > 0
            ? userInfo.phones
            : [{ id: nanoid(), phone: null, confirm: null }],
      });
    } else {
      navigation('/login');
    }
  }, [initData]);

  const handelSubmit = e => {
    e.preventDefault();
    if (validation()) return;

    switch (type) {
      case 'old':
        dispatch(authOperation.updateOldUserData({ data }));
        break;
      case 'new':
        dispatch(authOperation.updateNewUserData({ data }));
    }
  };

  const { name, email, password, confirmPassword, phones, agreement } = data;
  return (
    <Wrapper>
      <Form autoComplete="on" onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'}>
          {t(
            `login:${
              type === 'old' ? 'update_old_user_data' : 'update_new_user_data'
            }.title`,
          )}
        </Typography>

        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t(
            `login:${
              type === 'old' ? 'update_old_user_data' : 'update_new_user_data'
            }.slogan`,
          )}
        </Typography>

        <TextInput
          placeholder={t('login:field.name')}
          value={name}
          onChange={onChange('name')}
          max={50}
          sx={{ height: '48px' }}
          styleWrapper={{ marginTop: '28px' }}
          error={error?.name?.init}
          errorText={error?.name?.text}
        />

        <TextInput
          placeholder={t('login:field.email')}
          value={email}
          onChange={onChange('email')}
          sx={{ height: '48px' }}
          styleWrapper={{ marginTop: '28px' }}
          disabled
        />

        <ElWrap>
          <PasswordInput
            error={error?.password?.init}
            errorText={error?.password?.text}
            sx={{ mt: '28px', width: '100%', height: '48px' }}
            value={password}
            onChange={onChange('password')}
            placeholder={t('login:field.password', { req: '*' })}
            data-test-id="Password"
            autoComplete={'new-password'}
            inputProps={{
              maxLength: 255,
            }}
            onBlur={hideValidation}
          />
          <WrapperLights>
            {isVisibleValid &&
              steps.map(({ status, text }) => (
                <PasswordLights status={status} text={text} key={text} />
              ))}
          </WrapperLights>
        </ElWrap>

        <PasswordInput
          error={error?.confirmPassword?.init}
          errorText={error?.confirmPassword?.text}
          sx={{ mt: '28px', width: '100%', height: '48px' }}
          onChange={onChange('confirmPassword')}
          value={confirmPassword}
          placeholder={t('login:field.duplicate_password', {
            req: '*',
          })}
          data-test-id="Password"
          autoComplete={'current-password'}
          withOutSpace={true}
          inputProps={{
            maxLength: 255,
          }}
        />

        <ElWrap mt="28px">
          {phones.map((el, i) => {
            return (
              <PhoneConfirmation
                error={error?.phones && error?.phones[i]?.init}
                errorText={error?.phones && error?.phones[i]?.text}
                key={el.id}
                sx={{ width: '100%', height: '48px' }}
                placeholder={t('login:field.phone', { req: '*' })}
                onChange={onChange(`phone`, i)}
                value={el.phone}
                margin={i > 0 && '10px 0 0 0'}
                confirm={el.confirm}
                changeConfirm={ConfirmPhones}
                autoCompleteType={'tel'}
              />
            );
          })}
        </ElWrap>

        {phones.length < 10 && (
          <OutlineButtonBase
            type="button"
            sx={{ mt: '10px', width: '100%' }}
            onClick={handelAddPhoneInput}
          >
            <Svg width="16" height="16">
              <use href={`${Sprite}#icon-plus`}></use>
            </Svg>
            <Typography variant={'bodySmall'} align={'center'}>
              {t('login:add_phone_btn')}
            </Typography>
          </OutlineButtonBase>
        )}

        <ElWrap mt={'28px'}>
          <CheckboxBase
            width={24}
            onChange={onChange('agreement')}
            checked={agreement}
            error={error?.agreement?.init}
            data-test-id="Checkbox"
          >
            <Typography
              sx={{ ml: '10px' }}
              variant={'bodyLarge'}
              component={'p'}
            >
              {t('login:agreement.part_one')}{' '}
              <Link
                target="_blank"
                href={currentLang + `/user-agreement`}
                rel="noopener noreferrer"
              >
                {t('login:agreement.part_two')}
              </Link>{' '}
              і{' '}
              <Link
                target="_blank"
                href={currentLang + '/privacy-policy'}
                rel="noopener noreferrer"
              >
                {t('login:agreement.part_tree')}
              </Link>{' '}
              Viatec
            </Typography>
          </CheckboxBase>
        </ElWrap>

        <ButtonBase
          type="submit"
          size={'large'}
          sx={{ mt: '28px' }}
          data-test-id="SignIn"
        >
          <Typography variant={'bodyBoldLarge'}>
            {' '}
            {t('common:buttons.sent')}
          </Typography>
        </ButtonBase>
      </Form>
    </Wrapper>
  );
};

const WrapperLights = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 28px;
  left: 100%;
  width: 100%;
  margin-left: 15px;

  div + div {
    margin-top: 4px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const ElWrap = styled.div`
  position: relative;
  margin-top: ${({ mt }) => (mt ? mt : 'initial')};
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const Svg = styled.svg``;

const Link = styled.a`
  display: inline-block;
  text-decoration: underline;
`;

UpdateUserDataForm.propTypes = {
  initData: PropTypes.exact({
    data: PropTypes.exact({
      phones: PropTypes.array,
      password: PropTypes.string,
      confirmPassword: PropTypes.string,
      name: PropTypes.string,
      email: PropTypes.string,
    }),
    type: PropTypes.string,
  }),
};
