import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ButtonWithoutBorder, CustomScroll, ProgressLoader } from '@root/ui';

import commercialOfferOperation from '@redux/commercial-offer/commercial-offer-operation';
import commercialOfferSelectors from '@redux/commercial-offer/commercial-offer-selectors';
import { resetAllProducts } from '@redux/commercial-offer/commercial-offer-slice';

import { Typography, useTheme } from '@mui/material';

import { SmallProductCard } from '../SmallProductCard';

export const Details = ({
  handleCreate,
  handleEdit,
  handleClickMenu,
  commercialData,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation('commercial_offer', { useSuspense: false });
  const listLoading = useSelector(commercialOfferSelectors.listLoading);
  const products = useSelector(commercialOfferSelectors.getProducts);
  const dispatch = useDispatch();

  const { give_discount, discount, discount_option } = commercialData;

  const handleResetChanges = () => {
    dispatch(resetAllProducts());
  };

  const memoizedTotalSum = useMemo(
    () => () => {
      return [...products]
        .reduce((sum, currentValue) => {
          return (
            sum + Number(currentValue.price) * Number(currentValue.quantity)
          );
        }, 0)
        .toFixed(2);
    },
    [products],
  );

  const memoizedTotalSumWithDiscount = useMemo(
    () => () => {
      const total = Number(memoizedTotalSum());
      const discountNum = Number(discount);

      if (!give_discount) return memoizedTotalSum();

      switch (discount_option) {
        case 1:
          return (total - (total * discountNum) / 100).toFixed(2);
        case 2:
          return (total - discountNum).toFixed(2);
      }
    },
    [memoizedTotalSum(), give_discount, discount, discount_option],
  );

  return (
    <ContentWrap>
      <DetailsHeader>
        <DetailsSubHeader color={color}>
          <Typography
            variant={'bodyBoldLarge'}
            sx={{
              color: color.gray,
            }}
          >
            {t('details.goods')}
          </Typography>
          <div style={{ display: 'flex', alignItems: 'center', gap: '57px' }}>
            <Typography variant={'bodySmall'} color={color.gray_60}>
              {t('details.sum')}
            </Typography>
            <Typography variant={'bodySmall'} color={color.gray_60}>
              {t('details.warranty')}
            </Typography>
          </div>
        </DetailsSubHeader>
        <ButtonWrap color={color}>
          <ButtonWithoutBorder
            onClick={handleResetChanges}
            sx={{
              color: color.gray_60,
              padding: '3px',
              height: 'initial',
            }}
          >
            {t('buttons.reset')}
          </ButtonWithoutBorder>
        </ButtonWrap>
      </DetailsHeader>
      <CustomScroll vertical={true}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            justifyContent: 'space-between',
          }}
        >
          <DetailsBody>
            {listLoading && <ProgressLoader />}
            {products.length > 0 &&
              products.map(product => (
                <SmallProductCard
                  key={product.id}
                  productDetails={product}
                  handleEdit={handleEdit}
                  handleClickMenu={handleClickMenu}
                />
              ))}
          </DetailsBody>
          <DetailsFooter>
            <ButtonWrap color={color} borderTop={`1px solid ${color.line}`}>
              <ButtonWithoutBorder
                sx={{
                  color: color.gray_60,
                  padding: '3px',
                  height: 'initial',
                }}
                onClick={() => handleCreate(true, 'new')}
              >
                <svg
                  width={16}
                  height={16}
                  style={{
                    marginRight: '5px',
                    fill: 'transparent',
                    stroke: color.gray_60,
                  }}
                >
                  <use href={`${Sprite}#icon-plus`}></use>
                </svg>
                {t('buttons.add_position')}
              </ButtonWithoutBorder>
            </ButtonWrap>
            <DetailsSubFooter>
              <FlexWrap>
                <Typography color={color.gray} variant="bodyMedium">
                  {t('details.general')}
                </Typography>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  <Typography variant="h1" color={color.gray} component="span">
                    {memoizedTotalSumWithDiscount()}
                  </Typography>
                  <Typography color={color.gray} variant="bodyBoldSmall">
                    {t('details.currency')}
                  </Typography>
                </div>
              </FlexWrap>
              <FlexWrap>
                <Typography color={color.gray} variant="bodyMedium">
                  {t('details.sum')}
                </Typography>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  <Typography variant="h1" color={color.gray} component="span">
                    {memoizedTotalSum()}
                  </Typography>
                  <Typography color={color.gray} variant="bodyBoldSmall">
                    {t('details.currency')}
                  </Typography>
                </div>
              </FlexWrap>
              <FlexWrap>
                <Typography color={color.gray} variant="bodyMedium">
                  {t('details.discount')}
                </Typography>
                <div
                  style={{ display: 'flex', alignItems: 'center', gap: '5px' }}
                >
                  <Typography color={color.gray} variant="bodyMedium">
                    {give_discount && discount && discount_option
                      ? discount
                      : 0}
                  </Typography>
                  <Typography color={color.gray} variant="bodySmall">
                    {t(
                      `details.${
                        discount_option === 1 ? 'percentage' : 'currency'
                      }`,
                    )}
                  </Typography>
                </div>
              </FlexWrap>
            </DetailsSubFooter>
          </DetailsFooter>
        </div>
      </CustomScroll>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
const DetailsHeader = styled.div``;

const DetailsSubHeader = styled.div`
  padding: 12px 24px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  border-bottom: 1px solid ${({ color }) => color.line};
`;
const DetailsBody = styled.div`
  position: relative;
  height: auto;
`;

const DetailsFooter = styled.div``;

const DetailsSubFooter = styled.div`
  padding: 12px 24px;
  display: flex;
  flex-direction: column;
  gap: 13px;
`;
const FlexWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
`;

const ButtonWrap = styled.div`
  padding: 8px 24px;
  border-bottom: 1px solid ${({ color }) => color.line};
  border-top: ${({ borderTop }) => (borderTop ? borderTop : 'none')};
`;

Details.propTypes = {
  handleCreate: PropTypes.func.isRequired,
  handleEdit: PropTypes.func.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
  commercialData: PropTypes.shape({
    company_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    order_calculation: PropTypes.string,
    proposition_count: PropTypes.string,
    proposition_time: PropTypes.number,
    give_discount: PropTypes.bool,
    discount: PropTypes.string,
    discount_option: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    offer_name: PropTypes.string,
    offer_text: PropTypes.string,
    additional_info: PropTypes.string,
    hide_model: PropTypes.bool,
    hide_product_description: PropTypes.bool,
    offer_accepted: PropTypes.bool,
    offer_declined: PropTypes.bool,
    offer_open: PropTypes.bool,
    offer_expired: PropTypes.bool,
  }),
};
