export const getBaseNotifications = state =>
  state.notifications.baseNotifications;

export const getServiceNotifications = state =>
  state.notifications.serviceNotifications;

export const getIsBaseNotificationsLoading = state =>
  state.notifications.isBaseNotificationsLoading;

export const getNotificationsMeta = state =>
  state.notifications.notificationsMeta;

export const getHasNotViewedNotifications = state =>
  state.notifications.hasNewNotifications;

export default {
  getBaseNotifications,
  getServiceNotifications,
  getIsBaseNotificationsLoading,
  getNotificationsMeta,
  getHasNotViewedNotifications,
};
