import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

import styled from 'styled-components';

import { PasswordLights } from '@root/components';
import { PasswordConf } from '@root/conf';
import {
  getErrorStack,
  handleDispatchFetch,
  validEmail,
  validPassword,
} from '@root/helpers';
import { ButtonBase, CheckboxBase, TextInput } from '@root/ui';

import authOperation from '@redux/auth/auth-operation';

import Typography from '@mui/material/Typography';

export const RegistrationForm = () => {
  const [data, setData] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    agreement: false,
  });
  const { t } = useTranslation(['registration', 'validation']);
  const [steps, setSteps] = useState(PasswordConf(t));
  const [isVisibleValid, setIsVisibleValid] = useState(false);
  const [error, setError] = useState({});
  const dispatch = useDispatch();
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  useEffect(() => {
    const { password } = data;
    if (data.password === '' || !data.password) return;
    const stepsCopy = [...steps];

    if (!isVisibleValid) setIsVisibleValid(true);
    if (validPassword(password, { minUppercase: 1 })) {
      stepsCopy[0].status = true;
    } else {
      stepsCopy[0].status = false;
    }
    if (validPassword(password, { minLowercase: 1 })) {
      stepsCopy[1].status = true;
    } else {
      stepsCopy[1].status = false;
    }

    if (validPassword(password, { minNumbers: 1 })) {
      stepsCopy[2].status = true;
    } else {
      stepsCopy[2].status = false;
    }

    if (password.length >= 8) {
      stepsCopy[3].status = true;
    } else {
      stepsCopy[3].status = false;
    }

    setSteps(stepsCopy);
  }, [data.password]);

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const hideValidation = e => {
    setIsVisibleValid(false);
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const { email, password, confirmPassword } = data;

    Object.keys({
      email,
      password,
      confirmPassword,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (validEmail(email) && email !== '') {
      currentError = getErrorStack(
        currentError,
        'email',
        t('validation:incorrect_email'),
      );
      flag = true;
    }

    if (
      !validPassword(password, {
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minLength: 8,
      }) &&
      password !== ''
    ) {
      currentError = getErrorStack(
        currentError,
        'password',
        t('validation:password_not_secure'),
      );
      flag = true;
    }

    if (confirmPassword !== password && confirmPassword !== '') {
      currentError = getErrorStack(
        currentError,
        'confirmPassword',
        t('validation:password_not_match'),
      );
      flag = true;
    }

    if (!agreement) {
      currentError = {
        ...currentError,
        agreement: { init: true },
      };
      flag = true;
    }

    setError(currentError);
    return flag;
  };
  const handelSubmit = e => {
    e.preventDefault();
    const { email, password } = data;
    if (validation()) return;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          authOperation.signUp({
            email,
            password,
            onResolve,
            onReject,
          }),
        ),
      () => {},
      message =>
        setError({
          email: { init: true, text: message },
        }),
    );
  };

  const { email, password, confirmPassword, agreement } = data;
  return (
    <Wrapper>
      <Form autoComplete="on" onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'}>
          {t('registration:step_one.tittle')}
        </Typography>
        <Typography
          sx={{ mt: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('registration:step_one.slogan')}
        </Typography>
        <TextInput
          error={error?.email?.init}
          errorText={error?.email?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={email}
          onChange={onChange('email')}
          placeholder={'Email*'}
          type="text"
          withOutSpace={true}
          data-test-id="Email"
          autoComplete={'email'}
          inputProps={{
            maxLength: 255,
          }}
        />
        <TextInput
          error={error?.password?.init}
          errorText={error?.password?.text}
          sx={{ mt: '28px', height: '48px' }}
          value={password}
          onChange={onChange('password')}
          placeholder={t('registration:field.password', { req: '*' })}
          type="password"
          onBlur={hideValidation}
          withOutSpace={true}
          data-test-id="Password"
          autoComplete={'new-password'}
          inputProps={{
            maxLength: 255,
          }}
        />
        <TextInput
          error={error?.confirmPassword?.init}
          errorText={error?.confirmPassword?.text}
          sx={{ mt: '28px', height: '48px' }}
          onChange={onChange('confirmPassword')}
          value={confirmPassword}
          placeholder={t('registration:field.duplicate_password', {
            req: '*',
          })}
          type="password"
          withOutSpace={true}
          data-test-id="Password"
          autoComplete={'current-password'}
          inputProps={{
            maxLength: 255,
          }}
        />
        <CoordinationWrapper>
          <CheckboxBase
            width={24}
            onChange={onChange('agreement')}
            checked={agreement}
            error={error?.agreement?.init}
            data-test-id="Checkbox"
          >
            <Typography
              sx={{ ml: '10px' }}
              variant={'bodyLarge'}
              component={'p'}
            >
              {t('registration:step_one.agreement.part_one')}{' '}
              <Link
                target="_blank"
                href={currentLang + `/user-agreement`}
                rel="noopener noreferrer"
              >
                {t('registration:step_one.agreement.part_two')}
              </Link>{' '}
              і{' '}
              <Link
                target="_blank"
                href={currentLang + '/privacy-policy'}
                rel="noopener noreferrer"
              >
                {t('registration:step_one.agreement.part_tree')}
              </Link>{' '}
              Viatec
            </Typography>
          </CheckboxBase>
        </CoordinationWrapper>
        <ButtonBase
          type="submit"
          size={'large'}
          sx={{ mt: '28px' }}
          data-test-id="SignIn"
        >
          <Typography variant={'bodyBoldLarge'}>
            {' '}
            {t('registration:step_one.submit_btn')}
          </Typography>
        </ButtonBase>
      </Form>
      <WrapperLights data-test-id="PasswordLights">
        {isVisibleValid &&
          steps.map(({ status, text }) => (
            <PasswordLights
              className={`PasswordLights`}
              status={status}
              text={text}
              key={text}
            />
          ))}
      </WrapperLights>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const CoordinationWrapper = styled.label`
  display: flex;
  margin-top: 28px;
`;

const Link = styled.a`
  display: inline-block;
  text-decoration: underline;
`;

const WrapperLights = styled.div`
  margin-top: 216px;
  margin-left: 24px;
  display: flex;
  flex-direction: column;

  width: 210px;

  div + div {
    margin-top: 4px;
  }
`;
