import { useTranslation } from 'react-i18next';

import { t } from 'i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Arow_Serch } from '@root/assets/svg';
import { Sprite } from '@root/assets/svg';
import { IconBtn, TextInput } from '@root/ui';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

export const SearchInput = ({
  focus,
  onBlur,
  height,
  onChange,
  searchValue,
  placeholder,
  sx,
  sxLabel = {},
  includeClearButton = true,
  handleClearSearch,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['admin'], { useSuspense: false });

  return (
    <Label style={sxLabel}>
      <TextInput
        id="input-with-icon-adornment"
        sx={{
          width: '100%',
          height: height || '36px',
          input: { pl: '10px' },
          fontSize: '0.875rem',
          ...sx,
        }}
        placeholder={placeholder}
        startAdornment={
          <Svg width={20} height={20} fill={color.gray_60}>
            <use href={`${Sprite}#icon-loop`}></use>
          </Svg>
        }
        endAdornment={
          includeClearButton &&
          searchValue.length > 0 && (
            <IconBtn
              onClick={handleClearSearch}
              sx={{ marginRight: '4px' }}
              disableBorder
              size={18}
              href={`${Sprite}#icon-cross`}
            />
          )
        }
        onBlur={onBlur}
        onChange={onChange('searchValue')}
        value={searchValue}
        data-test-id="SearchInput"
      />
      {focus && (
        <>
          <Placeholder>
            <img src={Arow_Serch} alt="Icon Placeholder" />
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.line,
                ml: '4px',
              }}
              component={'p'}
            >
              {t('admin:init_search')}
            </Typography>
          </Placeholder>
        </>
      )}
    </Label>
  );
};

const Svg = styled.svg`
  fill: ${({ color }) => color};
`;

const Label = styled.div`
  display: block;
  position: relative;
  width: 100%;
  margin-right: 16px;
`;

const Placeholder = styled.div`
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  display: flex;
`;
SearchInput.propTypes = {
  focus: PropTypes.bool,
  onBlur: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  searchValue: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  sx: PropTypes.object,
  height: PropTypes.string,
  sxLabel: PropTypes.object,
  includeClearButton: PropTypes.bool,
  handleClearSearch: PropTypes.func,
};
