import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { OrderServiceDetails } from '@root/modules';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const RowItem = ({ title, value, sx }) => {
  const { color } = useTheme();
  return (
    <Row bg={color.line}>
      <Typography
        sx={{ color: color.gray_80, minWidth: '187px' }}
        variant={'bodySmall'}
        component={'span'}
      >
        {title}
      </Typography>
      <Typography
        sx={{ ...sx, color: color.gray_80 }}
        variant={'bodySmall'}
        component={'span'}
      >
        {value}
      </Typography>
    </Row>
  );
};

RowItem.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  sx: PropTypes.object,
};

const Row = styled.div`
  padding: 12px 24px;
  display: flex;
  position: relative;

  &:after {
    content: '';
    width: 100%;
    height: 1px;
    background: ${props => props.bg};
    position: absolute;
    bottom: 0;
    left: 0;
  }
  &:last-child:after {
    display: none;
  }
`;
