import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PasswordConf } from '@root/conf';
import { getErrorStack, validPassword } from '@root/helpers';

export const useUserAuthOptions = () => {
  const [data, setData] = useState({
    email: '',
    name: '',
    phones: [
      {
        phone: null,
        confirm: false,
      },
    ],
    password: '',
    confirmPassword: '',
    agreement: false,
  });
  const [error, setError] = useState({});
  const [isVisibleValid, setIsVisibleValid] = useState(false);
  const [steps, setSteps] = useState([]);
  const { t } = useTranslation('validation', { useSuspense: false });

  useEffect(() => {
    setSteps(PasswordConf(t));
  }, [t]);

  useEffect(() => {
    const { password } = data;
    if (data.password === '' || !data.password) return;
    const stepsCopy = [...steps];

    if (!isVisibleValid) setIsVisibleValid(true);
    if (validPassword(password, { minUppercase: 1 })) {
      stepsCopy[0].status = true;
    } else {
      stepsCopy[0].status = false;
    }
    if (validPassword(password, { minLowercase: 1 })) {
      stepsCopy[1].status = true;
    } else {
      stepsCopy[1].status = false;
    }

    if (validPassword(password, { minNumbers: 1 })) {
      stepsCopy[2].status = true;
    } else {
      stepsCopy[2].status = false;
    }

    if (password.length >= 8) {
      stepsCopy[3].status = true;
    } else {
      stepsCopy[3].status = false;
    }

    setSteps(stepsCopy);
  }, [data.password]);

  const ConfirmPhones = phone => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.phones.map(el => {
      if (el.phone === phone) el.confirm = true;
      return el;
    });
    setData(dataCopy);
  };

  const onChange = (name, subName) => value => {
    if (name === 'phone') {
      const dataCopy = JSON.parse(JSON.stringify(data));
      dataCopy.phones[subName][name] = value;
      dataCopy.phones[subName].confirm = false;
      setData(dataCopy);
    } else {
      setData(prevState => ({ ...prevState, [name]: value }));
    }
  };

  const hideValidation = e => {
    setIsVisibleValid(false);
  };

  const handelAddPhoneInput = () => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.phones = [...dataCopy.phones, { phone: '', confirm: false }];
    setData(dataCopy);
  };
  const validation = () => {
    let flag = false;
    let currentError = {};
    const { phones, password, confirmPassword, agreement, name } = data;

    Object.keys({
      password,
      confirmPassword,
      name,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (!agreement) {
      currentError = {
        ...currentError,
        agreement: { init: true },
      };
      flag = true;
    }

    if (
      !validPassword(password, {
        minLowercase: 1,
        minUppercase: 1,
        minNumbers: 1,
        minLength: 8,
      }) &&
      password !== ''
    ) {
      currentError = getErrorStack(
        currentError,
        'password',
        t('validation:password_not_secure'),
      );
      flag = true;
    }

    if (confirmPassword !== password && confirmPassword !== '') {
      currentError = getErrorStack(
        currentError,
        'confirmPassword',
        t('validation:password_not_match'),
      );
      flag = true;
    }

    let arrError = [];
    arrError = phones.reduce((acc, { phone }, i, arr) => {
      const some = arr.some(el => !!(el.phone && el.phone.length));

      if (!some) {
        acc = [
          ...acc,
          i === 0 && {
            init: true,
            text: t('validation:required'),
          },
        ];
        flag = true;
      }

      if (!phone) {
        acc = [
          ...acc,
          {
            init: false,
            text: '',
          },
        ];
        return acc;
      }
      if (phone.length === 12 || phone.length === 3) {
        acc = [
          ...acc,
          {
            init: false,
            text: '',
          },
        ];
      } else {
        acc = [
          ...acc,
          {
            init: true,
            text: t('validation:not_full_fill'),
          },
        ];
        flag = true;
      }
      return acc;
    }, []);

    currentError = { ...currentError, phones: arrError };

    setError(currentError);
    return flag;
  };

  return {
    data,
    setData,
    error,
    isVisibleValid,
    steps,
    validation,
    ConfirmPhones,
    handelAddPhoneInput,
    onChange,
    hideValidation,
  };
};
