import * as React from 'react';

import PropTypes from 'prop-types';
import styles from 'styled-components';

import { MessageError } from '@root/ui';

import { Switch, Typography, useTheme } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { styled } from '@mui/material/styles';

const IOSSwitch = styled(props => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme, checked }) => ({
  width: 36,
  height: 20,
  padding: 0,
  border: checked ? 0 : `1px solid ${theme.color.line}`,
  borderRadius: '45px',

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: checked ? 2 : 1,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: theme.color.white,
      border: 'none',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.color.main,
        opacity: 1,
        border: 0,
      },

      '.MuiSwitch-thumb': {
        backgroundColor: theme.color.white,
        opacity: 1,
        border: 0,
      },
    },
    input: {
      left: 0,
      width: '100%',
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.color.main,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 16,
    height: 16,
    backgroundColor: theme.color.gray_40,
  },

  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: 'transparent',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const CustomizedSwitches = ({
  checked = false,
  onChange,
  label,
  disabled,
  sx,
  errors,
  labelPlacement = 'end',
  ...prop
}) => {
  const { color } = useTheme();

  return (
    <SwitchWrapper>
      <FormControlLabel
        disabled={disabled}
        sx={{
          ...sx,
          opacity: disabled ? 0.6 : 1,
        }}
        control={
          <IOSSwitch
            checked={checked}
            onChange={e => onChange(e.target.checked)}
          />
        }
        label={
          typeof label === 'string' ? (
            <Typography
              variant={'bodyMedium'}
              sx={{
                color: color.gray,
                marginLeft: '8px',
                fontWeight: 400,
              }}
              component="p"
            >
              {label}
            </Typography>
          ) : (
            label
          )
        }
        labelPlacement={labelPlacement}
        {...prop}
      />
      {errors?.init && <MessageError errorText={errors?.text} />}
    </SwitchWrapper>
  );
};

const SwitchWrapper = styles.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

CustomizedSwitches.propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};
