const loadingArticlesList = state => state.newsAndEvents.loadingArticlesList;
const loadingArticleDetails = state =>
  state.newsAndEvents.loadingArticleDetails;
const articlesListMeta = state => state.newsAndEvents.articlesListMeta;
const articlesList = state => state.newsAndEvents.articlesList;
const articleDetails = state => state.newsAndEvents.articleDetails;
export default {
  loadingArticlesList,
  articlesList,
  articleDetails,
  loadingArticleDetails,
  articlesListMeta,
};
