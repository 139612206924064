import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { ModalBase, ProgressLoader } from '@root/ui';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { getRequisites } from './API';

export const ViewRequisitesModal = ({ open, handleCloseModal }) => {
  const { color } = useTheme();
  const { t } = useTranslation('logistic', { useSuspense: false });
  const dispatch = useDispatch();

  const [addresses, setAddresses] = useState(null);

  useEffect(() => {
    (async () => {
      const { data } = await getRequisites();
      setAddresses(data.data.addresses);
    })();
  }, []);
  return (
    <ModalBase
      open={open}
      sx={{ maxWidth: '462px', minHeight: '261px' }}
      handleClose={handleCloseModal}
      modalHeader={<HeaderDefault text="" onClose={handleCloseModal} />}
    >
      <Typography
        component="p"
        variant="bodyBoldMedium"
        color={color.gray}
        marginBottom="24px"
      >
        {t('warning.send_to_this_address')}:
      </Typography>

      {addresses ? (
        <AddressesWrapper>
          {addresses.map(address => (
            <Typography
              key={address}
              variant="bodyMedium"
              component="p"
              color={color.gray}
              dangerouslySetInnerHTML={{ __html: address }}
            />
          ))}
        </AddressesWrapper>
      ) : (
        <ProgressLoader />
      )}
    </ModalBase>
  );
};

const AddressesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;
