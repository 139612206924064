export const getNavMenu = (t, isVisible) => [
  {
    path: '/',
    name: t('common:nav_menu.main'),
    icon: 'icon-home',
    role: ['admin', 'user'],
    permission_key: 'exception',
    dataTestId: 'NavMenuMain',
  },
  {
    path: '/catalog',
    name: t('common:nav_menu.catalog'),
    icon: 'icon-catalog',
    role: ['admin', 'user'],
    permission_key: 'catalog_access',
    dataTestId: 'NavMenuCatalog',
  },
  {
    path: '/orders',
    name: t('common:nav_menu.my_orders'),
    icon: 'icon-orders',
    role: ['admin', 'user'],
    permission_key: 'orders_history_access',
    dataTestId: 'NavMenuMyOrders',
  },
  {
    path: '/accounting-documents',
    name: t('common:nav_menu.docs'),
    icon: 'icon-docs',
    role: ['admin', 'user'],
    visible: isVisible,
    permission_key: 'documents_access',
    dataTestId: 'NavMenuDocs',
  },
  {
    path: '/mutual-settlements',
    name: t('common:nav_menu.mutual_settlements'),
    icon: 'icon-mutual-settlements',
    role: ['admin', 'user'],
    visible: isVisible,
    permission_key: 'mutuals_access',
    dataTestId: 'NavMenuMutualSettlements',
  },
  {
    path: '/logistics',
    name: t('common:nav_menu.logistics'),
    icon: 'icon-logistics',
    role: ['admin', 'user'],
    visible: isVisible,
    permission_key: 'logictic_access',
    dataTestId: 'NavMenuLogistics',
  },
  {
    path: '/order-service',
    name: t('common:nav_menu.order_service'),
    icon: 'icon-order-service',
    role: ['admin', 'user'],
    visible: isVisible,
    permission_key: 'service_orders_access',
    dataTestId: 'NavMenuOrderService',
  },
  {
    path: '/admin-panel',
    name: t('common:nav_menu.admin_panel'),
    icon: 'icon-admin_panel',
    role: ['admin'],
    permission_key: 'exception',
    dataTestId: 'NavMenuAdminPanel',
  },
  {
    path: '/settings',
    name: t('common:nav_menu.settings'),
    icon: 'icon-setings',
    role: ['admin'],
    permission_key: 'exception',
    dataTestId: 'NavMenuSettings',
  },
];
