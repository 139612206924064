import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { getCurrentDate, getFirstDateOfCurrentMonth } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import { NavigationButton } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

export const DeliveryHistoryNavigation = ({ children }) => {
  const permission = useSelector(userSelectors.getPermissions);
  const { logisticsTab } = useParams();
  const { navigation } = useNavigation();

  const { t } = useTranslation(['logistic'], { useSuspense: false });

  const handleSetActiveTab = tab => {
    navigation(
      `/logistics/${tab}?date_from=${getFirstDateOfCurrentMonth()}&date_to=${getCurrentDate()}`,
    );
  };

  return (
    <>
      <TabsWrapper>
        {permission.logistic_from_client_access && (
          <NavigationButton
            isActive={logisticsTab === 'delivery-from-client'}
            onClick={() => handleSetActiveTab('delivery-from-client')}
          >
            {t('logistic:tabs.delivery_from_client')}
          </NavigationButton>
        )}
        {permission.logistic_to_client_access && (
          <NavigationButton
            isActive={logisticsTab === 'sending-to-client'}
            onClick={() => handleSetActiveTab('sending-to-client')}
          >
            {t('logistic:tabs.sending_to_client')}
          </NavigationButton>
        )}
      </TabsWrapper>
      {children}
    </>
  );
};

DeliveryHistoryNavigation.propTypes = {
  children: PropTypes.node,
};

const TabsWrapper = styled.div`
  display: flex;
`;
