import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { OutlineButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const CompanyTemplate = ({ handleOpenModal, data }) => {
  const { color } = useTheme();
  return (
    <TemplateWrap color={color}>
      <LeftSide>
        <FieldWrap>
          <Typography color={color.gray} variant="bodyBoldMedium">
            {data?.company_name}
          </Typography>
          <Typography color={color.gray} variant="bodyMedium">
            {data?.contact_person}
          </Typography>
        </FieldWrap>
        <FieldWrap>
          <Typography color={color.gray_80} variant="bodyMedium">
            {`${data?.address}, ${data?.city}, ${data?.zip_code}`}
          </Typography>
        </FieldWrap>
        <FieldWrap>
          <Typography color={color.gray_80} variant="bodyMedium">
            +{data?.phone}
          </Typography>
        </FieldWrap>
        <FieldWrap>
          <Typography
            color={color.gray_80}
            variant="bodyMedium"
            sx={{ overflowWrap: 'anywhere' }}
          >
            {data?.site}
          </Typography>
          <Typography color={color.gray_80} variant="bodyMedium">
            {data?.email}
          </Typography>
        </FieldWrap>
      </LeftSide>
      <RightSide>
        <OutlineButtonBase
          sx={{
            height: '36px',
            minWidth: '36px !important',
            alignItems: 'center',
          }}
          onClick={() => handleOpenModal(true, 'edit')}
        >
          <svg height={16} width={16}>
            <use href={`${Sprite}#icon-pan`}></use>
          </svg>
        </OutlineButtonBase>
      </RightSide>
    </TemplateWrap>
  );
};

const TemplateWrap = styled.div`
  margin-top: 15px;
  background: ${({ color }) => color.gray_20};
  padding: 16px;
  width: 70%;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const FieldWrap = styled.div`
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
`;

const LeftSide = styled.div`
  width: 80%;
`;

const RightSide = styled.div``;

CompanyTemplate.propTypes = {
  handleOpenModal: PropTypes.func.isRequired,
  data: PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.object,
    company_name: PropTypes.string,
    edrpou: PropTypes.string,
    phone: PropTypes.string,
    city: PropTypes.string,
    address: PropTypes.string,
    zip_code: PropTypes.string,
    site: PropTypes.string,
    email: PropTypes.string,
    contact_person: PropTypes.string,
  }),
};
