import { Fragment, useState } from 'react';
import { InView } from 'react-intersection-observer';

import PropTypes from 'prop-types';
import styled from 'styled-components';

export const InfiniteScroll = ({
  children,
  onScroll,
  isDisableFetching,
  isLoading,
  data,
}) => {
  const [isInView, setIsInView] = useState();
  const handleScroll = ({ inView, item, index }) => {
    setIsInView(inView);
    onScroll({ inView, item, index });
  };
  return (
    <InfiniteScrollWrapper>
      {data &&
        data.map((item, index) => {
          return (
            <Fragment key={item?.id}>
              {children({ isInView, item, index })}
              {data.length - 1 === index + 1 && (
                <InView
                  onChange={inView =>
                    !isDisableFetching &&
                    !isLoading &&
                    handleScroll({ inView, item, index })
                  }
                />
              )}
            </Fragment>
          );
        })}
    </InfiniteScrollWrapper>
  );
};

const InfiniteScrollWrapper = styled.div``;

InfiniteScroll.propTypes = {
  onScroll: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  isDisableFetching: PropTypes.bool,
  children: PropTypes.func.isRequired,
};
