import { ButtonBase, ButtonWithoutBorder, ModalBase } from '@root/ui';
import styled from 'styled-components';
import { Typography } from '@mui/material';
import { SettingsExchangeRateTable } from './SettingsExchangeRateTable';
import { theme } from '@root/styles';
import {useTranslation} from "react-i18next";

export const SettingsExchangeRateModal = ({
  isOpen,
  handleCloseModal,
  data,
}) => {
  const { t } = useTranslation(['header'], { useSuspense: false });
  return (
    <ModalBase
      open={isOpen}
      handleClose={handleCloseModal}
      sx={{
        width: '100%',
        maxWidth: '806px',
        padding: 0,
        transform: 'initial',
        top: 'initial',
        left: 'initial',
      }}
      modalSx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ExchangeRateWrapper>
        <ExchangeRateHeader>
          <Typography variant="bodyBoldLarge" color={theme.color.gray}>
            {t('header:modals.settings_exchange_rate_modal.title')}
          </Typography>
          <ButtonWithoutBorder
            sx={{ margin: '0 12px 0 auto' }}
            onClick={handleCloseModal}
          >
            {t('header:modals.settings_exchange_rate_modal.cancel')}
          </ButtonWithoutBorder>
          <ButtonBase sx={{ height: '28px' }}>Зберегти</ButtonBase>
        </ExchangeRateHeader>

        <SettingsExchangeRateTable />
      </ExchangeRateWrapper>
    </ModalBase>
  );
};

const ExchangeRateWrapper = styled.div``;
const ExchangeRateHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 24px;
`;
