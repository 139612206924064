import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { StepperStatusTyne } from '@root/components';
import { handleCopy } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import { AlertBase, ButtonBase, OutlineButtonBase } from '@root/ui';

import cartSelectors from '@redux/cart/cart-selectors';

import { Typography, useTheme } from '@mui/material';

export const CommercialOfferDetails = ({ data, callbackAfterCheckCart }) => {
  const [show, setShow] = useState(false);
  const [buttonId, setButtonId] = useState(null);
  const { t } = useTranslation('order_history');
  const { color } = useTheme();
  const { navigation } = useNavigation();
  const getCartData = useSelector(cartSelectors.getCartData);
  const dispatch = useDispatch();

  const { statuses, id, created, can_buy } = data;

  const actionButtons = [
    {
      id: 1,
      icon: 'icon-chain',
      text: t('buttons.copy_link'),
      link: data?.link_web,
    },
    {
      id: 2,
      icon: 'icon-docs',
      text: t('buttons.copy_link_to_pdf'),
      link: data?.link_pdf,
    },
    {
      id: 3,
      icon: 'icon-grid',
      text: t('buttons.copy_link_to_exs'),
      link: data?.link_xls,
    },
  ];

  const copyToClipboard = (path, id) => {
    handleCopy(path);
    setButtonId(id);
    setShow(true);
  };

  const editCoProduct = () => {
    navigation(`/commercial-offer?id=${id}`);
  };

  const checkAvailabilityShoppingCart = () => {
    if (getCartData.length > 0) {
      callbackAfterCheckCart('modal');
    } else {
      callbackAfterCheckCart('notification');
    }
  };

  return (
    <Details color={color}>
      <DetailsItem color={color}>
        <Typography
          color={color.gray_60}
          variant="bodyMedium"
          sx={{ minWidth: '160px' }}
        >
          {t('status')}:
        </Typography>
        <StepperStatusTyne
          steps={statuses}
          withSteps={false}
          boxSx={{ minHeight: 'initial' }}
        />
      </DetailsItem>
      {data?.feedback && (
        <DetailsItem color={color}>
          <Typography
            color={color.gray_60}
            variant="bodyMedium"
            sx={{ minWidth: '160px' }}
          >
            {t('comment')}:
          </Typography>
          <Typography
            color={color.gray}
            variant="bodyMedium"
            sx={{ minWidth: '160px' }}
          >
            {data.feedback}
          </Typography>
        </DetailsItem>
      )}
      <DetailsItem color={color}>
        <Typography
          color={color.gray_60}
          variant="bodyMedium"
          sx={{ minWidth: '160px' }}
        >
          {t('create')}:
        </Typography>
        <Typography
          color={color.gray}
          variant="bodyMedium"
          sx={{ minWidth: '160px' }}
        >
          {created}
        </Typography>
      </DetailsItem>
      <DetailsItem color={color}>
        <Typography
          color={color.gray_60}
          variant="bodyMedium"
          sx={{ minWidth: '160px' }}
        >
          {t('hours_until_completion')}:
        </Typography>
        <Typography
          color={color.gray}
          variant="bodyMedium"
          sx={{ minWidth: '160px' }}
        >
          {data?.hours_left}
        </Typography>
      </DetailsItem>
      <DetailsItem color={color}>
        <Typography
          color={color.gray_60}
          variant="bodyMedium"
          sx={{ minWidth: '160px' }}
        >
          {t('text_of_the_offer')}:
        </Typography>
        <Typography
          color={color.gray}
          variant="bodyMedium"
          sx={{ minWidth: '160px' }}
        >
          {data?.detail}
        </Typography>
      </DetailsItem>
      <ButtonsWrap>
        <ButtonBase
          onClick={can_buy ? checkAvailabilityShoppingCart : editCoProduct}
        >
          <Typography variant="bodyBoldSmall" color={color.white}>
            {t(`buttons.${can_buy ? 'make_an_order' : 'edit'}`)}
          </Typography>
        </ButtonBase>
        {actionButtons.map(button =>
          !show || button.id !== buttonId ? (
            <OutlineButtonBase
              key={button.id}
              onClick={() => copyToClipboard(button.link, button.id)}
            >
              <Svg>
                <use href={`${Sprite}#${button.icon}`}></use>
              </Svg>
              <Typography variant="bodyBoldSmall" color={color.gray_80}>
                {button.text}
              </Typography>
            </OutlineButtonBase>
          ) : (
            <AlertBase
              alertIcon={false}
              alertSx={{
                padding: '8px 10px',
                border: `1px solid ${color.line}`,
              }}
              alertTitle={t('copied')}
              alertTitleSx={{
                color: color.gray_60,
              }}
              setShow={setShow}
              closeAfterTimeout={true}
            />
          ),
        )}
      </ButtonsWrap>
    </Details>
  );
};

const Details = styled.div`
  padding: 20px 32px;
  border-bottom: 1px solid ${({ color }) => color.line};
`;

const DetailsItem = styled.div`
  display: flex;
  align-self: center;
  gap: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${({ color }) => color.line_light};

  &:not(:last-child) {
    margin-bottom: 8px;
  }
`;

const ButtonsWrap = styled.div`
  margin: 20px 0 10px 0;
  display: flex;
  align-self: center;
  gap: 16px;
`;

const Svg = styled.svg`
  width: 16px;
  height: 16px;
  display: inline-block;
  vertical-align: baseline;
  margin-right: 5px;
  position: relative;
  top: -2px;
`;
