import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { ModalBase } from '@root/ui';

import { ExchangeRateTable } from './ExchangeRateTable';

export const ExchangeRateModal = ({ isOpen, handleCloseModal, data }) => {
  const { t } = useTranslation(['header'], { useSuspense: false });
  return (
    <ModalBase
      open={isOpen}
      handleClose={handleCloseModal}
      sx={{ width: '100%', maxWidth: '462px', padding: 0 }}
      modalHeader={
        <HeaderDefault
          text={t('header:modals.exchange_rate_modal.title')}
          onClose={handleCloseModal}
          sx={{
            padding: '16px 40px 0 40px',
          }}
        />
      }
    >
      <ExchangeRateWrapper>
        <ExchangeRateTable />
      </ExchangeRateWrapper>
    </ModalBase>
  );
};

const ExchangeRateWrapper = styled.div``;
