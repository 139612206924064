import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { QuantityManager } from '@root/components/Managers/QuantityManager';
import { BasicTooltip, IconBtn, ToggleBtn } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const Action = ({
  onClick,
  handelDetailCard,
  addedToCart,
  isOpenDetail,
  chooseProduct,
  addAnimation,
  handelOpenSavedModal,
  isSaved,
  inCompare,
  relation_quantity,
  errorBorder,
  data,
  addOrRemoveFromComparisons,
  isHideArrowCard = false,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });
  const permission = useSelector(userSelectors.getPermissions);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;

  const QuantityMemo = useMemo(
    () => () =>
      (
        <QuantityManager
          errorBorder={errorBorder}
          addAnimation={addAnimation}
          data={data}
        />
      ),
    [chooseProduct, errorBorder],
  );

  const tooltipText = () => {
    return (
      <TooltipTitle>
        <Typography color={color.white} variant="bodyBoldSmall" component="p">
          {t('common:tooltip.current_product_need')} {relation_quantity}
        </Typography>
        <Typography color={color.white} variant="bodyBoldSmall" component="p">
          {data?.model}
        </Typography>
        <Typography
          color={color.white}
          variant="bodySmall"
          marginTop="3px"
          component="p"
        >
          {t('common:tooltip.can_change_after_adding_product_to_cart')}
        </Typography>
      </TooltipTitle>
    );
  };

  return (
    <FlexContainer>
      {permission.cart_add_access && (
        <Box>
          {addedToCart ? (
            QuantityMemo()
          ) : (
            <>
              <ToggleBtnWrapper onClick={e => e.preventDefault()}>
                <ToggleBtn
                  onClick={onClick}
                  size={32}
                  disabled={!data.can_buy[choosedCurrency]}
                  isActive={data.can_buy[choosedCurrency]}
                  href={`${Sprite}#icon-grocery_cart`}
                  data-test-id="ButtonAddToCart"
                />
              </ToggleBtnWrapper>
              {relation_quantity && (
                <BasicTooltip title={tooltipText()} placement="top">
                  <Typography
                    sx={{ ml: '8px', cursor: 'pointer' }}
                    variant={'bodyBoldSmall'}
                    component={'p'}
                  >
                    x{relation_quantity}
                  </Typography>
                </BasicTooltip>
              )}
            </>
          )}
        </Box>
      )}

      <Controllers onClick={e => e.preventDefault()}>
        <IconBtn
          href={`${Sprite}#icon-hart`}
          onClick={handelOpenSavedModal}
          size={16}
          saved={isSaved}
          tooltipText={
            isSaved
              ? t('common:tooltip.remove_from_saved')
              : t('common:tooltip.save')
          }
          data-test-id="AddToFavoritesButton"
        />
        {permission.product_compare_access && (
          <IconBtn
            href={`${Sprite}#icon-counter`}
            onClick={addOrRemoveFromComparisons}
            size={16}
            sx={{ marginTop: '10px' }}
            compare={inCompare}
            tooltipText={t('common:tooltip.compare')}
          />
        )}
        {!isHideArrowCard && (
          <IconBtn
            href={`${Sprite}#icon-arrow_card`}
            onClick={handelDetailCard}
            size={16}
            sx={{ marginTop: '10px' }}
            rotateSvg={isOpenDetail ? '180deg' : '0'}
            tooltipText={
              isOpenDetail
                ? t('common:tooltip.hide_details')
                : t('common:tooltip.show_more')
            }
            data-test-id="OpenCardDetailsInCatalogButton"
          />
        )}
      </Controllers>
    </FlexContainer>
  );
};

const ToggleBtnWrapper = styled.div`
  display: block;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

const Box = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 142px;
  height: 100%;
`;

const Controllers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TooltipTitle = styled.div``;

Action.propTypes = {
  isHideArrowCard: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  handelDetailCard: PropTypes.func.isRequired,
  addedToCart: PropTypes.bool,
  isOpenDetail: PropTypes.bool,
  chooseProduct: PropTypes.object,
  addAnimation: PropTypes.func,
  handelOpenSavedModal: PropTypes.func.isRequired,
  isSaved: PropTypes.bool,
  inCompare: PropTypes.bool,
  relation_quantity: PropTypes.number,
  errorBorder: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  data: PropTypes.shape({
    can_buy: PropTypes.shape({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    model: PropTypes.string,
  }),
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
};
