import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

const getCity = createAsyncThunk(
  'option/getCity',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/cities');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getActivityAreas = createAsyncThunk(
  'option/getActivityAreas',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('/company/activity-areas');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getHistoryFilters = createAsyncThunk(
  'option/getHistoryFilters',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('order/history/filters');

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getCoFilters = createAsyncThunk(
  'option/getCoFilters',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('commercials/filters');

      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getFilialList = createAsyncThunk(
  'option/getFilialList',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('filial/list');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getLogisticServices = createAsyncThunk(
  'option/getLogisticServices',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get('logistic/services');
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const addBrands = createAsyncThunk(
  'option/addBrands',
  async ({ name }, { rejectWithValue }) => {
    try {
      const { data } = await axios.post('product/edit/brands', { name });
      return data.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

export default {
  getCity,
  getActivityAreas,
  getFilialList,
  getHistoryFilters,
  getLogisticServices,
  addBrands,
  getCoFilters,
};
