import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const ConfirmSelection = ({
  discardChanges,
  callbackAfterApplyChanges,
}) => {
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <ContentWrap>
      <Typography
        variant={'bodyBoldLarge'}
        sx={{
          color: color.gray,
          textAlign: 'center',
        }}
        component={'p'}
      >
        {t('common:buttons.delete')}?
      </Typography>
      <ButtonsWrap>
        <ButtonBase
          onClick={discardChanges}
          sx={{
            maxWidth: '100px',
            width: '100%',
            height: '30px',
            background: 'transparent',
            border: `1px solid ${color.gray}`,
            color: color.gray,
            '&:hover': {
              background: 'transparent',
            },
          }}
        >
          {t('common:buttons.no')}
        </ButtonBase>
        <ButtonBase
          onClick={callbackAfterApplyChanges}
          sx={{
            maxWidth: '100px',
            width: '100%',
            height: '30px',
          }}
          data-test-id="ConfirmButton"
        >
          {t('common:buttons.yes')}
        </ButtonBase>
      </ButtonsWrap>
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 260px;
  max-width: 100%;
`;

const ButtonsWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
  gap: 15px;
`;

ConfirmSelection.propTypes = {
  discardChanges: PropTypes.func.isRequired,
  callbackAfterApplyChanges: PropTypes.func.isRequired,
};
