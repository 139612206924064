import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { UserAgreement } from '@root/components/UserAgreement';
import { IconBtn, ModalBase } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const UserAgreementModal = ({ open, handleToggleModal }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['user_agreement'], { useSuspense: false });
  return (
    <ModalBase
      handleClose={() => handleToggleModal(false)}
      open={open}
      sx={{ paddingRight: 0, paddingBottom: 0 }}
      headerBoxStyles={{
        padding: '0px 20px 15px 20px',
        borderBottom: `1px solid ${color.line}`,
        marginLeft: '-20px',
      }}
      modalHeader={
        <UserAgreementModalHeader>
          <Typography variant="h1" color={color.gray} component="h3">
            {t('title')}
          </Typography>
          <IconBtn
            size="24px"
            href={`${Sprite}#icon-cross`}
            onClick={() => handleToggleModal(false)}
          />
        </UserAgreementModalHeader>
      }
    >
      <UserAgreementModalWrapper>
        <UserAgreement />
      </UserAgreementModalWrapper>
    </ModalBase>
  );
};

const UserAgreementModalWrapper = styled.div`
  padding-right: 30px;
  padding-bottom: 28px;
`;
const UserAgreementModalHeader = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
