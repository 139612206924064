import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';

import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Navigation, Scrollbar } from 'swiper';
import 'swiper/css/bundle';
import TextSize from 'text-size';

import { Sprite } from '@root/assets/svg';
import {
  CustomScroll,
  CustomizedSwitches,
  IconBtn,
  ProgressLoader,
} from '@root/ui';

import comparisonsOperation from '@redux/comparisons/comparisons-operation';
import comparisonsSelectors from '@redux/comparisons/comparisons-selectors';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Divider, Typography, useTheme } from '@mui/material';

import { ComparisonCreatePdfModal } from './ComparisonCreatePdfModal';
import { ComparisonFileReadyModal } from './ComparisonFileReadyModal';
import { ComparisonsCardDetails } from './ComparisonsCardDetails';
import { ComparisonsCardRow } from './ComparisonsCardRow';
import { ListSectionsItem } from './ListSectionsItem';

export const ComparisonsDetails = ({
  handelOpenSavedModal,
  handleAddToCart,
  removeProductFromComparisons,
  handleMoveToCategory,
  callConfirmModal,
  openCreateModal,
  closeComparisonsModal,
}) => {
  const { t } = useTranslation('comparisons', { useSuspense: false });
  const { color } = useTheme();
  const ref = useRef(null);
  const [isDifference, setIsDifference] = useState(false);
  const [activeTab, setActiveTab] = useState({ idx: 0, id: null });
  const [listSectionsHeight, setListSectionsHeight] = useState(null);
  const [toggleList, setToggleList] = useState(false);
  const [cardRowHeight, setCardRowHeight] = useState({});
  const [cardTitleHeight, setCardTitleHeight] = useState([]);
  const [openFileModal, setOpenFileModal] = useState(false);
  const loading = useSelector(comparisonsSelectors.productsLoading);
  const categoryList = useSelector(
    comparisonsSelectors.comparisonCategoriesList,
  );
  const productsDetails = useSelector(comparisonsSelectors.getProductsDetails);
  const dispatch = useDispatch();
  const { products, properties, items, category } = productsDetails;

  //swiper
  const [_, setInit] = useState();
  const [swiper, setSwiper] = useState(null);
  const navigationPrevRef = useRef(null);
  const navigationNextRef = useRef(null);
  const scrollBarRef = useRef(null);
  const containerWrapRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  //swiper

  useEffect(() => {
    if (categoryList && categoryList.length > 0) {
      setCurrentCategoryTab();
      getListSectionsHeight();
    }
  }, [categoryList]);

  useEffect(() => {
    calculateRowsHeight();
    calculateHeadersHeight();
  }, [productsDetails]);

  useEffect(() => {
    const getContainerWidth = () => {
      setContainerWidth(containerWrapRef.current.clientWidth);
    };
    getContainerWidth();

    window.addEventListener('resize', getContainerWidth);
    return () => {
      window.removeEventListener('resize', getContainerWidth);
    };
  }, []);
  const setCurrentCategoryTab = () => {
    const find = categoryList.find(el => el.id === activeTab.id);
    if (find && activeTab.id) {
      dispatch(
        comparisonsOperation.getComparisonsProductsDetails({
          categoryId: activeTab.id,
        }),
      );
    } else {
      dispatch(
        comparisonsOperation.getComparisonsProductsDetails({
          categoryId: categoryList[0]?.id,
        }),
      );
      setActiveTab({ idx: 0, id: categoryList[0]?.id });
    }
  };
  const getListSectionsHeight = () => {
    setListSectionsHeight(ref.current.clientHeight);
  };
  const setActiveCategory = (idx, categoryId) => {
    setActiveTab({ idx, id: categoryId });
    dispatch(
      comparisonsOperation.getComparisonsProductsDetails({
        categoryId,
      }),
    );
    if (swiper) {
      swiper.slideTo(0, 700);
    }
  };
  const toggleOnlyDifferencesChar = val => setIsDifference(val);

  const calculateCategoryListHeight = () => {
    let result;
    if (listSectionsHeight <= 40 || toggleList) {
      result = listSectionsHeight + 'px';
    } else {
      result = '40px';
    }
    return result;
  };
  const calculateRowsHeight = () => {
    let valuesArr = {};
    let rebuildPropertiesObj = {};
    for (const key in properties) {
      rebuildPropertiesObj[key] = properties[key].name;
    }
    const mergeObj = { ...items, properties: rebuildPropertiesObj };
    if (mergeObj) {
      for (const key in mergeObj) {
        for (const subKey in mergeObj[key]) {
          let textSizeWidthRes = Math.round(
            TextSize.getTextWidth({
              text: mergeObj[key][subKey],
              fontSize: 14,
              fontName: 'Roboto',
            }),
          );
          let sizeValue = key === 'properties' ? 180 : 310;
          const heightValue = Math.ceil(textSizeWidthRes / sizeValue) * 19;
          if (!valuesArr[subKey]) {
            valuesArr[subKey] = heightValue;
          } else if (valuesArr[subKey] < heightValue) {
            valuesArr[subKey] = heightValue;
          }
        }
      }
    }
    setCardRowHeight(valuesArr);
  };
  const calculateHeadersHeight = () => {
    let valuesArr = [];
    if (products) {
      for (const key in products) {
        let textSizeWidthRes = Math.round(
          TextSize.getTextWidth({
            text: products[key]?.model,
            fontSize: 16,
            fontName: 'Roboto',
          }),
        );
        valuesArr.push(Math.ceil(textSizeWidthRes / 265) * 18);
      }
    }
    setCardTitleHeight(Math.max(...valuesArr));
  };

  return (
    <DetailsWrap bg={color.white}>
      <ListSections
        ref={ref}
        maxHeight={calculateCategoryListHeight()}
        overflow={toggleList ? 'initial' : 'hidden'}
      >
        <Typography
          color={color.gray}
          variant={'bodyBoldSmall'}
          sx={{ marginRight: '5px' }}
        >
          {t('list_sections_title')}
        </Typography>
        {listSectionsHeight > 40 && (
          <IconBtn
            href={`${Sprite}#icon-arrow_card`}
            size={16}
            sx={{
              position: 'absolute',
              right: '25px',
              top: '12px',
            }}
            onClick={() => setToggleList(!toggleList)}
            rotateSvg={toggleList ? '180deg' : '0'}
          />
        )}
        {categoryList &&
          categoryList.map((category, idx) => (
            <ListSectionsItem
              key={category.id}
              title={category.title}
              counter={category.count}
              active={activeTab.idx === idx}
              onClick={() => setActiveCategory(idx, category.id)}
            />
          ))}
      </ListSections>
      <Divider />
      <ScrollWrap>
        {loading && <ProgressLoader position={'absolute'} />}
        <CustomScroll vertical={true}>
          <FlexWrap ref={containerWrapRef}>
            <ComparisonItem
              minWidth={'218px'}
              width={'217px'}
              height={'135px'}
              zIndex={2}
            >
              <ComparisonItemHeader
                colors={{ white: color.white, line: color.line }}
              >
                <ComparisonsActionsBox
                  height={
                    cardTitleHeight === 18
                      ? 84 + cardTitleHeight + 'px'
                      : 69 + cardTitleHeight + 'px'
                  }
                >
                  <Button onClick={() => handleMoveToCategory(category.url)}>
                    <AddCircleOutlineIcon
                      fontSize={'small'}
                      htmlColor={color.gray_60}
                    />
                    <Typography color={color.gray_80} variant={'bodyMedium'}>
                      {t('buttons.add_product')}
                    </Typography>
                  </Button>
                  <Button onClick={callConfirmModal}>
                    <Svg>
                      <use href={`${Sprite}#icon-delete`}></use>
                    </Svg>
                    <Typography color={color.gray_80} variant={'bodyMedium'}>
                      {t('buttons.clear_list')}
                    </Typography>
                  </Button>
                  <CustomizedSwitches
                    sx={{
                      marginLeft: 'initial',
                      position: 'absolute',
                      bottom: 0,
                    }}
                    label={t('buttons.only_difference')}
                    onChange={val => toggleOnlyDifferencesChar(val)}
                    checked={isDifference}
                  />
                </ComparisonsActionsBox>
                <NavigationWrap>
                  <NavigationSwiperNext ref={navigationNextRef}>
                    <Svg stroke={color.main} width={'35px'} height={'35px'}>
                      <use href={`${Sprite}#icon-arrow-right`}></use>
                    </Svg>
                  </NavigationSwiperNext>
                </NavigationWrap>
                <NavigationWrap>
                  <NavigationSwiperPrev ref={navigationPrevRef}>
                    <Svg stroke={color.main} width={'35px'} height={'35px'}>
                      <use href={`${Sprite}#icon-arrow-left`}></use>
                    </Svg>
                  </NavigationSwiperPrev>
                </NavigationWrap>
              </ComparisonItemHeader>
              <ComparisonItemBody
                colors={{ white: color.white, line: color.line }}
                borderLeft={`1px solid ${color.line}`}
              >
                {properties &&
                  Object.keys(properties).map(key => (
                    <Fragment key={key}>
                      {isDifference ? (
                        properties[key].has_difference && (
                          <ComparisonsCardRow
                            descriptionCharacteristicsBold
                            name={properties[key].name}
                            rowHeight={cardRowHeight[key]}
                          />
                        )
                      ) : (
                        <>
                          <ComparisonsCardRow
                            descriptionCharacteristicsBold
                            name={properties[key].name}
                            rowHeight={cardRowHeight[key]}
                          />
                        </>
                      )}
                    </Fragment>
                  ))}
              </ComparisonItemBody>
            </ComparisonItem>
            <Swiper
              modules={[Scrollbar, Navigation]}
              scrollbar={{ el: scrollBarRef.current, draggable: false }}
              navigation={{
                prevEl: navigationPrevRef.current,
                nextEl: navigationNextRef.current,
              }}
              onInit={swiper => {
                setInit(true);
                setSwiper(swiper);
              }}
              speed={700}
              slidesPerView={'auto'}
              style={{
                width: '100%',
                overflow: 'visible',
                maxWidth: `${containerWidth - 218}px`,
              }}
            >
              {Object.keys(productsDetails).length > 0 &&
                Object.keys(products).map(key => (
                  <SwiperSlide style={{ width: 'auto' }} key={key}>
                    <ComparisonItem width={'356px'} height={'135px'}>
                      <ComparisonItemHeader
                        colors={{ white: color.white, line: color.line }}
                        borderLeft={'none'}
                      >
                        <ComparisonsCardDetails
                          data={products[key]}
                          handelOpenSavedModal={handelOpenSavedModal}
                          handleAddToCart={handleAddToCart}
                          removeProductFromComparisons={
                            removeProductFromComparisons
                          }
                          titleHeight={cardTitleHeight}
                        />
                      </ComparisonItemHeader>
                      <ComparisonItemBody
                        colors={{ white: color.white, line: color.line }}
                        borderLeft={'none'}
                      >
                        {products[key].properties &&
                          Object.keys(products[key].properties).map(
                            property => (
                              <Fragment key={property + key}>
                                {isDifference ? (
                                  properties[property].has_difference && (
                                    <ComparisonsCardRow
                                      name={products[key].properties[property]}
                                      rowHeight={cardRowHeight[property]}
                                    />
                                  )
                                ) : (
                                  <ComparisonsCardRow
                                    name={products[key].properties[property]}
                                    rowHeight={cardRowHeight[property]}
                                  />
                                )}
                              </Fragment>
                            ),
                          )}
                      </ComparisonItemBody>
                    </ComparisonItem>
                  </SwiperSlide>
                ))}
            </Swiper>
          </FlexWrap>
        </CustomScroll>
        <ScrollBarWrap>
          <ScrollBar
            color={color.gray_40}
            className="swiper-scrollbar"
            ref={scrollBarRef}
          ></ScrollBar>
        </ScrollBarWrap>
      </ScrollWrap>
      <Divider />
      {openCreateModal && (
        <ComparisonCreatePdfModal
          open={openCreateModal}
          handleCloseModal={closeComparisonsModal}
          fieldsForCreatePdf={{
            category_id: category?.id,
            only_differences: isDifference,
            products: products,
          }}
          openFileModal={setOpenFileModal}
        />
      )}
      {openFileModal && (
        <ComparisonFileReadyModal
          open={openFileModal}
          handleCloseModal={() => setOpenFileModal(false)}
        />
      )}
    </DetailsWrap>
  );
};

const DetailsWrap = styled.div`
  background-color: ${props => props.bg};
  height: calc(100% - 78px);
  overflow: hidden;
`;

const ListSections = styled.div`
  padding: 4px 42px 4px 20px;
  gap: 4px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow: ${props => props.overflow};
  max-height: ${props => props.maxHeight};
  min-height: 40px;
`;

const ScrollWrap = styled.div`
  height: calc(100% - 20px);
  position: relative;
`;

const FlexWrap = styled.div`
  display: flex;
  min-height: 100%;
`;

const ComparisonItem = styled.div`
  display: flex;
  flex-direction: column;
  width: ${props => (props.width ? props.width : 'auto')};
  min-height: ${props => (props.height ? props.height : '100%')};
  min-width: ${props => props.minWidth};
  z-index: ${props => props.zIndex};
`;

const ComparisonItemHeader = styled.div`
  min-height: 135px;
  border: 1px solid ${props => props.colors.line};
  border-top: none;
  border-left: ${props =>
    props.borderLeft
      ? props.borderLeft
      : `1px solid ${props => props.colors.line}`};
  padding: 16px;
  background-color: ${props => props.colors.white};
  position: sticky;
  top: 0;
`;

const ComparisonItemBody = styled.div`
  border: 1px solid ${props => props.colors.line};
  border-left: ${props => (props.borderLeft ? props.borderLeft : 'none')};
  border-top: none;
  display: flex;
  flex-direction: column;
  background-color: ${props => props.colors.white};
`;

const ComparisonsActionsBox = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  position: relative;
  width: 183px;
  min-height: 102px;
  height: ${props => props.height};
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  cursor: pointer;
`;

const Svg = styled.svg`
  width: ${({ width }) => (width ? width : '20px')};
  height: ${({ height }) => (height ? height : '20px')};
  stroke: ${props => props.stroke};
`;

const NavigationWrap = styled.div`
  width: calc(100vw - 374px);
  height: 1px;
  position: absolute;

  .swiper-button-disabled {
    display: none;
  }
`;

const NavigationSwiperNext = styled.div`
  position: absolute;
  bottom: -32px;
  right: 20px;
  z-index: 1;
  cursor: pointer;
  background-color: ${props => props.color};
`;

const NavigationSwiperPrev = styled.div`
  position: absolute;
  bottom: -32px;
  left: 184px;
  z-index: 3;
  cursor: pointer;
  background-color: ${props => props.color};
`;
const ScrollBarWrap = styled.div`
  width: calc(100% - 218px);
  height: 4px;
  position: absolute;
  right: 0;
  bottom: 18px;
`;
const ScrollBar = styled.div`
  .swiper-scrollbar-drag {
    background-color: ${props => props.color};
  }
`;

ComparisonsDetails.propTypes = {
  handelOpenSavedModal: PropTypes.func.isRequired,
  handleAddToCart: PropTypes.func.isRequired,
  removeProductFromComparisons: PropTypes.func.isRequired,
  handleMoveToCategory: PropTypes.func.isRequired,
  callConfirmModal: PropTypes.func.isRequired,
  openCreateModal: PropTypes.bool.isRequired,
  closeComparisonsModal: PropTypes.func.isRequired,
};
