import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { nanoid } from 'nanoid';
import { serialize } from 'object-to-formdata';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { InviteEmployees } from '@root/components';
import { handleDispatchFetch, validEmail } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import { ButtonBase, OutlineButtonBase } from '@root/ui';

import authOperation from '@redux/auth/auth-operation';

import { Typography } from '@mui/material';

export const InviteEmployeesForm = ({ initData, pathParent }) => {
  const [data, setData] = useState({
    employees: [{ email: null, role: null }],
  });
  const [error, setError] = useState([]);
  const { t } = useTranslation(['registration', 'validation', 'common'], {
    useSuspense: false,
  });

  const dispatch = useDispatch();
  const { navigation } = useNavigation();

  useEffect(() => {
    const { employees } = initData.company;
    setData({
      employees:
        employees.length === 0
          ? [{ email: null, role: null, id: nanoid() }]
          : employees,
    });
  }, [initData]);

  const onChange = (name, i, subName) => value => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy[name][i][subName] = value ? value : null;
    setData(dataCopy);
  };

  const handelSubmit = e => {
    e.preventDefault();

    if (isEmpty()) {
      navigation(`${pathParent}/5`);
    } else {
      if (validation()) return;
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            authOperation.signUpStepFifth({
              formData: serialize(
                {
                  ...data,
                },

                {
                  indices: true,
                  nullsAsUndefineds: true,
                  booleansAsIntegers: true,
                },
              ),
              onResolve,
              onReject,
            }),
          ),
        () => navigation(`${pathParent}/5`),
      );
    }
  };

  const isEmpty = () => {
    let flag = true;
    const { employees } = data;
    employees.forEach(({ email, role }, i) => {
      if (email || role) {
        flag = false;
      }
    });
    return flag;
  };

  const validation = () => {
    let flag = false;
    const { employees } = data;

    const arrError = employees.reduce((acc, employee, i) => {
      const { email } = employee;

      Object.keys(employee).forEach(key => {
        if (employee[key] === null) {
          acc[i] = {
            ...acc[i],
            [key]: {
              init: true,
              text: t('validation:required'),
            },
          };
          flag = true;
        }
      });

      if (email !== null && validEmail(email)) {
        acc[i] = {
          ...acc[i],
          email: {
            init: true,
            text: t('validation:incorrect_email'),
          },
        };
        flag = true;
      }

      return acc;
    }, []);

    setError(arrError);
    return flag;
  };

  const handelAddEmployees = () => {
    const dataCopy = JSON.parse(JSON.stringify(data));
    dataCopy.employees = [
      ...dataCopy.employees,
      { email: null, role: null, id: nanoid() },
    ];
    setData(dataCopy);
  };

  const deleteEmployees = id => {
    const dataCopy = JSON.parse(JSON.stringify(data));

    dataCopy.employees = [...dataCopy.employees].filter(el => el?.id !== id);
    if (dataCopy.employees.length === 0) {
      dataCopy.employees = [{ email: null, role: null, id: nanoid() }];
    }

    setData(dataCopy);
  };

  const { employees } = data;
  return (
    <Wrapper>
      <Form onSubmit={handelSubmit}>
        <Typography variant={'h1'} align={'center'}>
          {t('registration:step_five.tittle')}
        </Typography>
        <Typography
          sx={{ mt: '28px', mb: '28px' }}
          variant={'bodyLarge'}
          align={'center'}
          component={'p'}
        >
          {t('registration:step_five.slogan')}
        </Typography>

        <InviteEmployees
          employees={employees}
          handelAddEmployees={handelAddEmployees}
          onChange={onChange}
          error={error}
          deleteEmployees={deleteEmployees}
        />
        <WrapperRow>
          <Wrapper>
            <OutlineButtonBase
              type="button"
              sx={{ flex: 1, maxWidth: '208px' }}
              onClick={() => {
                navigation(`${pathParent}/3`);
              }}
            >
              <Typography variant={'bodyBoldLarge'} align={'center'}>
                {t('common:buttons.back')}
              </Typography>
            </OutlineButtonBase>

            <ButtonBase
              type="submit"
              size={'large'}
              sx={{ flex: 1, ml: '12px', maxWidth: '208px' }}
            >
              <Typography variant={'bodyBoldLarge'}>
                {t('common:buttons.continue')}
              </Typography>
            </ButtonBase>
          </Wrapper>
        </WrapperRow>
      </Form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 60px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 430px;
`;

const WrapperRow = styled.div`
  margin-top: 28px;
`;

InviteEmployeesForm.propTypes = {
  initData: PropTypes.shape({
    company: PropTypes.shape({
      employees: PropTypes.array,
    }),
  }),
  pathParent: PropTypes.string.isRequired,
};
