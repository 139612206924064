import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Img_Icon } from '@root/assets/svg';

import { Typography, useTheme } from '@mui/material';

export const ImgPrev = ({ src, countImg, onClick }) => {
  const { color } = useTheme();
  return (
    <Box onClick={onClick}>
      <img src={src} alt="" width={53} />
      {countImg > 0 && (
        <CounterWrapper>
          <img src={Img_Icon} alt="Img Icon" width={16} height={16} />
          <Typography
            sx={{ color: color.gray }}
            variant={'bodyBoldExSmall'}
            align={'center'}
            component={'p'}
          >
            {countImg}
          </Typography>
        </CounterWrapper>
      )}
    </Box>
  );
};

const Box = styled.div`
  position: relative;
  padding: 5px 12px;
  width: fit-content;
  max-height: 58px;
  cursor: pointer;
`;

const CounterWrapper = styled.div`
  position: absolute;
  bottom: 3px;
  left: 0;

  display: flex;
  flex-direction: row;
  align-items: center;
`;

ImgPrev.propTypes = {
  src: PropTypes.string.isRequired,
  countImg: PropTypes.number,
  onClick: PropTypes.func,
};
