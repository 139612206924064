import PropTypes from 'prop-types';
import styled from 'styled-components';

import { RequiredStar } from '@root/assets/svg';
import { sanitizeOnlyDigitsAndDot } from '@root/helpers';
import { MessageError } from '@root/ui';

import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { OutlinedInputCustom } from '../style/OutlinedInput';

export const TextInput = ({
  placeholder,
  value = '',
  onChange,
  sx,
  type,
  max,
  refers,
  onBlur = () => {},
  required = false,
  error,
  errorText,
  label,
  labelSx,
  digitsOnly = false,
  digitsAndDotOnly = false,
  styleWrapper,
  endInputIcons,
  withOutSpace = false,
  removeLeadingZero = false,
  labelRowDirection = false,
  labelRowGap = '',
  labelTextVariant = 'bodySmall',
  errorPositionLeft = '170px',
  ...prop
}) => {
  const { color } = useTheme();
  const handelChange = e => {
    const value = e.target.value;
    let valueCopy = value;

    if (digitsOnly) {
      let regexp = /\d/g;
      const valueEx = value.match(regexp);
      if (valueEx) {
        valueCopy = valueEx.join('');
      } else {
        valueCopy = '';
      }
    }

    if (digitsAndDotOnly) {
      valueCopy = sanitizeOnlyDigitsAndDot(value);
    }

    if (removeLeadingZero) {
      valueCopy = valueCopy.replace(/^0+/, '');
    }

    if (withOutSpace) {
      onChange(valueCopy.trim());
    } else {
      onChange(valueCopy);
    }
  };

  const onBlurBefore = e => {
    const value = e.target.value;
    onChange(value.trim());
    onBlur(e);
  };

  return (
    <label
      style={{
        display: labelRowDirection ? 'flex' : 'block',
        gap: labelRowGap ? labelRowGap : 'initial',
        position: labelRowDirection ? 'relative' : 'initial',
        marginBottom: error && errorText && labelRowDirection ? '20px' : '',
        ...styleWrapper,
      }}
    >
      {label && (
        <LabelWrapper isRowDirection={labelRowDirection}>
          {required && (
            <RequiredStarImg
              isRowDirection={labelRowDirection}
              src={RequiredStar}
              alt="Required Star"
            />
          )}
          <Typography
            variant={labelTextVariant}
            sx={{ color: color.gray, ml: '2px' }}
            component={'span'}
          >
            {label}
          </Typography>
        </LabelWrapper>
      )}

      <OutlinedInputCustom
        type={type}
        sx={{
          width: '100%',
          ...sx,
        }}
        value={value === null ? '' : value}
        onChange={e => handelChange(e)}
        onBlur={onBlurBefore}
        placeholder={placeholder}
        autoComplete={'new-password'}
        inputProps={{
          maxLength: max,
          autoComplete: 'off',
          form: {
            autoComplete: 'off',
          },
        }}
        inputRef={refers}
        error={error}
        {...prop}
      />
      {endInputIcons}
      {error && errorText && (
        <MessageError
          errorText={errorText}
          sx={{
            position: labelRowDirection ? 'absolute' : 'initial',
            top: labelRowDirection ? '100%' : '',
            left: labelRowDirection ? errorPositionLeft : '',
          }}
        />
      )}
    </label>
  );
};

const LabelWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  min-width: ${({ isRowDirection }) => (isRowDirection ? '150px' : '')};

  span {
    ${({ isRowDirection }) =>
      isRowDirection
        ? {
            alignSelf: 'center',
            whiteSpace: 'nowrap',
          }
        : {}}
  }
`;

const RequiredStarImg = styled.img`
  margin-top: 3px;
  ${({ isRowDirection }) =>
    isRowDirection
      ? {
          marginTop: 0,
          marginBottom: '3px',
          alignSelf: 'center',
        }
      : {}}
`;

TextInput.propTypes = {
  placeholder: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  sx: PropTypes.object,
  type: PropTypes.string,
  max: PropTypes.number,
  refers: PropTypes.object,
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  error: PropTypes.bool,
  errorText: PropTypes.string,
  label: PropTypes.string,
  labelSx: PropTypes.object,
  digitsOnly: PropTypes.bool,
  digitsAndDotOnly: PropTypes.bool,
  styleWrapper: PropTypes.object,
  endInputIcons: PropTypes.node,
  withOutSpace: PropTypes.bool,
  removeLeadingZero: PropTypes.bool,
  labelRowDirection: PropTypes.bool,
  labelRowGap: PropTypes.string,
  labelTextVariant: PropTypes.string,
  errorPositionLeft: PropTypes.string,
};
