export const AdminSettingMenu = (t, color) => [
  {
    id: 1,
    title: t('common:buttons.view'),
    typographyVariant: 'bodySmall',

    type: 'review',
  },
  {
    id: 2,
    title: t('common:buttons.edit'),
    typographyVariant: 'bodySmall',

    type: 'edit',
  },
  {
    id: 3,
    title: t('common:buttons.archive'),
    typographyVariant: 'bodySmall',

    type: 'archive',
  },
];

export const CommercialOfferSettingMenu = (t, color) => [
  {
    id: 1,
    title: t('common:buttons.edit'),
    typographyVariant: 'bodySmall',
    type: 'edit',
  },
  {
    id: 2,
    title: t('common:buttons.delete'),
    typographyVariant: 'bodySmall',
    color: color.red,
    type: 'delete',
  },
];

export const NewsAndEventsSettingMenu = (t, color) => [
  {
    id: 1,
    title: t('news_and_events:buttons.news_count.key', { count: 1 }),
    typographyVariant: 'bodySmall',
    type: 'news',
  },
  {
    id: 2,
    title: t('news_and_events:buttons.events_count.key', { count: 1 }),
    typographyVariant: 'bodySmall',
    type: 'events',
  },
];
