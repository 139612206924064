import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PricesTooltip } from '@root/components';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const Cost = ({ isHiddenTooltip, onlyUAH, data }) => {
  const { personal_price, price, prices } = data;
  const currency = useSelector(userSelectors.getChoosedCurrency);
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();

  return (
    <Box>
      {personal_price[choosedCurrency] > 0 || price[choosedCurrency] > 0 ? (
        <PricesTooltip prices={prices} isHiddenTooltip={isHiddenTooltip}>
          <TooltipInner>
            {personal_price[choosedCurrency] > 0 && (
              <Typography
                sx={{
                  color: color.gray,
                  display: 'flex',
                  alignItems: 'baseline',
                }}
                variant={'bodyBoldLarge'}
                align={'center'}
                component={'p'}
              >
                {personal_price[choosedCurrency]}
                <Typography
                  sx={{ color: color.gray, ml: '4px', display: 'block' }}
                  variant={'bodyBoldSmall'}
                  align={'center'}
                >
                  {onlyUAH ? t('common:currency') : currency}
                </Typography>
              </Typography>
            )}
            {price[choosedCurrency] > 0 && (
              <Typography
                sx={{ color: color.gray_60, display: 'flex' }}
                variant={'bodyExSmall'}
                align={'center'}
                component={'p'}
              >
                {price[choosedCurrency]}
                <Typography
                  sx={{ color: color.gray_60, ml: '2px', display: 'block' }}
                  variant={'bodyExSmall'}
                  align={'center'}
                >
                  {onlyUAH ? t('common:currency') : currency}
                </Typography>
              </Typography>
            )}
          </TooltipInner>
        </PricesTooltip>
      ) : (
        <Typography
          sx={{
            color: color.gray,
            display: 'flex',
            alignItems: 'baseline',
            width: '90%',
            textAlign: 'left',
          }}
          variant={'bodyBoldMedium'}
          align={'center'}
          component={'p'}
        >
          {t('common:check_price_for_details')}
        </Typography>
      )}
    </Box>
  );
};

const Box = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const TooltipInner = styled.div``;

Cost.propTypes = {
  isHiddenTooltip: PropTypes.bool,
  onlyUAH: PropTypes.bool,
  data: PropTypes.shape({
    personal_price: PropTypes.shape({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.shape({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.shape({
      price: PropTypes.shape({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.shape({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.shape({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.shape({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
  }),
};
