import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Logo } from '@root/components';
import { theme } from '@root/styles';
import { ButtonBase } from '@root/ui';

import { Typography } from '@mui/material';

export const Error = ({
  errorImage,
  errorTitle,
  errorDescription,
  errorButtonText,
  onButtonCLick,
  withLogo = true,
  pageHeight = '100vh',
  errorPageStyles,
}) => {
  return (
    <ErrorWrapper pageHeight={pageHeight} style={{ ...errorPageStyles }}>
      {withLogo && (
        <WrapperLogo>
          <Logo />
        </WrapperLogo>
      )}
      {errorImage && <ErrorImage src={errorImage} />}
      {errorTitle && (
        <Typography variant="h1" color={theme.color.gray} textAlign="center">
          {errorTitle}
        </Typography>
      )}
      {errorDescription && (
        <Typography
          variant="bodyLarge"
          color={theme.color.gray_80}
          textAlign="center"
        >
          {errorDescription}
        </Typography>
      )}
      {errorButtonText && (
        <ButtonBase onClick={onButtonCLick && onButtonCLick}>
          {errorButtonText}
        </ButtonBase>
      )}
    </ErrorWrapper>
  );
};

const ErrorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 28px;
  height: ${props => props.pageHeight};
  width: 100%;
  position: relative;
  padding: 100px;
`;

const ErrorImage = styled.img`
  max-width: 100%;
  height: auto;
`;
const WrapperLogo = styled.div`
  position: absolute;
  top: 23px;
  left: 100px;
`;

Error.propTypes = {
  errorImage: PropTypes.string,
  errorTitle: PropTypes.string,
  errorDescription: PropTypes.string,
  errorButtonText: PropTypes.string,
  onButtonCLick: PropTypes.func,
  withLogo: PropTypes.bool,
  pageHeight: PropTypes.string,
  errorPageStyles: PropTypes.object,
};
