import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import { OutlinedInput, useTheme } from '@mui/material';

export const CounterInput = ({
  data,
  handleDecrement,
  handleAddProduct,
  handleClickOutside,
  inputRef,
  handleEnter,
  inputWidth,
  errorBorder,
  productQuantity,
  handleSetNumberAddToCart,
  isDisabledIncrementBtn,
}) => {
  const { color } = useTheme();

  return (
    <CounterWrapper
      colors={{ white: color.white, line: color.line }}
      border={errorBorder}
      onClick={e => e.preventDefault()}
    >
      <IconBtn
        onClick={handleDecrement}
        disableBorder
        size={8}
        href={`${Sprite}#icon-minus`}
        data-test-id="ButtonDecrementFromCart"
        id={data?.id}
      />
      <form
        onSubmit={e => e.preventDefault()}
        onBlur={handleClickOutside}
        onFocus={e => e.preventDefault()}
      >
        <OutlinedInput
          id="phone-number-input"
          type="number"
          inputRef={inputRef}
          onKeyDown={handleEnter}
          sx={{
            height: 32,
            '&.MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
              border: 'none',
            },
            width: inputWidth,
          }}
          inputProps={{
            notched: 'false',

            style: {
              minWidth: 28,
              padding: 0,
              textAlign: 'center',
            },
          }}
          value={productQuantity}
          onChange={e => handleSetNumberAddToCart(e)}
          data-test-id="InputProductManager"
        />
      </form>
      <IconBtn
        onClick={handleAddProduct}
        disableBorder
        size={10}
        disabled={isDisabledIncrementBtn}
        href={`${Sprite}#icon-plus`}
        data-test-id="ButtonIncrementFromCart"
      />
    </CounterWrapper>
  );
};

const CounterWrapper = styled.div`
  display: flex;
  height: 32px;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 8px;
  margin-bottom: 6px;
  background: ${props => props.colors.white};
  border: ${props =>
    props.border ? '1px solid red' : `1px solid ${props.colors.line}`};
  border-radius: 4px;
`;

CounterInput.propTypes = {
  data: PropTypes.object,
  handleDecrement: PropTypes.func,
  handleAddProduct: PropTypes.func,
  handleClickOutside: PropTypes.func,
  inputRef: PropTypes.object,
  handleEnter: PropTypes.func,
  inputWidth: PropTypes.number,
  errorBorder: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  productQuantity: PropTypes.number,
  handleSetNumberAddToCart: PropTypes.func,
  isDisabledIncrementBtn: PropTypes.bool,
};
