import React from 'react';

import PropTypes from 'prop-types';
import styled from 'styled-components';

export const EditorContentWrapper = ({ editorContent }) => {
  return (
    <div className="ql-editor">
      <HtmlContent dangerouslySetInnerHTML={{ __html: editorContent }} />
    </div>
  );
};

const HtmlContent = styled.div`
  p {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  img {
    display: inline-block;
    width: auto;
    height: auto;
    max-width: 100%;
  }

  ul,
  ol {
    list-style: revert;
    list-style-position: inside;
  }

  strong {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  em {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  ins {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  span {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  code {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  div {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
  }

  a {
    font-family: Roboto, sans-serif !important;
    font-weight: revert !important;
    text-decoration: revert !important;
    color: revert !important;

    span {
      color: inherit !important;
    }
  }

  div[style*='text-align:none'] {
    text-align: center !important;
  }
`;

EditorContentWrapper.propTypes = {
  editorContent: PropTypes.string.isRequired,
};
