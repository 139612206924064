import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { InView } from 'react-intersection-observer';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { EmptyBox } from '@root/assets/svg';
import { Sprite } from '@root/assets/svg';
import {
  FooterDefault,
  HeaderDefault,
  ListSimilarProducts,
} from '@root/components';
import { theme } from '@root/styles';
import { ButtonBase, ModalBase } from '@root/ui';

import { Typography } from '@mui/material';

import { SimilarProductsModal } from './SimilarProductsModal';

export const SimilarProducts = ({
  isEdit,
  setNavSection,
  handleSaveAnalogueProduct,
  addOrRemoveComparisons,
  item,
  relatedScoop,
  openCard,
  choseIdCard,
  handleChoseItem,
  handelDetailCard,
  setRelatedScoop,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(['product_details', 'common'], {
    useSuspense: false,
  });

  return (
    <>
      <InView
        onChange={(inView, entry) =>
          inView && setNavSection('analogue_products')
        }
      >
        {(item?.analogue_products.length > 0 || isEdit) && (
          <WrapperRelated id="analogue_products">
            <FlexTitle>
              <Typography
                variant={'h2'}
                sx={{
                  color: theme.color.gray,
                  ml: '122px',
                }}
              >
                {t('product_details:view_analogous_products')}
              </Typography>
              {isEdit && (
                <ButtonBase
                  sx={{
                    padding: 0,
                    ml: '16px',
                    color: theme.color.gray_80,
                    backgroundColor: 'transparent',

                    '&:hover': {
                      background: 'transparent',
                    },
                  }}
                  onClick={() => {
                    setOpen(true);
                  }}
                >
                  <Svg fill={theme.color.gray_60} width={16} height={16}>
                    <use href={`${Sprite}#icon-pan`}></use>
                  </Svg>
                  <Typography variant={'bodyBoldSmall'}>
                    {t('common:buttons.edit')}
                  </Typography>
                </ButtonBase>
              )}
            </FlexTitle>
            {open && (
              <ModalBase
                open={open}
                handleClose={() => setOpen(false)}
                onMouseDown={e => e.stopPropagation()}
                modalHeader={
                  <HeaderDefault
                    onClose={() => setOpen(false)}
                    text={t('product_details:add_analogous_title')}
                  />
                }
                modalBottom={
                  <FooterDefault
                    conf={[
                      {
                        type: 'outline',
                        text: t('common:buttons.reject'),
                        onClose: () => setOpen(false),
                        form: '',
                      },
                      {
                        type: 'inline',
                        text: t('common:buttons.save'),
                        onClose: () => {},
                        form: 'top_sale',
                      },
                    ]}
                  />
                }
              >
                <SimilarProductsModal
                  data={item?.analogue_products}
                  id={item?.id}
                  handleCloseModal={() => setOpen(false)}
                />
              </ModalBase>
            )}
            {item?.analogue_products.length > 0 ? (
              <ListSimilarProducts
                handelOpenSavedModal={handleSaveAnalogueProduct}
                addOrRemoveFromComparisons={addOrRemoveComparisons}
                data={item?.analogue_products}
                isOpen={relatedScoop}
                productsCategory={t('common:analogues')}
                openCard={openCard}
                choseIdCard={choseIdCard}
                handleChoseItem={handleChoseItem}
                handelDetailCard={handelDetailCard}
                Scoop={relatedScoop}
                setScoop={setRelatedScoop}
              />
            ) : (
              <ListRelated>
                {[{ id: 4 }, { id: 5 }, { id: 6, isLast: true }].map(el => (
                  <EmptyItemRelated
                    key={el.id}
                    theme={theme}
                    isLast={el.isLast}
                  >
                    <img src={EmptyBox} alt="Empty Box" />
                  </EmptyItemRelated>
                ))}
              </ListRelated>
            )}
          </WrapperRelated>
        )}
      </InView>
    </>
  );
};

const WrapperRelated = styled.div`
  margin: 0 -19px;
`;

const Svg = styled.svg`
  margin-right: 5px;
  fill: ${props => props.fill};
`;

const ListRelated = styled.ul`
  display: block;
  padding: 16px 0;
`;
const EmptyItemRelated = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 16px;
  border-top: 1px solid ${({ theme }) => theme.color.line};
  border-bottom: ${({ theme, isLast }) =>
    isLast && `1px solid ${theme.color.line}`};
  border-left: 3px solid #3963e1;
`;

const FlexTitle = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

SimilarProducts.propTypes = {
  isEdit: PropTypes.bool.isRequired,
  data: PropTypes.array.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  setNavSection: PropTypes.func.isRequired,
  handleSaveAnalogueProduct: PropTypes.func.isRequired,
  addOrRemoveComparisons: PropTypes.func.isRequired,
  item: PropTypes.exact({
    analogue_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.exact({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    documents: PropTypes.array,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images: PropTypes.arrayOf(
      PropTypes.exact({
        big: PropTypes.string,
        full: PropTypes.string,
        thumbnail: PropTypes.string,
        thumbnail_second: PropTypes.string,
      }),
    ),
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    property_categories: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        name: PropTypes.string,
        properties: PropTypes.arrayOf(
          PropTypes.exact({
            id: PropTypes.number,
            is_pinned: PropTypes.bool,
            name: PropTypes.string,
            value: PropTypes.string,
          }),
        ),
      }),
    ),
    related_products: PropTypes.arrayOf(
      PropTypes.exact({
        brand: PropTypes.string,
        can_buy: PropTypes.exact({
          price: PropTypes.bool,
          price_uah_cash: PropTypes.bool,
          price_uah_no_pdv: PropTypes.bool,
          price_uah_pdv: PropTypes.bool,
        }),
        cart: PropTypes.exact({
          cart_product_id: PropTypes.number,
          id: PropTypes.number,
          personal_amount: PropTypes.exact({
            price: PropTypes.string,
            price_uah_cash: PropTypes.string,
            price_uah_no_pdv: PropTypes.string,
            price_uah_pdv: PropTypes.string,
          }),
          quantity: PropTypes.number,
        }),
        description: PropTypes.string,
        has_analogs: PropTypes.bool,
        id: PropTypes.number,
        image: PropTypes.string,
        images_quantity: PropTypes.number,
        in_compare: PropTypes.bool,
        is_in_cart: PropTypes.bool,
        is_saved: PropTypes.bool,
        model: PropTypes.string,
        name: PropTypes.string,
        personal_price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        price: PropTypes.exact({
          price: PropTypes.string,
          price_uah_cash: PropTypes.string,
          price_uah_no_pdv: PropTypes.string,
          price_uah_pdv: PropTypes.string,
        }),
        prices: PropTypes.exact({
          price: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_cash: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_no_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
          price_uah_pdv: PropTypes.exact({
            product: PropTypes.string,
            personal: PropTypes.string,
          }),
        }),
        relation_quantity: PropTypes.number,
        sku: PropTypes.string,
        slug: PropTypes.string,
        stock: PropTypes.number,
        stock_max: PropTypes.number,
        stock_percent: PropTypes.number,
        url: PropTypes.string,
        warehouses: PropTypes.arrayOf(
          PropTypes.exact({
            city_name: PropTypes.string,
            warehouses: PropTypes.exact({
              name: PropTypes.string,
              stock: PropTypes.number,
              stock_max: PropTypes.number,
              stock_percent: PropTypes.number,
            }),
          }),
        ),
      }),
    ),
  }),
  relatedScoop: PropTypes.bool.isRequired,
  openCard: PropTypes.bool.isRequired,
  choseIdCard: PropTypes.number.isRequired,
  handleChoseItem: PropTypes.func.isRequired,
  handelDetailCard: PropTypes.func.isRequired,
  setRelatedScoop: PropTypes.func.isRequired,
};
