import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import styled from 'styled-components';

import { CopyToClipboard, StepperWithSpace } from '@root/components';
import { SendingToClientDetailsConf } from '@root/conf';
import { correctTTNNumber, getStepData } from '@root/helpers';
import { ProgressLoader } from '@root/ui';

import logisticSelectors from '@redux/logistic/logistic-selectors';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import userSelectors from '@redux/user/user-selectors';

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const SendingToClient = () => {
  const [npData, setNpData] = useState({
    activeStep: 0,
    steps: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation(['common', 'logistic'], { useSuspense: false });
  const { color } = useTheme();
  const {
    repair_array,
    return_array,
    logistic_service,
    ttn_number,
    phone_number,
  } = useSelector(logisticSelectors.getSelectedDelivery);
  const userNpApiKey = useSelector(userSelectors.getNpApiKey);
  const dispatch = useDispatch();

  useEffect(() => {
    if (logistic_service === 'Нова Пошта') {
      (async function () {
        setIsLoading(true);
        const npObjData = await getStepData(
          userNpApiKey,
          ttn_number,
          phone_number,
          t,
        );
        setNpData(npObjData);
        setIsLoading(false);
      })();
    } else {
      setNpData({
        active: 0,
        steps: [],
      });
    }
  }, [ttn_number]);

  const splitTTNNUmberByPattern = correctTTNNumber(ttn_number);

  const ttnNumberWithoutLastFourNumbers = splitTTNNUmberByPattern.slice(0, -4);
  const ttnNumberLastFourNumbers = splitTTNNUmberByPattern.slice(-4);
  const handleOpenNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: 'Номер скопійовано',
      }),
    );
  };

  return (
    <>
      {logistic_service === 'Нова Пошта' && (
        <DeliveryDetailsInner>
          <Typography
            component="p"
            variant="bodyBoldSmall"
            color={color.gray_60}
            marginBottom="12px"
          >
            {t('logistic:details_of_route')}
          </Typography>

          <CopyToClipboard
            onCopySuccess={handleOpenNotification}
            copyText={splitTTNNUmberByPattern}
          >
            <Typography variant="bodyLarge" color={color.gray}>
              {ttnNumberWithoutLastFourNumbers}
              <Typography variant="bodyBoldLarge" color={color.gray}>
                {ttnNumberLastFourNumbers}
              </Typography>
            </Typography>
          </CopyToClipboard>
          {npData.steps.length > 0 && !isLoading ? (
            <StepperWithSpaceWrapper>
              <StepperWithSpace
                activeStep={npData.activeStep}
                steps={npData.steps}
              />
            </StepperWithSpaceWrapper>
          ) : (
            <div style={{ height: '150px' }}>
              <ProgressLoader position={'relative'} />
            </div>
          )}
        </DeliveryDetailsInner>
      )}
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow
              sx={{
                borderTop:
                  logistic_service === 'Нова Пошта' &&
                  `1px solid ${color.line}`,
              }}
            >
              {SendingToClientDetailsConf(t).map(detail => (
                <TableCell key={detail.slug} sx={{ ...detail.style }}>
                  <Typography
                    variant="bodyBoldSmall"
                    component="p"
                    color={color.gray_60}
                  >
                    {detail.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {[...repair_array, ...return_array].map(data => (
              <TableRow key={data.id}>
                {SendingToClientDetailsConf(t).map((detail, index) => {
                  switch (detail.slug) {
                    case 'id':
                      return (
                        <TableCell
                          key={detail.slug + data.id}
                          sx={{ ...detail.style }}
                        >
                          <Typography
                            variant="bodySmall"
                            component="p"
                            color={color.gray_80}
                          >
                            {index + 1}
                          </Typography>
                        </TableCell>
                      );
                    case 'name':
                      return (
                        <TableCell
                          key={detail.slug + data.id}
                          sx={{ ...detail.style }}
                        >
                          <Typography
                            variant="bodySmall"
                            component="p"
                            color={color.gray_80}
                          >
                            {data.name}
                          </Typography>
                        </TableCell>
                      );
                    case 'amount':
                      return (
                        <TableCell
                          key={detail.slug + data.id}
                          sx={{ ...detail.style }}
                        >
                          <Typography
                            variant="bodySmall"
                            component="p"
                            color={color.gray_80}
                          >
                            {data.qty}
                          </Typography>
                        </TableCell>
                      );
                    case 'unit_of_measurement':
                      return (
                        <TableCell
                          key={detail.slug + data.id}
                          sx={{ ...detail.style }}
                        >
                          <Typography
                            variant="bodySmall"
                            component="p"
                            color={color.gray_80}
                          >
                            {data.unit}
                          </Typography>
                        </TableCell>
                      );
                  }
                })}
              </TableRow>
            ))}
            <TableRow></TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

SendingToClient.propTypes = {};

const DeliveryDetailsInner = styled.div`
  padding: 30px 20px;
`;

const StepperWithSpaceWrapper = styled.div`
  margin-top: 12px;
`;
