import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { ImgPrev, LabelProduct, SettingsMenu } from '@root/components';
import { CommercialOfferSettingMenu } from '@root/conf';
import { IconBtn } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const SmallProductCard = ({
  productDetails,
  handleEdit,
  handleClickMenu,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation('commercial_offer', { useSuspense: false });

  const { picture, model, quantity, price, warranty, total, type, name, id } =
    productDetails;

  const isService = type === 'service';

  const calculateTotalSum = useMemo(
    () => () => {
      return (Number(price) * Number(quantity)).toFixed(2);
    },
    [price, quantity],
  );

  return (
    <CardWrap color={color} isService={isService}>
      <CardLeftSide>
        {!isService && <ImgPrev src={picture} />}
        <LabelProduct
          onlyCO={true}
          label={!isService ? model : name}
          payment={{
            price: price,
            quantity: quantity,
          }}
        />
      </CardLeftSide>
      <CardRightSide>
        <Typography color={color.gray} variant="bodyBoldMedium">
          {calculateTotalSum()} {t('details.currency')}
        </Typography>
        <CardSubWrap gap={'12px'}>
          <Typography
            sx={{
              whiteSpace: 'nowrap',
            }}
            color={color.gray}
            variant={'bodyBoldMedium'}
          >
            {warranty ? warranty : 0} {t('details.month')}
          </Typography>
          {type === 'base' ? (
            <IconBtn
              href={`${Sprite}#icon-pan`}
              onClick={() => handleEdit(type, id)}
              size={16}
              tooltipText={t('common:tooltip.edit')}
            />
          ) : (
            <SettingsMenu
              id={id}
              handelClickCard={(menuType, id) =>
                handleClickMenu(menuType, id, type)
              }
              verticalDots={true}
              menuList={CommercialOfferSettingMenu}
              menuButtonSx={{ minWidth: 'initial' }}
              menuBodyProp={{
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'right',
                },
              }}
            />
          )}
        </CardSubWrap>
      </CardRightSide>
    </CardWrap>
  );
};

const CardWrap = styled.div`
  padding: 12px 16px 12px 10px;
  display: flex;
  align-items: center;
  gap: 22px;
  border-bottom: 1px solid ${({ color }) => color.line};
  border-left: ${({ isService, color }) =>
    isService ? `3px solid ${color.main}` : 'none'};
`;

const CardLeftSide = styled.div`
  display: flex;
  align-items: center;
  min-width: 250px;
`;

const CardRightSide = styled.div`
  display: flex;
  align-items: center;
  gap: 35px;
  margin-left: auto;
`;

const CardSubWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

SmallProductCard.propTypes = {
  productDetails: PropTypes.shape({
    id: PropTypes.number,
    model_id: PropTypes.number,
    name: PropTypes.string,
    picture: PropTypes.string,
    price: PropTypes.string,
    quantity: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    total: PropTypes.string,
    warranty: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    model: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
  }),
  handleEdit: PropTypes.func.isRequired,
  handleClickMenu: PropTypes.func.isRequired,
};
