import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';

import { Cost, ImgPrev, LabelProduct, Managing } from '@root/components';
import { savedOrdersConf } from '@root/conf/SavedOrdersConf';
import { theme } from '@root/styles';
import { CustomScroll } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';

export const SavedOrderProducts = ({
  data,
  handleDeleteProduct,
  handleAddProductToCart,
}) => {
  const choosedCurrency = useSelector(
    userSelectors.getChoosedCurrencyWidget,
  ).field_name;
  const preventDefault = e => e.preventDefault();
  const { lang } = useParams();
  const currentLang = lang ? `/${lang}` : '';

  const { t } = useTranslation(['common', 'order_history'], {
    useSuspense: false,
  });

  return (
    <TableContainer component={Paper} sx={{ maxHeight: '327px' }}>
      <CustomScroll vertical={true}>
        <Table size="small" aria-label="a saved orders table">
          <TableBody sx={{ display: 'block' }}>
            {data.map(row => (
              <TableRow
                key={'pe' + row.id}
                sx={{
                  minHeight: '109px',
                  borderBottom: `1px solid ${theme.color.line}`,
                  userSelect: 'auto',
                  boxShadow: 'none',
                  th: {
                    borderBottom: 'none',
                  },
                  display: 'flex',
                  alignItems: 'center',
                  padding: '20px',
                }}
              >
                {savedOrdersConf.map(el => {
                  switch (el?.value) {
                    case 'product':
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          key={'si' + row.id + el?.value}
                          sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                        >
                          <NavLink to={currentLang + row?.product.url}>
                            <ImgPrev
                              src={row?.product.image}
                              countImg={row?.product.images_quantity}
                            />
                          </NavLink>
                        </TableCell>
                      );

                    case 'name':
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          key={'si' + row.id + el?.value}
                          sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                        >
                          <NavLink to={currentLang + row?.product.url}>
                            <LabelProduct
                              label={row?.product.model}
                              article={row?.product.sku}
                              isAvailable
                              canBuy={row?.product.stock !== 0}
                            />
                          </NavLink>
                        </TableCell>
                      );

                    case 'cost':
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          key={'si' + row.id + el?.value}
                          sx={{ p: 0, ...el.style, cursor: 'pointer' }}
                        >
                          <Cost data={row.product} />
                        </TableCell>
                      );
                    case 'quantity':
                      return (
                        <TableCell
                          component="th"
                          scope="row"
                          key={'si' + row.id + el?.value}
                          sx={{
                            p: 0,
                            ...el.style,
                          }}
                        >
                          <Typography
                            variant="bodyNormalSmall"
                            color={theme.color.gray_80}
                          >
                            {row?.quantity} {t('order_history:piece')}
                          </Typography>
                        </TableCell>
                      );
                    case 'managing':
                      return (
                        <TableCell
                          onClick={preventDefault}
                          component="th"
                          scope="row"
                          key={'si' + row.id + el?.value}
                          sx={{
                            p: 0,
                            ...el.style,
                          }}
                        >
                          <Managing
                            canBuy={row?.product.can_buy[choosedCurrency]}
                            onAddToCartProduct={() =>
                              handleAddProductToCart(
                                row.product_id,
                                row.quantity,
                              )
                            }
                            onDeleteProduct={() =>
                              handleDeleteProduct(row.product_id)
                            }
                          />
                        </TableCell>
                      );
                    default:
                      return '';
                  }
                })}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CustomScroll>
    </TableContainer>
  );
};

SavedOrderProducts.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  handleDeleteProduct: PropTypes.func.isRequired,
  handleAddProductToCart: PropTypes.func.isRequired,
};
