import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart } from 'react-minimal-pie-chart';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Typography, useTheme } from '@mui/material';

export const Availability = ({
  inStock,
  notAvailable,
  present,
  warehouseId,
  warehouses,
}) => {
  const { color } = useTheme();
  const subColorMenage = stockValue =>
    stockValue > 70
      ? color.green_light
      : stockValue <= 30
      ? color.red_light
      : color.orange_light;

  const colorMenage = stockValue =>
    stockValue > 70 ? color.green : stockValue <= 30 ? color.red : color.orange;

  const checkAvailableQuantity = (stock, notAvailable) => {
    const stockRes = stock > 999 ? 999 + '+' : stock;
    const notAvailableRes = notAvailable > 999 ? 999 + '+' : notAvailable;
    return stockRes + '/' + notAvailableRes;
  };

  const { t } = useTranslation(['common'], { useSuspense: false });
  return (
    <>
      <Component>
        <AvailabilityBox>
          <PieChart
            style={{
              width: '16px',
              height: '16px',
            }}
            data={[
              {
                title: '',
                value: present,
                color: colorMenage(present),
              },
              {
                title: '',
                value: 100 - present,
                color: subColorMenage(present),
              },
            ]}
            animate={false}
            lineWidth={40}
            startAngle={-90}
          />
          {present === 0 ? (
            <Typography
              variant={'bodySmall'}
              sx={{ color: color.gray, ml: '4px' }}
            >
              {t('common:not_available')}
            </Typography>
          ) : (
            <Typography
              variant={'bodySmall'}
              sx={{ color: color.gray, ml: '4px' }}
            >
              {checkAvailableQuantity(inStock, notAvailable)}
            </Typography>
          )}
        </AvailabilityBox>
      </Component>
      {warehouseId &&
        warehouses.map(
          item =>
            item.id === warehouseId && (
              <StorageBox>
                <Typography variant={'bodyExSmall'} sx={{ color: color.gray }}>
                  {item.name} :
                  <Typography
                    variant={'bodyBoldExSmall'}
                    sx={{ color: colorMenage(item.stock_percent), ml: '2px' }}
                  >
                    {item.stock}
                  </Typography>
                </Typography>
              </StorageBox>
            ),
        )}
    </>
  );
};

const Component = styled.div``;

const AvailabilityBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const StorageBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 4px;
`;

Availability.propTypes = {
  inStock: PropTypes.number,
  notAvailable: PropTypes.number,
  present: PropTypes.number,
  warehouseId: PropTypes.number,
  warehouses: PropTypes.arrayOf(PropTypes.object),
};
