import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useParams } from 'react-router-dom';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Logo } from '@root/components';
import { getNavMenu } from '@root/conf';
import { BasicTooltip } from '@root/ui';

import authSelectors from '@redux/auth/auth-selectors';
import userSelectors from '@redux/user/user-selectors';

import { useTheme } from '@mui/material/styles';

export const SideBar = () => {
  const userRole = useSelector(authSelectors.getRole);
  const getUserProfile = useSelector(userSelectors.getUserProfile);
  const permission = useSelector(userSelectors.getPermissions);
  const { pathname } = useLocation();
  const { t } = useTranslation(['common']);
  const { lang } = useParams();
  const { logo_small } = useSelector(userSelectors.getUserCompanyConf);
  const currentPath = lang ? pathname.split(`/${lang}`).join('') : pathname;
  const parentFolder = currentPath.split('/')[1];
  const currentLang = lang ? `/${lang}` : '';
  const { color } = useTheme();

  const handleClick = (e, isActive) => {
    if (isActive) {
      e.preventDefault();
    }
  };

  return useMemo(() => {
    return (
      <NavBox color={color}>
        <ul>
          <Item visible={true}>
            <WrapperLogo color={color}>
              <Logo isSmall src={logo_small} sx={{ height: '29px' }} />
            </WrapperLogo>
          </Item>
          {getNavMenu(t, getUserProfile.has_guid)
            .filter(({ permission_key }) => {
              if (permission[permission_key] || permission_key === 'exception')
                return true;
            })
            .map(({ path, icon, name, role, visible, dataTestId }) => {
              const navPath = path.split('/')[1];
              const isActive =
                parentFolder === 'saved'
                  ? `/${navPath}` === `/catalog`
                  : `/${navPath}` === `/${parentFolder}`;
              return (
                <Item
                  key={path}
                  visible={!!(role.includes(userRole) && visible !== false)}
                  data-test-id={dataTestId}
                >
                  <BasicTooltip
                    title={name}
                    placement="right"
                    sx={{ span: { color: `${color.menu} !important` } }}
                  >
                    <Wrapper
                      color={color}
                      isActive={isActive} //spike because saved page is deferent component accordion to catalog page
                    >
                      <NavLink
                        to={currentLang + path}
                        onClick={e => handleClick(e, isActive)}
                      >
                        <Svg color={color} width="24" height="24">
                          <use href={`${Sprite}#${icon}`}></use>
                        </Svg>
                      </NavLink>
                    </Wrapper>
                  </BasicTooltip>
                </Item>
              );
            })}
        </ul>
      </NavBox>
    );
  }, [color, parentFolder]);
};

const NavBox = styled.nav`
  width: 56px;
  min-height: 450px;
  height: 100%;

  background: ${prop => prop.color.menu};
`;

const Item = styled.li`
  display: ${props => (props.visible ? 'block' : 'none')};
`;

const WrapperLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 18px 7px 20px 7px;
  border-bottom: 1px solid ${prop => prop.color.line_light};
`;

const Wrapper = styled.div`
  padding: 16px;
  stroke: ${prop =>
    prop.isActive ? prop.color.white : prop.color.menu_icon_color};
  background: ${prop =>
    prop.isActive &&
    `linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),${({
      color,
    }) => color.menu} `};

  &:hover {
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
      ${({ color }) => color.menu};
    stroke: ${prop => prop.color.white};
  }
`;

const Svg = styled.svg``;
