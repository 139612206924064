import * as React from 'react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { AutocompleteCategorySelect } from '@root/components/Selects';
import { categoryList } from '@root/conf';
import { BasicSelect, Chip, TextInput } from '@root/ui';

import categorySelectors from '@redux/category/category-selectors';

import { Box, Skeleton, Typography, useTheme } from '@mui/material';

export const EditModeInput = ({
  isEdit,
  children,
  type,
  conf,
  isLoading = false,
}) => {
  const category = useSelector(categorySelectors.getCategoryListToEdit);

  const { color } = useTheme();
  const { t } = useTranslation(['product_details'], { useSuspense: false });

  const renderSelectedValue = (selectedId, placeholder, isGroup) => {
    if (typeof selectedId !== 'string') {
      if (isGroup) {
        return categoryList(category).map(parent =>
          parent.id === selectedId ? (
            <Typography
              variant={'bodyBoldMedium'}
              component={'span'}
              sx={{
                color: `${color.gray} !important`,
              }}
            >
              {parent.name}
            </Typography>
          ) : (
            parent.children.map(
              child =>
                child.id === selectedId && (
                  <div key={parent.id + child.id}>
                    <Typography
                      variant={'bodyBoldMedium'}
                      component={'span'}
                      sx={{
                        color: `${color.gray} !important`,
                      }}
                    >
                      {child?.name}{' '}
                    </Typography>
                    <Typography
                      variant={'bodyMedium'}
                      component={'span'}
                      sx={{ color: `${color.gray_80} !important` }}
                    >
                      {t('product_details:in_the_category')}
                    </Typography>
                    <Typography
                      variant={'bodyBoldMedium'}
                      component={'span'}
                      sx={{ color: `${color.gray_80} !important`, ml: '5px' }}
                    >
                      {parent?.name}
                    </Typography>
                  </div>
                ),
            )
          ),
        );
      } else {
        return conf.options.find(item => item.id === selectedId)?.name;
      }
    } else {
      return (
        <Typography
          variant={'bodyMedium'}
          component={'span'}
          sx={{ color: color.gray_60 }}
        >
          {placeholder}
        </Typography>
      );
    }

  };

  const getInput = useMemo(
    () => () => {
      const { onChange, value, error, key, placeholder } = conf;

      switch (type) {
        case 'input':
          return (
            <TextInput
              error={error?.[key]?.init}
              errorText={error?.[key]?.text}
              id="input-with-icon-adornment"
              sx={{
                width: '420px',
                height: '36px',
                input: {
                  fontSize: '0.875rem',
                },
              }}
              styleWrapper={{
                display: 'flex',
                flexDirection: 'column',
              }}
              placeholder={placeholder}
              max={100}
              onChange={onChange(key)}
              value={value}
              required={true}
            />
          );

        case 'select':
          return (
            <BasicSelect
              error={error?.[key]?.init}
              errorText={error?.[key]?.text}
              height={36}
              currentId={value}
              data={conf.options}
              callBack={onChange(key)}
              renderValue={value =>
                renderSelectedValue(value, placeholder, conf?.group)
              }
              customItem={conf?.customItem}
              group={conf?.group}
              sx={{
                width: '420px',
                height: '36px',
                input: {
                  fontSize: '0.875rem',
                },
              }}
            />
          );

        case 'multipleSelect':
          return (
            <AutocompleteCategorySelect
              options={conf.options}
              callBack={onChange(key)}
              value={value}
              placeholder={placeholder}
            />
          );

        default:
          break;
      }
    },
    [conf, type],
  );

  return (
    <Wrapper>
      {isLoading ? (
        <Box sx={{ width: 300 }}>
          <Skeleton animation="wave" />
        </Box>
      ) : isEdit ? (
        getInput()
      ) : (
        children
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  max-width: 420px;
`;
