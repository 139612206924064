import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, Header, VirtualizedTable } from '@root/components';
import { MutualSettlementsConf } from '@root/conf';
import { LabelStatus, ProgressLoader } from '@root/ui';

import mutualSettlementsSelectors from '@redux/mutual-settlements/mutual-settlements-selectors';
import {
  setSearchValue,
  setSelectMutualSettlement,
} from '@redux/mutual-settlements/mutual-settlements-slice';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const MutualSettlementsHistory = ({
  selectedValue,
  onCloseSelect,
  activeRadioBtn,
  setActiveRadioBtn,
  handleFetchMutualSettlementsHistory,
  getSelectedValue,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [activeRow, setActiveRow] = useState();

  const { t } = useTranslation(['mutual_settlements']);
  const { color } = useTheme();

  const mutualSettlements = useSelector(
    mutualSettlementsSelectors.getMutualSettlements,
  );
  const mutualSettlementsTypes = useSelector(
    mutualSettlementsSelectors.getMutualSettlementsTypes,
  );
  const loading = useSelector(
    mutualSettlementsSelectors.getIsMutualSettlementsLoading,
  );

  const filteredMutualSettlements = useSelector(
    mutualSettlementsSelectors.getFilteredMutualSettlements,
  );

  const searchTerm = useSelector(mutualSettlementsSelectors.getSearchValue);

  const dispatch = useDispatch();

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: false,
      defaultHeight: 34,
    }),
  );

  const tableBodyHeight = `calc(100vh - 285px - ${isExpanded ? 56 : 0}px - ${
    isFilterExpanded ? 232.5 : 0
  }px)`;

  const noHistoryHeight = `calc(100vh - 251px - ${isExpanded ? 56 : 0}px - ${
    isFilterExpanded ? 232.5 : 0
  }px)`;

  const handleToggleSearchExpended = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  const handleToggleFilterExpended = () => {
    setIsFilterExpanded(prevIsFilterExpanded => !prevIsFilterExpanded);
  };

  const onSearchTermUpdate = searchValue => {
    dispatch(setSearchValue(searchValue));
  };

  const handleSetActiveRow = data => {
    const { number } = data;
    setActiveRow(number);
    dispatch(setSelectMutualSettlement(data));
  };

  const handleFetchMutualSettlements = ({ date_from, date_to }) => {
    handleFetchMutualSettlementsHistory({
      date_from,
      date_to,
      type: activeRadioBtn,
      document_type: selectedValue,
    });
  };

  const MutualSettlementsTable = () => {
    return (
      <VirtualizedTable
        isLoading={false}
        tableConf={MutualSettlementsConf(t)}
        checkIsInclude={false}
        isTopBorder={false}
      >
        <TableBody
          sx={{
            height: tableBodyHeight,
            display: 'block',
          }}
        >
          <ScrollWrapper
            colors={{
              gray: color.gray_40,
              white: color.white,
              line: color.line,
            }}
          >
            <AutoSizer>
              {({ width, height }) => (
                <List
                  width={width}
                  height={height}
                  rowHeight={cache.current.rowHeight}
                  defferedMeasurementCache={cache.current}
                  rowCount={filteredMutualSettlements.length}
                  rowRenderer={({ key, index, style, parent }) => {
                    const data = filteredMutualSettlements[index];

                    return (
                      <CellMeasurer
                        key={key}
                        cache={cache.current}
                        parent={parent}
                        columnIndex={0}
                        rowIndex={index}
                      >
                        <TableRow
                          onClick={() => handleSetActiveRow(data)}
                          sx={{
                            minHeight: '34px',
                            paddingTop: '5px',
                            paddingBottom: '5px',
                            borderBottom: `1px solid ${color.line}`,
                            '&:last-child, &:last-child td': {
                              borderBottom: 0,
                            },
                            userSelect: 'auto',
                            boxShadow: 'none',
                            th: {
                              borderBottom: 'none',
                            },
                            display: 'flex',
                            alignItems: 'center',
                            padding: '4px 16px',
                            cursor: 'pointer',
                            background:
                              activeRow === data.number
                                ? color.main_light
                                : color.white,
                            borderLeft:
                              activeRow === data.number
                                ? `3px solid ${color.main}`
                                : 'none',

                            '&:hover': {
                              background:
                                activeRow !== data.number && color.gray_20,
                            },
                          }}
                          style={style}
                        >
                          {MutualSettlementsConf(t).map(delivery => {
                            switch (delivery.slug) {
                              case 'date':
                                return (
                                  <TableCell
                                    sx={{
                                      borderBottom: 'none',
                                      ...delivery.style,
                                      p: 0,
                                    }}
                                    key={delivery.slug + key}
                                  >
                                    <Typography
                                      variant="bodySmall"
                                      color={color.gray_80}
                                    >
                                      {data.date}
                                    </Typography>
                                  </TableCell>
                                );

                              case 'number':
                                return (
                                  <TableCell
                                    sx={{
                                      borderBottom: 'none',
                                      ...delivery.style,
                                      p: 0,
                                    }}
                                    key={delivery.slug + key}
                                  >
                                    <Typography
                                      variant="bodyBoldSmall"
                                      color={color.gray}
                                    >
                                      {data.number}
                                    </Typography>
                                  </TableCell>
                                );

                              case 'document_type':
                                return (
                                  <TableCell
                                    sx={{
                                      borderBottom: 'none',
                                      ...delivery.style,
                                      p: 0,
                                    }}
                                    key={delivery.slug + key}
                                  >
                                    <LabelStatus
                                      styles={{
                                        display: 'flex',
                                      }}
                                    >
                                      <Typography
                                        color={`${color.gray_80} !important`}
                                        variant="bodyBoldExSmall"
                                      >
                                        {data.document_type}
                                      </Typography>
                                    </LabelStatus>
                                  </TableCell>
                                );

                              case 'expenses':
                                return (
                                  <TableCell
                                    sx={{
                                      borderBottom: 'none',
                                      ...delivery.style,
                                      p: 0,
                                    }}
                                    key={delivery.slug + key}
                                  >
                                    <Typography
                                      variant="bodySmall"
                                      color={color.gray}
                                    >
                                      {data.money_minus !== 0 &&
                                        data.money_minus}
                                    </Typography>
                                  </TableCell>
                                );

                              case 'income':
                                return (
                                  <TableCell
                                    sx={{
                                      borderBottom: 'none',
                                      ...delivery.style,
                                      p: 0,
                                    }}
                                    key={delivery.slug + key}
                                  >
                                    <Typography
                                      variant="bodySmall"
                                      color={color.gray}
                                    >
                                      {data.money_plus > 0 && data.money_plus}
                                    </Typography>
                                  </TableCell>
                                );
                            }
                          })}
                        </TableRow>
                      </CellMeasurer>
                    );
                  }}
                />
              )}
            </AutoSizer>
          </ScrollWrapper>
        </TableBody>
      </VirtualizedTable>
    );
  };

  const additionalContent = () => {
    return (
      <Balance>
        <BalanceItem>
          <Typography color={color.gray_60} variant="bodyExSmall">
            {t('mutual_settlements:initial_balance')}
          </Typography>
          <Typography color={color.gray} variant="bodyBoldExSmall">
            {mutualSettlements.length && mutualSettlements[0].start_balance}
          </Typography>
        </BalanceItem>
        <BalanceItem>
          <Typography color={color.gray_60} variant="bodyExSmall">
            {t('mutual_settlements:ending_balance')}
          </Typography>
          <Typography color={color.gray} variant="bodyBoldExSmall">
            {mutualSettlements.length &&
              mutualSettlements[mutualSettlements.length - 1].end_balance}
          </Typography>
        </BalanceItem>
      </Balance>
    );
  };

  return (
    <MutualSettlementsHistoryWrapper
      colorBorder={color.line}
      colorBg={color.white}
    >
      <Header
        useFilter={true}
        isFilterExpanded={isFilterExpanded}
        isExpanded={isExpanded}
        handleFetchDeliveryHistory={handleFetchMutualSettlements}
        handleToggleSearchExpended={handleToggleSearchExpended}
        handleToggleFilterExpended={handleToggleFilterExpended}
        isTopBorder={false}
        isBottomBorder={true}
        onSearchTermUpdate={onSearchTermUpdate}
        additionalContent={additionalContent}
        selectData={mutualSettlementsTypes}
        getSelectedValue={val => getSelectedValue(val)}
        selectedValue={selectedValue}
        activeRadioBtn={activeRadioBtn}
        setActiveRadioBtn={setActiveRadioBtn}
        onCloseSelect={onCloseSelect}
      />
      {loading && <ProgressLoader />}
      {filteredMutualSettlements.length > 0 && MutualSettlementsTable()}

      {searchTerm === '' && filteredMutualSettlements.length === 0 && (
        <NoHistoryWrapper height={noHistoryHeight}>
          <Empty type={'NoHistory'} />
        </NoHistoryWrapper>
      )}

      {searchTerm !== '' && filteredMutualSettlements.length === 0 && (
        <NoHistoryWrapper height={noHistoryHeight}>
          <Empty type={'EmptySearchLogo'} />
        </NoHistoryWrapper>
      )}
    </MutualSettlementsHistoryWrapper>
  );
};

const MutualSettlementsHistoryWrapper = styled.div`
  flex: 0 0 50%;
  border-radius: 4px;
  border: ${({ colorBorder }) => `1px solid ${colorBorder}`};
  background: ${({ colorBg }) => colorBg};
  position: relative;
  height: 100%;
`;

const NoHistoryWrapper = styled.div`
  height: ${({ height }) => height};
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  height: inherit;
  max-height: inherit;

  div {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => props.colors.gray};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => props.colors.white};
      border-left: 1px solid ${props => props.colors.line};
    }
  }
`;

const Balance = styled.div`
  display: flex;
  column-gap: 10px;
  padding-left: 11px;
  padding-bottom: 11px;
`;

const BalanceItem = styled.div`
  width: 120px;
  display: flex;
  column-gap: 10px;
  padding-left: 12px;
`;

MutualSettlementsHistory.propTypes = {
  selectedValue: PropTypes.arrayOf(PropTypes.string),
  activeRadioBtn: PropTypes.string,
  setActiveRadioBtn: PropTypes.func.isRequired,
  handleFetchMutualSettlementsHistory: PropTypes.func.isRequired,
};
