import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { getErrorStack } from '@root/helpers';
import { validEmail, validURL } from '@root/helpers';

export const useCommercialOffer = () => {
  const [data, setData] = useState({
    logo: null,
    is_delete_file: false,
    company_name: '',
    company_type_id: '',
    contractor_code: '',
    identification_code: '',
    phone: '',
    city: '',
    address: '',
    zip_code: '',
    contact_person: '',
    site: '',
    email: '',
  });
  const [error, setError] = useState({});
  const { t } = useTranslation('validation', { useSuspense: false });

  const onChange = name => value => {
    setData(prevState => ({ ...prevState, [name]: value }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const {
      logo,
      company_name,
      phone,
      city,
      address,
      site,
      email,
      company_type_id,
      contractor_code,
      identification_code,
    } = data;

    if (site) {
      if (!validURL(site)) {
        currentError = getErrorStack(
          currentError,
          'site',
          t('validation:enter_valid_url'),
        );
        flag = true;
      }
    }

    if (validEmail(email) && email !== '') {
      currentError = getErrorStack(
        currentError,
        'email',
        t('validation:incorrect_email'),
      );
      flag = true;
    }

    Object.keys({
      company_name,
      phone,
      city,
      address,
      company_type_id,
    }).forEach(key => {
      if (!data[key] || data[key] === '') {
        currentError = getErrorStack(
          currentError,
          key,
          t('validation:required'),
        );
        flag = true;
      }
    });

    if (phone?.length < 12 && phone !== '') {
      currentError = getErrorStack(
        currentError,
        'phone',
        t('validation:input_phone_number'),
      );
      flag = true;
    }

    if (logo) {
      if (logo?.size > 6291456) {
        currentError = getErrorStack(
          currentError,
          'logo',
          t('validation:file_size'),
        );
        flag = true;
      }
    }

    if (company_type_id === 1) {
      if (!contractor_code || contractor_code === '') {
        currentError = getErrorStack(
          currentError,
          'contractor_code',
          t('validation:required'),
        );
        flag = true;
      } else if (contractor_code.length < 8) {
        currentError = getErrorStack(
          currentError,
          'contractor_code',
          t(`${t('validation:minLengthLetters')} 8`),
        );
        flag = true;
      }
    }

    if (company_type_id === 2) {
      if (
        (!identification_code || identification_code === '') &&
        company_type_id !== ''
      ) {
        currentError = getErrorStack(
          currentError,
          'identification_code',
          t('validation:required'),
        );
        flag = true;
      } else if (identification_code.length < 10) {
        currentError = getErrorStack(
          currentError,
          'identification_code',
          t(`${t('validation:minLengthLetters')} 10`),
        );
        flag = true;
      }
    }

    setError(currentError);

    return flag;
  };

  return { data, setData, error, setError, onChange, validation };
};
