import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { useOrder } from '@root/hooks';
import { ButtonBase, ModalBase, OutlineButtonBase } from '@root/ui';

import orderOperation from '@redux/order/order-operation';

import { Typography } from '@mui/material';

import { FormPaymentInfo } from '../FormPaymentInfo';
import { servicesValidation } from '../FormPersonalInfo/DeliveryClientInfo/Validation/validation';

export const ModalPaymentInfo = ({
  open,
  toggleOpenModal,
  available_methods,
  currentObj,
  flag = 'New',
  openAllList,
}) => {
  const { onChangeRadio, data, setData, onChange, validationPayment, error } =
    useOrder({
      servicesValidation,
    });
  const { t } = useTranslation(['common', 'placing_order'], {
    useSuspense: false,
  });

  const dispatch = useDispatch();

  useEffect(() => {
    if (flag === 'New') return;

    const { key, id, info } = currentObj;

    setData({
      ...data,
      id,
      payment_type: key,
      payment_info: Array.isArray(info) ? data.payment_info : info,
    });
  }, []);

  const handelSubmit = e => {
    e.preventDefault();
    if (validationPayment()) return;
    const { payment_type, payment_info } = data;
    switch (flag) {
      case 'edit':
        dispatch(
          orderOperation.editSaveOrderTemplate({
            orderData: { payment_type, payment_info },
            entity: 'payment',
            id: data.id,
          }),
        );
        break;

      default:
        dispatch(
          orderOperation.addSaveOrderTemplate({
            orderData: { payment_type, payment_info },
            entity: 'payment',
          }),
        );
        break;
    }

    toggleOpenModal();
    openAllList();
  };

  const { payment_type, payment_info } = data;

  return (
    <ModalBase
      open={open}
      handleClose={toggleOpenModal}
      sx={{
        maxWidth: '600px',
        width: '100%',
      }}
      modalHeader={
        <HeaderDefault
          text={t(
            'placing_order:modals.order_payment_template_modal.order_payment_template',
          )}
          onClose={toggleOpenModal}
        />
      }
      modalBottom={
        <WrapperBtn>
          <OutlineButtonBase
            sx={{ p: '11px 8px' }}
            onClick={toggleOpenModal}
            data-test-id="CancelPaymentModalButton"
          >
            <Typography variant={'bodyBoldSmall'}>
              {t('common:buttons.cancel')}
            </Typography>
          </OutlineButtonBase>

          <ButtonBase
            data-test-id="ApplyPaymentModalButton"
            onClick={handelSubmit}
            type="submit"
            size={'large'}
            sx={{
              minWidth: '68px',
              height: '38px',
              ml: '10px',
              p: '11px 8px',
            }}
          >
            <Typography variant={'bodyBoldSmall'}>
              {t('common:buttons.save')}
            </Typography>
          </ButtonBase>
        </WrapperBtn>
      }
      dataTestId="OpenedPaymentModal"
    >
      <Wrapper>
        <InfoClient>
          <FormPaymentInfo
            payment_type={payment_type}
            onChangeRadio={onChangeRadio}
            available_methods={available_methods}
            onChange={onChange}
            payment_info={payment_info}
            error={error}
          />
        </InfoClient>
      </Wrapper>
    </ModalBase>
  );
};

const WrapperBtn = styled.div`
  display: flex;
  justify-content: flex-end;

  padding-top: 20px;
`;

const Wrapper = styled.div`
  min-height: 601px;
`;
const InfoClient = styled.div`
  padding: 2px;
`;

ModalPaymentInfo.propTypes = {
  open: PropTypes.bool.isRequired,
  toggleOpenModal: PropTypes.func.isRequired,
  available_methods: PropTypes.exact({
    delivery_types: PropTypes.arrayOf(
      PropTypes.exact({
        id: PropTypes.number,
        items: PropTypes.arrayOf(
          PropTypes.exact({
            icon: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            key: PropTypes.string,
            subTitle: PropTypes.string,
            title: PropTypes.string,
          }),
        ),
        key: PropTypes.string,
        label: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    payment_types: PropTypes.arrayOf(
      PropTypes.exact({
        icon: PropTypes.string,
        id: PropTypes.number,
        isDefault: PropTypes.bool,
        key: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
  currentObj: PropTypes.object,
  flag: PropTypes.string,
  openAllList: PropTypes.func.isRequired,
};
