import { createSlice } from '@reduxjs/toolkit';

import logisticOperation from './logistic-operation';

const initialState = {
  deliveryHistoryMeta: {},
  isLogisticHistoryLoading: false,
  selectedDelivery: null,
  isLogisticHistoryError: false,
  logisticHistoryTextError: '',
  logisticHistory: null,
  searchValue: '',
  searchLogisticHistory: [],
  isLogisticDetailsLoading: false,
  isLogisticDetailsError: false,
  logisticDetailsTextError: '',
  errorOneC: false,
};

const logisticSlice = createSlice({
  name: 'logistic',
  initialState,
  reducers: {
    setSelectedDelivery: (state, { payload }) => {
      state.selectedDelivery = payload;
    },
    onClearLogistic: state => {
      state.logisticHistory = initialState.logisticHistory;
      state.selectedDelivery = initialState.selectedDelivery;
      state.errorOneC = initialState.errorOneC;
    },
    setSearchValue: (state, { payload }) => {
      state.searchValue = payload;
    },
  },
  extraReducers: {
    //====getDeliveryHistoryFromClient====//
    [logisticOperation.getDeliveryHistoryFromClient.pending]: (
      state,
      { payload },
    ) => {
      state.isLogisticHistoryLoading = true;
      state.logisticHistoryTextError = false;
      state.logisticHistoryTextError = '';
      state.selectedDelivery = null;
    },
    [logisticOperation.getDeliveryHistoryFromClient.fulfilled]: (
      state,
      { payload },
    ) => {
      state.isLogisticHistoryLoading = false;
      state.logisticHistoryTextError = false;
      state.logisticHistoryTextError = '';
      state.logisticHistory = payload;
    },
    [logisticOperation.getDeliveryHistoryFromClient.rejected]: (
      state,
      { payload },
    ) => {
      const { data } = payload;
      state.isLogisticHistoryLoading = false;
      state.logisticHistoryTextError = true;
      state.logisticHistoryTextError = payload;
      state.errorOneC = data.error_key === '1C_OUT';
    },
    //====getDeliveryHistorySendingToClient====//
    [logisticOperation.getDeliveryHistorySendingToClient.pending]: (
      state,
      { payload },
    ) => {
      state.isLogisticHistoryLoading = true;
      state.logisticHistoryTextError = false;
      state.logisticHistoryTextError = '';
      state.selectedDelivery = null;
      state.errorOneC = false;
    },
    [logisticOperation.getDeliveryHistorySendingToClient.fulfilled]: (
      state,
      { payload },
    ) => {
      state.isLogisticHistoryLoading = false;
      state.logisticHistoryTextError = false;
      state.logisticHistoryTextError = '';
      state.errorOneC = false;
      state.logisticHistory = payload;
    },
    [logisticOperation.getDeliveryHistorySendingToClient.rejected]: (
      state,
      { payload },
    ) => {
      const { data } = payload;
      state.isLogisticHistoryLoading = false;
      state.logisticHistoryTextError = true;
      state.logisticHistoryTextError = payload;
      state.errorOneC = data.error_key === '1C_OUT';
    },
    //=========getDeliveryDetailsById=========//
    [logisticOperation.getDeliveryDetailsById.pending]: (
      state,
      { payload },
    ) => {
      state.isLogisticDetailsLoading = true;
      state.isLogisticDetailsError = false;
      state.logisticDetailsTextError = '';
    },
    [logisticOperation.getDeliveryDetailsById.fulfilled]: (
      state,
      { payload },
    ) => {
      state.isLogisticHistoryLoading = false;
      state.logisticHistoryTextError = false;
      state.logisticHistoryTextError = '';

      state.logisticDetails = payload;
    },
    [logisticOperation.getDeliveryDetailsById.rejected]: (
      state,
      { payload },
    ) => {
      const { data } = payload;
      state.isLogisticDetailsLoading = false;
      state.isLogisticDetailsError = true;
      state.logisticDetailsTextError = payload;
      state.errorOneC = data.error_key === '1C_OUT';
    },

    //=========createNewDelivery=========//
    [logisticOperation.createNewDelivery.pending]: (state, { payload }) => {
      state.isLogisticDetailsLoading = true;
    },
    [logisticOperation.createNewDelivery.fulfilled]: (state, { payload }) => {
      state.logisticHistory = payload;
    },
    [logisticOperation.createNewDelivery.rejected]: (state, { payload }) => {
      const { data } = payload;
      state.isLogisticDetailsLoading = false;
      state.errorOneC = data.error_key === '1C_OUT';
    },
  },
});

export const {
  setSelectedDelivery,
  onClearLogistic,
  setSearchValue,
  resetDeliveriesSearch,
} = logisticSlice.actions;

export default logisticSlice.reducer;
