import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { Notifications } from '@root/components';
import { useNavigation } from '@root/hooks';
import { ButtonWithoutBorder, IconBtn, MenuBody, MenuButton } from '@root/ui';

import notificationsSelectors from '@redux/notifications/notifications-selectors';

import { Typography, useTheme } from '@mui/material';

export const NotificationsWidget = () => {
  const { t } = useTranslation('common');
  const [anchorEl, setAnchorEl] = useState(null);

  const hasNotViewedNotifications = useSelector(
    notificationsSelectors.getHasNotViewedNotifications,
  );

  const { navigation } = useNavigation();
  const { color } = useTheme();

  const handleMoveToNotificationsPage = () => {
    handleClosePopUp();
    navigation('/notifications');
  };

  const handleOpenPopUp = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePopUp = () => {
    setAnchorEl(null);
  };
  return (
    <NotificationsWidgetWrapper>
      <MenuButton
        open={!!anchorEl}
        handleClick={handleOpenPopUp}
        sx={{ position: 'relative' }}
      >
        <UnreadNotificationsDotWrapper
          color={color}
          hasNotViewedNotifications={hasNotViewedNotifications}
        >
          <Svg
            color={color}
            hasNotViewedNotifications={hasNotViewedNotifications}
          >
            <use href={`${Sprite}#icon-bell`} />
          </Svg>
        </UnreadNotificationsDotWrapper>
      </MenuButton>

      <MenuBody
        anchorEl={anchorEl}
        open={!!anchorEl}
        handleClose={handleClosePopUp}
        sx={{ maxWidth: '430px', width: '100%', padding: '6px 0 0 0' }}
        anchorOriginPosition={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOriginPosition={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <Notifications
          containerHeight="calc(100vh - 443px)"
          handleClosePopUp={handleClosePopUp}
          baseLoader={true}
        />
        <NotificationsFooter borderColor={color.line}>
          <ButtonWithoutBorder onClick={handleMoveToNotificationsPage}>
            <Typography variant="bodyBoldSmall" color={color.gray}>
              {t('notifications.view_all')}
            </Typography>
          </ButtonWithoutBorder>

          <IconBtn onClick={() => {}} href={`${Sprite}#icon-settings`} />
        </NotificationsFooter>
      </MenuBody>
    </NotificationsWidgetWrapper>
  );
};

const NotificationsFooter = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px 10px 12px;
  border-top: 1px solid ${({ borderColor }) => borderColor};
`;

const NotificationsWidgetWrapper = styled.div``;

const Svg = styled.svg`
  width: 24px;
  height: 24px;
  transform-origin: center;
  stroke: ${({ hasNotViewedNotifications, color }) =>
    hasNotViewedNotifications ? color.main : color.gray_60};
  position: relative;
`;

const UnreadNotificationsDotWrapper = styled.div`
  &::before {
    content: '';
    display: ${({ hasNotViewedNotifications }) =>
      hasNotViewedNotifications ? 'block' : 'none'};
    width: 5px;
    height: 5px;
    background: ${({ color }) => color.main};
    border-radius: 50%;
    position: absolute;
    top: 0;
    right: 18px;
    z-index: 1000;
  }
`;
