import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { ExchangeRateModal } from './ExchnageRateModal';
import { SettingsExchangeRateModal } from './SettingsExchnageRateModal';

export const ExchangeRate = () => {
  const currencyRates = useSelector(userSelectors.getCurrencyRates);

  const [isSettingsModalOpen, setIsSettingsModalOpen] = useState(false);
  const [isExchangeRatesModalOpen, setIsExchangeRatesModalOpen] =
    useState(false);

  const handleOpenSettingsModal = () => {
    // setIsSettingsModalOpen(true);
  };
  const handleCloseSettingsModal = () => setIsSettingsModalOpen(false);

  const handleOpenExchangeRatesModal = () => {
    // setIsExchangeRatesModalOpen(true);
  };
  const handleCloseExchangeRatesModal = () =>
    setIsExchangeRatesModalOpen(false);

  const { t } = useTranslation(['header'], { useSuspense: false });
  const { color } = useTheme();

  return (
    <ExchangeBox>
      <Typography
        sx={{ color: `${color.gray} !important` }}
        variant={'bodySmall'}
        component={'p'}
        color={color.gray}
        alignSelf="flex-start"
      >
        {t('header:widget_rate.exchange_rate')}
      </Typography>
      <Wrapper onClick={handleOpenExchangeRatesModal}>
        {currencyRates.map(currencyRate => (
          <Typography
            sx={{ display: 'flex', color: `${color.gray_60} !important` }}
            variant={'bodySmall'}
            component={'p'}
            key={currencyRate.public_name}
          >
            {currencyRate.public_name}
            <Typography
              sx={{
                color: `${color.gray} !important`,
                ml: '4px',
                display: 'block',
              }}
              variant={'bodyBoldSmall'}
            >
              {currencyRate.rate}
            </Typography>
          </Typography>
        ))}
      </Wrapper>

      {/*<IconBtn*/}
      {/*  href={`${Sprite}#icon-edit`}*/}
      {/*  sx={{ marginLeft: '8px' }}*/}
      {/*  onClick={handleOpenSettingsModal}*/}
      {/*/>*/}

      {isSettingsModalOpen && (
        <SettingsExchangeRateModal
          isOpen={isSettingsModalOpen}
          handleCloseModal={handleCloseSettingsModal}
        />
      )}

      {isExchangeRatesModalOpen && (
        <ExchangeRateModal
          isOpen={isExchangeRatesModalOpen}
          handleCloseModal={handleCloseExchangeRatesModal}
        />
      )}
    </ExchangeBox>
  );
};

const ExchangeBox = styled.div`
  display: flex;
  align-items: flex-end;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 9px;
  cursor: pointer;
`;

const Svg = styled.svg`
  width: 16px;
  height: 16px;
  margin-left: 8px;
`;
