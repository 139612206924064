export const categoryList = filters => {
  return filters
    .filter(item => item.can_delete)
    .map(item => {
      return {
        id: item.id,
        name: item.name,
        children: item.children,
        slug: item.slug,
      };
    });
};

export const FiltersConf = [
  {
    label: 'Назва',
    value: 'name',
    style: { maxWidth: '154px', width: '100%' },
  },
  {
    label: 'Категорія',
    value: 'category',
    style: { ml: '15px', mr: '15px', maxWidth: '211px', width: '100%' },
  },
  {
    label: 'Значення',
    value: 'value',
    style: { maxWidth: '492px', width: '100%' },
  }, // change
  {
    label: 'Порядок сорт.',
    value: 'order-sort',
    style: {
      textAlign: 'center',
      maxWidth: '85px',
      width: '100%',
      ml: 'auto',
      mr: '5px',
    },
  }, // change
  {
    label: '',
    value: 'edit',
    style: { maxWidth: '82px', width: '100%', textAlign: 'center' },
  }, // change
  {
    label: '',
    value: 'remove',
    style: { maxWidth: '82px', width: '100%', textAlign: 'center' },
  }, // change
];

export const WarehouseConf = [
  {
    label: 'Назва',
    value: 'name',
    style: { maxWidth: '205px', width: '100%' },
  },
  {
    label: 'Місто',
    value: 'city',
    style: { ml: '15px', mr: '15px', maxWidth: '200px', width: '100%' },
  },
  {
    label: 'Публічна назва',
    value: 'public_name',
    style: { maxWidth: '200px', width: '100%' },
  }, // change
  {
    label: 'Guid',
    value: 'guid',
    style: { maxWidth: '388px', width: '100%' },
  }, // change
  {
    label: 'Порядок сорт.',
    value: 'order-sort',
    style: {
      textAlign: 'center',
      maxWidth: '85px',
      width: '100%',
      ml: 'auto',
      mr: '5px',
    },
  }, // change
  {
    label: '',
    value: 'edit',
    style: { maxWidth: '82px', width: '100%', textAlign: 'center' },
  }, // change
  {
    label: '',
    value: 'remove',
    style: { maxWidth: '82px', width: '100%', textAlign: 'center' },
  }, // change
];

export const ActionBtns = permission => [
  {
    title: 'Фільтр',
    value: 'filters',
    isHide: false,
  },
  {
    title: 'Склади',
    value: 'werehouse',
    isHide: !permission['product_stock_access'],
  },
];
