import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { getAccountOptionList, getPayerTypeList } from '@root/conf';
import { correctPhone } from '@root/helpers/phoneMask';
import { IconBtn, OutlineButtonBase, RadioCustom } from '@root/ui';

import {
  clearModalState,
  setOpenModal,
} from '@redux/modal-watcher/modal-watcher-slice';
import { choseTemplate } from '@redux/order/order-slice';
import userSelectors from '@redux/user/user-selectors';

import { FormControlLabel, Typography, useTheme } from '@mui/material';

export const CartItem = ({
  type = 'delivery',
  data,
  edit,
  clone,
  removeItem,
  pin,
  hidePin = false,
  hideDellBtn = false,
}) => {
  const { color } = useTheme();
  const dispatch = useDispatch();
  const { t } = useTranslation(['common', 'placing_order'], {
    useSuspense: false,
  });
  const currencyRates = useSelector(userSelectors.getCurrencyRates)[0];
  const accountOption = getAccountOptionList(t, currencyRates);
  const payerTypeOption = getPayerTypeList(t);

  const getMurkUp = () => {
    const { title } = data;
    switch (type) {
      case 'delivery':
        const { name, middle_name, surname, phone, address } = data?.info;
        return (
          <ContentWrapper>
            {' '}
            <Box>
              <div>
                {' '}
                <Typography
                  variant={'bodyBoldSmall'}
                  sx={{ color: color.grey }}
                  component={'h4'}
                >
                  {surname} {name} {middle_name}
                </Typography>
                <Typography
                  variant={'bodySmall'}
                  sx={{ color: color.grey }}
                  component={'p'}
                >
                  {correctPhone(phone)}
                </Typography>
              </div>

              <div>
                <Typography
                  variant={'bodyBoldSmall'}
                  sx={{ color: color.grey }}
                  component={'h4'}
                >
                  {title}
                </Typography>
                <Typography
                  variant={'bodySmall'}
                  sx={{ color: color.grey }}
                  component={'p'}
                >
                  {address}
                </Typography>
              </div>
            </Box>
          </ContentWrapper>
        );

      case 'payment':
        const { key, info } = data;
        const isBill = key === 'Bill';

        return (
          <ContentWrapper>
            {' '}
            <Box>
              <div>
                {' '}
                <Typography
                  variant={'bodyBoldSmall'}
                  sx={{ color: color.grey }}
                  component={'h4'}
                >
                  {title}
                </Typography>
                {isBill && <>{getMurkBill(info)}</>}
              </div>
            </Box>
          </ContentWrapper>
        );

      default:
        break;
    }
  };

  const getMurkBill = ({
    account_option,
    payer_type,
    name_organization,
    contractor_code,
    name_fop_organization,
    identification_code,
    send_invoice,
    email,
    full_name,
  }) => {
    const currentAccountType = accountOption.find(
      el => el.value === account_option,
    ).label;
    const currentPayerType = payerTypeOption.find(
      el => el.value === payer_type,
    ).label;
    const infoPayer =
      payer_type === 'legal_entity'
        ? `${name_organization}/${contractor_code}`
        : payer_type === 'individual_entrepreneur'
        ? `${name_fop_organization}/${identification_code}`
        : full_name;

    return (
      <>
        <Typography
          variant={'bodySmall'}
          sx={{ color: color.grey }}
          component={'p'}
        >
          {t(
            'placing_order:modals.my_saved_payment_templates_modal.type_of_bill',
          )}
        </Typography>
        <Typography
          variant={'bodySmall'}
          sx={{ color: color.grey }}
          component={'p'}
        >
          {currentAccountType}
        </Typography>

        <Typography
          variant={'bodyBoldSmall'}
          sx={{ color: color.grey, mt: '8px' }}
          component={'p'}
        >
          {currentPayerType}
        </Typography>
        <Typography
          variant={'bodySmall'}
          sx={{ color: color.grey }}
          component={'p'}
        >
          {infoPayer}
        </Typography>
        {send_invoice && (
          <>
            {' '}
            <Typography
              variant={'bodyBoldSmall'}
              sx={{ color: color.grey, mt: '8px' }}
              component={'p'}
            >
              {t(
                'placing_order:modals.my_saved_payment_templates_modal.send_invoice_to_email',
              )}
            </Typography>
            <Typography
              variant={'bodySmall'}
              sx={{ color: color.grey }}
              component={'p'}
            >
              {email}
            </Typography>
          </>
        )}
      </>
    );
  };

  const { is_pinned, id, is_selected } = data;
  return (
    <Wrapper color={color}>
      <FormControlLabel
        value={data.id}
        control={
          <RadioCustom
            sx={{ pt: 0 }}
            checked={is_selected}
            onClick={() => dispatch(choseTemplate({ id, type }))}
          />
        }
        componentsProps={{}}
        disableTypography={true}
        sx={{
          display: 'flex',
          alignItems: 'flex-start',
          width: '70%',
        }}
        label={getMurkUp()}
      />

      <ActionWrapper>
        <div>
          {[
            {
              icon: 'icon-pan',
              action: edit,
              hide: false,
              tooltipText: t('common:tooltip.edit'),
            },
            {
              icon: 'icon-copy',
              action: clone,
              hide: false,
              tooltipText: t('common:tooltip.copy'),
            },
            {
              icon: 'icon-delete',
              action: () => {
                dispatch(
                  setOpenModal({
                    open: true,
                    key: 'confirm',
                    isHiddenHeader: true,
                    inactiveBackdrop: true,
                    dataForConfirm: {
                      id: data.id,
                      entity: type,
                    },
                    callback: data => {
                      removeItem(data);
                      dispatch(clearModalState());
                    },
                  }),
                );
              },
              hide: hideDellBtn,
              tooltipText: t('common:tooltip.remove'),
            },
          ].map(({ icon, action, hide, tooltipText }, idx) => (
            <OutlineButtonBase
              key={icon}
              data-test-id="EditOrderButton"
              sx={{
                height: '36px',
                minWidth: '36px !important',
                alignItems: 'center',
                marginLeft: idx !== 0 && '9px',
                display: hide ? 'none' : 'inline',
              }}
              onClick={() => {
                action();
              }}
              tooltipText={tooltipText}
            >
              <svg height={16} width={16}>
                <use href={`${Sprite}#${icon}`}></use>
              </svg>
            </OutlineButtonBase>
          ))}
        </div>
        {!hidePin && (
          <IconBtn
            size="16px"
            href={is_pinned ? `${Sprite}#icon-is-pinned` : `${Sprite}#icon-pin`}
            onClick={pin}
            sx={{}}
            tooltipText={t('common:tooltip.pin')}
          />
        )}
      </ActionWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.li`
  display: flex;
  justify-content: space-between;

  padding: 16px;

  background: ${({ color }) => color.gray_20};
  border-radius: 4px;
  min-height: 108px;
`;

const ContentWrapper = styled.div`
  width: 70%;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  flex: 1;
`;

const Box = styled.div`
  div + div {
    margin-top: 10px;
  }
`;

CartItem.propTypes = {
  type: PropTypes.string,
  data: PropTypes.shape({
    entity: PropTypes.string,
    id: PropTypes.number,
    info: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.shape({
        address: PropTypes.string,
        delivery_info: PropTypes.shape({
          city_ref: PropTypes.string,
          city_title: PropTypes.string,
          date: PropTypes.string,
          department_ref: PropTypes.string,
          department_title: PropTypes.string,
          elevator: PropTypes.bool,
          flat_num: PropTypes.string,
          floor_num: PropTypes.string,
          npPhone: PropTypes.string,
          post_office_ref: PropTypes.string,
          post_office_title: PropTypes.string,
          street_number: PropTypes.string,
          street_title: PropTypes.string,
          time: PropTypes.string,
          type: PropTypes.string,
        }),
        is_drop: PropTypes.bool,
        middle_name: PropTypes.string,
        name: PropTypes.string,
        phone: PropTypes.string,
        surname: PropTypes.string,
      }),
    ]),
    is_pinned: PropTypes.number,
    is_selected: PropTypes.bool,
    key: PropTypes.string,
    title: PropTypes.string,
    subTitle: PropTypes.string,
  }),
  edit: PropTypes.func.isRequired,
  clone: PropTypes.func.isRequired,
  removeItem: PropTypes.func.isRequired,
  pin: PropTypes.func.isRequired,
  hidePin: PropTypes.bool,
  hideDellBtn: PropTypes.bool,
};
