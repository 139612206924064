import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonBase, CheckboxBase, CustomScroll, TextArea } from '@root/ui';

import { Divider, Typography, useTheme } from '@mui/material';

import { FormPaymentInfo } from './FormPaymentInfo';
import { FormPersonalInfo } from './FormPersonalInfo';
import { ModalPaymentInfo } from './ModalPaymentInfo';
import { ModalPersonalInfo } from './ModalPersonalInfo';
import { WrapperSection } from './WrapperSection';

// InfoWrapper move to component when we have a address list

export const InfoClientMurk = ({
  error,
  data,
  onChange,
  onChangeRadio,
  available_methods,
  handleSelectFilial,
  handleCardRadio,
  setData,
  delivery,
  payment,
}) => {
  const { color } = useTheme();

  const { payment_type, note, dont_call, payment_info } = data;

  const { t } = useTranslation(['placing_order'], { useSuspense: false });

  return (
    <InfoWrapper>
      <CustomScroll vertical={true}>
        <ColorWrapper color={color.white}>
          {delivery.length > 0 ? (
            <WrapperSection
              title={t('placing_order:delivery_address')}
              btnAllListTitle={t('placing_order:buttons.view_all_list')}
              addNewTitle={t('placing_order:buttons.add_new_delivery_address')}
              modalDetails={({
                open,
                toggleOpenModal,
                currentObj,
                flag,
                openAllList,
              }) => (
                <ModalPersonalInfo
                  open={open}
                  toggleOpenModal={toggleOpenModal}
                  available_methods={available_methods}
                  currentObj={currentObj}
                  flag={flag}
                  openAllList={openAllList}
                />
              )}
              renderData={delivery}
              available_methods={available_methods}
              sectionType={'delivery'}
              dataTestId="DeliveryAddressList"
            />
          ) : (
            <>
              <Typography
                variant={'h3'}
                sx={{ color: color.gray, p: '26px 0 16px 24px' }}
                component={'h2'}
              >
                {t('placing_order:delivery_address')}
              </Typography>
              <Divider />

              <InfoClient>
                <Box gap={0}>
                  <FormPersonalInfo
                    data={data}
                    error={error}
                    onChange={onChange}
                    onChangeRadio={onChangeRadio}
                    handleSelectFilial={handleSelectFilial}
                    handleCardRadio={handleCardRadio}
                    setData={setData}
                    available_methods={available_methods}
                  />
                </Box>
              </InfoClient>
            </>
          )}
          {payment.length > 0 ? (
            <WrapperSection
              title={t('placing_order:payment')}
              btnAllListTitle={t('placing_order:buttons.view_all_list')}
              addNewTitle={t('placing_order:buttons.add_order_payment_option')}
              modalDetails={({
                open,
                toggleOpenModal,
                currentObj,
                flag,
                openAllList,
              }) => (
                <ModalPaymentInfo
                  open={open}
                  toggleOpenModal={toggleOpenModal}
                  available_methods={available_methods}
                  currentObj={currentObj}
                  flag={flag}
                  openAllList={openAllList}
                />
              )}
              renderData={payment}
              available_methods={available_methods}
              sectionType={'payment'}
              dataTestId="PaymentOptionsList"
            />
          ) : (
            <Box gap={20}>
              <Divider />
              <Typography
                variant={'h3'}
                sx={{ color: color.gray, p: '19px 0 19px 24px' }}
                component={'h2'}
              >
                {t('placing_order:payment')}
              </Typography>
              <Divider />
              <InfoClient>
                <FormPaymentInfo
                  payment_type={payment_type}
                  onChangeRadio={onChangeRadio}
                  available_methods={available_methods}
                  payment_info={payment_info}
                  error={error}
                  onChange={onChange}
                />
              </InfoClient>
            </Box>
          )}

          <Box gap={20}>
            <Divider />
            <Typography
              variant={'h3'}
              sx={{ color: color.gray, p: '19px 24px 19px 24px' }}
              component={'h2'}
            >
              {t('placing_order:additional')}
            </Typography>
            <Divider />
            <InfoClient>
              <TextArea
                placeholder={t('placing_order:fields.comment_to_order')}
                sx={{ width: '100%' }}
                value={note}
                onChange={onChange('note')}
                max={500}
              />
              <CoordinationWrapper>
                <CheckboxBase
                  width={16}
                  onChange={onChange('dont_call')}
                  checked={dont_call}
                >
                  <Typography
                    sx={{ ml: '8px' }}
                    variant={'bodyMedium'}
                    component={'p'}
                  >
                    {t('placing_order:checkboxes.sure_about_order_not_call_me')}
                  </Typography>
                </CheckboxBase>
              </CoordinationWrapper>
            </InfoClient>
          </Box>
          <Box gap={10}>
            <BtnWrapper>
              <ButtonBase
                type="submit"
                size={'large'}
                sx={{ flex: 1, maxWidth: '240px' }}
                data-test-id="ConfirmOrderButton"
              >
                <Typography variant={'bodyBoldLarge'}>
                  {t('placing_order:buttons.confirm_order')}
                </Typography>
              </ButtonBase>
            </BtnWrapper>
          </Box>
        </ColorWrapper>
      </CustomScroll>
    </InfoWrapper>
  );
};

const Box = styled.div`
  margin-top: ${({ gap }) => gap}px;
`;

const ColorWrapper = styled.div`
  background: ${props => props.color};
`;

const InfoWrapper = styled.div`
  height: calc(100% - 60px);
  width: 58%;
  border-radius: 4px;

  li + li {
    margin-top: 10px;
  }
`;

const InfoClient = styled.div`
  padding: 16px 24px 16px 24px;
`;

const CoordinationWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: 16px;
`;

const BtnWrapper = styled.div`
  padding: 0 24px 60px 24px;
`;

InfoClientMurk.propTypes = {
  error: PropTypes.object,
  data: PropTypes.shape({
    delivery_filial: PropTypes.string,
    delivery_info: PropTypes.shape({
      city_ref: PropTypes.string,
      city_title: PropTypes.string,
      date: PropTypes.string,
      department_ref: PropTypes.string,
      department_title: PropTypes.string,
      elevator: PropTypes.bool,
      flat_num: PropTypes.string,
      floor_num: PropTypes.string,
      npPhone: PropTypes.string,
      post_office_ref: PropTypes.string,
      post_office_title: PropTypes.string,
      street_number: PropTypes.string,
      street_title: PropTypes.string,
      time: PropTypes.string,
      type: PropTypes.string,
    }),
    delivery_type: PropTypes.string,
    discount: PropTypes.string,
    dont_call: PropTypes.bool,
    id: PropTypes.string,
    its_drop: PropTypes.bool,
    middle_name: PropTypes.string,
    name: PropTypes.string,
    payment_type: PropTypes.string,
    phone: PropTypes.string,
    surname: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  onChangeRadio: PropTypes.func.isRequired,
  available_methods: PropTypes.shape({
    delivery_types: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        items: PropTypes.arrayOf(
          PropTypes.shape({
            icon: PropTypes.string,
            id: PropTypes.number,
            isDefault: PropTypes.bool,
            key: PropTypes.string,
            subTitle: PropTypes.string,
            title: PropTypes.string,
          }),
        ),
        key: PropTypes.string,
        label: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
    payment_types: PropTypes.arrayOf(
      PropTypes.shape({
        icon: PropTypes.string,
        id: PropTypes.number,
        isDefault: PropTypes.bool,
        key: PropTypes.string,
        subTitle: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }),
  handleSelectFilial: PropTypes.func.isRequired,
  handleCardRadio: PropTypes.func.isRequired,
  setData: PropTypes.func.isRequired,
  delivery: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.string,
      id: PropTypes.number,
      info: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.shape({
          address: PropTypes.string,
          delivery_info: PropTypes.shape({
            city_ref: PropTypes.string,
            city_title: PropTypes.string,
            date: PropTypes.string,
            department_ref: PropTypes.string,
            department_title: PropTypes.string,
            elevator: PropTypes.bool,
            flat_num: PropTypes.string,
            floor_num: PropTypes.string,
            npPhone: PropTypes.string,
            post_office_ref: PropTypes.string,
            post_office_title: PropTypes.string,
            street_number: PropTypes.string,
            street_title: PropTypes.string,
            time: PropTypes.string,
            type: PropTypes.string,
          }),
          is_drop: PropTypes.bool,
          middle_name: PropTypes.string,
          name: PropTypes.string,
          phone: PropTypes.string,
          surname: PropTypes.string,
        }),
      ]),

      is_pinned: PropTypes.number,
      is_selected: PropTypes.bool,
      key: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
  payment: PropTypes.arrayOf(
    PropTypes.shape({
      entity: PropTypes.string,
      id: PropTypes.number,
      info: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
      is_pinned: PropTypes.number,
      is_selected: PropTypes.bool,
      key: PropTypes.string,
      subTitle: PropTypes.string,
      title: PropTypes.string,
    }),
  ),
};
