import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Double_Check, Sprite } from '@root/assets/svg';
import { correctPhone } from '@root/helpers';
import { BasicTooltip, IconBtn } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const Phone = ({ variant, lastStep, value, sx, onEdit, goToStep }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();
  return (
    <>
      <FieldDescription>
        <WrapperBox>
          {value &&
            value.map(({ phone, confirm }, i) => (
              <PhoneWrapper key={i + 200}>
                <Typography
                  sx={{ flexShrink: 0, mr: '8px', ...sx }}
                  variant={variant}
                  component={'p'}
                >
                  {lastStep
                    ? phone
                      ? correctPhone(phone)
                      : '-'
                    : correctPhone(phone)}
                </Typography>
                {confirm && (
                  <BasicTooltip
                    title={t('common:tooltip.confirm')}
                    placement="top"
                  >
                    <img src={Double_Check} alt="Double Check" />
                  </BasicTooltip>
                )}
              </PhoneWrapper>
            ))}
        </WrapperBox>
      </FieldDescription>

      {(onEdit || goToStep) &&
        (!isEdit ? (
          <IconBtn
            href={`${Sprite}#icon-pan`}
            onClick={() => (goToStep ? goToStep() : setIsEdit(true))}
            tooltipText={t('common:tooltip.edit')}
          />
        ) : (
          <IconsWrapper>
            <IconBtn
              onClick={() => setIsEdit(false)}
              href={`${Sprite}#icon-cross`}
              sx={{ stroke: color.red }}
            />
            <IconBtn
              onClick={() => {}}
              href={`${Sprite}#icon-tick`}
              sx={{ stroke: color.green }}
            />
          </IconsWrapper>
        ))}
    </>
  );
};

const FieldDescription = styled.div`
  flex: 1 1 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const PhoneWrapper = styled.div`
  display: flex;
`;
