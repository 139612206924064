import * as React from 'react';

import PropTypes from 'prop-types';

import { Typography, useTheme } from '@mui/material';
import TableCell from '@mui/material/TableCell';

export const InfoText = ({ text, style, variant, sx }) => {
  const { color } = useTheme();

  return (
    <TableCell component="th" scope="row" sx={{ p: 0, ...style }}>
      {text && (
        <Typography
          sx={{ color: color.gray, wordBreak: 'break-all', ...sx }}
          variant={variant}
          component={'p'}
        >
          {text}
        </Typography>
      )}
    </TableCell>
  );
};

InfoText.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  variant: PropTypes.string.isRequired,
  sx: PropTypes.object,
};
