export const getEmployees = state => state.employees.employees;
export const getEmployeesMeta = state => state.employees.employeesMeta;
export const getIsEmployeesLoading = state =>
  state.employees.isEmployeesLoading;
export const getEmployeeRoles = state => state.employees.employeeRoles;

const employeesSelectors = {
  getEmployees,
  getEmployeesMeta,
  getIsEmployeesLoading,
  getEmployeeRoles,
};

export default employeesSelectors;
