export const DefaultColorsConf = {
  main: '#3963E1',
  menu: '#2B3042',
  text_color: '#364A63',
  background_color: '#F5F6FA',
  menu_icon_color: '#8094AE',
};

export const ColorConf = t => [
  {
    title: t('profile:workflow_settings_page.color_conf.main_color.title'),
    details: t('profile:workflow_settings_page.color_conf.main_color.details'),
    key: 'main',
  },
  {
    title: t('profile:workflow_settings_page.color_conf.menu_color.title'),
    details: t('profile:workflow_settings_page.color_conf.menu_color.details'),
    key: 'menu',
  },
  {
    title: t('profile:workflow_settings_page.color_conf.text_color.title'),
    details: t('profile:workflow_settings_page.color_conf.text_color.details'),
    key: 'text_color',
  },
  {
    title: t(
      'profile:workflow_settings_page.color_conf.background_color.title',
    ),
    details: t(
      'profile:workflow_settings_page.color_conf.background_color.details',
    ),
    key: 'background_color',
  },
  {
    title: t('profile:workflow_settings_page.color_conf.menu_icon_color.title'),
    details: t(
      'profile:workflow_settings_page.color_conf.menu_icon_color.details',
    ),
    key: 'menu_icon_color',
  },
];
