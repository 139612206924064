import { useTranslation } from 'react-i18next';
import { Swiper, SwiperSlide } from 'swiper/react';

import styled from 'styled-components';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';

import {
  Logo_five,
  Logo_four,
  Logo_ono,
  Logo_six,
  Logo_tree,
  Logo_two,
} from '@root/assets/image';

import { useTheme } from '@mui/material';

import { SliderItem } from './SliderItem';

export const Slider = () => {
  const { color } = useTheme();
  const { t } = useTranslation(['login'], { useSuspense: false });
  const TEST = [
    {
      id: 1,
      img: Logo_ono,
      // text: t('login:slider_text'),
      // title: 'Security',
      // slogan: 'HUB',
      // styles: {
      //   top: 'auto',
      //   left: '10px',
      // },
    },
    {
      id: 2,
      img: Logo_two,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
      styles: {
        top: '10px',
        left: '10px',
      },
    },
    {
      id: 3,
      img: Logo_tree,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
      styles: {
        top: '10px',
        left: '10px',
      },
    },
    {
      id: 4,
      img: Logo_four,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
      styles: {
        top: '10px',
        right: '10px',
      },
    },
    {
      id: 5,
      img: Logo_five,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
      styles: {
        top: '10px',
        left: '10px',
      },
    },
    {
      id: 6,
      img: Logo_six,
      text: t('login:slider_text'),
      title: 'Security',
      slogan: 'HUB',
      styles: {
        bottom: '20px',
        top: 'auto',
        left: '10px',
      },
    },
  ];

  return (
    <SwiperWrapper>
      <Swiper
        pagination={true}
        style={{
          '--swiper-pagination-color': color.white,
        }}
        modules={[Autoplay, Pagination]}
        className="mySwiper"
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={600}
      >
        {TEST.map(({ img, title, text, slogan, styles, id }) => (
          <SwiperSlide style={{ width: 'auto' }} key={id}>
            <SliderItem
              img={img}
              title={title}
              text={text}
              slogan={slogan}
              styles={styles}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </SwiperWrapper>
  );
};

const SwiperWrapper = styled.div`
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    opacity: 1;
    margin: 0;
  }

  .swiper-pagination-bullet + .swiper-pagination-bullet {
    margin-left: 16px;
  }

  .swiper-pagination-bullet-active {
    background: white;
  }

  .swiper-pagination {
    margin-bottom: 50px;
  }
`;
