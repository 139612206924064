import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { HeaderDefault } from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { ButtonBase, ModalBase, ProgressLoader, TextInput } from '@root/ui';

import permissionOperation from '@redux/permission/permission-operation';

import { useTheme } from '@mui/material/styles';

export const AddNewRoleModal = ({
  open,
  handleClose,
  cloneId,
  handleRedirectToNewRole,
}) => {
  const [data, setData] = useState({ name: '' });
  const [error, setError] = useState({});
  const dispatch = useDispatch();

  const { color } = useTheme();
  const { t } = useTranslation(['profile', 'validation']);

  const onChange = name => value => setData({ ...data, [name]: value });

  const handleSubmit = e => {
    e.preventDefault();
    e.stopPropagation();

    if (cloneId) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            permissionOperation.cloneRoleById({
              id: cloneId,
              ...data,
              onResolve,
              onReject,
            }),
          ),
        data => {
          handleRedirectToNewRole(0, data[0].id);
          handleClose();
        },
        message => {
          setError({ init: true, text: message });
        },
      );
    } else {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            permissionOperation.createNewRole({ ...data, onResolve, onReject }),
          ),
        data => {
          handleRedirectToNewRole(0, data[0].id);
          handleClose();
        },
        message => {
          setError({ init: true, text: message });
        },
      );
    }
  };

  const { name } = data;

  return (
    <ModalBase
      open={open}
      handleClose={handleClose}
      modalHeader={
        <HeaderDefault
          text={t('profile:permission.modal.title')}
          onClose={handleClose}
        />
      }
      modalBottom={
        <ButtonWrapper>
          <ButtonBase
            type="submit"
            form="add-roles-form"
            disabled={name.length === 0}
          >
            {t('common:buttons.save')}
          </ButtonBase>
        </ButtonWrapper>
      }
    >
      <Form id="add-roles-form" onSubmit={handleSubmit}>
        {
          // loading && <ProgressLoader />
        }
        <InputWrapper color={color}>
          <TextInput
            placeholder={t('profile:permission.modal.input')}
            value={name}
            onChange={onChange('name')}
            max={255}
            error={error?.init}
            errorText={error?.text}
          />
        </InputWrapper>
      </Form>
    </ModalBase>
  );
};

const Form = styled.form`
  width: 466px;
  padding: 0 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  padding: 20px;
  border-radius: 4px;
  background: ${({ color }) => color.line};
`;

AddNewRoleModal.propsTypes = {
  handleClose: PropTypes.func,
  open: PropTypes.bool,
  cloneId: PropTypes.number,
  handleRedirectToNewRole: PropTypes.func,
};
