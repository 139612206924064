import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Chart, Empty } from '@root/components';
import { ProgressLoader } from '@root/ui';

import homeOperation from '@redux/home/home-operation';
import homeSelectors from '@redux/home/home-selectors';

import { useTheme } from '@mui/material';

export const OrderChart = ({ conf }) => {
  const [chartData, setChartData] = useState({
    datasets: [],
  });
  const [maxValGraph, setMaxValGraph] = useState(0);
  const loading = useSelector(homeSelectors.getHomeOrderChartLoading);
  const data = useSelector(homeSelectors.getHomeOrderChart);
  const dispatch = useDispatch();

  const { color } = useTheme();

  useEffect(() => {
    dispatch(homeOperation.getHomeOrderChart({}));
  }, []);

  useEffect(() => {
    createChart();
  }, [data]);

  const getMaxValGraph = num => {
    const numLength = num.toString().length;

    switch (numLength) {
      case 1:
        return num + 2;
      case 2:
        return num + 10;
      case 3:
        return num + 100;
      case 4:
        return num + 1000;
    }
  };

  const createChart = () => {
    const sortedData = data.slice(0).sort((a, b) => b.count - a.count);
    const dataCounts = sortedData.map(item => item.count);
    const max = Math.max(...dataCounts);
    setMaxValGraph(getMaxValGraph(max));
    setChartData({
      labels: sortedData.map(item => item.name),
      datasets: [
        {
          label: sortedData.map(item => item.name),
          data: dataCounts,
          backgroundColor: [color.main],
          datalabels: {
            backgroundColor: color.main,
            borderRadius: 4,
            color: color.white,
            anchor: 'end',
            align: 'end',
            padding: 4,
            font: {
              size: '10px',
              weight: 'bold',
            },
          },
        },
      ],
    });
  };

  return (
    <ContentWrap>
      {loading ? (
        <ProgressLoader />
      ) : data.length ? (
        <Chart
          data={chartData}
          defaultSize={false}
          customLabels={true}
          verticalMode={conf.size === '2fr'}
          maxValGraph={maxValGraph}
        />
      ) : (
        <Empty type={'EmptyBox'} />
      )}
    </ContentWrap>
  );
};

const ContentWrap = styled.div`
  height: 100%;
  max-height: 305px;
  padding: 5px 13px 13px;
`;

OrderChart.propTypes = {
  conf: PropTypes.exact({
    id: PropTypes.number,
    isHide: PropTypes.bool,
    isNav: PropTypes.bool,
    key: PropTypes.string,
    name: PropTypes.string,
    position: PropTypes.object,
    size: PropTypes.string,
    sort: PropTypes.number,
    title: PropTypes.string,
    tabNames: PropTypes.array,
  }),
};
