import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import styled from 'styled-components';

import { ServiceOrderWidgetConf } from '@root/conf/ServiceOrderWidgetConf';
import { LabelStatus } from '@root/ui';

import orderServiceSelectors from '@redux/order-service/order-service-selectors';

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useTheme,
} from '@mui/material';

export const FullServiceOrdersWidget = () => {
  const { t } = useTranslation(['order_service'], { useSuspense: false });
  const { color } = useTheme();

  const serviceOrders = useSelector(orderServiceSelectors.getServiceOrders);

  return (
    <FullServiceOrdersWidgetWrapper>
      {serviceOrders && serviceOrders.length > 0 && (
        <Table>
          <TableHead>
            <TableRow
              sx={{
                minHeight: '34px',
                borderBottom: `1px solid ${color.line}`,
                userSelect: 'auto',
                boxShadow: 'none',
                th: {
                  borderBottom: 'none',
                },
                display: 'flex',
                alignItems: 'center',
                padding: '0px 16px',
              }}
            >
              {ServiceOrderWidgetConf(t).map(serviceOrder => (
                <TableCell
                  sx={{
                    borderBottom: 'none',
                    ...serviceOrder.style,
                    p: 0,
                  }}
                  key={serviceOrder.slug}
                >
                  <Typography variant="bodyBoldSmall" color={color.gray_60}>
                    {serviceOrder.label}
                  </Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>

          <TableBody>
            {serviceOrders &&
              serviceOrders.map(serviceOrder => (
                <TableRow
                  sx={{
                    borderBottom: `1px solid ${color.line}`,
                    userSelect: 'auto',
                    boxShadow: 'none',
                    td: {
                      borderBottom: 'none',
                    },
                    display: 'flex',
                    alignItems: 'center',
                    padding: '7px 16px',
                    '&:last-child': {
                      borderBottom: 'none',
                    },
                  }}
                  key={serviceOrder.number}
                >
                  {ServiceOrderWidgetConf(t).map(orderService => {
                    switch (orderService.slug) {
                      case 'date':
                        return (
                          <TableCell
                            sx={{
                              padding: '0',
                              ...orderService.style,
                            }}
                            key={orderService.slug + serviceOrder.number}
                          >
                            <Typography
                              variant="bodySmall"
                              color={color.gray_80}
                            >
                              {serviceOrder.date}
                            </Typography>
                          </TableCell>
                        );
                      case 'order_number':
                        return (
                          <TableCell
                            sx={{
                              padding: '0',
                              ...orderService.style,
                            }}
                            key={orderService.slug + serviceOrder.number}
                          >
                            <Typography
                              variant="bodyBoldSmall"
                              color={color.gray}
                            >
                              {serviceOrder.number}
                            </Typography>
                          </TableCell>
                        );
                      case 'device':
                        return (
                          <TableCell
                            sx={{
                              padding: '0',
                              ...orderService.style,
                            }}
                            key={orderService.slug + serviceOrder.number}
                          >
                            <Typography variant="bodySmall" color={color.gray}>
                              {serviceOrder.model}
                            </Typography>
                          </TableCell>
                        );
                      case 'serial_number':
                        return (
                          <TableCell
                            sx={{
                              padding: '0',
                              ...orderService.style,
                            }}
                            key={orderService.slug + serviceOrder.number}
                          >
                            <Typography variant="bodySmall" color={color.gray}>
                              {serviceOrder.serial}
                            </Typography>
                          </TableCell>
                        );
                      case 'warranty_payments':
                        return (
                          <TableCell
                            sx={{
                              padding: '0',
                              ...orderService.style,
                            }}
                            key={orderService.slug + serviceOrder.number}
                          >
                            <Typography
                              variant="bodyBoldExSmall"
                              color={color.gray}
                              sx={{
                                display: 'flex',
                                alignItems: 'center',
                              }}
                            >
                              {serviceOrder.type}
                            </Typography>
                          </TableCell>
                        );
                      case 'status':
                        return (
                          <TableCell
                            sx={{
                              padding: '0',
                              ...orderService.style,
                            }}
                            key={orderService.slug + serviceOrder.number}
                          >
                            <LabelStatus>
                              <Typography
                                variant="bodyBoldExSmall"
                                color={`${color.gray_80} !important`}
                              >
                                {serviceOrder.status}
                              </Typography>
                            </LabelStatus>
                          </TableCell>
                        );
                    }
                  })}
                </TableRow>
              ))}
          </TableBody>
        </Table>
      )}
    </FullServiceOrdersWidgetWrapper>
  );
};

const FullServiceOrdersWidgetWrapper = styled.div`
  table,
  tbody {
    width: 100%;
    display: block;
  }
`;
