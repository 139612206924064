import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  Action,
  Availability,
  Cost,
  ImgPrev,
  LabelProduct,
} from '@root/components';
import { useNavigation } from '@root/hooks';
import { CheckboxBase } from '@root/ui';

import userSelectors from '@redux/user/user-selectors';

import { useTheme } from '@mui/material';

const DEFAULT_STYLE = {
  wrapper: {},
  wrapperLabel: {},
  wrapperCost: {},
  wrapperAvailability: {},
  wrapperAction: {},
};

export const ProductCard = ({
  data,
  handelDetailCard,
  isOpenDetail,
  handleChoseItem,
  handelOpenSavedModal,
  addOrRemoveFromComparisons,
  style = DEFAULT_STYLE,
  isHideArrowCard,
  isHideAction = false,
  isCheckBox = false,
  checked,
  handleChoseProduct,
  customAction,
}) => {
  const { navigation } = useNavigation();
  const permission = useSelector(userSelectors.getPermissions);
  const handelNavigate = () => {
    navigation(data?.product_url ? data.product_url : data.url);
  };
  const { color } = useTheme();

  return (
    <ComponentWrapper
      colors={{ main: color.main, line: color.line }}
      style={{ ...style?.wrapper }}
      data-test-id="ProductCard"
    >
      {isCheckBox && (
        <CheckboxBase
          checked={checked}
          onChange={e => handleChoseProduct(e)}
          width={16}
        />
      )}

      <ImgPrev
        onClick={handelNavigate}
        src={data?.image?.thumbnail ? data.image.thumbnail : data.image}
        countImg={data.images_quantity}
      />
      <Wrapper
        ml={'15px'}
        width={'423px'}
        onClick={handelNavigate}
        style={{ ...style?.wrapperLabel }}
      >
        <LabelProduct
          label={data?.name}
          parm={data?.model}
          article={data?.sku}
        />
      </Wrapper>
      <Wrapper ml={'auto'} width={'110px'} style={{ ...style?.wrapperCost }}>
        <Cost data={data} />
      </Wrapper>
      <Wrapper
        ml={'10px'}
        width={'100px'}
        style={{ ...style?.wrapperAvailability }}
      >
        {permission.product_stock_access && (
          <Availability
            present={data?.stock_percent}
            inStock={data?.stock}
            notAvailable={data?.stock}
            storage={data?.stock}
          />
        )}
      </Wrapper>
      {!isHideAction && (
        <Wrapper ml={0} width={'158px'}>
          <Action
            isSaved={data.is_saved}
            inCompare={data.in_compare}
            availableNumber={data?.stock}
            chooseProduct={data.cart}
            productPrice={data?.price}
            addedToCart={data.is_in_cart}
            handelDetailCard={handelDetailCard}
            isOpenDetail={isOpenDetail}
            handelOpenSavedModal={e =>
              handelOpenSavedModal(e, data?.is_saved, data?.id)
            }
            addOrRemoveFromComparisons={() =>
              addOrRemoveFromComparisons(data?.id, data?.in_compare)
            }
            onClick={handleChoseItem}
            relation_quantity={data?.relation_quantity}
            data={data}
            isHideArrowCard={isHideArrowCard}
          />
        </Wrapper>
      )}
      {customAction && <Wrapper ml={'auto'}>{customAction(data)} </Wrapper>}
    </ComponentWrapper>
  );
};

const ComponentWrapper = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  padding: 20px 20px 20px 16px;

  border-left: 3px solid ${props => props.colors.main};
  border-bottom: 1px solid ${props => props.colors.line};
`;

const Wrapper = styled.div`
  margin-left: ${({ ml }) => ml};
  width: ${({ width }) => width};
  cursor: pointer;
`;

ProductCard.propTypes = {
  style: PropTypes.exact({
    wrapper: PropTypes.object,
    wrapperLabel: PropTypes.object,
    wrapperCost: PropTypes.object,
    wrapperAvailability: PropTypes.object,
    wrapperAction: PropTypes.object,
  }),
  data: PropTypes.exact({
    brand: PropTypes.string,
    can_buy: PropTypes.exact({
      price: PropTypes.bool,
      price_uah_cash: PropTypes.bool,
      price_uah_no_pdv: PropTypes.bool,
      price_uah_pdv: PropTypes.bool,
    }),
    cart: PropTypes.shape({
      cart_product_id: PropTypes.number,
      id: PropTypes.number,
      personal_amount: PropTypes.exact({
        price: PropTypes.string,
        price_uah_cash: PropTypes.string,
        price_uah_no_pdv: PropTypes.string,
        price_uah_pdv: PropTypes.string,
      }),
      quantity: PropTypes.number,
    }),
    description: PropTypes.string,
    has_analogs: PropTypes.bool,
    id: PropTypes.number,
    image: PropTypes.string,
    images_quantity: PropTypes.number,
    in_compare: PropTypes.bool,
    is_in_cart: PropTypes.bool,
    is_saved: PropTypes.bool,
    model: PropTypes.string,
    name: PropTypes.string,
    personal_price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    price: PropTypes.exact({
      price: PropTypes.string,
      price_uah_cash: PropTypes.string,
      price_uah_no_pdv: PropTypes.string,
      price_uah_pdv: PropTypes.string,
    }),
    prices: PropTypes.exact({
      price: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_cash: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_no_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
      price_uah_pdv: PropTypes.exact({
        product: PropTypes.string,
        personal: PropTypes.string,
      }),
    }),
    sku: PropTypes.string,
    slug: PropTypes.string,
    stock: PropTypes.number,
    stock_max: PropTypes.number,
    stock_percent: PropTypes.number,
    url: PropTypes.string,
    warehouses: PropTypes.arrayOf(
      PropTypes.exact({
        city_name: PropTypes.string,
        warehouses: PropTypes.exact({
          name: PropTypes.string,
          stock: PropTypes.number,
          stock_max: PropTypes.number,
          stock_percent: PropTypes.number,
        }),
      }),
    ),
  }),
  handelDetailCard: PropTypes.func.isRequired,
  isOpenDetail: PropTypes.bool.isRequired,
  handleChoseItem: PropTypes.func.isRequired,
  handelOpenSavedModal: PropTypes.func.isRequired,
  addOrRemoveFromComparisons: PropTypes.func.isRequired,
  isHideAction: PropTypes.bool,
  checked: PropTypes.bool,
  handleChoseMain: PropTypes.func,
  isCheckBox: PropTypes.bool,
  handleChoseProduct: PropTypes.func,
  customAction: PropTypes.func,
};
