import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { PDFFile } from '@root/assets/image';
import { CopyToClipboard, HeaderDefault } from '@root/components';
import { AlertBase, ButtonBase, ModalBase } from '@root/ui';

import comparisonsSelectors from '@redux/comparisons/comparisons-selectors';

import { Typography, useTheme } from '@mui/material';

export const ComparisonFileReadyModal = ({ open, handleCloseModal }) => {
  let timer = null;
  const { color } = useTheme();
  const { t } = useTranslation('comparisons', { useSuspense: false });
  const [show, setShow] = useState(false);
  const fileDetails = useSelector(
    comparisonsSelectors.comparisonItemPdfDetails,
  );

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  });

  const onCopySuccess = () => {
    setShow(true);
  };

  return (
    <ModalBase
      open={open}
      handleClose={handleCloseModal}
      sx={{ width: '100%', maxWidth: '402px' }}
      modalHeader={<HeaderDefault text="" onClose={handleCloseModal} />}
    >
      <ComparisonFileReadyModalWrapper>
        <Img src={PDFFile} />
        <Typography
          marginBottom="24px"
          marginTop="24px"
          color={color.gray}
          variant="bodyMedium"
        >
          {t(
            'comparisons:modals.comparison_pdf_file_ready_modal.comparison_pdf_file_ready_title',
          )}
        </Typography>
        <ActionsWrapper>
          <NavigationLink
            target="_blank"
            href={fileDetails.url}
            color={color.line}
          >
            <Typography variant={'bodyBoldSmall'} color={color.gray_60}>
              {t('buttons.download')}
            </Typography>
          </NavigationLink>
          <CopyToClipboard
            copyIcon={false}
            copyText={fileDetails.url ?? ''}
            onCopySuccess={onCopySuccess}
            styles={{
              flexShrink: 0,
              alignSelf: 'stretch',
            }}
          >
            <ButtonBase sx={{ alignSelf: 'stretch' }}>
              {!show ? (
                <Typography variant={'bodyBoldSmall'}>
                  {t('buttons.copy_link')}
                </Typography>
              ) : (
                <AlertBase
                  alertIcon={false}
                  alertTitle={t('copied')}
                  setShow={setShow}
                  closeAfterTimeout={true}
                  alertTitleSx={{
                    minWidth: '125px !important',
                    display: 'inline-block',
                    color: `${color.white} !important`,
                  }}
                />
              )}
            </ButtonBase>
          </CopyToClipboard>
        </ActionsWrapper>
      </ComparisonFileReadyModalWrapper>
    </ModalBase>
  );
};

const ComparisonFileReadyModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 213px;
`;

const ActionsWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  height: 38px;
  flex-basis: 100%;
  justify-content: center;
  align-items: center;
`;

const NavigationLink = styled.a`
  max-width: 113px;
  width: 100%;
  height: 36px;
  border-radius: 4px;
  border: 1px solid ${props => props.color};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
`;

const Img = styled.img``;

ComparisonFileReadyModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleCloseModal: PropTypes.func.isRequired,
};
