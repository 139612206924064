import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { IconBtn } from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const ActivityAreas = ({ value, sx, variant, onEdit, goToStep }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation(['common'], { useSuspense: false });
  const { color } = useTheme();

  return (
    <>
      <FieldDescription>
        <WrapperBox>
          {value.map(({ name, id }) => (
            <ActivityAreasWrapper key={id}>
              <Typography
                sx={{ mr: '8px', ...sx }}
                variant={variant}
                component={'p'}
              >
                {name}
              </Typography>
            </ActivityAreasWrapper>
          ))}
        </WrapperBox>
      </FieldDescription>

      {(onEdit || goToStep) &&
        (!isEdit ? (
          <IconBtn
            href={`${Sprite}#icon-pan`}
            onClick={() => (goToStep ? goToStep() : setIsEdit(true))}
            tooltipText={t('common:tooltip.edit')}
          />
        ) : (
          <IconsWrapper>
            <IconBtn
              onClick={() => setIsEdit(false)}
              href={`${Sprite}#icon-cross`}
              sx={{ stroke: color.red }}
            />
            <IconBtn
              onClick={() => {}}
              href={`${Sprite}#icon-tick`}
              sx={{ stroke: color.green }}
            />
          </IconsWrapper>
        ))}
    </>
  );
};

const FieldDescription = styled.div`
  flex: 1 1 auto;
`;

const IconsWrapper = styled.div`
  display: flex;
  column-gap: 6px;
`;

const WrapperBox = styled.div`
  display: flex;
  flex-direction: column;
`;

const ActivityAreasWrapper = styled.div`
  display: flex;
`;
