import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import { ColorConf, DefaultColorsConf } from '@root/conf';
import { getErrorStack, handleDispatchFetch } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  ButtonBase,
  FileLoader,
  OutlineButtonBase,
  ProgressLoader,
} from '@root/ui';

import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

import { ItemColorCard } from './ItemColorCard';

const DEFAULT_CONF = {
  ...DefaultColorsConf,
  is_delete_logo_big: false,
  is_delete_logo_small: false,
  logo_small: null,
  logo: null,
};

export const WorkflowSettings = () => {
  const { t } = useTranslation(['profile'], { useSuspense: false });
  document.title = t('profile:conf.workflow_settings.title');
  const { color } = useTheme();
  const fileRefLogoBig = useRef();
  const fileRefLogoSmall = useRef();
  const [error, setError] = useState({});
  const [data, setData] = useState({});
  const permission = useSelector(userSelectors.getPermissions);
  const custom_color_conf = useSelector(userSelectors.getUserColorConf);
  const custom_logo_menu = useSelector(userSelectors.getUserCompanyConf);

  const dispatch = useDispatch();
  const { navigation } = useNavigation();

  useEffect(() => {
    if (!permission.profile_colors_access) {
      navigation('/error-403');
      return;
    }
  }, []);

  useEffect(() => {
    setData({ ...DEFAULT_CONF, ...custom_color_conf, ...custom_logo_menu });
  }, [custom_color_conf, custom_logo_menu]);

  const handleChangeColor = key => value => {
    setData({ ...data, [key]: value });
  };

  const handleSetImage = key => img => {
    const deleteKey =
      key === 'logo_small' ? 'is_delete_logo_small' : 'is_delete_logo_big';
    const obj = {
      [key]: img,
      [deleteKey]: !img ? true : false,
    };
    setData({ ...data, ...obj });
  };

  const handleReset = key => () => {
    switch (key) {
      case 'all':
        setData({
          ...DEFAULT_CONF,
          is_delete_logo_big: true,
          is_delete_logo_small: true,
        });
        break;
      default:
        setData({ ...data, [key]: DEFAULT_CONF[key] });
        break;
    }
  };

  const handleOpenNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('profile:notifications.update_data_successfully'),
      }),
    );
  };

  const validation = () => {
    const { logo_small, logo } = data;
    let flag = false;
    let currentError = {};

    if (logo_small && typeof logo_small !== 'string') {
      if (logo_small.size > 6291456) {
        currentError = getErrorStack(
          currentError,
          'logo',
          t('validation:file_size'),
        );
        flag = true;
      }
    }

    if (logo && typeof logo !== 'string') {
      if (logo.size > 6291456) {
        currentError = getErrorStack(
          currentError,
          'logo',
          t('validation:file_size'),
        );
        flag = true;
      }
    }
    setError(currentError);
    return flag;
  };
  const handleSubmit = e => {
    e.preventDefault();

    if (validation()) return;
    const { logo_small, logo } = data;

    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          userOperation.createNewColorConfig({
            formData: serialize(
              {
                ...data,
                logo_small: typeof logo_small === 'string' ? null : logo_small,
                logo: typeof logo === 'string' ? null : logo,
              },
              {
                indices: true,
                nullsAsUndefineds: true,
                booleansAsIntegers: true,
              },
            ),
            onResolve,
            onReject,
          }),
        ),
      () => handleOpenNotification(),
    );
  };

  const { logo_small, logo } = data;
  return (
    <Form onSubmit={handleSubmit}>
      {Object.keys(data).length > 0 ? (
        <>
          <div>
            <Typography
              variant={'bodyMedium'}
              component={'h3'}
              color={color.gray_80}
            >
              {t('profile:workflow_settings_page.color_conf.title')}
            </Typography>
            <List>
              {ColorConf(t).map((el, idx) => (
                <Item top={idx !== 0 ? '8px' : 0} key={el.title}>
                  <ItemColorCard
                    data={el}
                    currentColor={data[el.key]}
                    handleChangeColor={handleChangeColor(el.key)}
                    handleReset={handleReset(el.key)}
                  />
                </Item>
              ))}
            </List>
          </div>
          {permission.profile_logo_edit && (
            <Wrap>
              <Typography
                variant={'bodyMedium'}
                component={'h3'}
                color={color.gray_80}
              >
                {t('profile:workflow_settings_page.logo_conf.title')}
              </Typography>
              <Flex>
                <div style={{ width: '48%' }}>
                  <FileLoader
                    title={t('profile:fields.load_main_logo')}
                    isLogo={true}
                    fileRef={fileRefLogoBig}
                    file={logo}
                    setFile={handleSetImage('logo')}
                    handleSetImage={handleSetImage('logo')}
                    initError={error?.logo}
                  />
                </div>
                <div style={{ width: '48%' }}>
                  <FileLoader
                    title={t('profile:fields.load_sab_logo')}
                    isLogo={true}
                    fileRef={fileRefLogoSmall}
                    file={logo_small}
                    setFile={handleSetImage('logo_small')}
                    handleSetImage={handleSetImage('logo_small')}
                    initError={error?.logo}
                  />
                </div>
              </Flex>
            </Wrap>
          )}

          <ButtonWrapper>
            <OutlineButtonBase
              type="button"
              onClick={() => handleReset('all')('')}
              sx={{ padding: '5px 16px', marginRight: '30px' }}
            >
              {t('common:buttons.reset_all')}
            </OutlineButtonBase>
            <ButtonBase type="submit">{t('common:buttons.save')}</ButtonBase>
          </ButtonWrapper>
        </>
      ) : (
        <ProgressLoader />
      )}
    </Form>
  );
};

const Form = styled.form``;

const List = styled.ul`
  margin-top: 8px;
`;

const Item = styled.li`
  margin-top: ${({ top }) => top};
`;
const Wrap = styled.div`
  margin-top: 24px;
`;
const Flex = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;

  margin-top: 40px;
`;
