const productsLoading = state => state.commercialOffer.productsLoading;
const loading = state => state.commercialOffer.loading;
const listLoading = state => state.commercialOffer.listLoading;
const customProductsLoading = state =>
  state.commercialOffer.customProductsLoading;
const companyListLoading = state => state.commercialOffer.companyListLoading;
const getCompanyList = state => state.commercialOffer.companyList;
const getCompanyTemplates = state =>
  state.commercialOffer.companyTemplatesDetails;
const getProducts = state => state.commercialOffer.products;
const getProductsTotalSum = state => state.commercialOffer.productsTotalSum;
const getCOLinks = state => state.commercialOffer.links;
const getCustomProducts = state =>
  state.commercialOffer.customProducts.products;
const getCustomServices = state =>
  state.commercialOffer.customProducts.services;
const getCustomProductsList = state => state.commercialOffer.customProductsList;
const getCustomServicesList = state => state.commercialOffer.customServicesList;
const getCoEditData = state => state.commercialOffer.commercialOfferEditData;
const getCoId = state => state.commercialOffer.commercialOfferId;
export default {
  getCompanyList,
  getCompanyTemplates,
  productsLoading,
  loading,
  companyListLoading,
  listLoading,
  getProducts,
  getProductsTotalSum,
  getCOLinks,
  getCustomProducts,
  getCustomServices,
  getCustomProductsList,
  getCustomServicesList,
  customProductsLoading,
  getCoEditData,
  getCoId,
};
