import { useCallback, useState } from 'react';

import PropTypes from 'prop-types';

import { CustomScroll } from '@root/ui';

import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { styled } from '@mui/material/styles';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 'fit-content',
  bgcolor: 'background.paper',
  boxShadow: '6px 6px 10px rgba(54, 74, 99, 0.06)',
  borderRadius: '4px',
  border: 'none',
  padding: '20px',
};

const BackdropCustom = styled(Backdrop)(({ theme }) => ({
  backgroundColor: theme.color.menu_overlay,
}));

export const ModalBase = ({
  handleClose,
  children,
  sx,
  open,
  modalSx,
  modalHeader,
  modalBottom,
  scrollPadding,
  onMouseDown,
  headerBoxStyles,
  scrollConf = { vertical: true, horizontal: false },
  dataTestId,
}) => {
  const [headerHeight, setHeaderHeight] = useState();
  const [bottomHeight, setBottomHeight] = useState();

  const getHeaderHeight = useCallback(element => {
    setHeaderHeight(element?.clientHeight);
  }, []);

  const getBottomHeight = useCallback(element => {
    setBottomHeight(element?.clientHeight);
  }, []);

  const { vertical } = scrollConf;

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="child-modal-title"
      aria-describedby="child-modal-description"
      BackdropComponent={BackdropCustom}
      onMouseDown={onMouseDown}
      sx={{ ...modalSx }}
    >
      <Box sx={{ ...style, ...sx }} data-test-id={dataTestId}>
        <Box sx={{ pb: '10px', ...headerBoxStyles }} ref={getHeaderHeight}>
          {modalHeader && modalHeader}
        </Box>
        <Box
          sx={{
            maxHeight: `calc(100vh - ${80 + headerHeight + bottomHeight}px)`,
            overflowY: vertical ? 'auto' : 'hidden',
          }}
        >
          <CustomScroll padding={scrollPadding} vertical={vertical}>
            {children}
          </CustomScroll>
        </Box>
        <Box ref={getBottomHeight}>{modalBottom && modalBottom}</Box>
      </Box>
    </Modal>
  );
};

ModalBase.propTypes = {
  handleClose: PropTypes.func,
  children: PropTypes.node,
  sx: PropTypes.object,
  open: PropTypes.bool,
  modalSx: PropTypes.object,
  modalHeader: PropTypes.node,
  modalBottom: PropTypes.node,
  scrollPadding: PropTypes.string,
  onMouseDown: PropTypes.func,
  headerBoxStyles: PropTypes.object,
};
