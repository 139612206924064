import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { options } from 'axios';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { InfoIcon } from '@root/assets/svg';
import { npHotCities } from '@root/conf';
import { handleDispatchFetch } from '@root/helpers';
import { useNovaPoshta } from '@root/hooks/useNovaPoshta';
import userSelectors from '@root/redux/user/user-selectors';
import {
  BasicAutocompleteSelect,
  ButtonBase,
  CheckboxBase,
  CustomizedSwitches,
  DatePickerCustom,
  PhoneInput,
  TextInput,
} from '@root/ui';

import userOperation from '@redux/user/user-operation';

import { Typography, useTheme } from '@mui/material';

import { ModalDropShiping } from '../../../ModalDropShiping/index';

export const NovaPoshta = ({
  data,
  setData,
  onChange,
  deliveryType,
  error,
}) => {
  const { its_drop } = data;
  const { color } = useTheme();
  const userApiKey = useSelector(userSelectors.getNpApiKey);
  const [isOpen, setIsOpen] = useState(false);
  const [userKeyValue, setUserKeyValue] = useState('');

  const { t } = useTranslation(['common', 'placing_order'], {
    useSuspense: false,
  });

  const {
    quickCitySelection,
    onAutocompleteOpen,
    onAutocompleteChange,
    autocompletePagination,
    autocompleteInputChange,
    setNpValidationKey,
    validationApiKey,
    autocompleteData,
    npValues,
  } = useNovaPoshta({ data, setData });

  const {
    street_title,
    street_number,
    flat_num,
    floor_num,
    npPhone,
    date,
    time,
    elevator,
  } = data.delivery_info;

  const npTypeValues = ['department', 'post_office', 'np_courier'];
  const dispatch = useDispatch();
  const npType = () => {
    let res;
    if (data.delivery_info.type === 'department') {
      res = 'department';
    } else {
      res = 'office';
    }
    return res;
  };
  const renderContent = () => {
    switch (deliveryType) {
      case 'Nova_Poshta_branch':
        return (
          <>
            {(data.delivery_info.type === 'department' ||
              data.delivery_info.type === 'post_office') && (
              <ContentWrap>
                <BasicAutocompleteSelect
                  filterOptions={options => options}
                  error={error?.city_title?.init}
                  helperText={error?.city_title?.text}
                  placeholder={t(
                    'placing_order:product_delivery.nova_poshta.fields.select_city',
                  )}
                  size={'small'}
                  listBoxStyles
                  sx={{
                    maxWidth: '300px',
                    width: '100%',
                  }}
                  noOptionsText={t(
                    'placing_order:product_delivery.nova_poshta.fields.no_city_found',
                  )}
                  pagination={true}
                  paginationCallback={autocompletePagination}
                  options={autocompleteData.cities}
                  getOptionLabel={option => option.label ?? option.Description}
                  isOptionEqualToValue={(option, value) =>
                    option.Ref === value.Ref
                  }
                  autocompleteInputChange={autocompleteInputChange}
                  onAutocompleteChange={onAutocompleteChange}
                  value={npValues.cityValue}
                  onAutocompleteOpen={onAutocompleteOpen}
                />
                <CitiesList data-test-id="CitiesListQuick">
                  {npHotCities.map(city => (
                    <CitiesListItem
                      key={city.id}
                      onClick={() => quickCitySelection(city.ref)}
                      color={color.main}
                    >
                      {city.title}
                    </CitiesListItem>
                  ))}
                </CitiesList>
                {npValues.cityValue && (
                  <BasicAutocompleteSelect
                    filterOptions={options => options}
                    error={
                      data.delivery_info.type === 'department'
                        ? error?.department_title?.init
                        : error?.post_office_title?.init
                    }
                    helperText={
                      data.delivery_info.type === 'department'
                        ? error?.department_title?.text
                        : error?.post_office_title?.text
                    }
                    type={npType()}
                    placeholder={
                      data.delivery_info.type === 'department'
                        ? t(
                            'placing_order:product_delivery.nova_poshta.fields.select_branch',
                          )
                        : t(
                            'placing_order:product_delivery.nova_poshta.fields.select_post_office',
                          )
                    }
                    size={'small'}
                    listBoxStyles
                    sx={{
                      maxWidth: '300px',
                      width: '100%',
                    }}
                    noOptionsText={
                      npType() === 'department'
                        ? t(
                            'placing_order:product_delivery.nova_poshta.fields.no_branch_found',
                          )
                        : t(
                            'placing_order:product_delivery.nova_poshta.fields.no_post_office_found',
                          )
                    }
                    pagination={true}
                    paginationCallback={autocompletePagination}
                    options={autocompleteData.departmentOrOffice}
                    getOptionLabel={option =>
                      option.label ?? option.Description
                    }
                    isOptionEqualToValue={(option, value) =>
                      option.Ref === value.Ref
                    }
                    autocompleteInputChange={autocompleteInputChange}
                    onAutocompleteChange={onAutocompleteChange}
                    value={npValues.departmentOrOfficeValue}
                    onAutocompleteOpen={onAutocompleteOpen}
                    data-test-id="NovaPoshtaSelfPickUp"
                  />
                )}
              </ContentWrap>
            )}
          </>
        );
      case 'Address':
        return (
          <>
            {data.delivery_info.type === 'np_courier' && (
              <ContentWrap>
                <BasicAutocompleteSelect
                  filterOptions={options => options}
                  error={error?.city_title?.init}
                  helperText={error?.city_title?.text}
                  placeholder={t(
                    'placing_order:product_delivery.nova_poshta.fields.select_city',
                  )}
                  size={'small'}
                  listBoxStyles
                  sx={{
                    maxWidth: '300px',
                    width: '100%',
                  }}
                  noOptionsText={t(
                    'placing_order:product_delivery.nova_poshta.fields.no_city_found',
                  )}
                  pagination={true}
                  paginationCallback={autocompletePagination}
                  options={autocompleteData.cities}
                  getOptionLabel={option => option.label ?? option.Description}
                  isOptionEqualToValue={(option, value) =>
                    option.Ref === value.Ref
                  }
                  autocompleteInputChange={autocompleteInputChange}
                  onAutocompleteChange={onAutocompleteChange}
                  value={npValues.cityValue}
                  onAutocompleteOpen={onAutocompleteOpen}
                />
                <CitiesList data-test-id="CitiesListQuick">
                  {npHotCities.map(city => (
                    <CitiesListItem
                      key={city.id}
                      onClick={() => quickCitySelection(city.ref)}
                      color={color.main}
                    >
                      {city.title}
                    </CitiesListItem>
                  ))}
                </CitiesList>
                {npValues.cityValue && (
                  <ContentFormWrap>
                    <TextInput
                      placeholder={t(
                        'placing_order:product_delivery.fields.type_street',
                      )}
                      error={error?.street_title?.init}
                      errorText={error?.street_title?.text}
                      onChange={onChange('street_title', 'np_courier')}
                      value={street_title}
                      data-test-id="DeliveryStreet"
                    />
                    <TextInput
                      error={error?.street_number?.init}
                      errorText={error?.street_number?.text}
                      placeholder={t(
                        'placing_order:product_delivery.fields.build',
                      )}
                      onChange={onChange('street_number', 'np_courier')}
                      value={street_number}
                      withOutSpace={true}
                      data-test-id="DeliveryHouseNumber"
                    />
                    <TextInput
                      placeholder={t(
                        'placing_order:product_delivery.fields.flat',
                      )}
                      onChange={onChange('flat_num', 'np_courier')}
                      value={flat_num}
                      withOutSpace={true}
                      data-test-id="DeliveryApartmentNumber"
                    />
                    <TextInput
                      placeholder={t(
                        'placing_order:product_delivery.fields.floor',
                      )}
                      onChange={onChange('floor_num', 'np_courier')}
                      value={floor_num}
                      withOutSpace={true}
                      data-test-id="DeliveryFloorNumber"
                    />
                    <CheckBoxWrap>
                      <CheckboxBase
                        width={16}
                        checked={elevator}
                        onChange={onChange('elevator', 'np_courier')}
                      >
                        <Typography
                          variant={'bodySmall'}
                          color={color.gray}
                          sx={{
                            marginLeft: '8px',
                          }}
                        >
                          {t('placing_order:product_delivery.is_lift_checkbox')}
                        </Typography>
                      </CheckboxBase>
                    </CheckBoxWrap>
                    <FormPhoneWrap>
                      <Typography variant={'bodySmall'} color={color.gray}>
                        {t(
                          'placing_order:product_delivery.fields.additional_telephone',
                        )}
                      </Typography>
                      <PhoneInput
                        error={error?.npPhone?.init}
                        errorText={error?.npPhone?.text}
                        onChange={onChange('npPhone', 'np_courier')}
                        value={npPhone}
                        sx={{
                          marginTop: '5px',
                        }}
                        placeholder={'+380'}
                        data-test-id="DeliveryAdditionalPhone"
                      />
                    </FormPhoneWrap>

                    <div>
                      <DatePickerCustom
                        placeholder={t(
                          'placing_order:product_delivery.fields.date',
                        )}
                        minDate={true}
                        maxDate={false}
                        value={date}
                        onChange={onChange('date', 'np_courier')}
                        data-test-id="DeliveryDatePicker"
                      />
                    </div>
                    <TextInput
                      disabled
                      styleWrapper={{
                        gridColumnEnd: 'span 2',
                      }}
                      sx={{
                        input: {
                          '&:disabled': {
                            WebkitTextFillColor: color.gray_80,
                          },
                        },
                      }}
                      placeholder={t(
                        'placing_order:product_delivery.fields.from_10_to_20',
                      )}
                      value={time}
                      onChange={onChange('time', 'np_courier')}
                      withOutSpace={true}
                    />
                  </ContentFormWrap>
                )}
              </ContentWrap>
            )}
          </>
        );
      default:
        return <div>it is Default</div>;
    }
  };

  const saveUserApiKey = () => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          userOperation.saveUserNpApiKey({
            userApiKey: userKeyValue,
            onResolve,
            onReject,
          }),
        ),
      res => {
        setNpValidationKey(true);
        setData(prevState => {
          return {
            ...prevState,
            its_drop: false,
            delivery_info: {
              ...prevState.delivery_info,
            },
          };
        });
      },
      error => {},
    );

    setIsOpen(false);
    setUserKeyValue('');
  };

  return (
    <>
      {npTypeValues.includes(data.delivery_info.type) && (
        <DeliveryTypeContent>
          <DeliveryInfoWrap>
            <ButtonsWrap>
              <CustomizedSwitches
                value={its_drop}
                onChange={onChange('its_drop')}
                checked={its_drop}
                disabled={!userApiKey || !validationApiKey}
                label={t(
                  'placing_order:product_delivery.nova_poshta.fields.dropshipping',
                )}
                sx={{
                  marginLeft: 0,
                }}
              />
              <ButtonBase
                onClick={() => setIsOpen(true)}
                sx={{
                  marginTop: '14px',
                  height: '28px',
                  lineHeight: 1.5,
                  fontWeight: 400,
                }}
              >
                {t('placing_order:product_delivery.nova_poshta.fields.add_key')}
              </ButtonBase>
            </ButtonsWrap>
            {!its_drop && (
              <Typography
                variant={'bodySmall'}
                sx={{
                  color: color.gray_80,
                  fontWeight: 400,
                  marginTop: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  lineHeight: 1.2,
                }}
                component={'p'}
              >
                <Img src={InfoIcon} alt="info-icon" />
                {t(
                  'placing_order:product_delivery.nova_poshta.fields.add_key_to_activate_feature',
                )}
              </Typography>
            )}
          </DeliveryInfoWrap>
        </DeliveryTypeContent>
      )}
      {renderContent()}
      {isOpen && (
        <ModalDropShiping
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setUserKeyValue={setUserKeyValue}
          userKeyValue={userKeyValue}
          saveUserApiKey={saveUserApiKey}
        />
      )}
    </>
  );
};

const ContentWrap = styled.div`
  padding-bottom: 15px;
`;

const ContentFormWrap = styled.div`
  display: grid;
  grid-template-columns: 179px 60px 60px;
  grid-column-gap: 4px;
  grid-row-gap: 10px;
`;

const FormPhoneWrap = styled.div`
  grid-column-end: span 3;
`;

const CheckBoxWrap = styled.div`
  grid-column-end: span 2;
  align-self: center;
  margin-left: 3px;
`;

const CitiesList = styled.ul`
  display: flex;
  align-items: center;
  margin: 10px 0;
  gap: 12px;

  li + li {
    margin-top: 0 !important;
  }
`;

const CitiesListItem = styled.li`
  color: ${props => props.color};
  font-size: 12px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;
`;

const DeliveryTypeContent = styled.div``;

const DeliveryInfoWrap = styled.div`
  margin-bottom: 10px;
`;

const Img = styled.img`
  width: auto;
  height: auto;
  margin-right: 5px;
`;

const ButtonsWrap = styled.div``;

NovaPoshta.propTypes = {
  data: PropTypes.exact({
    delivery_filial: PropTypes.string,
    delivery_info: PropTypes.exact({
      city_ref: PropTypes.string,
      city_title: PropTypes.string,
      date: PropTypes.string,
      department_ref: PropTypes.string,
      department_title: PropTypes.string,
      elevator: PropTypes.bool,
      flat_num: PropTypes.string,
      floor_num: PropTypes.string,
      npPhone: PropTypes.string,
      post_office_ref: PropTypes.string,
      post_office_title: PropTypes.string,
      street_number: PropTypes.string,
      street_title: PropTypes.string,
      time: PropTypes.string,
      type: PropTypes.string,
    }),
    delivery_type: PropTypes.string,
    discount: PropTypes.string,
    dont_call: PropTypes.bool,
    id: PropTypes.number,
    its_drop: PropTypes.bool,
    middle_name: PropTypes.string,
    name: PropTypes.string,
    payment_type: PropTypes.string,
    phone: PropTypes.string,
    surname: PropTypes.string,
  }),
  setData: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  deliveryType: PropTypes.string.isRequired,
  error: PropTypes.object,
};
