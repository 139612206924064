export const staticTimeSelectData = t => [
  {
    id: 1,
    name: t('calculation_fields.week'),
  },
  {
    id: 2,
    name: t('calculation_fields.day'),
  },
  {
    id: 3,
    name: t('calculation_fields.hour'),
  },
];

export const staticDiscountSelectData = t => [
  {
    id: 1,
    name: t('calculation_fields.percentages'),
  },
  {
    id: 2,
    name: t('calculation_fields.uah'),
  },
];

export const staticNotificationsSwitchData = t => [
  {
    id: 1,
    key: 'offer_accepted',
    label: t('offer_details_fields.offer_accepted'),
  },
  {
    id: 2,
    key: 'offer_declined',
    label: t('offer_details_fields.offer_declined'),
  },
  {
    id: 3,
    key: 'offer_open',
    label: t('offer_details_fields.offer_open'),
  },
  {
    id: 4,
    key: 'offer_expired',
    label: t('offer_details_fields.offer_expired'),
  },
];
