import React from 'react';
import { CircleStencil } from 'react-advanced-cropper';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import { AvatarManager, ImgPrev, LabelProduct } from '@root/components';
import {
  BasicSelect,
  ButtonBase,
  IconBtn,
  OutlineButtonBase,
  TextArea,
  TextInput,
} from '@root/ui';

import commercialOfferSelectors from '@redux/commercial-offer/commercial-offer-selectors';

import { Typography, useTheme } from '@mui/material';

export const ProductOrService = ({
  modalContent,
  data,
  error,
  productDetails,
  handleSetImage,
  handleQuantity,
  onChange,
  onSubmit,
  handleClose,
  handleSelect,
  selectedVal,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation('commercial_offer', { useSuspense: false });
  const { type, picture, name, model, price, quantity, description, warranty } =
    data;
  const customProductsList = useSelector(
    commercialOfferSelectors.getCustomProductsList,
  );
  const customServicesList = useSelector(
    commercialOfferSelectors.getCustomServicesList,
  );

  const baseType = modalContent === 'base';
  const customType = modalContent === 'custom';
  const serviceType = modalContent === 'service';

  return (
    <>
      {productDetails.type === 'new' && (
        <BasicSelect
          sx={{
            marginBottom: '12px',
          }}
          currentId={selectedVal}
          data={customType ? customProductsList : customServicesList}
          callBack={handleSelect}
        />
      )}
      {!baseType && (
        <Typography
          variant="bodyBoldSmall"
          color={color.gray}
          component="p"
          sx={{ marginBottom: '12px' }}
        >
          {t(`info_about_${customType ? 'product' : 'service'}`)}
        </Typography>
      )}
      <Form onSubmit={onSubmit}>
        {customType ? (
          <AvatarManager
            img={picture}
            handleSetImage={handleSetImage}
            stencilVariant={CircleStencil}
            outputImageOptions={{ width: 100, height: 100 }}
            title={t('product_picture')}
            styles={{ background: color.background, margin: '0' }}
          />
        ) : baseType ? (
          <FieldWrap borderBottom={`1px solid ${color.line_light}`}>
            <ImgPrev src={picture} />
            <LabelProduct label={model} />
          </FieldWrap>
        ) : (
          ''
        )}
        {!baseType && (
          <TextInput
            label={t(
              `custom_position_fields.${
                serviceType ? 'service_name_label' : 'name_label'
              }`,
            )}
            placeholder={t(
              `custom_position_fields.${
                serviceType ? 'service_name_placeholder' : 'name_placeholder'
              }`,
            )}
            required
            max={100}
            value={name}
            onChange={onChange('name')}
            error={error?.name?.init}
            errorText={error?.name?.text}
            sx={{ height: '36px' }}
          />
        )}
        {!serviceType && !baseType && (
          <TextInput
            label={t('custom_position_fields.model_label')}
            placeholder={t('custom_position_fields.model_placeholder')}
            required
            max={100}
            value={model}
            onChange={onChange('model')}
            error={error?.model?.init}
            errorText={error?.model?.text}
            sx={{ height: '36px' }}
          />
        )}
        <FieldWrap margin={error?.price?.init ? '0 0 20px 0' : 'initial'}>
          <TextInput
            label={t(
              `custom_position_fields.${
                serviceType ? 'service_price_label' : 'price_label'
              }`,
            )}
            placeholder={t('custom_position_fields.price_placeholder')}
            required
            digitsAndDotOnly
            value={price}
            onChange={onChange('price')}
            error={error?.price?.init}
            errorText={error?.price?.text}
            sx={{ height: '36px' }}
          />
          <TextInput
            sx={{
              input: {
                '-webkit-text-fill-color': `${color.gray} !important`,
              },
              height: '36px',
            }}
            value={t('details.currency')}
            disabled
          />
        </FieldWrap>
        {!baseType && (
          <TextArea
            label={t(
              `custom_position_fields.${
                serviceType ? 'service_description_label' : 'description_label'
              }`,
            )}
            placeholder={t('custom_position_fields.description_placeholder')}
            required
            max={500}
            value={description}
            onChange={onChange('description')}
            error={error?.description?.init}
            errorText={error?.description?.text}
          />
        )}
        {!serviceType && (
          <TextInput
            label={t('custom_position_fields.quantity_label')}
            onChange={onChange('quantity', 'quantity')}
            value={quantity}
            digitsOnly
            sx={{
              input: {
                '-webkit-text-fill-color': `${color.gray} !important`,
                textAlign: 'center',
              },
              padding: '0 10px 0',
              height: '36px',
            }}
            startAdornment={
              <IconBtn
                disabled={Number(quantity) === 1}
                onClick={e => handleQuantity('minus', e)}
                disableBorder
                size={12}
                href={`${Sprite}#icon-minus`}
              />
            }
            endAdornment={
              <IconBtn
                onClick={e => handleQuantity('plus', e)}
                disableBorder
                size={16}
                href={`${Sprite}#icon-plus`}
              />
            }
          />
        )}
        <TextInput
          label={t('custom_position_fields.warranty_label')}
          placeholder={t('custom_position_fields.warranty_placeholder')}
          required
          digitsOnly
          value={warranty}
          onChange={onChange('warranty')}
          error={error?.warranty?.init}
          errorText={error?.warranty?.text}
          sx={{ height: '36px' }}
          endAdornment={
            <Typography color={color.gray} variant="bodyMedium">
              {t('details.month')}
            </Typography>
          }
        />
        {baseType && (
          <TotalWrap color={color}>
            <LabelProduct
              onlyCO={true}
              payment={{
                price: price,
                quantity: quantity,
              }}
            />
            <Typography color={color.gray} variant="bodyBoldMedium">
              {(Number(price) * Number(quantity)).toFixed(2)}{' '}
              {t('details.currency')}
            </Typography>
          </TotalWrap>
        )}
        <FormActionsBtns>
          <OutlineButtonBase onClick={handleClose} sx={{ height: '100%' }}>
            <Typography variant="bodyBoldSmall">
              {t('buttons.declined')}
            </Typography>
          </OutlineButtonBase>
          <ButtonBase type="submit" sx={{ height: '100%' }}>
            <Typography variant="bodyBoldSmall">
              {t(`buttons.${productDetails.type === 'new' ? 'add' : 'edit'}`)}
            </Typography>
          </ButtonBase>
        </FormActionsBtns>
      </Form>
    </>
  );
};

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const FormActionsBtns = styled.div`
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
`;

const FieldWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  margin: ${({ margin }) => (margin ? margin : 'initial')};
  border-bottom: ${({ borderBottom }) =>
    borderBottom ? borderBottom : 'none'};

  & label {
    &:nth-child(1) {
      flex: 70%;

      p {
        position: absolute;
      }
    }

    &:nth-child(2) {
      flex: 30%;
      align-self: end;
    }
  }
`;

const TotalWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  border-top: 1px solid ${({ color }) => color.line_light};
  padding-top: 5px;
  margin-top: 8px;
`;

ProductOrService.propTypes = {
  modalContent: PropTypes.string,
  data: PropTypes.shape({
    company_id: PropTypes.string,
    order_calculation: PropTypes.string,
    proposition_count: PropTypes.string,
    proposition_time: PropTypes.number,
    give_discount: PropTypes.bool,
    discount: PropTypes.string,
    discount_option: PropTypes.string,
    offer_name: PropTypes.string,
    offer_text: PropTypes.string,
    additional_info: PropTypes.string,
    hide_model: PropTypes.bool,
    hide_product_description: PropTypes.bool,
    offer_accepted: PropTypes.bool,
    offer_declined: PropTypes.bool,
    offer_open: PropTypes.bool,
    offer_expired: PropTypes.bool,
  }),
  error: PropTypes.object,
  productDetails: PropTypes.shape({
    type: PropTypes.string,
    id: PropTypes.number,
  }),
  handleSetImage: PropTypes.func.isRequired,
  handleQuantity: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
};
