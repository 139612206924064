import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  List,
} from 'react-virtualized';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty, Header, VirtualizedTable } from '@root/components';
import { OrderServiceConf } from '@root/conf/OrderServiceConf';
import { LabelStatus, ProgressLoader } from '@root/ui';

import orderServiceSelectors from '@redux/order-service/order-service-selectors';
import { setSearchValue } from '@redux/order-service/order-service-slice';

import { TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

export const OrderServiceHistory = ({
  handleFetchServiceOrders,
  handleSelectServiceOrder,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [activeRow, setActiveRow] = useState();

  const { t } = useTranslation(['order-service'], { useSuspense: false });

  const { color } = useTheme();
  const dispatch = useDispatch();

  const filteredServiceOrders = useSelector(
    orderServiceSelectors.getFilteredServiceOrders,
  );
  const isServiceOrdersLoading = useSelector(
    orderServiceSelectors.getIsServiceOrdersLoading,
  );
  const searchTerm = useSelector(orderServiceSelectors.getSearchValue);

  const cache = useRef(
    new CellMeasurerCache({
      fixedWidth: true,
      defaultHeight: 50,
    }),
  );

  const handleSetActiveRow = orderService => {
    const { number } = orderService;
    setActiveRow(number);
    handleSelectServiceOrder(orderService);
  };

  const handleToggleIsExpanded = () => {
    setIsExpanded(prevIsExpanded => !prevIsExpanded);
  };

  return (
    <OrderServiceHistoryWrapper color={color.line}>
      <Header
        isTopBorder={false}
        isExpanded={isExpanded}
        handleFetchDeliveryHistory={handleFetchServiceOrders}
        handleToggleSearchExpended={handleToggleIsExpanded}
        onSearchTermUpdate={searchTerm => dispatch(setSearchValue(searchTerm))}
      />

      <div
        style={{
          position: 'relative',
          height: 'calc(100vh - 238px)',
        }}
      >
        {isServiceOrdersLoading && <ProgressLoader />}
        {filteredServiceOrders.length > 0 && (
          <VirtualizedTable
            checkIsInclude={false}
            isSearchExpanded={isExpanded}
            tableConf={OrderServiceConf(t)}
          >
            <TableBody
              sx={{
                display: 'block',
                height: 'calc(100vh - 272px)',
              }}
            >
              <ScrollWrapper
                colors={{
                  gray: color.gray_40,
                  white: color.white,
                  line: color.line,
                }}
              >
                <AutoSizer>
                  {({ width, height }) => (
                    <List
                      width={width}
                      height={height}
                      rowHeight={cache.current.rowHeight}
                      defferedMeasurementCache={cache.current}
                      rowCount={filteredServiceOrders.length}
                      rowRenderer={({ key, index, style, parent }) => {
                        const data = filteredServiceOrders[index];

                        return (
                          <CellMeasurer
                            key={key}
                            cache={cache.current}
                            parent={parent}
                            columnIndex={0}
                            rowIndex={index}
                          >
                            <TableRow
                              sx={{
                                cursor: 'pointer',
                                borderBottom: `1px solid ${color.line}`,
                                userSelect: 'auto',
                                boxShadow: 'none',
                                td: {
                                  borderBottom: 'none',
                                },
                                display: 'flex',
                                alignItems: 'center',
                                padding: '7px 16px',
                                '&:last-child': {
                                  borderBottom: 'none',
                                },
                                background:
                                  activeRow === data.number
                                    ? color.main_light
                                    : color.white,
                                borderLeft:
                                  activeRow === data.number
                                    ? `3px solid ${color.main}`
                                    : 'none',

                                '&:hover': {
                                  background:
                                    activeRow !== data.number && color.gray_20,
                                },
                              }}
                              onClick={() => handleSetActiveRow(data)}
                              style={style}
                            >
                              {OrderServiceConf(t).map(orderService => {
                                switch (orderService.slug) {
                                  case 'date':
                                    return (
                                      <TableCell
                                        key={orderService.slug + key}
                                        sx={{
                                          padding: '0',
                                          ...orderService.style,
                                        }}
                                      >
                                        <Typography
                                          variant="bodySmall"
                                          color={color.gray_80}
                                        >
                                          {data.date}
                                        </Typography>
                                      </TableCell>
                                    );
                                  case 'order_number':
                                    return (
                                      <TableCell
                                        key={orderService.slug + key}
                                        sx={{
                                          padding: '0',
                                          ...orderService.style,
                                        }}
                                      >
                                        <Typography
                                          variant="bodyBoldSmall"
                                          color={color.gray}
                                        >
                                          {data.number}
                                        </Typography>
                                      </TableCell>
                                    );
                                  case 'device':
                                    return (
                                      <TableCell
                                        key={orderService.slug + key}
                                        sx={{
                                          padding: '0',
                                          ...orderService.style,
                                        }}
                                      >
                                        <Typography
                                          variant="bodySmall"
                                          color={color.gray}
                                        >
                                          {data.model}
                                        </Typography>
                                      </TableCell>
                                    );
                                  case 'serial_number':
                                    return (
                                      <TableCell
                                        key={orderService.slug + key}
                                        sx={{
                                          padding: '0',
                                          ...orderService.style,
                                        }}
                                      >
                                        <Typography
                                          variant="bodySmall"
                                          color={color.gray}
                                        >
                                          {data.serial}
                                        </Typography>
                                      </TableCell>
                                    );
                                  case 'warranty_payments':
                                    return (
                                      <TableCell
                                        key={orderService.slug + key}
                                        sx={{
                                          padding: '0',
                                          ...orderService.style,
                                        }}
                                      >
                                        <Typography
                                          variant="bodyBoldExSmall"
                                          color={color.gray}
                                          sx={{
                                            display: 'flex',
                                            alignItems: 'center',
                                          }}
                                        >
                                          {data.type}
                                        </Typography>
                                      </TableCell>
                                    );
                                  case 'status':
                                    return (
                                      <TableCell
                                        key={orderService.slug + key}
                                        sx={{
                                          padding: '0',
                                          ...orderService.style,
                                        }}
                                      >
                                        <LabelStatus>
                                          <Typography
                                            variant="bodyBoldExSmall"
                                            color={`${color.gray_80} !important`}
                                          >
                                            {data.status}
                                          </Typography>
                                        </LabelStatus>
                                      </TableCell>
                                    );
                                }
                              })}
                            </TableRow>
                          </CellMeasurer>
                        );
                      }}
                    />
                  )}
                </AutoSizer>
              </ScrollWrapper>
            </TableBody>
          </VirtualizedTable>
        )}

        {searchTerm === '' && filteredServiceOrders.length === 0 && (
          <Empty type={'NoHistory'} />
        )}

        {searchTerm !== '' && filteredServiceOrders.length === 0 && (
          <Empty type={'EmptySearchLogo'} />
        )}
      </div>
    </OrderServiceHistoryWrapper>
  );
};

OrderServiceHistory.propTypes = {
  handleFetchServiceOrders: PropTypes.func,
  handleSelectServiceOrder: PropTypes.func,
};

const OrderServiceHistoryWrapper = styled.div`
  background: #ffffff;
  border-radius: 4px;
  width: 60%;
  border: 1px solid ${props => props.color};
`;

const ScrollWrapper = styled.div`
  overflow: hidden;
  height: inherit;
  max-height: inherit;

  div {
    ::-webkit-scrollbar {
      width: 5px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${props => props.colors.gray};
      border-radius: 4px;
    }

    ::-webkit-scrollbar-track {
      background: ${props => props.colors.white};
      border-left: 1px solid ${props => props.colors.line};
    }
  }
`;
