import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import moment from 'moment';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty } from '@root/components';
import {
  ButtonBase,
  CheckboxBase,
  CustomScroll,
  DatePickerCustom,
  RangeInput,
  TextInput,
} from '@root/ui';

import optionSelectors from '@redux/option/option-selectors';
import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const FilterList = ({
  filterActive,
  setFilterActive,
  handelSubmitFilter,
  filterClose,
}) => {
  const [data, setData] = useState({
    amount: [],
    period: [],
  });
  const { orderTab } = useParams();
  const { t } = useTranslation(['order_history', 'common'], {
    useSuspense: false,
  });
  const { color } = useTheme();
  const filters = useSelector(optionSelectors.getHistoryFilters);
  const permission = useSelector(userSelectors.getPermissions);
  const { filter, amount, period } = filters;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      amount &&
      filterActive.amount.length === 0 &&
      filterActive.period.length === 0
    ) {
      setData({
        ...filterActive,
        amount:
          typeof amount?.options?.min === 'number' && amount?.options?.max
            ? [amount?.options?.min, amount?.options?.max]
            : [],
        period: [
          moment(period?.options?.min).format('YYYY-MM-DD'),
          moment(period?.options?.max).format('YYYY-MM-DD'),
        ],
      });
    } else if (amount && filterActive.amount.length === 0) {
      setData({
        ...filterActive,
        amount: [amount?.options?.min, amount?.options?.max],
      });
    } else if (amount && filterActive.period.length === 0) {
      setData({
        ...filterActive,
        period: [
          moment(period?.options?.min).format('YYYY-MM-DD'),
          moment(period?.options?.max).format('YYYY-MM-DD'),
        ],
      });
    } else if (amount) {
      setData({
        ...filterActive,
      });
    }
    setLoading(true);
  }, [amount?.options?.max, amount?.options?.min, filterActive]);

  const onChangeCheckBox = (name, childrenName) => value => {
    let findArr = data[name];
    if (findArr) {
      if (findArr.includes(childrenName)) {
        setData({
          ...data,
          [name]: findArr.filter(el => el !== childrenName),
        });
      } else {
        setData({
          ...data,
          [name]: [...data[name], childrenName],
        });
      }
    } else {
      setData({
        [name]: [childrenName],
        ...data,
      });
    }
  };

  const getList = (item, i) => {
    const { title, key, options } = item;

    return (
      <Box marginTop={i === 0 ? 0 : 24}>
        <Typography
          variant={'bodyBoldSmall'}
          component={'p'}
          color={color.gray}
          sx={{
            letterSpacing: '0.02em',
            textTransform: 'uppercase',
          }}
        >
          {title}
        </Typography>
        <WrapperCheckBox>
          {options.map(el => (
            <CheckboxBase
              key={el.key + el.title}
              el={el.key}
              width={16}
              onChange={onChangeCheckBox(key, el.key)}
              checked={data[key]?.includes(el.key)}
              widthLabel={'180px'}
              disable={el.is_disabled}
            >
              <Typography variant={'bodyMedium'} sx={{ ml: '8px' }}>
                {el.title}
              </Typography>
            </CheckboxBase>
          ))}{' '}
        </WrapperCheckBox>
      </Box>
    );
  };

  const onChange = (name, key) => (value, newRangeValue) => {
    if (newRangeValue) {
      setData(prevState => ({ ...prevState, [name]: newRangeValue }));
    } else {
      let newValue;
      if (name === 'amount') {
        newValue =
          key === 'min'
            ? [Number(value), data[name][1]]
            : [data[name][0], Number(value)];
      } else {
        newValue =
          key === 'start' ? [value, data[name][1]] : [data[name][0], value];
      }

      setData(prevState => ({
        ...prevState,
        [name]: newValue,
      }));
    }
  };

  const handelResetFilter = () => {
    let newFilterActive = {
      amount: [],
      period: [],
    };
    setData(newFilterActive);
    setFilterActive(newFilterActive);
    handelSubmitFilter(newFilterActive);
    filterClose();
  };
  const canRender = Object.keys(filters).length > 0 && data.amount.length > 0;

  const isShowAmount =
    orderTab === 'my-orders'
      ? permission.orders_history_own_prices_access
      : orderTab === 'archive-orders'
      ? permission.orders_history_archives_prices_access
      : true;

  return (
    <Wrapper color={color}>
      {loading ? (
        canRender ? (
          <>
            {' '}
            <Header>
              <Typography
                sx={{
                  letterSpacing: '0.02em',
                  textTransform: 'uppercase',
                }}
                variant={'bodyBoldSmall'}
                component={'h3'}
                color={color.gray}
              >
                {t('order_history:filter.title')}
              </Typography>
              <button
                type="button"
                onClick={handelResetFilter}
                style={{ cursor: 'pointer' }}
              >
                <Typography
                  color={color.gray_60}
                  variant={'bodyBoldSmall'}
                  component={'span'}
                >
                  {t('common:buttons.reset')}
                </Typography>
              </button>
            </Header>
            <Inner>
              <CustomScroll vertical={true} padding="20px">
                {filter.map((el, i) => (
                  <Fragment key={el.key}>{getList(el, i)}</Fragment>
                ))}
                <Box marginTop={24}>
                  <Typography
                    variant={'bodyBoldSmall'}
                    component={'p'}
                    color={color.gray}
                    sx={{
                      letterSpacing: '0.02em',
                      textTransform: 'uppercase',
                    }}
                  >
                    {period?.options?.title}
                  </Typography>
                  <WrapperContent>
                    <After color={color}>
                      <DatePickerCustom
                        onChange={onChange('period', 'start')}
                        value={data.period[0]}
                        placeholder={'ДД/ММ/РРРРР'}
                        minDate={false}
                        maxDate={false}
                        minValue={new Date(period?.options?.min)}
                        maxValue={new Date(period?.options?.max)}
                      />
                    </After>
                    <Before>
                      <DatePickerCustom
                        onChange={onChange('period', 'end')}
                        value={data.period[1]}
                        placeholder={'ДД/ММ/РРРРР'}
                        minDate={false}
                        maxDate={false}
                        minValue={new Date(data.period[0])}
                        maxValue={new Date(period?.options?.max)}
                      />
                    </Before>
                  </WrapperContent>
                </Box>
                {isShowAmount && (
                  <Box marginTop={24}>
                    <Typography
                      variant={'bodyBoldSmall'}
                      component={'p'}
                      color={color.gray}
                      sx={{
                        letterSpacing: '0.02em',
                        textTransform: 'uppercase',
                      }}
                    >
                      {amount?.title}
                    </Typography>

                    <Box>
                      <WrapperContent>
                        <After color={color}>
                          <TextInput
                            value={data.amount[0]}
                            withOutSpace={true}
                            onChange={onChange(`amount`, 'min')}
                            type="text"
                            max={255}
                            styleWrapper={{ width: '100%' }}
                          />
                        </After>
                        <Before>
                          <TextInput
                            value={data.amount[1]}
                            withOutSpace={true}
                            onChange={onChange(`amount`, 'max')}
                            type="text"
                            max={255}
                            styleWrapper={{ width: '100%' }}
                          />
                        </Before>
                      </WrapperContent>

                      <Box marginTop={10}>
                        <RangeInput
                          value={data.amount}
                          onChange={onChange(`amount`)}
                          min={amount?.options?.min}
                          max={amount?.options?.max}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}
                <ButtonBase
                  onClick={() => {
                    handelSubmitFilter(data);
                    setFilterActive(data);
                    filterClose();
                  }}
                  sx={{
                    height: '38px',
                    ml: 'auto',
                    mt: '10px',
                    display: 'block',
                  }}
                >
                  {t('common:buttons.apply')}
                </ButtonBase>
              </CustomScroll>
            </Inner>
          </>
        ) : (
          <Empty
            type={'EmptySearchLogo'}
            text={t('order_history:filter_empty')}
          />
        )
      ) : (
        <></>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ color }) => color.white};
  border: 1px solid ${({ color }) => color.line};
  position: absolute;
  top: 97px;
  left: 0;
  border-radius: 4px;
  min-height: 300px;
  z-index: 1299;
`;

const Header = styled.div`
  display: flex;

  align-items: center;
  justify-content: space-between;
  margin-bottom: 4px;
  padding: 20px;
`;

const Box = styled.div`
  margin-top: ${({ marginTop }) => marginTop}px;
`;

const WrapperCheckBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
`;

const WrapperContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const After = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-right: 26px;
  flex: 1;

  &::after {
    position: absolute;
    content: ' ';
    width: 10px;
    height: 1px;
    background-color: ${({ color }) => color.gray};
    display: block;

    top: 22px;
    right: -14px;
    transform: translate(50%, 0);
  }
`;

const Before = styled.div`
  flex: 1;
`;

const Inner = styled.div`
  height: calc(100vh - 340px);
`;

FilterList.propTypes = {
  filterActive: PropTypes.exact({
    amount: PropTypes.arrayOf(PropTypes.number),
    period: PropTypes.arrayOf(PropTypes.string),
  }),
  setFilterActive: PropTypes.func.isRequired,
  handelSubmitFilter: PropTypes.func.isRequired,
  filterClose: PropTypes.func.isRequired,
};
