import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { StatusWithoutSteps, StepperStatusWithIcon } from '@root/components';
import { correctPhone } from '@root/helpers';

import userSelectors from '@redux/user/user-selectors';

import { Typography, useTheme } from '@mui/material';

export const MyOrdersDetails = ({ data, activeTab }) => {
  const { color } = useTheme();
  const { t } = useTranslation(['order_history'], {
    useSuspense: false,
  });
  const permission = useSelector(userSelectors.getPermissions);
  const { orderTab } = useParams();

  const getStatus = () => {
    switch (orderTab ? orderTab : activeTab) {
      case 'archive-orders':
        return <StatusWithoutSteps data={data} />;

      default:
        break;
    }
  };

  const isShowAmount =
    orderTab === 'my-orders'
      ? permission.orders_history_own_prices_access
      : permission.orders_history_archives_prices_access;

  return (
    <Wrapper
      color={color.line}
      padding={'31px 7px 19px 24px'}
      switchDirection={true}
    >
      <DeliveryWrapper>
        {isShowAmount && (
          <PayInfo>
            <Typography
              variant={'bodyBoldSmall'}
              component={'p'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '10px',
                color: color.gray_60,
              }}
            >
              {t('order_history:payment')}
            </Typography>

            <Typography
              variant={'h2'}
              component={'p'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '4px',
                color: color.gray,
              }}
              data-test-id="HistoryOrderAmount"
            >
              {data?.amount} {data?.currency}
            </Typography>

            <Typography
              variant={'bodyBoldMedium'}
              component={'p'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                color: color.gray,
              }}
            >
              {data?.payment_title}
            </Typography>
            {getStatus()}
          </PayInfo>
        )}
        <DeliveryInfo>
          <Typography
            variant={'bodyBoldSmall'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: '10px',
              color: color.gray_60,
            }}
          >
            {t('order_history:delivery_address')}
          </Typography>

          <Typography
            variant={'bodyBoldMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray,
              maxWidth: '268px',
            }}
            data-test-id="HistoryOrderName"
          >
            {data?.last_name} {data?.first_name} {data?.middle_name}
          </Typography>

          <Typography
            variant={'bodyMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              color: color.gray_80,
            }}
            data-test-id="HistoryOrderPhone"
          >
            {correctPhone(data?.phone)}
          </Typography>

          <Typography
            variant={'bodyBoldMedium'}
            component={'p'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mt: '10px',
              color: color.gray,
            }}
          >
            {data?.delivery_title}
          </Typography>

          <Typography
            variant={'bodyMedium'}
            component={'p'}
            sx={{
              color: color.gray_80,
              height: '40px',
              maxWidth: '100%',
            }}
          >
            <EllipsisText
              data-test-id="HistoryOrderDeliveryAddress"
              title={data?.address}
            >
              {data?.address}
            </EllipsisText>
          </Typography>
        </DeliveryInfo>

        <MoreInfo>
          {data?.payment && (
            <>
              <Typography
                variant={'bodyBoldSmall'}
                component={'p'}
                sx={{
                  display: 'flex',

                  alignItems: 'center',
                  mb: '10px',
                  color: color.gray_60,
                }}
              >
                {t('order_history:payment_address')}
              </Typography>{' '}
              <Typography
                variant={'bodyMedium'}
                component={'p'}
                dangerouslySetInnerHTML={{
                  __html: data?.payment,
                }}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  color: color.gray,
                  maxWidth: '268px',
                }}
                data-test-id="HistoryOrderCompanyName"
              />
            </>
          )}
        </MoreInfo>
      </DeliveryWrapper>
      <StatusDelivery>
        {data?.statuses && <StepperStatusWithIcon steps={data?.statuses} />}
      </StatusDelivery>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ switchDirection }) =>
    switchDirection ? 'column' : 'row'};
  justify-content: space-between;

  padding: ${({ padding }) => padding};
  border-bottom: 1px solid ${props => props.color};
`;

const DeliveryWrapper = styled.div`
  display: flex;
  justify-content: start;
  margin-bottom: 28px;
`;

const StatusDelivery = styled.div`
  width: 100%;
  height: 50px;
`;

const PayInfo = styled.div`
  width: 165px;
  flex-shrink: 0;
`;

const DeliveryInfo = styled.div`
  margin-left: 26px;
  margin-right: 60px;
  min-width: 268px;
`;

const MoreInfo = styled.div`
  width: 311px;
  height: 100px;
  flex-shrink: 0;
`;

const Svg = styled.svg`
  fill: ${({ colorChosen }) => colorChosen};
  stroke: ${({ colorChosen }) => colorChosen};
  margin: 0 4px 0 0;
  flex-shrink: 0;
`;

const EllipsisText = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
`;

MyOrdersDetails.prototype = {
  data: PropTypes.object,
  activeTab: PropTypes.string,
};
