import styled from 'styled-components';

import { Skeleton, useTheme } from '@mui/material';

export const NotificationLoader = () => {
  const { color } = useTheme();
  return (
    <NotificationsLoaderWrapper borderColor={color.line}>
      <Skeleton
        variant="text"
        sx={{ fontSize: '12px' }}
        animation="wave"
        width="70%"
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: '10px', marginTop: '0', height: '40px' }}
        animation="wave"
      />
      <Skeleton
        variant="text"
        sx={{ fontSize: '10px' }}
        animation="wave"
        width="35%"
      />
    </NotificationsLoaderWrapper>
  );
};

const NotificationsLoaderWrapper = styled.div`
  padding: 16px 20px;
  border-bottom: 1px solid ${({ borderColor }) => borderColor};
`;
