import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Empty } from '@root/components';
import { ActivityAreas } from '@root/components/ContactField/ActivityAreas';
import { InputField } from '@root/components/ContactField/InputField';
import { Phone } from '@root/components/ContactField/Phone';
import { SwitchField } from '@root/components/ContactField/SwitchField';
import { AvatarUI } from '@root/ui';

import { Typography } from '@mui/material';
import Divider from '@mui/material/Divider';

export const ContactField = ({
  lastStep,
  type = 'field',
  variant = 'bodyLarge',
  title,
  value,
  subValue,
  padding = '12px 0',
  sx,
  onEdit,
  onCancel,
  editValue,
  errors,
  goToStep,
}) => {
  const FIELDS_DESCRIPTION_MAP = {
    phone: (
      <Phone
        editValue={editValue}
        value={value}
        variant={variant}
        lastStep={lastStep}
        sx={sx}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
      />
    ),
    activity_areas: (
      <ActivityAreas
        editValue={editValue}
        value={value}
        variant={variant}
        sx={sx}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
      />
    ),
    field: (
      <InputField
        editValue={editValue}
        sx={sx}
        type={type}
        variant={variant}
        value={value}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
      />
    ),
    switch: (
      <SwitchField
        errors={errors}
        editValue={editValue}
        sx={sx}
        type={type}
        variant={variant}
        value={value}
        onEdit={onEdit}
        onCancel={onCancel}
        goToStep={goToStep}
      />
    ),
  };

  const getHead = type => {
    switch (type) {
      case 'img':
        return (
          <AvatarUI src={value}>
            <Empty
              type={'EmptyLogo'}
              isCompany={false}
              name={subValue}
              noAvatarSx={{
                fontSize: '2rem',
              }}
            />
          </AvatarUI>
        );

      case 'companyLogo':
        return value ? (
          <CompanyLogo src={value} alt="Logo Company" />
        ) : (
          <Empty
            type={'EmptyLogo'}
            isCompany
            tag={'div'}
            wrapperStyles={{
              width: '100%',
              maxWidth: '160px',
              height: '58px',
            }}
            contentStyles={{
              width: '100%',
              height: '100%',
            }}
          />
        );

      default:
        return (
          <Typography
            sx={{ flex: '0 0 130px', marginRight: '40px' }}
            variant={variant}
            component={'p'}
          >
            {title}
          </Typography>
        );
    }
  };

  const fieldDescription = FIELDS_DESCRIPTION_MAP[type];

  return (
    <>
      <Wrapper padding={padding}>
        {getHead(type)}
        {fieldDescription}
      </Wrapper>
      {type === 'area' && (
        <Typography sx={{ mt: '12px' }} variant={variant} component={'p'}>
          {value}
        </Typography>
      )}
      <Divider />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  padding: ${({ padding }) => padding};
  align-items: ${({ align }) => align};
`;

const CompanyLogo = styled.img`
  height: 58px;
`;

ContactField.propTypes = {
  lastStep: PropTypes.bool,
  type: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.array,
  ]),
  subValue: PropTypes.string,
  goToStep: PropTypes.func,
  padding: PropTypes.string,
  sx: PropTypes.object,
};
