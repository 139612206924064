export const settingsExchangeRatesConf = [
  {
    label: '',
    value: 'name',
    type: 'name',
    style: {
      mr: '39px',
      ml: '20px',
      flex: '0 0 90px',
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      columnGap: '20px',
    },
  },
  {
    label: 'Українська',
    value: 'ua',
    type: 'language',
    style: {
      mr: '39px',
      flex: '0 1 159px',
      width: '100%',
    },
  },
  {
    label: 'English',
    value: 'en',
    type: 'language',
    style: {
      mr: '39px',
      flex: '0 1 159px',
      width: '100%',
    },
  },
  {
    label: 'Русский',
    value: 'ru',
    type: 'language',
    style: {
      mr: '39px',
      flex: '0 1 159px',
      width: '100%',
    },
  },
  {
    label: 'Курс',
    value: 'quantity',
    type: 'exchange-rate',
    style: {
      mr: '39px',
      flex: '0 1 105px',
      width: '100%',
    },
  },
  {
    label: 'Відображати',
    value: 'visibility',
    type: 'visibility',
    style: {
      ml: '39px',
      mr: '20px',
      flex: '0 1 140px',
      width: '100%',
      display: 'flex',
      justifyContent: 'flex-end',
    },
  },
];

export const exchangeRatesConf = [
  {
    label: 'Валюта',
    value: 'currency',
    type: 'currency',
    style: {
      mr: '39px',
      ml: '40px',
      width: '100%',
      display: 'flex',
      columnGap: '25px',
    },
  },
  {
    label: 'Курс',
    value: 'exchange_rate',
    type: 'exchange_rate',
    style: {
      mr: '39px',
      width: '100%',
    },
  },
];
