import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { serialize } from 'object-to-formdata';
import styled from 'styled-components';

import { InputLabel } from '@root/components';
import { getErrorStack, handleDispatchFetch, validURL } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  BasicSelect,
  ButtonBase,
  CheckboxBase,
  FileLoader,
  MessageError,
  TextArea,
  TextInput,
} from '@root/ui';

import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import optionOperation from '@redux/option/option-operation';
import optionSelectors from '@redux/option/option-selectors';
import userOperation from '@redux/user/user-operation';
import userSelectors from '@redux/user/user-selectors';

import { Typography } from '@mui/material';

export const CompanyInfo = () => {
  const { t } = useTranslation(
    ['profile', 'validation', 'registration', 'common'],
    { useSuspense: false },
  );

  const dispatch = useDispatch();

  const fileRef = useRef();

  const [profile, setProfile] = useState({});
  const [error, setError] = useState({});

  const activity = useSelector(optionSelectors.getActivityAreas);
  const userCompany = useSelector(userSelectors.getUserCompany);
  const permission = useSelector(userSelectors.getPermissions);
  const { navigation } = useNavigation();
  document.title = t('profile:conf.company_info.title');

  useEffect(() => {
    if (!permission.profile_company_info_access) {
      navigation('/error-403');
      return;
    }
    dispatch(optionOperation.getActivityAreas());
  }, []);

  useEffect(() => {
    setProfile({
      ...userCompany,
      activity_areas: userCompany?.activity_areas
        ? userCompany?.activity_areas.map(activity_area => activity_area.id)
        : [],
    });
  }, [userCompany]);

  const handleOpenNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('profile:notifications.update_data_successfully'),
      }),
    );
  };

  const onChange = (name, subName) => value => {
    const { activity_areas } = profile;
    if (name === 'activity_areas') {
      let dataCopy = activity_areas ? [...activity_areas] : [];
      if (value) {
        dataCopy.push(subName);
      } else {
        dataCopy = dataCopy.filter(id => id !== subName);
      }
      setProfile(prevProfile => ({ ...prevProfile, [name]: dataCopy }));
    } else if (name === 'company_type_id') {
      setProfile(prevState => ({
        ...prevState,
        [name]: value,
        identification_code: value === 2 ? prevState.identification_code : null,
        contractor_code: value === 1 ? prevState.contractor_code : null,
      }));
    } else {
      setProfile(prevProfile => ({ ...prevProfile, [name]: value }));
    }
  };

  const handleSetImage = logo => {
    setProfile(prevProfile => ({
      ...prevProfile,
      is_delete_file: false,
      logo,
    }));
  };

  const handleDeleteImage = () => {
    setProfile(prevProfile => ({
      ...prevProfile,
      is_delete_file: true,
      logo: null,
    }));
  };

  const validation = () => {
    let flag = false;
    let currentError = {};
    const {
      logo,
      official_name,
      company_type_id,
      contractor_code,
      identification_code,
      activity_areas,
      site,
    } = profile;

    if (!official_name || official_name === '') {
      currentError = getErrorStack(
        currentError,
        'official_name',
        t('validation:required'),
      );
      flag = true;
    } else if (official_name.length < 2) {
      currentError = getErrorStack(
        currentError,
        'official_name',
        t(`${t('validation:minLengthLetters')} 2`),
      );
      flag = true;
    }

    if (!company_type_id || company_type_id === '') {
      currentError = getErrorStack(
        currentError,
        'company_type_id',
        t('validation:required'),
      );
      flag = true;
    }

    if (company_type_id === 1) {
      if (!contractor_code || contractor_code === '') {
        currentError = getErrorStack(
          currentError,
          'contractor_code',
          t('validation:required'),
        );
        flag = true;
      } else if (contractor_code.length < 8) {
        currentError = getErrorStack(
          currentError,
          'contractor_code',
          t(`${t('validation:minLengthLetters')} 8`),
        );
        flag = true;
      }
    }

    if (company_type_id === 2) {
      if (
        (!identification_code || identification_code === '') &&
        company_type_id !== ''
      ) {
        currentError = getErrorStack(
          currentError,
          'identification_code',
          t('validation:required'),
        );
        flag = true;
      } else if (identification_code.length < 10) {
        currentError = getErrorStack(
          currentError,
          'identification_code',
          t(`${t('validation:minLengthLetters')} 10`),
        );
        flag = true;
      }
    }

    if (site) {
      if (!validURL(site)) {
        currentError = getErrorStack(
          currentError,
          'site',
          t('validation:enter_valid_url'),
        );
        flag = true;
      }
    }

    if (activity_areas.length === 0) {
      currentError = getErrorStack(
        currentError,
        'activity_areas',
        t('validation:choose_one_of_activity'),
      );
      flag = true;
    }
    if (logo)
      if (logo?.size > 6291456) {
        currentError = getErrorStack(
          currentError,
          'logo',
          t('validation:file_size'),
        );
        flag = true;
      }
    setError(currentError);
    return flag;
  };

  const handleSubmit = async e => {
    e.preventDefault();
    if (validation()) return;
    const { logo, ...restProfile } = profile;
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          userOperation.updateUserCompany({
            formData: serialize(
              {
                ...restProfile,
                logo: typeof logo === 'string' ? null : logo,
              },
              {
                indices: true,
                booleansAsIntegers: true,
              },
            ),
            onResolve,
            onReject,
          }),
        ),
      () => handleOpenNotification(),
    );
  };

  return (
    <>
      <CompanyInfoWrapper>
        <AvatarManagerWrapper>
          <FileLoader
            fileRef={fileRef}
            file={profile.logo}
            setFile={handleSetImage}
            title={t('registration:step_tree.loader.title')}
            handleSetImage={handleDeleteImage}
            isLogo={true}
            initError={error?.logo}
            disabled={!permission.profile_company_info_edit}
          />
        </AvatarManagerWrapper>

        <Form onSubmit={handleSubmit}>
          <TextInput
            value={profile.official_name}
            onChange={onChange('official_name')}
            error={error?.official_name?.init}
            errorText={error?.official_name?.text}
            placeholder={t('registration:field.company_name', { req: '*' })}
            max={255}
            sx={{
              height: '48px',
            }}
            label={t('profile:fields.company_name')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
            disabled={!permission.profile_company_info_edit}
            required
          />
          <BasicSelect
            error={error?.company_type_id?.init}
            helperText={error?.company_type_id?.text}
            currentId={profile.company_type_id}
            data={[
              { id: 1, name: t('registration:option.tov') },
              { id: 2, name: t('registration:option.fop') },
            ]}
            name={t('registration:field.type_of_cooperation', { req: '*' })}
            callBack={onChange('company_type_id')}
            height="48px"
            label={t('profile:fields.type')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
            disabled={!permission.profile_company_info_edit}
            required
          />
          {profile.company_type_id && (
            <>
              {profile.company_type_id === 1 && (
                <TextInput
                  error={error?.contractor_code?.init}
                  errorText={error?.contractor_code?.text}
                  value={profile.contractor_code}
                  onChange={onChange('contractor_code')}
                  placeholder={t(
                    'registration:field.company_registration_number',
                    {
                      req: '*',
                    },
                  )}
                  type="text"
                  max={8}
                  digitsOnly={true}
                  sx={{
                    height: '48px',
                  }}
                  label={t('registration:field.company_registration_number', {
                    req: '',
                  })}
                  labelRowGap={'30px'}
                  labelTextVariant={'bodyMedium'}
                  labelRowDirection={true}
                  errorPositionLeft={'180px'}
                  disabled={!permission.profile_company_info_edit}
                  required
                />
              )}

              {profile.company_type_id === 2 && (
                <TextInput
                  error={error?.identification_code?.init}
                  errorText={error?.identification_code?.text}
                  value={profile.identification_code}
                  onChange={onChange('identification_code')}
                  placeholder={t('registration:field.ipn', { req: '*' })}
                  type="text"
                  max={10}
                  digitsOnly={true}
                  sx={{
                    height: '48px',
                  }}
                  label={t('registration:field.ipn_company')}
                  labelRowGap={'30px'}
                  labelTextVariant={'bodyMedium'}
                  labelRowDirection={true}
                  errorPositionLeft={'180px'}
                  disabled={!permission.profile_company_info_edit}
                  required
                />
              )}
            </>
          )}
          <TextInput
            value={profile.employees_quantity}
            onChange={onChange('employees_quantity')}
            placeholder={t('registration:field.number_of_employees')}
            max={10}
            removeLeadingZero={true}
            digitsOnly
            sx={{
              height: '48px',
            }}
            label={t('registration:field.number_of_employees')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
            disabled={!permission.profile_company_info_edit}
          />

          <TextInput
            value={profile.site}
            onChange={onChange(`site`)}
            placeholder={t('registration:field.site_url')}
            error={error?.site?.init}
            errorText={error?.site?.text}
            max={255}
            sx={{
              height: '48px',
            }}
            label={t('registration:field.site_url')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
            disabled={!permission.profile_company_info_edit}
          />

          <InputWrapper>
            <InputLabel required aligning="flex-start">
              {t('registration:field.type_of_activity')}
            </InputLabel>

            <ActivityAreaWrapper>
              <ActivityAreaGridWrapper>
                {activity &&
                  profile.activity_areas &&
                  activity.map(({ name, id }) => (
                    <WrapperCheckBox key={id}>
                      <CheckboxBase
                        width={24}
                        onChange={onChange('activity_areas', id)}
                        checked={profile?.activity_areas.includes(id)}
                        error={error?.activity_areas?.init}
                        disabled={!permission.profile_company_info_edit}
                      >
                        <Typography variant={'bodyLarge'} sx={{ ml: '8px' }}>
                          {name}
                        </Typography>
                      </CheckboxBase>
                    </WrapperCheckBox>
                  ))}
              </ActivityAreaGridWrapper>

              {error?.activity_areas?.text && (
                <MessageError errorText={error?.activity_areas?.text} />
              )}
            </ActivityAreaWrapper>
          </InputWrapper>

          <TextArea
            error={error?.company?.init}
            errorText={error?.company?.text}
            value={profile?.additional_info}
            onChange={onChange('additional_info')}
            placeholder={t('registration:field.additional_info')}
            type="text"
            max={5000}
            styleWrapper={{
              alignItems: 'flex-start',
            }}
            label={t('registration:field.additional_info')}
            labelRowGap={'30px'}
            labelTextVariant={'bodyMedium'}
            labelRowDirection={true}
            errorPositionLeft={'180px'}
            disabled={!permission.profile_company_info_edit}
          />
          {permission.profile_company_info_edit && (
            <ButtonWrapper>
              <ButtonBase type="submit">{t('common:buttons.save')}</ButtonBase>
            </ButtonWrapper>
          )}
        </Form>
      </CompanyInfoWrapper>
    </>
  );
};

const CompanyInfoWrapper = styled.div``;

const InputWrapper = styled.div`
  display: grid;
  column-gap: 30px;
  grid-template-columns: 150px 1fr;
  // align-items: baseline;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-top: 16px;
  max-width: 606px;
`;

const AvatarManagerWrapper = styled.div`
  max-width: 606px;
`;

const ActivityAreaGridWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 23px;
  max-width: 460px;
`;

const WrapperCheckBox = styled.div`
  display: flex;
  margin-bottom: 16px;
`;

const ActivityAreaWrapper = styled.div``;
