import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  AddOrReplaceCartProducts,
  Empty,
  ModalLiqPay,
  StepperStatusTyne,
} from '@root/components';
import { handleDispatchFetch } from '@root/helpers';
import { useNavigation } from '@root/hooks';
import {
  ButtonBase,
  ButtonWithoutBorder,
  CustomScroll,
  ProgressLoader,
} from '@root/ui';

import commercialOfferOperation from '@redux/commercial-offer/commercial-offer-operation';
import { setOpenNotification } from '@redux/notification-watcher/notification-watcher-slice';
import orderOperation from '@redux/order/order-operation';
import orderSelectors from '@redux/order/order-selectors';
import savedOrdersOperation from '@redux/saved-orders/saved-orders-operation';
import userSelectors from '@redux/user/user-selectors';

import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

import { CommercialOfferDetails } from './CommercialOfferDetails';
import { MyOrdersDetails } from './MyOrdersDetails';
import { TableHistoryOrder } from './TableHistoryOrder';

export const HistoryDetails = ({ isModal = false, activeTab }) => {
  const [openModal, setOpenModal] = useState(false);
  const [openLiqPay, setOpenLiqPay] = useState(false);
  const { color } = useTheme();
  const dispatch = useDispatch();
  const { navigation } = useNavigation();
  const orderHistoryById = useSelector(orderSelectors.getOrderHistoryById);
  const loading = useSelector(orderSelectors.getLoadingHistoryDetails);
  const onlineForm = useSelector(orderSelectors.getOnlineForm);
  const permission = useSelector(userSelectors.getPermissions);

  const { t } = useTranslation(['order_history', 'common'], {
    useSuspense: false,
  });

  const { orderTab } = useParams();

  const isCommercialOffers = orderTab
    ? orderTab === 'my-commercial-offers'
    : activeTab === 'my-commercial-offers';

  const callNotification = () => {
    dispatch(
      setOpenNotification({
        open: true,
        message: t('order_history:add_to_cart_message'),
        action: (
          <>
            <ButtonBase
              onClick={() => navigation('/catalog/cart')}
              sx={{ height: '25px' }}
            >
              {t('common:buttons.view')}
            </ButtonBase>
          </>
        ),
      }),
    );
  };

  const cloneCo = () => {
    dispatch(
      commercialOfferOperation.cloneCommercialOffer({
        id: orderHistoryById.id,
      }),
    ).then(res => {
      navigation(`/commercial-offer?id=${res.payload.id}`);
    });
  };

  const actionBtn = () => {
    const type = orderTab ? orderTab : activeTab;
    switch (type) {
      case 'my-commercial-offers':
        if (!permission.make_commercial_offer_access) return <></>;
        return (
          <ButtonWithoutBorder sx={{ flexShrink: 0 }} onClick={cloneCo}>
            {t('order_history:buttons.repeat_the_offer')}
          </ButtonWithoutBorder>
        );
      case 'archive-orders':
        break;
      default:
        const permissionCart = permission.cart_add_access;
        const permissiomLiqPay = permission.order_liqpay_access;
        const resultCheckIsOrderSaved = checkIsOrderSaved();
        const curentPermision = resultCheckIsOrderSaved
          ? permissionCart
          : onlineForm
          ? permissiomLiqPay
          : permissionCart;
        return (
          curentPermision && (
            <ButtonBase
              onClick={handelActionBtn}
              sx={{
                height: '38px',
              }}
            >
              {resultCheckIsOrderSaved
                ? t('order_history:buttons.add_to_cart')
                : onlineForm
                ? t('order_history:buttons.pay_the_order')
                : t('order_history:buttons.repeat_the_order')}
            </ButtonBase>
          )
        );
    }
  };

  const handelActionBtn = () => {
    if (onlineForm) {
      setOpenLiqPay(true);
    } else if (checkIsOrderSaved()) {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            savedOrdersOperation.addSavedOrderToCart({
              id: orderHistoryById?.id,
              onResolve,
              onReject,
            }),
          ),
        () => callNotification(),
      );
    } else {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            orderOperation.cloneHistoryOrderById({
              id: orderHistoryById?.id,
              onResolve,
              onReject,
            }),
          ),
        data => callNotification(),
        error => {},
      );
    }
  };

  const checkIsOrderSaved = () => orderHistoryById.model === 'saved_cart';

  const titleOrder = () =>
    checkIsOrderSaved() || isCommercialOffers
      ? `${orderHistoryById.title} ( ${orderHistoryById?.quantity} )`
      : `${t('order_history:order')} № ${orderHistoryById?.title} ( ${
          orderHistoryById?.quantity
        } )`;

  const callbackAfterCheckCart = type => {
    if (type === 'notification') {
      handleDispatchFetch(
        ({ onResolve, onReject }) =>
          dispatch(
            commercialOfferOperation.buyCommercialOfferProducts({
              id: orderHistoryById?.id,
              operation: 'add',
              onResolve,
              onReject,
            }),
          ),
        data => callNotification(),
        error => {},
      );
    } else {
      setOpenModal(true);
    }
  };

  const addOrReplaceCartProducts = type => {
    handleDispatchFetch(
      ({ onResolve, onReject }) =>
        dispatch(
          commercialOfferOperation.buyCommercialOfferProducts({
            id: orderHistoryById?.id,
            operation: type === 'add' ? 'add' : 'replace',
            onResolve,
            onReject,
          }),
        ),
      data => {
        setOpenModal(false);
        callNotification();
      },
      error => {},
    );
  };

  const AboutOrderInMemo = () => {
    switch (orderHistoryById.model) {
      case 'saved_cart':
        return '';
      case 'commercial':
        return (
          <CommercialOfferDetails
            data={orderHistoryById}
            callbackAfterCheckCart={callbackAfterCheckCart}
          />
        );
      default:
        return (
          <>
            <MyOrdersDetails data={orderHistoryById} activeTab={activeTab} />
          </>
        );
    }
  };

  return (
    <OrderHistory
      colors={{ line: color.line, white: color.white }}
      width={isModal ? '100%' : '70%'}
    >
      {Object.keys(orderHistoryById).length > 0 ? (
        <>
          <Wrapper color={color.line} padding={'17px 9px 17px 20px'}>
            <Typography
              variant={'h2'}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: '4px',
                color: color.gray,
              }}
              data-test-id="HistoryOrderTitle"
            >
              {titleOrder()}
            </Typography>
            {actionBtn()}
          </Wrapper>
          {orderHistoryById.model !== 'saved_cart' && !isCommercialOffers && (
            <Wrapper color={color.line} padding={'12px 20px'}>
              <Typography
                variant={'bodyBoldSmall'}
                component={'p'}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: '4px',
                  color: color.gray_60,
                }}
              >
                {t('order_history:order_info')}
              </Typography>
              {orderTab === 'my-orders' && (
                <StepperStatusTyne
                  steps={orderHistoryById?.statuses}
                  withSteps={false}
                  boxSx={{ minHeight: 'auto', minWidth: 'fit-contend' }}
                />
              )}
            </Wrapper>
          )}

          <Inner maxHeight={isCommercialOffers ? '245px' : '300px'}>
            <CustomScroll vertical={true}>
              <AboutOrderInMemo />
              <TableHistoryOrder
                data={orderHistoryById?.details}
                currency={orderHistoryById?.currency}
                checkIsOrderSaved={checkIsOrderSaved}
              />
            </CustomScroll>
          </Inner>
        </>
      ) : (
        !loading && (
          <Empty
            type={'EmptyBlank'}
            text={t(
              `order_history:${
                orderTab !== 'my-commercial-offers'
                  ? 'empty_order_history'
                  : 'empty_co_order_history'
              }`,
            )}
          />
        )
      )}
      {loading && <ProgressLoader />}
      {openLiqPay && <ModalLiqPay onlineForm={onlineForm} />}
      {openModal && (
        <AddOrReplaceCartProducts
          open={openModal}
          handleClose={() => setOpenModal(false)}
          handleCallback={addOrReplaceCartProducts}
        />
      )}
    </OrderHistory>
  );
};

const OrderHistory = styled.div`
  width: ${({ width }) => width};
  position: relative;
  border: 1px solid ${props => props.colors.line};
  background: ${props => props.colors.white};
  border-radius: 4px;
  min-width: 884px;
  min-height: 300px;
`;

const EmptyBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
  align-content: center;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${({ switchDirection }) =>
    switchDirection ? 'column' : 'row'};
  justify-content: space-between;

  padding: ${({ padding }) => padding};
  border-bottom: 1px solid ${props => props.color};
`;

const Inner = styled.div`
  max-height: calc(100vh - ${({ maxHeight }) => maxHeight});
`;

HistoryDetails.propTypes = {
  isModal: PropTypes.bool,
};
