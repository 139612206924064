const getHomeConfig = state => state.home.homeConfig;
const getHomeBanner = state => state.home.homeBanner;
const getHomeTopSale = state => state.home.homeTopSale;
const getHomeOrderChart = state => state.home.homeOrderChart;
const getHomeOrderChartLoading = state => state.home.isHomeOrderChartLoading;
const getHomeOrders = state => state.home.homeOrders;
const getHomeOrdersLoading = state => state.home.isHomeOrdersLoading;
const getHomeOrdersMeta = state => state.home.homeOrdersMeta;
const getHomeBuysMostOften = state => state.home.homeBuysMostOften;
const getIsHomeBannerLoading = state => state.home.isHomeBannerLoading;
const getBuysMostOftenLoading = state => state.home.isHomeBuysMostOftenLoading;
const getHomeTopSalleLoading = state => state.home.isHomeTopSalleLoading;
const getHomeCommercialOffersChart = state =>
  state.home.homeCommercialOffersChart;
const getIsHomeCommercialOffersChartLoading = state =>
  state.home.isHomeCommercialOffersChartLoading;
const getLastMonthsOrdersList = state => state.home.lastMonthsOrders;
const getIsLastMonthsOrdersLoading = state =>
  state.home.isLastMonthsOrdersLoading;
const getErrorOneС = state => state.home.errorOneС;

export default {
  getHomeConfig,
  getHomeBanner,
  getHomeTopSale,
  getHomeOrderChart,
  getHomeOrderChartLoading,
  getHomeBuysMostOften,
  getHomeOrders,
  getHomeOrdersLoading,
  getHomeOrdersMeta,
  getIsHomeBannerLoading,
  getBuysMostOftenLoading,
  getHomeTopSalleLoading,
  getHomeCommercialOffersChart,
  getIsHomeCommercialOffersChartLoading,
  getLastMonthsOrdersList,
  getIsLastMonthsOrdersLoading,
  getErrorOneС,
};
