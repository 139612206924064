export const UserAgreementConf = t => {
  return {
    header: [
      {
        text: t('header.0'),
      },
      {
        text: t('header.1'),
      },
      {
        text: t('header.2'),
      },
    ],
    main: [
      {
        listTitle: t('main.0.title'),
        subList: [
          {
            text: t('main.0.list.0'),
          },
          {
            text: t('main.0.list.1'),
          },
          {
            text: t('main.0.list.2'),
          },
          {
            text: t('main.0.list.3.title'),
            subList: [
              {
                text: t('main.0.list.3.list.0'),
              },
              {
                text: t('main.0.list.3.list.1'),
              },
            ],
          },
          {
            text: t('main.0.list.4'),
          },
          {
            text: t('main.0.list.5'),
          },
          {
            text: t('main.0.list.6'),
          },
          {
            text: t('main.0.list.7'),
          },
        ],
      },
      {
        listTitle: t('main.1.title'),
        subList: [
          {
            text: t('main.1.list.0'),
          },
          {
            text: t('main.1.list.1'),
          },
          {
            text: t('main.1.list.2'),
          },
          {
            text: t('main.1.list.3'),
          },
          {
            text: t('main.1.list.4'),
          },
        ],
      },
      {
        listTitle: t('main.2.title'),
        subList: [
          {
            text: t('main.2.list.0'),
          },
          {
            text: t('main.2.list.1'),
          },
          {
            text: t('main.2.list.2'),
          },
          {
            text: t('main.2.list.3'),
          },
          {
            text: t('main.2.list.4'),
          },
          {
            text: t('main.2.list.5'),
          },
        ],
      },
    ],
    footer: [
      {
        text: t('footer.0'),
      },
      {
        text: t('footer.1'),
      },
      {
        text: t('footer.2'),
      },
      {
        text: t('footer.3'),
      },
      {
        text: t('footer.4'),
      },
      {
        text: t('footer.5'),
      },
      {
        text: t('footer.6'),
      },
    ],
  };
};
