import React from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { DashedBorder } from '@root/assets/svg';

import { Typography, useTheme } from '@mui/material';

export const EmptyLogo = ({
  isCompany,
  tag,
  contentStyles,
  wrapperStyles,
  noAvatarSx,
  textColor = '#8094ae',
  name,
  emptyLogoText = true,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['common'], { useSuspense: false });

  return isCompany ? (
    <ContentWrap contentWrapStyles={wrapperStyles} as={tag}>
      <NoLogo contentStyles={contentStyles} color={color.gray_20}>
        {emptyLogoText && (
          <Typography
            variant={'uppercaseBoldSmall'}
            component={'span'}
            sx={{
              color: textColor,
            }}
          >
            {t('common:logo')}
          </Typography>
        )}
      </NoLogo>
    </ContentWrap>
  ) : (
    <Typography
      variant={'bodyBoldLarge'}
      component={'span'}
      sx={{
        ...noAvatarSx,
        color: color.gray,
        textTransform: 'uppercase',
      }}
    >
      {name && name.charAt(0)}
    </Typography>
  );
};

const ContentWrap = styled.div`
  ${props => props.contentWrapStyles}
`;

const NoLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url(${DashedBorder});
  background-color: ${props => props.color};
  border-radius: 4px;
  ${props => props.contentStyles}
`;

EmptyLogo.propTypes = {
  isCompany: PropTypes.bool,
  tag: PropTypes.node,
  contentStyles: PropTypes.object,
  wrapperStyles: PropTypes.object,
  noAvatarSx: PropTypes.object,
  textColor: PropTypes.string,
  name: PropTypes.string,
  emptyLogoText: PropTypes.bool,
};
