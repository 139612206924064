const getPermission = state => state.permission.roles;
const getPermissionConf = state => state.permission.rolesConf;
const getLoading = state => state.permission.loading;
const getLoadingDetails = state => state.permission.loadingDetails;
const getLoadingList = state => state.permission.Loadinglist;
const getDataById = state => state.permission.dataById;

const permissionSelectors = {
  getPermission,
  getLoading,
  getPermissionConf,
  getLoadingDetails,
  getLoadingList,
  getDataById,
};
export default permissionSelectors;
