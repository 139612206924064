import { createSlice } from '@reduxjs/toolkit';

import operation from './permission-operation';

const initialState = {
  loading: true,
  loadingDetails: false,
  Loadinglist: false,
  roles: [],
  dataById: null,
  rolesConf: null,
};

const permissionSlice = createSlice({
  name: 'permission ',
  initialState,
  reducers: {
    onClear: (state, { payload }) => {
      state.dataById = null;
      state.rolesConf = null;
    },
  },
  extraReducers: {
    //====getPermission====//
    [operation.getPermission.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.getPermission.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.roles = payload.data;
    },
    [operation.getPermission.rejected]: (state, { payload }) => {
      state.loading = false;
    },

    //====getPermissionById====//
    [operation.getPermissionById.pending]: (state, action) => {
      state.loadingDetails = true;
    },
    [operation.getPermissionById.fulfilled]: (state, { payload }) => {
      const { data } = payload;

      const dataById = [...data.permissions].reduce((acc, el, idx) => {
        const { code, children, checked } = el;
        acc = { ...acc, [code]: checked };
        if (children) {
          children.forEach(childrenEl => {
            const { code, checked, children: subChildren } = childrenEl;
            acc = { ...acc, [code]: checked };
            if (subChildren) {
              subChildren.forEach(subChildrenEl => {
                const { code, checked } = subChildrenEl;
                acc = { ...acc, [code]: checked };
              });
            }
          });
        }
        return acc;
      }, {});

      state.loadingDetails = false;
      state.rolesConf = data;
      state.dataById = dataById;
    },
    [operation.getPermissionById.rejected]: (state, { payload }) => {
      state.loadingDetails = false;
    },

    //====createNewRole====//
    [operation.createNewRole.pending]: (state, action) => {
      state.loading = true;
    },
    [operation.createNewRole.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.roles = payload.data;
    },
    [operation.createNewRole.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    //====saveRoleById====//
    [operation.saveRoleById.pending]: (state, action) => {
      state.loadingDetails = true;
    },
    [operation.saveRoleById.fulfilled]: (state, { payload }) => {
      state.loadingDetails = false;
    },
    [operation.saveRoleById.rejected]: (state, { payload }) => {
      state.loadingDetails = false;
    },
    //====cloneRoleById====//
    [operation.cloneRoleById.pending]: (state, action) => {
      state.loadingDetails = true;
    },
    [operation.cloneRoleById.fulfilled]: (state, { payload }) => {
      state.loadingDetails = false;
      state.roles = payload.data;
    },
    [operation.cloneRoleById.rejected]: (state, { payload }) => {
      state.loadingDetails = false;
    },

    //====deleteRoleById====//
    [operation.deleteRoleById.pending]: (state, action) => {
      state.loadingDetails = true;
    },
    [operation.deleteRoleById.fulfilled]: (state, { payload }) => {
      state.loadingDetails = false;
      state.roles = payload.data;
    },
    [operation.deleteRoleById.rejected]: (state, { payload }) => {
      state.loadingDetails = false;
    },
  },
});

export const { onClear } = permissionSlice.actions;

export default permissionSlice.reducer;
