import {
  EditableInput,
  Hue,
  Saturation,
} from '../../../../../node_modules/react-color/lib/components/common';
import { CustomPicker } from 'react-color';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonBase, MenuBody } from '@root/ui';

import { useTheme } from '@mui/material';

const Picker = ({
  open,
  onClose,
  onChangeComplete,
  handleSubmit,
  anchorEl,
  ...props
}) => {
  const { color } = useTheme();
  const { t } = useTranslation([], { useSuspense: false });
  const inputStyles = {
    wrap: { display: 'block' },
    input: {
      display: 'block',
      padding: '8px 16px',
      borderRadius: '4px',
      border: `1px solid ${color.line}`,
      background: color.white,
      fontSize: '14px',
    },
  };

  return (
    <>
      {open && (
        <MenuBody
          anchorEl={anchorEl}
          open={open}
          handleClose={onClose}
          sx={{ width: '211px', p: '8px' }}
        >
          <Content>
            <SaturationWrapper>
              <Saturation {...props} onChangeComplete={onChangeComplete} />
            </SaturationWrapper>
            <HueWrapper>
              <Hue
                {...props}
                style={{ borderRadius: '50% ' }}
                onChangeComplete={onChangeComplete}
                pointer={CustomHuePointer}
              />
            </HueWrapper>
            <InputWrapper>
              <EditableInput
                style={inputStyles}
                value={props.color}
                onChange={onChangeComplete}
              />
            </InputWrapper>

            <ButtonBase onClick={handleSubmit} sx={{ width: '100%' }}>
              {t('common:buttons.apply')}
            </ButtonBase>
          </Content>
        </MenuBody>
      )}
    </>
  );
};

const Content = styled.div`
  display: flex;
  flex-direction: column;

  gap: 12px;
  align-items: center;
`;
const HueWrapper = styled.div`
  width: 193px;
  height: 12px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
`;
const CustomHuePointer = styled.div`
  width: 10px;
  height: 10px;
  border: 2px solid #ffffff;
  background-color: transparent;
  border-radius: 50%;
  margin-top: 1px;
`;

const SaturationWrapper = styled.div`
  width: 193px;
  height: 120px;
  position: relative;
  border-radius: 5px;
  overflow: hidden;
`;

const InputWrapper = styled.div`
  position: relative;

  input {
    width: 193px;
  }
`;

export default CustomPicker(Picker);

Picker.propTypes = {
  hsl: PropTypes.shape({
    h: PropTypes.number,
    s: PropTypes.number,
    l: PropTypes.number,
    a: PropTypes.number,
  }),
  hsv: PropTypes.shape({
    h: PropTypes.number,
    s: PropTypes.number,
    v: PropTypes.number,
    a: PropTypes.number,
  }),
  rgb: PropTypes.shape({
    r: PropTypes.number,
    g: PropTypes.number,
    b: PropTypes.number,
    a: PropTypes.number,
  }),
  source: PropTypes.node,
  color: PropTypes.string.isRequired,
  hex: PropTypes.string.isRequired,
  oldHue: PropTypes.number,
  onChange: PropTypes.func,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeComplete: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  anchorEl: PropTypes.object,
};
