import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Sprite } from '@root/assets/svg';
import {
  MenuBody,
  MenuButton,
  MenuItemCustoms,
  MessageError,
  StatusBase,
} from '@root/ui';

import { Typography, useTheme } from '@mui/material';

export const Menu = ({
  menuItems,
  onChange,
  itemName,
  itemValue,
  menuButtonSx,
  itemSx,
  menuBodySx,
  anchorOriginPosition,
  transformOriginPosition,
  type,
  isNotDefaultRole = true,
  isDisplayItem,
  disabled = false,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const { t } = useTranslation();
  const { color } = useTheme();

  const handleOpen = event => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuButtonContent = useMemo(() => {
    switch (type) {
      case 'role':
        return (
          <>
            {isNotDefaultRole ? (
              itemName
            ) : (
              <MessageError errorText={itemName} />
            )}

            <Svg
              width="16px"
              height="16px"
              style={{
                flexShrink: 0,
                transform: 'rotate(-90deg)',
                stroke: !isNotDefaultRole && color.red,
              }}
            >
              <use href={`${Sprite}#icon-arrow-left`} />
            </Svg>
          </>
        );

      case 'status':
        return (
          <>
            <StatusBase
              currentStatus={itemValue}
              statuses={{
                active: {
                  id: 'active',
                  name: t('common:active'),
                  color: color.green,
                },
                inactive: {
                  id: 'inactive',
                  name: t('common:locked'),
                  color: color.red,
                },
                invited: {
                  id: 'invited',
                  name: t('common:invited'),
                  color: color.orange,
                },
              }}
            />
            <Svg
              width="16px"
              height="16px"
              style={{
                flexShrink: 0,
                transform: 'rotate(-90deg)',
              }}
            >
              <use href={`${Sprite}#icon-arrow-left`} />
            </Svg>
          </>
        );

      case 'employee':
        return (
          <Svg width="18" height="18">
            <use href={`${Sprite}#icon-dots`} />
          </Svg>
        );
    }
  }, [type, itemName, itemValue]);

  return (
    <>
      <MenuButton
        handleClick={handleOpen}
        open={open}
        sx={menuButtonSx}
        disabled={disabled}
      >
        {getMenuButtonContent}
      </MenuButton>
      <MenuBody
        sx={menuBodySx}
        anchorEl={anchorEl}
        open={open}
        handleClose={handleClose}
        anchorOriginPosition={anchorOriginPosition}
        transformOriginPosition={transformOriginPosition}
      >
        {menuItems &&
          menuItems.map(
            el =>
              isDisplayItem(el) && (
                <MenuItemCustoms
                  key={el.id}
                  sx={itemSx}
                  handelClick={() => {
                    onChange(el.id, el);
                    handleClose();
                  }}
                >
                  <Typography variant="bodySmall">{el.name}</Typography>
                </MenuItemCustoms>
              ),
          )}
      </MenuBody>
    </>
  );
};

const Svg = styled.svg``;

Menu.propTypes = {
  menuItems: PropTypes.arrayOf(PropTypes.object),
  onChange: PropTypes.func,
  itemName: PropTypes.string,
  itemValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  menuButtonSx: PropTypes.object,
  itemSx: PropTypes.object,
  menuBodySx: PropTypes.object,
  anchorOriginPosition: PropTypes.object,
  transformOriginPosition: PropTypes.object,
  type: PropTypes.string,
  isNotDefaultRole: PropTypes.bool,
  isDisplayItem: PropTypes.func,
};
