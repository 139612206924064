import { useTranslation } from 'react-i18next';

import PropTypes from 'prop-types';
import styled from 'styled-components';

import { ButtonBase, ModalBase, TextInput } from '@root/ui';

import CloseIcon from '@mui/icons-material/Close';
import { IconButton, Typography, useTheme } from '@mui/material';

export const ModalDropShiping = ({
  isOpen,
  setIsOpen,
  setUserKeyValue,
  userKeyValue,
  saveUserApiKey,
}) => {
  const { color } = useTheme();
  const { t } = useTranslation(['placing_order', 'common'], {
    useSuspense: false,
  });
  return (
    <ModalBase
      open={isOpen}
      sx={{
        width: '350px',
        padding: '15px',
      }}
      modalHeader={
        <ModalHeader>
          <Typography
            variant={'bodyBoldLarge'}
            sx={{ color: color.gray }}
            component={'p'}
          >
            {t('placing_order:type_api_key')}
          </Typography>
          <IconButton
            edge="start"
            color={color.gray}
            aria-label="close"
            onClick={() => setIsOpen(false)}
            sx={{
              padding: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </ModalHeader>
      }
      modalBottom={
        <ModalFooter>
          <ButtonBase
            sx={{
              height: '28px',
              lineHeight: 1.5,
              fontWeight: 400,
            }}
            onClick={saveUserApiKey}
          >
            {t('common:buttons.save')}
          </ButtonBase>
        </ModalFooter>
      }
    >
      <ModalContent>
        <TextInput
          onChange={setUserKeyValue}
          value={userKeyValue}
          withOutSpace={true}
          sx={{
            height: '36px',
          }}
        />
      </ModalContent>
    </ModalBase>
  );
};

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalContent = styled.div`
  margin: 15px 0;
`;

const ModalFooter = styled.div`
  text-align: right;
`;

ModalDropShiping.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
  setUserKeyValue: PropTypes.func.isRequired,
  userKeyValue: PropTypes.string.isRequired,
  saveUserApiKey: PropTypes.func.isRequired,
};
