import { createSlice } from '@reduxjs/toolkit';

import operation from '@redux/news-and-events/news-and-events-operation';

const initialState = {
  articlesList: [],
  articlesListMeta: [],
  articleDetails: {},
  loading: false,
  loadingArticlesList: true,
  loadingArticleDetails: false,
};

const newsAndEventsSlice = createSlice({
  name: 'newsAndEvents',
  initialState,
  reducers: {
    clearState(state, { payload }) {
      state.articlesList = initialState.articlesList;
      state.articlesListMeta = initialState.articlesListMeta;
      state.articleDetails = initialState.articleDetails;
      state.loadingArticlesList = initialState.loadingArticlesList;
    },
  },
  extraReducers: {
    // ---- createNews ----- //
    [operation.createNews.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [operation.createNews.fulfilled]: (state, { payload }) => {
      state.articlesList = [payload, ...state.articlesList];
      state.loading = false;
    },
    [operation.createNews.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    // ---- createNews ----- //

    // ---- createEvent ----- //
    [operation.createEvent.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [operation.createEvent.fulfilled]: (state, { payload }) => {
      state.articlesList = [payload, ...state.articlesList];
      state.loading = false;
    },
    [operation.createEvent.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    // ---- createEvent ----- //

    // ---- updateNews ----- //
    [operation.updateNews.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [operation.updateNews.fulfilled]: (state, { payload }) => {
      const copyData = [...state.articlesList];
      const { id } = payload;
      const targetIdx = copyData.findIndex(i => i.id === id);
      state.articlesList = copyData.map((obj, index) => {
        return index === targetIdx ? payload : obj;
      });
      state.articleDetails = payload;
      state.loading = false;
    },
    [operation.updateNews.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    // ---- updateNews ----- //

    // ---- updateEvent ----- //
    [operation.updateEvent.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [operation.updateEvent.fulfilled]: (state, { payload }) => {
      const copyData = [...state.articlesList];
      const { id } = payload;
      const targetIdx = copyData.findIndex(i => i.id === id);
      state.articlesList = copyData.map((obj, index) => {
        return index === targetIdx ? payload : obj;
      });
      state.articleDetails = payload;
      state.loading = false;
    },
    [operation.updateEvent.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    // ---- updateEvent ----- //

    // ---- deleteNews ----- //
    [operation.deleteNews.pending]: (state, { payload }) => {
      state.loadingArticleDetails = true;
    },
    [operation.deleteNews.fulfilled]: (state, { payload }) => {
      state.articlesList = payload.data;
      state.articlesListMeta = payload.meta;
      state.loadingArticleDetails = false;
    },
    [operation.deleteNews.rejected]: (state, { payload }) => {
      state.loadingArticleDetails = false;
    },
    // ---- deleteNews ----- //

    // ---- deleteEvent ----- //
    [operation.deleteEvent.pending]: (state, { payload }) => {
      state.loadingArticleDetails = true;
    },
    [operation.deleteEvent.fulfilled]: (state, { payload }) => {
      state.articlesList = payload.data;
      state.articlesListMeta = payload.meta;
      state.loadingArticleDetails = false;
    },
    [operation.deleteEvent.rejected]: (state, { payload }) => {
      state.loadingArticleDetails = false;
    },
    // ---- deleteEvent ----- //

    // ---- getNewsList ----- //
    [operation.getNewsList.pending]: (state, { payload }) => {
      state.loadingArticlesList = true;
    },
    [operation.getNewsList.fulfilled]: (state, { payload }) => {
      state.articlesList =
        payload.meta.current_page > 1
          ? [...state.articlesList, ...payload.data]
          : payload.data;
      state.articlesListMeta = payload.meta;
      state.loadingArticlesList = false;
    },
    [operation.getNewsList.rejected]: (state, { payload }) => {
      state.loadingArticlesList = false;
    },
    // ---- getNewsList ----- //

    // ---- getEventsList ----- //
    [operation.getEventsList.pending]: (state, { payload }) => {
      state.loadingArticlesList = true;
    },
    [operation.getEventsList.fulfilled]: (state, { payload }) => {
      state.articlesList =
        payload.meta.current_page > 1
          ? [...state.articlesList, ...payload.data]
          : payload.data;
      state.articlesListMeta = payload.meta;
      state.loadingArticlesList = false;
    },
    [operation.getEventsList.rejected]: (state, { payload }) => {
      state.loadingArticlesList = false;
    },
    // ---- getEventsList ----- //

    // ---- getNewsListItemDetails ----- //
    [operation.getNewsListItemDetails.pending]: (state, { payload }) => {
      state.loadingArticleDetails = true;
    },
    [operation.getNewsListItemDetails.fulfilled]: (state, { payload }) => {
      state.articleDetails = payload;
      state.loadingArticleDetails = false;
    },
    [operation.getNewsListItemDetails.rejected]: (state, { payload }) => {
      state.loadingArticleDetails = false;
    },
    // ---- getNewsListItemDetails ----- //

    // ---- getEventsListItemDetails ----- //
    [operation.getEventsListItemDetails.pending]: (state, { payload }) => {
      state.loadingArticleDetails = true;
    },
    [operation.getEventsListItemDetails.fulfilled]: (state, { payload }) => {
      state.articleDetails = payload;
      state.loadingArticleDetails = false;
    },
    [operation.getEventsListItemDetails.rejected]: (state, { payload }) => {
      state.loadingArticleDetails = false;
    },
    // ---- getEventsListItemDetails ----- //

    // ---- eventRegistration ----- //
    [operation.eventRegistration.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [operation.eventRegistration.fulfilled]: (state, { payload }) => {
      state.loading = false;
    },
    [operation.eventRegistration.rejected]: (state, { payload }) => {
      state.loading = false;
    },
    // ---- eventRegistration ----- //
  },
});

export const { clearState } = newsAndEventsSlice.actions;

export default newsAndEventsSlice.reducer;
